
import React from 'react';

import { createBrowserHistory } from 'history';
import { searchParams } from 'src/utils';
import { CircleMode } from 'src/libs';

export const history = createBrowserHistory();

export const routes: {
  [key: string]: {path: string; title: {[key: string]: string}};
} = {
  Dashboard: {
    path: '/dashboard',
    title: {
      index: 'chronos.app.mapsList'
    }
  },
  Instruments: {
    path: '/instruments',
    title: {
      index: 'Карта',
      tmpMap: 'chronos.app.tmpMap'
    }
  },
  AppSettings: {
    path: '/settings',
    title: {
      prefix: 'chronos.app.settings.settingsTitle',
      payments: 'settings.payments',
      chooseTariff: 'chronos.app.chooseTariff',
      addBuilds: 'chronos.app.settings.addBuilds',
      personal: 'settings.personal',
      consultations: 'settings.consultations',
      maps: 'chronos.app.settings.mapsTitle',
      changes: 'settings.changes'
    }
  },
  Consultations: {
    path: '/consultations',
    title: {
      index: 'settings.consultations'
    }
  },
  Meeting: {
    path: '/meeting',
    title: {
      index: 'Митинг'
    }
  },
  Courses: {
    path: '/courses',
    title: {
      index: 'chronos.app.sidebar.events'
    }
  },
};


export function goToMap(id: number, modes?: CircleMode[]): void {
  const _modes = modes
    ?.join(',')
    .replace('prognostics', 'directions')
    .replace('synastry', 'compatibility1');

  const sp = searchParams();

  let share = '';

  if (sp.has('share') && id.toString() === sp.get('id')) {
    share = '&share';
  }

  history.push(`${routes.Instruments.path}?id=${id}${_modes ? `&modes=${_modes}` : ''}${share}`);
}
