import styled from 'styled-components';
import { compatibilityToPartner, getModeMetaData, isCompatibility } from '../utils';
import { CircleMode } from 'src/libs';

export const modeTitles: { [key: string]: {title: string; showTab: boolean} } = {
  compatibility: {
    title: "astro.compatibility",
    showTab: true
  },
  partner1: {
    title: "astro.partner1",
    showTab: true
  },
  partner2: {
    title: "astro.partner2",
    showTab: true
  },
  directions: {
    title: "astro.directions",
    showTab: false
  },
  horar: {
    title: "astro.horar",
    showTab: true
  },
  soul: {
    title: "astro.formulaSoul",
    showTab: true
  },
  natal: {
    title: "astro.natal",
    showTab: true
  },
  transits: {
    title: "astro.transits",
    showTab: false
  },
  solars: {
    title: "astro.solars",
    showTab: false
  },
  prog_natal: {
    title: "astro.progNatal",
    showTab: false
  },
  prog_prog: {
    title: "astro.progProg",
    showTab: false
  },
  // RU
  relocation_natal: {
    title: "chronos.app.settings.relocation",
    showTab: false
  },
  relocation1: {
    title: "chronos.app.settings.relocation",
    showTab: false
  },
  relocation2: {
    title: "chronos.app.settings.relocation",
    showTab: false
  },
  relocation3: {
    title: "chronos.app.settings.relocation",
    showTab: false
  },
  relocation4: {
    title: "chronos.app.settings.relocation",
    showTab: false
  },
  relocation5: {
    title: "chronos.app.settings.relocation",
    showTab: false
  },
  relocation6: {
    title: "chronos.app.settings.relocation",
    showTab: false
  },
  relocation7: {
    title: "chronos.app.settings.relocation",
    showTab: false
  },
  relocation8: {
    title: "chronos.app.settings.relocation",
    showTab: false
  },
  relocation9: {
    title: "chronos.app.settings.relocation",
    showTab: false
  },
};

export const getTabs = (modes: CircleMode[]): CircleMode[] => {
  const tabs: CircleMode[] = [];
  modes.forEach((mode: CircleMode, index) => {
    if (isCompatibility(mode)) {
      const partnerMode = compatibilityToPartner(mode);
      if (modes.includes('syn_natal') && modes.includes(partnerMode)) {
        return;
      }
      if (!tabs.find(tab => tab === 'syn_natal')) {
        tabs.push('syn_natal');
      }
      if (!tabs.find(tab => tab === partnerMode)) {
        tabs.push(partnerMode);
      }
      return;
    }
    if (!getModeMetaData(mode).showTab) return;
    if (!tabs.find(tab => tab === mode)) {
      tabs.push(mode);
    }
  });
  return tabs;
};

export const PrintedHeader = styled.div`
  --color-sea: #16CAB9;

  display: none;
  justify-content: space-between;
  margin: 0 1rem;
  margin-bottom: 1.75rem;

  svg {
    width: 2.25rem;
    height: 2.25rem;
    color: #C3CCCA;
  }

  svg + svg {
    margin-left: 0.225rem;
    color: var(--color-sea);
  }

  .label {
    display: flex;
    align-items: center;
    height: 2.25rem;
    padding: 0 1rem;
    border: 1pt solid var(--color-sea);
    border-radius: 2.438rem;

    font-size: 1.125rem;
    color: var(--color-sea);
  }

  @media print {
    display: flex;
  }
`;

export const PrintedTitle = styled.h1`
  display: none;
  position: relative;
  margin: 0 1rem 0.875rem 1rem !important;
  font-size: 2.25rem;
  color: var(--text-primary);
  font-weight: 500;

  @media print {
    display: block;
  }
`;

export const PrintedFormData = styled.div`
  display: none;
  position: relative;
  margin: 0 1rem;
  margin-bottom: 1rem;

  font-size: 1rem;
  color: var(--text-third);

  @media print {
    display: flex;
  }
`;

export const Prolog = styled.div`
  display: none;
  position: relative;
  align-items: flex-start;
  box-sizing: border-box;
  margin: 0 1rem;
  margin-bottom: 1.313rem;
  margin-top: 1rem;
  font-size: 1.125rem;

  & svg {
    position: absolute;
    width: 1.875rem;
    height: 1.875rem;
    left: -2.5rem;
    color: var(--text-third);
  }

  & .text {
    font-size: 1.125rem;
    color: var(--text-secondary);
    line-height: 1.5;
  }

  @media print {
    display: flex;

    & .text {
      text-indent: 1.7rem;
    }

    & svg {
      width: 1.5rem;
      height: 1.5rem;
      left: -0.2rem;
      top: 0rem;
      vertical-align: baseline;
    }
  }
`;
