import React from 'react';
import styled, { css, keyframes, CSSProperties } from 'styled-components';

import { InfoIcon } from 'src/assets/icons/notifications';

import { CloseIcon } from 'src/assets/icons/system';
import { MessageType } from '../Alert';

export default function Notify({
  type,
  title,
  content,
  buttons,
  style,
  onClose,
  className
}: {
  type: MessageType;
  title: React.ReactNode | string;
  content: React.ReactNode | string;
  buttons: React.ReactNode | Array<React.ReactNode>;
  style?: CSSProperties;
  onClose?(): void;
  className?: string;
}) {
  return (
    <Container type={type} style={style} className={className} onClick={(evt: any) => evt.stopPropagation()}>
      <Controls>
        <InfoIcon width="1.5rem" height="1.5rem" className="icon" />
        <Closer onClick={onClose}><CloseIcon /></Closer>
      </Controls>
      <Title>
        {typeof title === 'string' ? <h3>{title}</h3> : title}
      </Title>
      <Content>{content}</Content>
      <Buttons>{buttons}</Buttons>
    </Container>
  );
}

const animateShow = keyframes`
  from {
    opacity: 0;
    transform: translateX(-1.25rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;
export const Container = styled.figure<{ type: MessageType }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  max-width: 24.5rem;
  min-width: 20rem;
  margin: 0;
  padding: 1.375rem 1.375rem 1.25rem;
  background-color: var(--bg-100);
  box-shadow: 0px 3.2px 7.2px rgba(0, 0, 0, 0.13), 0px 0.6px 1.8px rgba(0, 0, 0, 0.11);
  border-radius: 6px;
  z-index: 777;

  animation: ${animateShow} 0.32s var(--easeOutQuart);

  & button > a {
    color: var(--text-primary);
    text-decoration: none;
  }

  & button:hover {
    opacity: 0.87;
  }

  
  ${props => props.type === 'error' && css`
    & .icon,
    & a {
      color: var(--colors-red);
    }
    button, button:hover {
      background-color: var(--colors-red);
    }
  `}

  ${props => props.type === 'warning' && css`
    & .icon,
    & a {
      color: var(--colors-orange);
    }
    button, button:hover {
      background-color: var(--colors-orange);
    }
  `}

  ${props => props.type === 'info' && css`
    & .icon,
    & a {
      color: var(--colors-blue);
    }
    button, button:hover {
      background-color: var(--colors-blue);
    }
  `}

  ${props => props.type === 'success' && css`
    & .icon,
    & a {
      color: var(--colors-green);
    }
    button, button:hover {
      background-color: var(--colors-green);
    }
  `}
`;

const Controls = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .closer {
    color: var(--text-secondary);
  }
`;

const Closer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: -0.25rem;
  cursor: pointer;

  &:hover {
    opacity: 0.87;
  }

  & svg {
    width: 0.75rem;
    height: 0.75rem;
    color: var(--text-secondary);
  }
`;

const Title = styled.div`
  position: relative;
  margin-top: 1rem;
  font-weight: 600;
  font-size: 1rem;
  color: var(--text-primary);

  & h3 {
    margin: 0;
    font-size: inherit;
  }
`;

const Content = styled.div`
  position: relative;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  line-height: 150%;
  color: var(--text-secondary);
`;

const Buttons = styled.div`
  position: relative;
  margin-top: 1rem;
`;
