import React from 'react';

import { objectsIcons } from 'src/icons';

import { CirclePartProps, Group } from './index';

interface SoulObjectsProps extends CirclePartProps {
  soulObjects: number[];
}

export default class SoulObjects extends React.Component<SoulObjectsProps> {
  private readonly _objects: React.RefObject<SVGGElement>[] = [];

  constructor(props: SoulObjectsProps) {
    super(props);

    for (let i = 0; i < 12; i++) {
      this._objects.push(React.createRef<SVGGElement>());
    }
  }

  shouldComponentUpdate(newProps: SoulObjectsProps): boolean {
    this.update(newProps);
    return newProps.mode !== this.props.mode || !!newProps.isEditor;
  }

  componentDidMount(): void {
    this.update(this.props);
  }

  update = (newProps: SoulObjectsProps) => {
    const { personalization } = newProps;
    const { computedRadius } = newProps;

    for (let i = 0; i < 12; i++) {
      this._objects[i].current?.style.setProperty('display', 'none');
    }

    let objectsRadius = 0;

    objectsRadius = newProps.radius * personalization.circle.radius.zodiacsExternal + computedRadius.zodiacsExternal + 40;

    newProps.soulObjects.map((object, index) => {
      const color = 'var(--icon-primary)';

      const p = newProps.point(objectsRadius, 30 * index + 15);

      const _obj = this._objects[index].current as SVGGElement;
      _obj.style.display = 'block';

      const obj = _obj.firstChild as SVGSVGElement;

      obj.style.color = color;

      obj.setAttribute('x', p.x.toString());
      obj.setAttribute('y', p.y.toString());
    });
  };

  render(): JSX.Element {
    const { props } = this;

    const iconSize = props.radius * props.personalization.circle.objects.iconSize;

    const objects: any[] = [];
    const divisions: any[] = [];
    const strongs: any[] = [];

    for (let id = 0; id < 12; id++) {
      const Icon = objectsIcons[props.soulObjects[id]];

      objects.push(
        <Group
          key={`${props.mode}_${id}`}
          ref={this._objects[id]}
          onClick={() => props.onHelp('objects', id)}
        >
          <svg overflow="visible">

            {/* Icon */}
            <Icon
              // FIXME:
              // @ts-ignore
              x={-iconSize / 2}
              y={-iconSize / 2}
              width={iconSize}
              height={iconSize}
              fill="currentColor"
              stroke="currentColor"
              strokeWidth="0"
            />
          </svg>
        </Group>
      );
    }
    const outRadius = props.radius * props.personalization.circle.radius.external;
    const endRadius = outRadius + 15;

    for (let i = 0; i < 84; i++) {
      const lon = 360 / 84 * i;

      if (lon % 30 !== 0) {
        const endPos = props.point(endRadius, lon);
        const outPos = props.point(outRadius, lon);
        divisions.push(
          <Group
            key={`division${i}`}
          >
            <line
              stroke="var(--circle-border)"
              x1={endPos.x.toString()}
              y1={endPos.y.toString()}
              x2={outPos.x.toString()}
              y2={outPos.y.toString()}
            />
          </Group>
        );
      }
    }

    const getOrbitRadius = (index: number) => {
      const zodiacsInternal = props.radius * props.personalization.circle.radius.zodiacsInternal;
      const internalSoul = props.radius * (props.personalization.circle.radius.internalSoul || 0.1);
      const objectsRadius = zodiacsInternal - internalSoul;
      const cellWidth = objectsRadius / 8;

      return cellWidth * (index + 1) + internalSoul + cellWidth / 2;
    };

    for (let i = 0; i <= 6; i++) {

      const { x, y } = this.props.point(getOrbitRadius(i), 0);

      strongs.push(
        <Group
          key={`strong_${i}`}
        >
          <text
            x={x}
            y={y - 5}
            fill="var(--circle-border)"
          >{6 - i}</text>
        </Group>
      );
    }

    return (
      <>
        {objects}
        {divisions}
        {strongs}
      </>
    );
  }
}
