import { makeAutoObservable } from 'mobx';
import api from 'src/api';
import { ICourse, ICourseInfo } from 'src/libs';

export default class CoursesStore {
  constructor() {
    makeAutoObservable(this);
  }

  private coursesPromise: Promise<ICourse[]> | null = null;
  private coursesInfoByIdPromise: {[key: string]: Promise<ICourseInfo> | undefined} = {};

  getCourses(reload: boolean = false) {
    if(this.coursesPromise && !reload) {
      return this.coursesPromise;
    }
    this.coursesPromise = api.getCourses();
    return this.coursesPromise
      .then((data) => {
        this.courses = data;
        return this.courses;
      })
  }

  getCourseInfo(id: string | number, reload: boolean = false) {
    if(this.coursesInfoByIdPromise[id] && !reload) {
      return this.coursesInfoByIdPromise[id];
    }
    this.coursesInfoByIdPromise[id] = api.getCourse(+id);
    return this.coursesInfoByIdPromise[id]
      ?.then((data) => {
        this.coursesInfoById[id] = data;
        return this.coursesInfoById[id];
      })
  }

  calculateHasCoursees() {
    const target = this.courses && this.courses
      .find(c => c.planId);
    return !!target;
  }

  async init() {
    return await this.getCourses();
    //this.hasCourses = this.calculateHasCoursees();
  }

  courses: ICourse[] | null = null;
  coursesInfoById: {[key: string]: ICourseInfo} = {};
  //hasCourses: boolean = false;
}