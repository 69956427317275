import dayjs from 'dayjs';
import { IFormData, fillNumber, makeForm } from 'src/libs';

import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { degFromString } from 'src/store/coordinateUtils';
import { readFile } from 'src/store/readFile';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const getGMTFromString = (gmt: string): number => {
  try {
    const splitted = gmt.split(':');
    return Number(splitted[0]) + Number(splitted[1]) / 60;
  } catch (e) {
    console.error(e);
    return 0;
  }
};

export const genders: any = {
  'M': 'male',
  'F': 'female',
  'MALE': 'male',
  'FEMALE': 'female',
  'М': 'male',
  'Ж': 'female',
  'МУЖ': 'male',
  'ЖЕН': 'female'
};

export const headers = [
  "chronos.app.components.editForm.name",
  "chronos.app.components.zet.birthDate",
  "chronos.app.components.zet.birthTime",
  'GMT',
  "chronos.app.components.zet.birthPlace",
  "chronos.app.components.zet.latitude",
  "chronos.app.components.zet.longitude",
  "chronos.app.components.zet.gender"
];

// FIXME: IAstroProfile
export function parseZetString(value: string, profile: IFormData): IFormData | null {
  const toks = value.split(';').map(t => t.trim());

  if (toks.length < 8) return null;

  const [
    name,
    bdate,
    btime,
    gmt,
    place,
    lat,
    lon,
    gender,
    note,
    tail
  ] = toks;

  const date = bdate
    .split('.')
    .map(t => fillNumber(+t))
    .join('.');

  const timeToks = btime.split(':').map(t => fillNumber(+t));
  if (timeToks.length == 2) {
    timeToks.push('00');
  }
  const time = timeToks.join(':');
  const m = dayjs.utc(`${date},${time}`, 'DD.MM.YYYY,HH:mm:ss');
  
  if (!m.isValid()) {
    return null;
  }

  const res = makeForm({
    ...profile, // FIXME: not sure
    name,
    id: profile.id,
    housesSystem: profile.housesSystem,
    natal: {
      dt: m.toISOString(),
      gmt: getGMTFromString(gmt),
      place: {
        name: place,
        lat: degFromString(lat),
        lon: degFromString(lon)
      },
      // @ts-ignore
      gender: genders[gender.toUpperCase()]
    },
  });

  if (
    !name ||
    !place ||
    isNaN(res.natal.place.lat) ||
    isNaN(res.natal.place.lon)
  ) {
    return null;
  }

  return res;
}

export function parseZetStrings(lines: string[], profile: any) {
  const forms: IFormData[] = [];
  const errors: string[] = [];

  let firstBad = 0;
  let lastBad = 0;

  const addError = () => {
    if (firstBad == lastBad) {
      errors.push(firstBad.toString());
    } else {
      errors.push(`${firstBad}-${lastBad}`);
    }

    firstBad = lastBad = 0;
  };

  lines.forEach((line, ind) => {
    ind++;

    const res = parseZetString(line, profile);

    if (res) {
      forms.push(res);

      if (firstBad) addError();
    } else if (firstBad == 0) {
      firstBad = lastBad = ind;
    } else if (ind - 1 == lastBad) {
      lastBad = ind;
    } else {
      addError();
    }
  });

  if (firstBad) addError();

  return {
    forms,
    errors
  };
}

export async function parseZetFile(f: File, profile: any) {
  const file = await readFile<string>(f);
  const lines = file.split('\n').filter(l => l.trim().length);
  return parseZetStrings(lines, profile);
}
