import { IChain, ObjectType } from 'src/libs';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Ephemeris } from './Ephemeris';
import { IWidgetData } from 'src/pages/Instruments/Widgets/Widget';
import { useTranslation } from 'src/i18n/useTranslation';

export default function EphemerisFormulas(props: {
  data: IWidgetData;
}) {
  const [totalPoints, setTotalPoints] = useState(0);
  const [centralPoints, setCentralPoints] = useState(0);
  const [outPoints, setOutPoints] = useState(0);
  const { t } = useTranslation()

  useEffect(() => {
    const [{ strongs }] = props.data.maps;
    if (strongs) {
      setTotalPoints(strongs.reduce((acc: number, strong: number) => acc + strong, 0));

      let centralPoints = 0;
      // FIXME:
      // @ts-ignore
      props.data.maps[0].chains.map((chain: IChain) => Object.keys(chain.objects).map(obj => {
        // FIXME:
      // @ts-ignore
        if (chain.objects[obj].center) {
          centralPoints += strongs[+obj];
        }
      }));
      setCentralPoints(centralPoints);

      let outerPoints = 0;
      // FIXME:
      // @ts-ignore
      props.data.maps[0].chains.map((chain: IChain) => Object.keys(chain.objects).map(obj => {
        // FIXME:
        // @ts-ignore
        if (chain.objects[obj].orbit !== 0) {
          outerPoints += strongs[+obj] || 0;
        }
      }));

      setOutPoints(outerPoints);
    }
  }, [props.data.maps]);

  const getEphRows = () => {
    const rows = [];
    const [{ strongs }] = props.data.maps;
    for (const eph of Ephemeris) {
      // FIXME:
      // @ts-ignore
      const points = eph.planets.reduce((acc, planet) => acc + strongs[planet], 0);
      rows.push(
        <TableRow key={`row_${eph.type}`}>
          <Cell>
            {t(eph.type)}
          </Cell>
          <Cell>
            {points}
          </Cell>
          <Cell>
            {(points / totalPoints * 100).toFixed(1)}%
          </Cell>
        </TableRow>
      );
    }
    return rows;
  };

  return (
    <Container>
      <TableHeader>
        <Cell>
          {t("astro.planets")}
        </Cell>
        <Cell>
          {t("chronos.app.instruments.widgets.soul.efemerisFormulas.points")}
        </Cell>
        <Cell>
          {t("chronos.app.settings.statistics.percent")}
        </Cell>
      </TableHeader>

      {getEphRows()}

      <TableRow>
        <Cell>
          {t("chronos.app.instruments.widgets.soul.efemerisFormulas.central")}
        </Cell>
        <Cell>
          {centralPoints}
        </Cell>
        <Cell>
          {(centralPoints / totalPoints * 100).toFixed(1)}%
        </Cell>
      </TableRow>
      <TableRow>
        <Cell>
          {t("chronos.app.instruments.widgets.soul.efemerisFormulas.external")}
        </Cell>
        <Cell>
          {outPoints}
        </Cell>
        <Cell>
          {(outPoints / totalPoints * 100).toFixed(1)}%
        </Cell>
      </TableRow>
      <TableRow>
        <Cell>
          {t("chronos.app.instruments.widgets.soul.efemerisFormulas.total")}
        </Cell>
        <Cell>
          {totalPoints}
        </Cell>
        <Cell>
          {'100 %'}
        </Cell>
      </TableRow>
    </Container>
  );
}

const Container = styled.div`
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--element-neutral);
`;

const TableHeader = styled.div`
  display: flex;
  color: var(--text-third);
  padding: 0.625rem 0;
  > :nth-child(n + 2) {
    width: 25%;
    justify-content: flex-end;
  }
`;

const TableRow = styled.div`
  border-bottom: 1px solid var(--element-neutral);
  display: flex;
  padding: 0.625rem 0;
  
  > :nth-child(n + 2) {
    width: 25%;
    justify-content: flex-end;
  }
  :nth-last-child(1) {
    border-bottom: none;
  }
`;

const Cell = styled.span`
  width: 50%;
  display: flex;
  align-items: center;
  > svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.75rem;
  }
`;
