import React, { Ref, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { INavigationPointsProps } from '../types';

export const selectNavigationPoint = (ref: Ref<HTMLUListElement>, index: number) => {
  //@ts-ignore
  const listEl = ref!.current as HTMLUListElement;
  const elements = Array.from(listEl.children);
  elements.forEach(item => item.classList.remove('active'));
  elements[index].classList.add('active');
};


const NavigationPoints = React.forwardRef<HTMLUListElement, INavigationPointsProps>(({
  length,
  moveTo
}, pointsRef) => {

  useEffect(() => {
    selectNavigationPoint(pointsRef, 0);
  }, []);

  const items = new Array(length).fill(1);

  return <Container>
    <ul ref={pointsRef}>
      {items.map((item, i) =>
        <li key={i} onClick={() => { moveTo(i) }}></li>
      )}
    </ul>
  </Container>;
});

const Container = styled.div`
  margin-top: 1.5rem;

  & ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;

    & li {
      margin-left: 0.75rem;
      width: 0.375rem;
      height: 0.375rem;
      border-radius: 1rem;
      background-color: var(--text-third);
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        background-color: var(--accent-blue);
      }

      &.active {
        width: 0.875rem;
        background-color: var(--accent-blue);
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }
`;

export default NavigationPoints;
