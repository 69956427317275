import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { observer } from 'mobx-react';
import { useTranslation } from 'src/i18n/useTranslation'; 

import { history, goToMap, routes } from 'src/router';
import api, { IFormData, s3urls } from 'src/api';
import { useKeyHook } from 'src/hooks';
import { getDeviceDetect } from 'src/utils';
import store from 'src/store';
import { ICard } from 'src/store/Dashboard';
import dispatcher from 'src/dispatcher';

import ImportZetModal from 'src/components/ImportZetModal';
import HotkeysHint from 'src/components/HotkeysHint';
import CoursesMenuItem from './Courses/components/MenuItem';
import { MenuItem } from './SideBarMenuItem';

import { Button, Avatar, MoreMenu, AppSwitcher }  from 'src/libs';
import Amount from 'src/ui/Amount';
import Notify from 'src/ui/Wrappers/Notify';

import { 
  MenuIcon,
  ImportIcon, 
  HotkeyIcon, 
  SettingsIcon,
  PersonIcon,  
} from 'src/assets/icons/system';

import { InfoIcon } from 'src/assets/icons/notifications';
import { ShevronNarrowIcon as ShevronIcon } from 'src/assets/icons/system';
import { TmpMapIcon, DashboardIcon, ConsultationsIcon, CoursesIcon } from 'src/assets/icons/system/24';
import { acccesforRuAndEn, acccesOnlyForRu } from 'src/helpers/permissions';
import i18n from 'src/i18n/i18n';

export const SIDEBAR_TOOGLE_EVENT = 'SIDEBAR_TOOGLE_EVENT';
const { isTablet, isIPad } = getDeviceDetect();

export default observer(function SideBar(props: {
  form?: IFormData | null;
  updateForm(formData: IFormData): void;
}) {
  const { user: userStore } = store.settings;
  // const { appSwitchShow } = store;
  const sidebarRef = useRef<HTMLDivElement>(null);
  const { courses: coursesStore } = store;

  const [appSwitcherShow, setAppSwitcherShow] = useState(false);
  const [forms, setForms] = useState<ICard[]>([]);
  const [showImport, setShowImport] = useState<boolean>(false);
  const [showImportModal, setShowImportModal] = useState<boolean>(false);
  const [showHotkeys, setShowHotkeys] = useState(false);

  const location = useLocation();
  const { t } = useTranslation();

  React.useEffect(() => {
    if(isTablet) {
      const onClick = (ev: any) => toggleSideBar(true);

      window.addEventListener('click', onClick);

      return () => {
        window.removeEventListener('click', onClick);
      };
    }
    acccesOnlyForRu();
  }, []);
  const showEducationButton = false;// location.pathname === '/consultations';

  useEffect(() => {
    setShowImport(location.pathname === '/instruments');
  }, [location]);

  useEffect(() => {
    store.dashboard.forms && procForms(store.dashboard.forms);
  }, [store.dashboard.forms]);

  useKeyHook(['ControlLeft+Slash', 'ControlLeft+KeyK', 'ShiftLeft+KeyS'], {
    onKeyDown: key => {
      if (store.settings.user.isLimitedAccess) return
      if (key === 'ControlLeft+Slash') { setShowHotkeys(prev => !prev) }
      if (key === 'ControlLeft+KeyK') { setShowImportModal(prev => !prev) }
      if (key === 'ShiftLeft+KeyS') { settingLinkHandler() }
    }
  }, [showImport]);

  const settingLinkHandler = () => {
    store.setAppPath();
    history.push('/settings');
  };

  const toggleSideBar = (close = false) => {
    const { classList }: { classList: DOMTokenList } = sidebarRef.current!;

    if (close) {
      classList.remove('opened');

      store.settings.profile.updateUi(ui => {
        ui.sidebar = false;
      });
    } else {
      classList.toggle('opened');

      store.settings.profile.updateUi(ui => {
        ui.sidebar = classList.contains('opened');
      });

      window.dispatchEvent(new CustomEvent('sidebar'));
    }
  };

  const handleToggleClick = (e: any) => {
    e.stopPropagation();
    toggleSideBar();
  }

  const procForms = (forms: ICard[]) => {
    const formSize = 5;
    setForms(forms
      .filter(f => f.isPinned)
      .sort((a, b) => b.id - a.id)
      .slice(0, formSize)
    );
  };

  const onLogOut = async () => {
    try {
      localStorage.removeItem('token');

      await api.signOut(+store.settings.user.auth.sessionId);

      // FIXME:
      setTimeout(() => {
          api.goToAuth(false, i18n.language);
      }, 500);

      // window.location.href = '/';
    } catch (err) {
      console.log(err);
    }
  };

  const onAgainEducation = () => {
    dispatcher.emit('consultationsOnboarding');
  };

  return (
    <SideBarContainer
      ref={sidebarRef}
      className={store.settings.profile.ui.sidebar ? 'opened no-print' : 'no-print'}
      onClick={(e: any) => e.stopPropagation()}
      id="side-bar-container"
    >
      <Content>
      <header id="app-switch-button">
        <StyledButton name="" className="app-switcher-button" onClick={() => { setAppSwitcherShow(!appSwitcherShow) }}><MenuIcon /></StyledButton>
        <i>{t("chronos.app.title")}</i>
        <AvatarUser
          image={userStore.profile.avatarUrl!}
        />
      </header>

      {showImport &&
        <MenuItemWithIcon name="import-menuitem" onClick={() => setShowImportModal(true)}>
          <ImportIcon />
          <i>{t("chronos.app.import")}</i>
          <Button bordered size="small" color="transparent">Ctrl + K</Button>
          {/* </ImportZet> */}
        </MenuItemWithIcon>
      }

      {showImportModal && props.form && props.updateForm &&
        <ImportZetModal
          onClose={() => setShowImportModal(false)}
          onSubmit={props.updateForm}
          form={props.form}
        />
      }

      <MenuItem name="instruments-menuitem" onClick={() => history.push(routes.Instruments.path)}>
        <TmpMapIcon />
        <i>{t("chronos.app.tmpMap")}</i>
      </MenuItem>

        <MenuItem name="dashboard-menuitem" onClick={() => history.push(routes.Dashboard.path)}>
        <DashboardIcon />
        <i>{t("chronos.app.mapsList")}</i>
      </MenuItem>

      {acccesforRuAndEn() && <MenuItem onClick={() => history.push(routes.Consultations.path)}>
        <ConsultationsIcon />
        <i>{t("settings.consultations")}</i>
      </MenuItem>}

      {acccesOnlyForRu() && <MenuItem onClick={() => history.push(routes.Courses.path)}>
        <CoursesIcon />
        <i>{t("chronos.app.sidebar.events")}</i>
      </MenuItem>}


      {/* {coursesStore.hasCourses && <CoursesMenuItem />} */}

      <Line />

      <Cards>
        <header>
          {t("chronos.app.pinnedMap")}
          <Amount
            background="rgba(64, 147, 244, 0.2)"
            color="var(--accent-blue)"
            value={forms?.length || 0}
          />
        </header>

        {forms?.map(f =>
          <StyledButton
            key={f.id}
            onClick={() => goToMap(f.id)}
            color={f.color}
          >
            <PersonIcon/>
            <i>{f.name}</i>
          </StyledButton>
        )}
      </Cards>

      <footer>
          { !store.settings.user.isLimitedAccess && 
            <MenuItemWithIcon name="hotkeys-menuitem" onClick={() => setShowHotkeys(true)} style={{paddingLeft: '0.5rem'}}>
              <HotkeyIcon style={{width: '1.17rem'}} />
              <i>{t("chronos.app.shortKeys")}</i>
              <Button bordered size="small" color="transparent">Ctrl + /</Button>
            </MenuItemWithIcon>
        }

        <StyledButton name="settings-menuitem" onClick={() => settingLinkHandler()}><SettingsIcon style={{width: '1.17rem', marginLeft: '0.2rem'}}/><i>{t("chronos.app.appSettings")}</i></StyledButton>

        <MoreMenu options={[
          { label: t("chronos.app.helpCenter"), visible: true, action: () => { window.open(s3urls.support, '_blank'); } },
          { label: t("chronos.app.sidebar.getCourses"), visible: showEducationButton, action: onAgainEducation },
        ]}
          button={<StyledButton><InfoIcon style={{width: '1.17rem', marginLeft: '0.2rem'}}/><i>{t("chronos.app.sidebar.support")}</i></StyledButton>}
          position="top-right"
        />

        {store.supportNotifyShow &&
          <Notify
            type='warning'
            style={{ bottom: '10px'}}
            title={t("chronos.app.sidebar.writeToUs")}
            content={<p>{t("chronos.app.sidebar.supportInfo")}<a target="_blank" href="mailto:support@chronos.mg">{'support@chronos.mg'}</a></p>}
            buttons={<StyledButton
              // size="medium"
            ><a target="_blank" href="mailto:support@chronos.mg">{t("base.write")}</a></StyledButton>}
            onClose={() => { store.setSupportNotifyShow(false) }}
          />
        }
      </footer>

      {appSwitcherShow &&
        <AppSwitcher
          user={{
            firstName: userStore.profile.firstName,
            avatarUrl: userStore.profile.avatarUrl,
            levelOfAstrology: userStore.levelOfAstrologyName
          }}
          current={process.env.REACT_APP_PROCESSOR_APP_URL ?? ''}
          style={{ top: '48px', left: '9px', zIndex: 2 }}
          onLogOut={onLogOut}
          onClose={() => setAppSwitcherShow(false)}
          lang={i18n.language}
          t={t}
        />
      }
      {showHotkeys &&
        <HotkeysHint onClose={() => setShowHotkeys(false)} />
      }
      </Content>
      <Toggle onClick={handleToggleClick}>
        <ToggleButton>
          <ShevronIcon />
        </ToggleButton>
      </Toggle>
    </SideBarContainer>
  );
});


const Toggle = styled.div`
  position: absolute;
  ${ isTablet
      ? css`right: 0;`
      : css`right: -1em;`
  }
  top: 0;
  width: 1em;
  height: 100vh;
  cursor: pointer;
  z-index: 1;

  ${!isTablet
      ? css`
          &:hover:before {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 2px;
            height: 100%;
            background: var(--accent-blue);
            opacity: 0.37;
          }
        `
      : ''
  }

  &:hover button {
    opacity: 0.87;
  }
`;

const ToggleButton = styled.button`
  position: absolute;
  display: flex;
  top: 50%;
  left: -0.5rem;
  transform: translateY(-50%);
  width: 1rem !important;
  height: 2.625rem;

  justify-content: center;
  align-items: center;
  cursor:pointer;

  border-radius: 42px;
  background: var(--accent-blue);
  outline: none;
  border: none;
  padding: 0;
  text-align: center;

  & > svg {
    height: 1.125rem;
    color: var(--colors-white);
    overflow: visible;
  }  
`;

const Cards = styled.div`
  width: 100%;
  & > header {
    font-size: 0.875rem;
    color: var(--text-primary);
    display: none;
    margin: 0.5rem 0 0.5rem 0.625rem;

    & > span {
      margin-left: 0.5rem;
    }
  }
`;

const MenuItemWithIcon = styled(MenuItem)`
  ${isTablet
      ? css`white-space: nowrap;`
      : css``
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow-y: auto;
  color: var(--text-secondary);
  padding: 0 0.375rem 0 0.33rem;
  background-color: var(--bg-sidebar);

  & i {
    text-align: left;
    margin-left: 0.5rem;
    font-size: 0.875rem;
    font-style: normal;
    display: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
  }

  & > header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;

    & i {
      color: var(--text-primary) !important;
      margin-left: 0.275rem !important;
      padding-right: 0.5rem;
    }
  }

  & > footer {
    margin-top: auto;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-bottom: 0.8rem;

    & > a {
      margin-top: 0.8rem;
      color: inherit;
      text-decoration: none;
      
      &  button {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    ${MenuItemWithIcon} {
      margin-bottom: 0;
      border-radius: 4px;
      padding-left: 0.625rem;

      & svg {
        width: 1.5rem;
      }
        
      & i {
        padding-right: 0.5rem;
      }
      
      &:hover {
        background: var(--element-neutral);

        & svg {
          opacity: 1;
        }
        
        & i {
          color: var(--text-primary);
        }
      }
    }
  }
`;

const SideBarContainer = styled.div`
  position: sticky;
  box-sizing: border-box;

  top: 0;
  height: 100vh;

  width: 3rem;
  
  z-index: 6;
  transition: width 0.2s ease;
  
  ${isTablet
    ? `
      @media (max-width: 1439px) {
        position: fixed;
        height: auto;
        bottom: 0;
      }

      @media (max-width: 1366px) {
        position: fixed;
        height: auto;
        bottom: 0;
        width: 2rem;
        padding-right: 1rem;
        padding-left: 0;
        box-sizing: border-box;

        &:not(.opened) > ${Content} > header, &:not(.opened) > ${Content} > footer, &:not(.opened) > ${Content} > * {
          display: none;
        }
  
        & > ${Toggle} {
          display: block;
        }
      }
    `
    : ``
  }

  &.opened {
    width: ${isTablet ? '264px' : '19.5rem'};
    
    & > ${Content} > header {
      justify-content: space-between;
      flex-direction: row;

      & > span {
        margin-left: auto;
        margin-right: 0.375rem;
      }

      & > button {
        width: auto;
      }
    }

    & i {
      display: inline-block;
    }

    & .label {
      display: flex;
    }

    & hr {
      display: none;
    }

    & > ${Content} > button {
      width: 100%;
    }

    ${Cards} {
      & > header {
        display: flex;
        align-items: center;
      }
    }

    ${ToggleButton} {
      & > svg {
        transform: scale(-1);
      }
    }
    ${MenuItemWithIcon} {
      & > i {
        display: inline-block;
      }
      & > button {
        display: inline-block;
      }
    }
  }
`;

const StyledButton = styled.button<{ color?: string; }>`
  width: 100%;
  ${isIPad
      ? css`
          display: flex;
        `
      : css`
          display: grid;
          grid-template-columns: min-content 1fr;
        `
  }
  align-items: center;
  padding: 0.625rem 0.4rem 0.625rem 0.3rem;
  margin-top: 0rem;
  margin-bottom:0rem;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  outline: none;
  background: none;
  color: inherit;

  & svg {
    display: block;
    width: 1.5rem;
    color: var(--text-primary);
    opacity: 0.37;
    
    ${props => props.color && css`
    color: ${props.color};
  `}
  }

  &:hover {
    background: var(--element-neutral);

    & svg {
      opacity: 1;
    }
    
    & i {
      color: var(--text-primary);
    }
  }

  &:last-of-type{
    margin-bottom: 0rem;
  }
`;

const AvatarUser = styled(Avatar)`
  display: block;
  margin-top: 0.250rem;
  margin-bottom: 0.5rem;
`;

const Line = styled.hr`
  width: 1rem;
  height: 1px;
  background: var(--element);
  margin: 0.5rem 0;
  border: none;
`;
