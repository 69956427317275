import React from 'react';
import styled from 'styled-components';

import { ShevronDownIcon } from 'src/assets/icons/system';
import { useTranslation } from 'src/i18n/useTranslation';

interface DropDownProps {
  value: number;
  onChange(v: number): void;
  options: {
    [key: number]: string;
  };
  label?: string;
  placeholder?: string;
  width?: string;
}

export default function DropDown(props: DropDownProps) {
  const onChange = (ev: any) => props.onChange(+ev.target.value);
  const { t } = useTranslation();
  return (
    <Container
      style={{
        width: props.width
      }}
    >
      <Select
        value={props.value}
        onChange={onChange}
        title={props.placeholder ? t(props.placeholder) : ''}
      >
        {Object
          .keys(props.options)
          .map(o => parseInt(o))
          .map(opt =>
            <Option key={opt} value={opt}>
              {t(props.options[opt])}
            </Option>
          )
        }
      </Select>
      <ShevronDownIcon />
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
  min-width: 15ch;

  position: relative;

  background-color: var(--dropdown-background);
  border-radius: 0.25em;

  svg {
    width: 1.2rem;
    height: 1.2rem;

    position: absolute;
    right: 0.875rem;
    top: 50%;
    z-index: 0;

    transform: translateY(-50%);
    transform-origin: 50% 0;

    fill: var(--text-secondary);
  }
`;

const Select = styled.select`
  width: 100%;
  height: 2.571em;
  padding: 0.786em 2.429em 0.786em 1em;
  
  color: var(--dropdown-color);
  font-size: 0.875rem;
  line-height: 1;
  
  position: relative;
  z-index: 1;

  background-color: transparent;
  border: none;
  border-radius: 4px;

  cursor: pointer;
  appearance: none;

  :focus {
    outline: none;
  }

  transition: all 0.3s;
`;

const Option = styled.option`
  color: var(--text-primary);
  background-color: var(--dropdown-background);
`;
