import { IPromptDataAspect } from '../../types';

import { BinovileIcon as icon } from 'src/assets/icons/astro/aspects';

const aspect: IPromptDataAspect = {
  type: "Binovile",
  deg: 80,
  name: "chronos.app.instruments.widgets.prompts.aspects.binovile.name",
  icon,
  description: {
    list: [
      {
        text: "chronos.app.aspects.novile.description"
      }
    ]
  }
};


export default aspect;


