export function degFromString(value: string) {
    value = value.trim().toUpperCase();
  
    const decimalValue= value.match(/^(\-?\d+(\.\d+)?)([NESW]?)$/);
    if (decimalValue) {
        const [, num, , dir] = decimalValue;
        const sign = (dir === "S" || dir === "W") ? -1 : 1;
        return sign * parseFloat(num);
    }

    let toks: string[] = [];
    let dir = "";
  
    const m = value.match(/(\d+)°(\d+)'(\d+)"([NESW])/);
    if (m) {
      toks = m.slice(1, 4);
      dir = m[4];
    } else {
      const m = value.match(/(\d+)([NESW])(\d+)/);
      if (m) {
        toks = [m[1], m[3], "0"];
        dir = m[2];
      }
    }
  
    const nums = toks.map(t => +t).filter(v => !isNaN(v));
  
    if (nums.length != 3) return 0;
  
    const sign = dir == "S" || dir == "W" ? -1 : 1;
  
    return sign * (nums[0] + nums[1] / 60 + nums[2] / 3600);
  }
  