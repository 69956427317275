import styled from "styled-components";
import { Checkbox } from 'src/libs';
import { IPaymentCardMethod } from "src/pages/Settings/sections/payments/helpers";
import { s3urls } from 'src/api';
import { useTranslation } from 'src/i18n/useTranslation';

interface PolicyProps {
  className?: string
  paymentMethod: IPaymentCardMethod
}

export const Policy = (props: PolicyProps) => {
  const {
    className = '',
    paymentMethod,
  } = props

  const { t } = useTranslation()

  const getPrivacyLink = () => {
		switch(paymentMethod.country) {
			case 'kz':
			case 'us':
				return (
          <LinkDoc target="_blank" href={s3urls.privacyPolicyKZ}>
          {t("chronos.app.payments.paymentAgree.2")}
          </LinkDoc>
				)
			default:
				return (
					<LinkDoc target="_blank" href={s3urls.privacyPolicyRU}>
          {t("chronos.app.payments.paymentAgree.2")}
          </LinkDoc>
				)
		}
	}

  const getLinks = () => {
		switch(paymentMethod.country) {
			case 'kz':
			case 'us':
				return (
          <LinkDoc target="_blank" href={s3urls.termsOfUseKZ}>
          {t("chronos.app.settings.payment.selectedFormations.termOfUse")}
          </LinkDoc>
				)
			default:
				return (
					<LinkDoc target="_blank" href={s3urls.termsOfUseRU}>
          {t("chronos.app.settings.payment.selectedFormations.termOfUse")}
          </LinkDoc>
				)
		}
	}

  return (
    <Container className={className}>
      <Content>{t("chronos.app.payments.paymentAgree.1")} {getPrivacyLink()} {t("base.union")} {getLinks()}</Content>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  margin-top: 1.125rem;
`

const Content = styled.div`
  text-align: center;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: var(--text-third);
`

const LinkDoc = styled.a`
  color: var(--accent-color);
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`
