import React, {useState} from 'react';
import styled, {css, keyframes} from "styled-components";
import {MenuItem, MenuPosition} from './types';

interface IDropDownMenuItem {
    item: MenuItem
    subMenuSidePosition: MenuPosition
    onClose: () => void
}

export default React.memo(function DropDownMenuItem(props: IDropDownMenuItem) {
    const [subMenuShow, setSubMenuShow] = useState(false)
    const onClickHandler = props.item.action
      ? () => {
        props.item.action()
        props.onClose()
      }
      : () => {}

    return (
        <Container onMouseEnter={() => setSubMenuShow(true)}
                   onMouseLeave={() => setSubMenuShow(false)}
                   className={props.item.className}
                   onClick={onClickHandler}
            >
            {props.item.icon}<span>{props.item.title}</span>
            {props.item.subMenu && subMenuShow && <SubMenu position={props.subMenuSidePosition}>
                {props.item.subMenu}
            </SubMenu>}
        </Container>
    );
})

const animateShow = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
const Container = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  padding: 0.438rem 0.688rem;
  font-size: 0.875rem;
  cursor: pointer;

  &:before {
    position: absolute;
    display: block;
    content: '';
    left: 0.375rem;
    width: calc(100% - (0.375rem * 2));
    height: 100%;
    border-radius: 0.25rem;
  }

  &:hover::before {
    background-color: var(--bg-element-hover);
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.625rem;
    color: var(--text-secondary);
    opacity: 1;
  }

  span + svg {
    margin-left: auto;
    margin-right: 0;
  }

  &.drop {
    color: var(--accent-red);

    svg {
      color: var(--accent-red);
      opacity: 0.6;
    }
  }
`
const SubMenu = styled.div<{ position: MenuPosition }>`
  position: absolute;
  box-sizing: border-box;
  align-self: flex-start;
  margin-top: -1rem;
  //width: 15.313rem;
  height: 30rem;
  padding-left: 0.375rem;
  transform: translateX(calc(100% - 0.688rem));
  z-index: 2;
  overflow: auto;

  ${props => props.position.horizontal === 'right' && css`
    padding-left: 0.375rem;
    transform: translateX(calc(100% - 0.688rem));
  `}

  ${props => props.position.horizontal === 'left' && css`
    padding-right: 0.375rem;
    transform: translateX(calc(-100% - 0.688rem));
  `}

  animation: ${animateShow} 0.5s var(--easeOutQuart);
`