import { useTranslation } from 'src/i18n/useTranslation';
import React from 'react';
import styled, { css } from 'styled-components';

import { Button, TColorType } from 'src/libs';

type StyleType = Object | null;
type AlignType = 'left' | 'center' | 'right';

interface IButton {
  color: TColorType;
  title: string;
  onClick(e: any): void;
}

export default function PopupNotify({
  title = "chronos.app.instruments.footer.cannotEditTheMap",
  text = "chronos.app.instruments.footer.signToChanges",
  style = null,
  buttons = [],
  align = 'center'
}: {
  title?: string;
  text?: string;
  style?: StyleType;
  buttons?: Array<IButton>;
  align?: AlignType;
}) {
  const { t } = useTranslation();
  return <Container style={style ?? {}}>
    <Block>
      <header>{t(title)}</header>
      <span>{t(text)}</span>
      {buttons && buttons.length > 0 && <nav>
        {buttons.map((item, i) =>
          <Button 
            key={i} 
            color={item.color} 
            bordered={true} 
            onClick={item.onClick}
          >
            <span>{t(item.title)}</span>
          </Button>
        )}
      </nav>}
    </Block>
    <Triangle align={align}/>
  </Container>;
}

const Container = styled.div`
  position: absolute;
  bottom: 2.75rem;
  width: 410px;
  color: var(--text-primary);
`;

const Block = styled.div`
    background: var(--accent-blue);
    border-radius: 6px;
    padding: 1.5rem;

    & > header {
        display: flex;
        justify-content: space-between;
        font-size: 1.125rem;
        margin-bottom: 0.675rem;

        svg {
            fill: white;
        }
    }

    & > span {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    & > nav {
        margin-top: 0.875rem;

        & > *:not(:first-child) {
            margin-left: 0.5rem;
        }
    }
`;

const Triangle = styled.div<{ align?: AlignType }>`
    width: 0;
    margin: 0 auto;

    ${p => p.align === 'left' && css`
      margin: 0 auto 0 0.75rem;
    `}

    ${p => p.align === 'right' && css`
      margin: 0 0.75rem 0 auto;
    `}

    border: 8px solid transparent; /* Прозрачные границы */
    border-top: 8px solid var(--accent-blue); /* Добавляем треугольник */
`;
