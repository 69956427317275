import { useTranslation } from 'src/i18n/useTranslation';
import React from 'react';
import styled from 'styled-components';
import Items, { IParsedObject } from './InterpretationItems';

export default function InterpretationList({
  title,
  items,
  icon
}: {
  title: string;
  items: string[];
  icon?: any;
}) {
  const prosPrefix = '(+): ';
  const consPrefix = '(-): ';

  if (items.length === 0) {
    return null;
  }

  const sortItems = (acc: IParsedObject, i: string): IParsedObject => {
    if (i.startsWith(prosPrefix)) {
      acc.pros.push(i.replace(prosPrefix, ''));
      return acc;
    }

    if (i.startsWith(consPrefix)) {
      acc.cons.push(i.replace(consPrefix, ''));
      return acc;
    }

    acc.others.push(i);

    return acc;
  };

  const { others, pros, cons } = items.reduce<IParsedObject>(
    (acc: IParsedObject, i: string | { [k: string]: string[] }) => {
      if (typeof i === 'string') {
        acc = sortItems(acc, i);
      } else if (typeof i === 'object') {
        const keys = Object.keys(i);
        keys?.forEach(key => {
          const nestedParsed: IParsedObject = i[key]?.reduce<IParsedObject>(
            (nestedAcc: IParsedObject, nestedI) => {
              nestedAcc = sortItems(nestedAcc, nestedI);
              return nestedAcc;
            },
            { others: [], pros: [], cons: [] },
          );

          if (key.startsWith(prosPrefix)) {
            acc?.pros?.push({ [key.replace(prosPrefix, '')]: nestedParsed });
            return;
          }

          if (key.startsWith(consPrefix)) {
            acc?.cons?.push({ [key.replace(consPrefix, '')]: nestedParsed });
            return;
          }

          acc?.others?.push({ [key]: nestedParsed });
        });
      }

      return acc;
    },
    { others: [], pros: [], cons: [] },
  );
  const { t } = useTranslation();
  return (
    <>
      {title &&
        <Title>
          {icon}
          {t(title)}
        </Title>
      }

      <Items cons={cons} pros={pros} others={others} />
    </>
  );
}

const Title = styled.h2`
  display: flex;
  align-items: center;
  color: var(--text-primary);

  margin: 0em;

  &:not(:first-of-type) {
    margin-top: 2em;
  }

  & svg {
    width: 1.215em;
    height: 1.215em;
    margin-right: 0.5rem;

    fill: var(--text-primary);
  }
`;
