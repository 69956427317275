import React from 'react';
import styled from 'styled-components';
import { ReactMemo } from 'src/hooks';
import { MoreIcon } from 'src/assets/icons/system';

export default ReactMemo(function ContextMenuButton({onClick}: {
    onClick: (position: { top: number; left: number }) => void;
}) {
    const ref = React.useRef<HTMLDivElement>(null);
    return (
        <Container ref={ref} onClick={() => {
            const { top, left } = ref.current?.getBoundingClientRect()!;
            onClick({ top, left });
        }}>
            <MoreIcon />
        </Container>
    );
});

const Container = styled.div`
  position: relative;
  cursor: pointer;
  color: var(--text-third);
  padding: 0.625rem 0.813rem;
  margin-left: 0.5rem;
  border-radius: 0.375rem;

  &:hover {
    color: var(--text-secondary);
    background-color: var(--bg-share-hover);
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;
    vertical-align: middle;
  }
`;