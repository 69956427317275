import React from 'react';
import ym from 'react-yandex-metrika';
import api from 'src/api';
import { Policy } from 'src/components/payment/Policy';
import { getUtmLs } from 'src/helpers/localStorage';
import { getIokaLink } from 'src/helpers/payments';
import { useCurrency } from 'src/hooks';
import { useTranslation } from 'src/i18n/useTranslation';
import { Button, IGenInfo, PaymentCardPicker } from 'src/libs';
import { IPaymentCardMethod, initPaymentMethod, paymentMethods } from 'src/pages/Settings/sections/payments/helpers';
import store from 'src/store';
import styled from 'styled-components';
import Counter from './Counter';


export default function Payment(props: {
  info: IGenInfo;
  onSuccess(): void;
}) {
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();
	const [paymentMethod, setPaymentMethod] = React.useState<IPaymentCardMethod>(initPaymentMethod(store.settings.user.region?.region));
  const currency = useCurrency(paymentMethod.code, paymentMethod.country);

  const onSubmit = async () => {
    ym('reachGoal', 'click-payment-add-buildings-button');

    const res = await api.payment({
      type: 'additional',
      counter: props.info.prices[value][0],
      country: paymentMethod.country,
      language: localStorage.getItem('i18nextLng'),
      backUrl: getIokaLink('back'),
      failUrl: getIokaLink('fail'),
      successUrl: getIokaLink('success'),
      comment: getUtmLs(),
    }, 'charge');

    if (res == null) {}
    else if (res) { props.onSuccess() }
  };

  const totalSum = props.info.counters?.filter(count => count.currencyCode === paymentMethod.code)?.[value].price || 0;

  const infoString = "chronos.app.payments.infoString";

  return (
    <Container>
      <Info>{t(infoString)}</Info>

      <Counters>
        <Counter
          title="chronos.app.payments.numberOfBuilds"
          index={value}
          onChange={setValue}
          info={props.info}
        />
      </Counters>

      <StyledPaymentCardPicker
        items={paymentMethods}
				value={paymentMethod}
				onChange={(value: IPaymentCardMethod) => setPaymentMethod(value)}
				t={t}
				showInfo
      />

      <Totals>
        {totalSum > 0 && props.info.discount > 0 && <DiscountedSum><s>{totalSum} {currency.symbol}</s></DiscountedSum>}
        <Total>{Math.round(totalSum * (1 - (props.info.discount || 0)))} {currency.symbol}</Total>
        {props.info.discount > 0 && <Discount> -{props.info.discount * 100}%</Discount>}
      </Totals>

      <Button
        disabled={!totalSum}
        onClick={onSubmit}
      >{t("chronos.app.payments.pay")}</Button>

      <Policy paymentMethod={paymentMethod} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  height: 100%;
`;

const Info = styled.div`
  margin-top: 2em;
  color: var(--text-secondary);
  font-size: 0.875em;
`;

const Counters = styled.div`
  display: flex;
  width: 100%;
  margin-top: 2em;
`;

const Totals = styled.div`
  display: flex;
  align-items: center;
  vertical-align: middle;
  width: 100%;
  margin-bottom: 1em;
`;

const Total = styled.div`
  font-size: 1.75em;
`;

const DiscountedSum = styled(Total)`
  color: gray;
  margin-right: 0.75em;
`;

const Discount = styled.div`
  margin-left: 1em;
  color: white;
  font-size: 0.8em;
  background-color: #4093F4;
  padding: 0.5em;
  border-radius: 0.25em;
`;

const LinkDoc = styled.a`
  color: var(--accent-color);
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

const StyledPaymentCardPicker = styled(PaymentCardPicker)`
  position: relative;

  & .switcher_bg {
    gap: 0.25rem;
  }

  & .switcher_button {
    border: none;
    font-size: 0.75rem;
  }
`;
