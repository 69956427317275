import React from 'react';
import { useParams } from 'react-router-dom';

import Sections from 'src/pages/Settings/sections';

export default function SectionSwitch(): any {
  const { section } = useParams<{
    section: string;
  }>();
  const SectionComponent = Sections[section];
  return (
    <SectionComponent />
  );
}
