import { Input, CircleMode, IFormData, PrognosticsMode, SynastryMode, ISynastryPartnerData } from 'src/libs'
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react';
import { SwapIcon, PlusIcon, CloseIcon, DotIcon } from 'src/assets/icons/system'
import { EyeIcon, EyeCrossedIcon } from 'src/assets/icons/maps'
import { useTranslation } from 'src/i18n/useTranslation'
import { IExtendedMap } from 'src/pages/Instruments/Maps'
import { isCompatibility, isPartner, isRelocation, progressionsTitle, prognosticModes, isPrognostics } from 'src/pages/Instruments/utils'
import store from 'src/store'
import styled, { css } from 'styled-components'
import theme from 'src/theme';
import { Tooltip } from 'src/libs';

import { ECompatibilityTransitMode } from 'src/store/InstrumentsStore';

interface IMapIndicatorProps {
  form?: IFormData;
  map: IExtendedMap
  mapIndex: number
  partners: ISynastryPartnerData[]
  width: number
  top?: number
  left?: number
  currentMode: string
  onModeChanged(mode: CircleMode | CircleMode[]): void
}

export type TEyeButtonMode = 'open' | 'close';

export const EyeButton = ({
  mode = 'open',
  onChange,
  className
}: {
  mode?: TEyeButtonMode
  onChange?: (mode: TEyeButtonMode) => void;
  className?: string;
}) => {
  const [localMode, setLocalMode] = React.useState<TEyeButtonMode>(mode);

  React.useEffect(() => {
    setLocalMode(mode);
  }, [mode])
  
  const onChangeMode = () => {
    const mode = localMode === 'open' ? 'close' : 'open';
    setLocalMode(mode);
    onChange?.(mode);
  }
  return (
    <div className={className} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', cursor: 'pointer' }} onClick={onChangeMode}>
      { localMode === 'open' ? <EyeIcon/> : <EyeCrossedIcon/>}
    </div>
  );
}

export default observer(function MapIndicator(props: IMapIndicatorProps) {
  const { t } = useTranslation();
  const localCurrentMode = props.currentMode as CircleMode;
  const partnersOptions = props.partners?.map((partner, index) => ({ value: `compatibility${index + 1}`, label: partner.name })) || []; 
  const [firstNatal, setFirstNatal] = useState(true);
  
  const isDoubleMap = isCompatibility(localCurrentMode as CircleMode) 
    || prognosticModes.includes(localCurrentMode as string);

  const isPartnerMap = isPartner(localCurrentMode);

  const { compatibilityTransits, compatibilityReversed } = store.instruments.mapIndicatorCurrent;
  const isCompatibilityMode = isCompatibility(localCurrentMode);
  
  const compatibilityTransitModes = [
    { label: t('Транзит — режим 1'), value: ECompatibilityTransitMode.MODE_1, tooltip: 'Транзитные аспекты на объекты<br>натала и партнера' },
    { label: t('Транзит — режим 2'), value: ECompatibilityTransitMode.MODE_2, 
      tooltip: 'Транзитные соединения на: <br> <ul><li>Куспиды и планеты партнера и натала</li><li>Объекты в соединении у партнера и натала</li></ul>' },
    { divider: true  },
    { label: t('Удалить'), value: 'delete-syn-prognostics', color: 'var(--colors-red)', action: () => store.instruments.setInitSynastryTransits(2) }
  ]

  
  React.useEffect(() => {
    if(!isCompatibility(localCurrentMode)) {
      store.instruments.setMapIndicatorPrognosticsExt(true, localCurrentMode as PrognosticsMode);
      if (localCurrentMode === 'directions') {
        store.instruments.setMapIndicatorPrognosticsNatal(true, localCurrentMode as PrognosticsMode);
      }
    } else {
      setFirstNatal(!compatibilityReversed)
    }
  }, [props.mapIndex, localCurrentMode]);

  const singleName = React.useMemo((): string => {
    let result = t('astro.natal');
    if (isDoubleMap) return result;
    
    if (isRelocation(localCurrentMode)) {
      //@ts-ignore
      const placeName = (props.form?.relocation as any)?.[localCurrentMode].place.name.split(',')[0];
      result = `${t('astro.relocation.localMap')} - ${placeName}`
    
    } else if('horar' === localCurrentMode) {
      result = t(`astro.horar`)
    }

    return result; 
  }, [localCurrentMode]);
  
  // console.log(`current mode - ${localCurrentMode} isDouble: ${isDoubleMap} singleName: ${singleName}`)

  const extName = React.useMemo(() => {
    let result = 'extName';
    
    if (isCompatibility(localCurrentMode)) {
      return partnersOptions.find(option => option.value === localCurrentMode)?.label || result
    } else if(isPartner(localCurrentMode)) {
      return props.map.name || `partner${props.mapIndex + 1}`
    } else if (isPrognostics(localCurrentMode)) {
      return t(progressionsTitle[localCurrentMode]);
    }

    return result;
  }, [localCurrentMode]);

  const EyeModeForMapMode = React.useMemo((): TEyeButtonMode => {
    const storeValue = store.instruments.mapIndicatorCurrent[isPrognostics(localCurrentMode) ? 'prognosticsNatal' : 'partnerNatal'];
    // console.log(`EyeModeForMapMode - mode: ${localCurrentMode} value: ${(storeValue as any)[localCurrentMode]}`)
    return ( (storeValue as any)[localCurrentMode] ?? true ) ? 'open' : 'close';
  }, [
    store.instruments.mapIndicatorCurrent.prognosticsNatal,
    store.instruments.mapIndicatorCurrent.partnerNatal,
    props.mapIndex,
    localCurrentMode
  ]);
  
  const reorder = () => {
    setFirstNatal(firstNatal => {
      store.instruments.setMapCompatibilityReversed(!store.instruments.mapIndicatorCurrent.compatibilityReversed);
      return !firstNatal;
    });
    
  }
  
  const onChangeNatalVisible = (eyeMode: TEyeButtonMode) => {
    // console.log(`onChangeNatalEye - localCurrentMode: ${localCurrentMode} mode: ${eyeMode}`,);
    if (isPrognostics(localCurrentMode)) {
      store.instruments.setMapIndicatorPrognosticsNatal(Boolean(eyeMode === 'open'), localCurrentMode as PrognosticsMode);
    } 
    else if(isPartner(localCurrentMode)) {
      store.instruments.setMapIndicatorPartnerNatal(localCurrentMode as SynastryMode, Boolean(eyeMode === 'open'), props.mapIndex);
    }
  }

  React.useEffect(() => {
    if (isPartnerMap) {
        onChangeNatalVisible('close');
    }
  }, [props.currentMode])
   
	return (
    <IndicatorContainer width={props.width}>
      
      {isCompatibility(localCurrentMode) && <SwapButton onClick={reorder}><SwapIcon /></SwapButton>}
      
      <SwitchWrapper firstNatal={firstNatal}>
        
        { (isDoubleMap || isPartnerMap) &&
            <ExtContainer className='ext-container'>
              {
                isCompatibilityMode && partnersOptions.length > 1
                  ? <CustomInput
                      value={extName}
                      options={partnersOptions}
                      onChange={(mode) => props.onModeChanged([mode])}
                      theme={theme.current}
                    />
                : <Tag theme={theme.current}><span className='tag-name'>{extName}</span>
                  {/* { isDoubleMap && localCurrentMode !== 'directions' &&
                      <div className='tag-button'><EyeButton mode='open' onChange={onChangeExtVisible()} /></div>
                    } */}
                  </Tag>
              }
            </ExtContainer>
        }
        { !isPartnerMap &&
          <Tag className='natal-container' theme={theme.current} mode={EyeModeForMapMode}><span className='tag-name'>{singleName}</span>
            {/* если только двойная карта и это не ! совместимость и не дирекции */}
            { isDoubleMap && !isCompatibility(localCurrentMode) && localCurrentMode !== 'directions' &&
                <div className='tag-button'><EyeButton mode={EyeModeForMapMode} onChange={onChangeNatalVisible} /></div>
            }
          </Tag>
        }
      
      </SwitchWrapper>

      { isCompatibilityMode &&
        <CompatibilityTransitsBlock>
          
          { !Boolean(props.form?.syn_prognostics) 
              ? <Tooltip text={'Добавить транзитную карту'}>
                <div 
                  className='icon-button' 
                  onClick={() => store.instruments.setInitSynastryTransits(1)}
                >
                  <PlusIcon />
                </div>
              </Tooltip>
              : <div className="icon-button dot-icon"><DotIcon/></div>
          }
          
          { Boolean(props.form?.syn_prognostics) &&
            <div className='input-container'>
              <CustomInput
                value={compatibilityTransits[localCurrentMode].transitMode}
                options={compatibilityTransitModes}
                onChange={(mode) => {
                  store.instruments.setCompatibilityTransitMode(mode,  localCurrentMode);
                }}
                theme={theme.current}
              />

              {/* <Tooltip text={'Удалить транзитную карту'}>
                <div className='close-button' onClick={() => store.instruments.setInitSynastryTransits(2)}><CloseIcon /></div>
              </Tooltip> */}
            </div>
          }

          {/* <StyledEyeButton 
            mode={compatibilityTransits[localCurrentMode].showTransit ? 'open' : 'close'} 
            onChange={(mode: TEyeButtonMode) => {
              store.instruments.setCompatibilityTransitShow(Boolean(mode === 'open'), localCurrentMode);
            }} /> */}
        </CompatibilityTransitsBlock>
      }

		</IndicatorContainer>
	)
})

const IndicatorContainer = styled.div<{ width?: number }>`
	display: flex;
	align-items: center;
  justify-content: center;
  flex-direction: row;
  width: ${(p) => (p.width ? `${p.width + 100}px` : '5rem')};
`

const SwapButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  margin-right: 0.5rem;
  cursor: pointer;
  
  svg {
		width: 1.5rem;
		height: 1.5rem;
	}
`;

const ExtContainer = styled.div`
  display: flex;
`

const SwitchWrapper = styled.div<{ firstNatal: boolean }>`
  display: flex;
  flex-direction: row;

  & .ext-container {
    margin-left: 0;
  }

  & .natal-container {
    margin-left: 0.5rem;
  }

  ${p => p.firstNatal && css`
      flex-direction: row-reverse;

      & .ext-container {
        margin-left: 0.5rem; 
      }

      & .natal-container {
        margin-left: 0;
      }
  `}
  /* gap: 0.5rem; */
`;

const CustomInput = styled(Input)<{theme: string}>`

  background-color: ${p => (p.theme === 'light' || p.theme === 'auto') ? 'var(--background) !important' : 'var(--dialog-background) !important' };
  border-radius: var(--ui-border-radius-medium);
  border:${p => (p.theme === 'light' || p.theme === 'auto') ? 'var(--ui-input-border-size) solid var(--ui-input-border-color)' : 'none' };

  input {
    padding: 0.25rem 0.875rem 0.25rem 0.875rem;
    max-width: 11rem !important;
    border: none;
    
    white-space: nowrap;
    overflow: hidden  !important;
    text-overflow: ellipsis !important;

  }

  .option {
      background-color: var(--background);

      &:hover {
        background-color: var(--bg-element-hover-hover)
      }
    }

  ${p => p.theme === 'dark' && css`
    .option {
      background-color: var(--dialog-background);

      &:hover {
        background-color: var(--bg-element-hover-hover);
      }
    }

  `}

  .tooltip-item ul {
    padding-left: 1rem;
    margin: 0;
  }
  
`

const Tag = styled.div<{theme: string, mode?: string}>`
  display: flex;
  position: relative;
  min-height: 1.5rem;
  align-items: center;
  text-align: center;
  font-size:  0.875rem;
  padding: 0 0 0 0.75rem;
  color: var(--input-color);
  background-color: var(--input-background-highlighted);
  border-radius: 0.25rem;
  border:${p => p.theme === 'light' ? 'var(--ui-input-border-size) solid var(--ui-input-border-color)' : 'none' };
  opacity:${p => p.mode === 'close' ? 0.5 : 1};
  max-width: 15rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & .tag-name {
    margin-right: 0.75rem;
  }

  & .tag-button {
    position: relative;
    flex: 1;
    min-width: 2rem;
    height: 100%;

    & svg {
      width: 1rem;
    }
  }
`

const CompatibilityTransitsBlock = styled.div`
  display: flex;
  position: relative;
  margin-left: 0.5rem;
  align-items: center;
  gap: 0.5rem;

  & svg {
    width: 1.5rem;
    height: 1.5rem;
    color: var(--text-third);
  }

  & .icon-button {
    display: flex;
    box-sizing: border-box;
    align-self: stretch;
    justify-content: center;
    align-items: center;

    padding: 0 0.125rem;
    border-radius: var(--ui-border-radius-medium);

    cursor: pointer;

    &:hover {
      background-color: var(--element-neo);
      
      svg {
        color: var(--neo-element);
      }
    }

    &.dot-icon {
      padding: 0;
      cursor: default;

      &:hover {
        background-color: initial;
        
        svg {
          color: var(--text-third);
        }
      }

      & svg {
        width: 0.25rem;
        height: 0.25rem;
      }
    }
  }

  & .input-container {
    position: relative;
  }

  & .close-button {
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    top: 0;
    left: 0.25rem;
    padding: 0 0.25rem;
    z-index: 2;
    cursor: pointer;

    & svg {
      width: 0.875rem;
      height: 0.875rem;
    }

    &:hover svg {
      color: var(--text-primary);
    }
  }
`
const StyledEyeButton = styled(EyeButton)`
  position: absolute;
  width: auto !important;
  right: 0.5rem;

  & svg {
    width: 1.125rem;
    height: 1.125rem;
  }
`;


