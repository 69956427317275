import { makeAutoObservable, runInAction } from 'mobx';

import api from 'src/api';
import { IAuth, IUser, IUserRegion, nowISOString, IPlace, IProfile } from 'src/libs';
import dayjs from 'dayjs';

export const emptyPlace: IPlace = {
  name: '',
  lat: 0,
  lon: 0
};

export default class User {

  public auth: IAuth = {
    id: -1,

    firstName: '',
    lastName: '',
    avatarUrl: '',
    // @ts-ignore
    gender: null,
    // @ts-ignore
    levelOfAstrology: null,

    sessionId: -1,
    permissions: null,
    showWelcomeInstruments: false,
    region: {
      id: -1,
      userId: -1,
      default: false
    },

    autoPayment: false,
    expiryDate: '',
    isUnderConstruction: false,
    
    name: '',
    notifications: [],
    showWelcome: false,
    showWelcomeNewInterface: false,
    showDawnOnboarding: false,
    subscriptions: [],
    theme: '',
    token: { access: '', refresh: '' },
    confirmations: {
      privacyAndTerms: false,
    },
    language: '',
    nps2Visited: []
  }

  public profile: Partial<IUser> = {
    firstName: '',
    lastName: '',
    levelOfAstrology: undefined,
    avatarUrl: '',
    birth: {
      dateTime: nowISOString(),
      gmt: null,
      place: emptyPlace
    },
    email: '',
    phoneNumber: '',
    gender:	undefined,
    partner: '',
    // hasIndiSubscription: false,
    fixedGmt: {}
  }

  constructor() {
    makeAutoObservable(this);
  }


  private readonly levelName: {[key: string]: string} = {
    '0': "chronos.app.settings.bedinning",
    '1': "chronos.app.settings.amateur",
    '2': "chronos.app.settings.professional"
  };

  get levelOfAstrologyName() {
    return this.profile.levelOfAstrology ? this.levelName[this.profile.levelOfAstrology] : '';
  }


  get isTrialAccess(): boolean {
    return Boolean(this.auth.permissions?.isTrial && !this.auth.subscriptions?.length);
  }

  get isBaseTariff(): boolean {
    const { isTrial, canUsePro, isUnlimited } = this.auth.permissions!;
    const { subscriptions } = this.auth;
    return Boolean(
      !isTrial 
      && !canUsePro 
      && !isUnlimited 
      && subscriptions?.slice(-1)?.[0] === 2)
      && dayjs().isSameOrBefore(dayjs(this.auth.expiryDate)
    )
  }

  get isArchiveTariff(): boolean {
    const { isTrial, canUsePro, isUnlimited } = this.auth.permissions!;
    const { subscriptions } = this.auth;
    return Boolean(
      !isTrial  
      && !isUnlimited 
      && canUsePro
      && subscriptions?.slice(-1)?.[0] === 1)
      && dayjs().isSameOrBefore(dayjs(this.auth.expiryDate)
    )
  }

  get isExtendedTariff(): boolean {
    const { isTrial, canUsePro, isUnlimited } = this.auth.permissions!;
    const { subscriptions } = this.auth;
    return Boolean(
      !isTrial  
      && !isUnlimited 
      && canUsePro
      && subscriptions?.slice(-1)?.[0] === 3)
      && dayjs().isSameOrBefore(dayjs(this.auth.expiryDate)
    )
  }

  get isUnlimitedTariff(): boolean {
    const { isTrial, canUsePro, isUnlimited } = this.auth.permissions!;
    const { subscriptions } = this.auth;
    return Boolean(
      !isTrial  
      && isUnlimited 
      && canUsePro
      && subscriptions?.slice(-1)?.[0] === 4)
      && dayjs().isSameOrBefore(dayjs(this.auth.expiryDate)
    )
  }

  get isLimitedAccess(): boolean {
    // const isExpiry = Boolean(this.auth.subscriptions?.length) && dayjs().isAfter(dayjs(this.auth.expiryDate));
    return Boolean(
      !this.isTrialAccess
      && !this.isBaseTariff
      && !this.isArchiveTariff
      && !this.isExtendedTariff
      && !this.isUnlimitedTariff
    );
  }

  get region() {
    return this.auth.region
  }

  setRegion(region: IUserRegion) {
    this.auth.region = region;
  }

  async updateProfile(newValues: Partial<IUser>, remote: boolean = false) {

    const result = {
      ...this.profile,
      ...newValues
    }
    
    if (!remote) {
      runInAction(() => {
        this.profile = result
      });
      return result;
    }

    try {
      const profile = await api.updateProfile(result as IProfile);
      runInAction(() => {
        this.profile = result
      });
      return profile
    } catch (e) {
      console.log('update user profile err -', e);
    }
  }

  updateBirth(date: string) {
    this.profile.birth.dateTime = date;
  }

  updateBirthPlace(place: IPlace) {
    this.profile.birth.place = place;
  }

  updateShowWelcomeInstruments(value: boolean) {
    this.auth.showWelcomeInstruments = value;
  }

  async getUserProfile(): Promise<IUser> {
    try {
      const result = await api.profile();
      runInAction(() => {
        this.profile = result;
      });
      return result;
    } catch (e) {
      console.log('fetch user profilr err -', e);
      throw e;
    }
  }
}
