import React from 'react';
import styled, {css, keyframes} from 'styled-components';
import { ReactMemo, clickOutsideHook } from 'src/hooks';

import {contextButtonPositionType, MenuItem, MenuPosition} from './types';
import {getAllowedMenuPosition} from "./utils";
import DropDownList from "./DropDownList";

interface IDropDownProps {
    contextButtonPosition: contextButtonPositionType
    groupsItems: Array<MenuItem[]>
    onClose: () => void
}

export default ReactMemo(function DropDown(props: IDropDownProps) {
    const [menuSidePosition, setMenuSidePosition] = React.useState<MenuPosition>({
        horizontal: 'right',
        vertical: 'bottom',
    });

  const forOutsideRef = React.useRef<any>(null);
  clickOutsideHook(forOutsideRef, props.onClose);

    React.useEffect(() => {
        if (!props.contextButtonPosition) return

        const menuHtmlElement = document.getElementById('card-context-menu')
        const menuPosition = getAllowedMenuPosition(menuHtmlElement!, props.contextButtonPosition.top, props.contextButtonPosition.left)
        setMenuSidePosition(menuPosition)

    }, [props.contextButtonPosition])

    return (
        <Container
            id="card-context-menu"
            ref={forOutsideRef}
            position={menuSidePosition}
            style={{top: `${props.contextButtonPosition!.top}px`, left: `${props.contextButtonPosition!.left}px`}}
        >
            {
                props.groupsItems.map((group, index, array) => {
                    const lastItems = index === (array.length - 1)
                    return (
                        <div key={index}>
                            <DropDownList items={group} subMenuSidePosition={menuSidePosition} onClose={props.onClose}/>
                            {!lastItems && <Delimiter/>}
                        </div>
                    )
                })
            }
        </Container>
    )
})

const animateShow = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
const Container = styled.div<{ position: MenuPosition }>`
  position: fixed;
  width: 15.313rem;
  z-index: 777;
  transform: translateX(1.25rem);
  background-color: var(--bg-100);
  border-radius: 0.375rem;

  ${props => props.position.horizontal === 'left' && props.position.vertical === 'top' && css`
    transform: translate(-100%, -100%);
  `}

  ${props => props.position.horizontal === 'right' && props.position.vertical === 'top' && css`
    transform: translate(0, -100%);
  `}

  ${props => props.position.horizontal === 'left' && props.position.vertical === 'bottom' && css`
    transform: translate(-100%, 0%);
  `}

  ${props => props.position.horizontal === 'left' && props.position.vertical === 'top' && css`
    transform: translate(-100%, -100%);
  `}

  animation: ${animateShow} 0.2s var(--easeOutQuart);
`
const Delimiter = styled.div`
  position: relative;
  width: 100%;
  height: 1px;
  max-height: 1px;
  margin: 0.375rem 0;
  padding: 0;
  background-color: var(--button-border);
`
