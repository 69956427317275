import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import { Button } from 'src/libs';

import Event from './Event';
import { IEvent } from './types';
import EventInput from './EventInput';
import { nextOnboarding } from 'src/lib/onboarding';

import onboarding from './onboarding.cfg';
import { intervalName } from './ResultItem';
import { IFormData } from 'src/api';
import { useTranslation } from 'src/i18n/useTranslation';

const minEventsCount = 3;

export default function EventList(props: {
  allEvents: IEvent[];
  events: IEvent[];
  zeroEvents: number[];
  setEvents(events: IEvent[]): void;
  build(events: IEvent[]): void;
  onSupport(): void;
  form: IFormData;
}) {
  const [added, setAdded] = React.useState(false);

  React.useEffect(() => {
    if (onboarding.show && added) {
      requestAnimationFrame(() => {
        nextOnboarding('event-more');
      });
    }

    if (props.events.length == 1 && onboarding.show && onboarding.edited) {
      requestAnimationFrame(() => {
        nextOnboarding('event-2-left');
      });
    }
  });

  const deleteEvent = (index: number) => {
    const newEvents = [...props.events];
    newEvents.splice(index, 1);
    props.setEvents(newEvents);
  };

  const saveEvent = (index: number, event: IEvent) => {
    const newEvents = [...props.events];
    newEvents[index] = event;
    props.setEvents(newEvents);
  };

  const addEvent = (ev: any) => {
    setAdded(true);

    const event = props.allEvents.find(e => e.id == ev.id) as IEvent;

    props.setEvents([
      ...props.events,
      {
        ...event,
        ...ev,
        rules: event.rules.map(r => ({
          id: r.id,
          asp: r.asp,
          nat: r.nat,
          dir: r.dir,
          name: r.name,
          checked: true
        }))
      }
    ]);
  };

  const onEnabled = (index: number, enabled: boolean) => {
    props.setEvents(props.events.map((ev, i) => i == index ? {
      ...ev,
      enabled
    } : ev));
  };

  const enabledEventsLength = props.events.filter(e => e.enabled).length;

  const isDeathError = props.events.filter(e => e.enabled).find(item => {
    const event = props.allEvents.find(allItem => item.id === allItem.id);
    if (event?.isDeath) {
      const eventDateParts = item.date!.split('.');
      const eventDate = new Date(+eventDateParts[2], +eventDateParts[1] - 1, +eventDateParts[0]);
      const years = (+eventDate - +props.form.natal.dt) / (1000 * 60 * 60 * 24 * 365);
      return years < 5;
    }
  });

  const isCountError = enabledEventsLength < minEventsCount;
  const isError = isCountError || isDeathError;
  const { t } = useTranslation();
  return <>
    <EventInput
      events={_.cloneDeep(props.allEvents)}
      onSupport={props.onSupport}
      onComplete={addEvent}
    />

    <EventListC>
      {props.events.map((item, index) => <Event
        key={`ev_${index}`}
        index={index}
        event={item}
        allEvents={props.allEvents}
        onDelete={() => deleteEvent(index)}
        onSave={(event: IEvent) => saveEvent(index, event)}
        onEnabled={value => onEnabled(index, value)}
        last={index == props.events.length - 1}
        failed={props.zeroEvents.includes(index) || isDeathError?.id === item.id}
      />)}
    </EventListC>

    {!isError &&
      <ButtonContainer>
        <Button
          color="accent"
          onClick={() => props.build(props.events)}
        >{t("chronos.app.rectification.EventList.getResult")}</Button>
      </ButtonContainer>
    }

    {isCountError &&
      <EventsLack>
        <header>{t("chronos.app.rectification.EventList.notEnoughEvents")}</header>
        <div>{t("chronos.app.rectification.EventList.addMinimum")} {minEventsCount} {t("chronos.app.rectification.EventList.eventForRecti")}</div>
      </EventsLack>
    }

    {!isCountError && isDeathError &&
      <EventsLack>
        <header>{t("chronos.app.rectification.EventList.deathEvent")}</header>
        <div>{t("base.event")} "{isDeathError.name} {isDeathError.date}" {t("chronos.app.rectification.EventList.notSuitable")}</div>
      </EventsLack>
    }
  </>;
}

const EventListC = styled.div`
  margin-top: 0.75rem;
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 1.5rem;
  padding-bottom: 5rem;
`;

const EventsLack = styled.div`
  margin-top: 8rem;
  text-align: center;

  & > header {
    margin-bottom: 0.75rem;
    font-size: 1.5rem;
    line-height: 1.5rem;
    color: var(--text-secondary);
    font-weight: 600;
  }

  & > div {
    font-size: 0.875rem;
    line-height: 1.312rem;
    color: var(--text-third);
  }
`;
