import React from 'react';
import { ICalculation } from 'src/libs';

interface IGlobalTrendGroupItem {
  text: string;
  weight: string;
  compensation?: string;
  texts?: {
    text: string;
    weight: string;
  }[];
}

interface IGlobalTrend {
  title: string;
  importantNotice?: string | null;
  description: string;
  actualTo: string;
  group: IGlobalTrendGroupItem[];
}

export interface IGlobalTrends {
  natal?: ICalculation;
  solars?: any;
  primaryTrends?: IGlobalTrend;
  secondaryTrends?: IGlobalTrend;
  heading: string;
  description: string;
  compensationIntro: string;
  actualTo?: string;
  actualToText?: string;
}

const GlobalTrends = (props: IGlobalTrends) => {
  const { primaryTrends, secondaryTrends, heading, description } = props;

  return <div className="trends">
    <h1>{heading}</h1>
    <p>{description}</p>

    {[primaryTrends, secondaryTrends].map((trends, i) => trends ?
      <React.Fragment key={`trend_${i}`}>
        <h2>{trends.title}</h2>

        {trends.importantNotice &&
          <p className="important-notice">
            {trends.importantNotice}
          </p>
        }

<       ins>{props.actualToText}{props.actualTo}</ins> 
        <p>{trends.description}</p>
        <ul className="list-wrap-global">
          {trends.group?.map((item, j) => item.compensation ?
            <li className="list-item" key={`trend_${i}_group_${j}`}>
              <span className="list-item-red bold-text">
                {item?.texts?.length ?
                  item.texts.map(text => `+${text.weight} ${text.text}`) :
                  null
                }
              </span>
              <p className="compensation bold-text">!{props.compensationIntro}: {item.compensation}</p>
            </li> :
            <li className="list-item" key={`trend_${i}_group_${j}`}>
              <span>+{item.weight} {item.text}</span>
            </li>
          )}
        </ul>
      </React.Fragment> :
      null
    )}
  </div>
}

export default GlobalTrends;