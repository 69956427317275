import { IPromptDataHouse } from '../../types';

const house: IPromptDataHouse = {
  description: {
    short: "chronos.app.instruments.5.descriptionShort",
    facts: [
      {
        name: "astro.facts.position",
        value: "chronos.app.instruments.widgets.prompts.houses.2.description.facts.0.value"
      },
      {
        name: "astro.facts.hemisphere",
        value: "chronos.app.instruments.widgets.prompts.houses.4.description.facts.1.value"
      },
      {
        name: "astro.facts.quadrant",
        value: "chronos.app.instruments.widgets.prompts.houses.5.description.facts.2.value"
      },
      {
        name: "astro.facts.cross",
        value: "chronos.app.facts.fixed"
      },
      {
        name: "astro.facts.element",
        value: "astro.facts.fire"
      },
      {
        name: "astro.facts.significator",
        value: "astro.planets.sun"
      }
    ],
    full: "chronos.app.instruments.5.descriptionFull"
  },
  
  planets: {
    Sun: [
      {
        icon: "🧗‍♂️",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Sun.0.text"
      },
      {
        icon: "💑",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Sun.1.text"
      },
      {
        icon: "🤩",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Sun.2.text"
      },
      {
        icon: "👶️",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Sun.3.text"
      }
    ],
    Moon: [
      {
        icon: "🎮",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Moon.0.text"
      },
      {
        icon: "🏖",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Moon.1.text"
      },
      {
        icon: "😢",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Moon.2.text"
      },
      {
        icon: "❤️",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Moon.3.text"
      },
      {
        icon: "👩‍👧‍👧",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Moon.4.text"
      },
      {
        icon: "👩‍🍳️",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Moon.5.text"
      }
    ],
    Mercury: [
      {
        icon: "📖",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Mercury.0.text"
      },
      {
        icon: "👩‍🏫",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Mercury.1.text"
      },
      {
        icon: "🤔",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Mercury.2.text"
      },
      {
        icon: "🗣",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Mercury.3.text"
      },
      {
        icon: "📊",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Mercury.4.text"
      }
    ],
    Venus: [
      {
        icon: "❤️",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Venus.0.text"
      },
      {
        icon: "💑",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Venus.1.text"
      },
      {
        icon: "💃",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Venus.2.text"
      },
      {
        icon: "👩‍🎨",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Venus.3.text"
      },
      {
        icon: "👨‍👩‍👧‍👦️",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Venus.4.text"
      }
    ],
    Mars: [
      {
        icon: "🎰",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Mars.0.text"
      },
      {
        icon: "👑",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Mars.1.text"
      },
      {
        icon: "🏋️‍♀️",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Mars.2.text"
      },
      {
        icon: "👨‍👦",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Mars.3.text"
      },
      {
        icon: "🤬",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Mars.4.text"
      }
    ],
    Jupiter: [
      {
        icon: "🤗",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Jupiter.0.text"
      },
      {
        icon: "🥂",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Jupiter.1.text"
      },
      {
        icon: "👋",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Jupiter.2.text"
      },
      {
        icon: "👯‍♀️",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Jupiter.3.text"
      },
      {
        icon: "👶️",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Jupiter.4.text"
      }
    ],
    Saturn: [
      {
        icon: "😳",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Saturn.0.text"
      },
      {
        icon: "🗿",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Saturn.1.text"
      },
      {
        icon: "😢",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Saturn.2.text"
      },
      {
        icon: "👩‍🔬",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Saturn.3.text"
      },
      {
        icon: "👨‍👧",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Saturn.4.text"
      }
    ],
    Uranus: [
      {
        icon: "❤️",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Uranus.0.text"
      },
      {
        icon: "💥",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Uranus.1.text"
      },
      {
        icon: "🕵️‍♂️",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Uranus.2.text"
      },
      {
        icon: "😂",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Uranus.3.text"
      },
      {
        icon: "🏃‍♂️",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Uranus.4.text"
      }
    ],
    Neptune: [
      {
        icon: "💑",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Neptune.0.text"
      },
      {
        icon: "❤️",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Neptune.1.text"
      },
      {
        icon: "☁️",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Neptune.2.text"
      },
      {
        icon: "👩‍🎨",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Neptune.3.text"
      },
      {
        icon: "👨‍👦‍👦",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Neptune.4.text"
      }
    ],
    Pluto: [
      {
        icon: "🎰",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Pluto.0.text"
      },
      {
        icon: "😭",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Pluto.1.text"
      },
      {
        icon: "🎲️",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Pluto.2.text"
      },
      {
        icon: "💑",
        text: "chronos.app.instruments.widgets.prompts.houses.5.planets.Pluto.3.text"
      }
    ]
  },

  signs: {
    Aries: [
      {
        icon: "👨‍🎨",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Aries.0.text"
      },
      {
        icon: "✍️",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Aries.1.text"
      },
      {
        icon: "🏇",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Aries.2.text"
      },
      {
        icon: "💋",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Aries.3.text"
      },
      {
        icon: "👀",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Aries.4.text"
      },
      {
        icon: "👨‍👦",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Aries.5.text"
      }
    ],
    Taurus: [
      {
        icon: "🗽",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Taurus.0.text"
      },
      {
        icon: "🍷",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Taurus.1.text"
      },
      {
        icon: "💑",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Taurus.2.text"
      },
      {
        icon: "💸",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Taurus.3.text"
      },
      {
        icon: "👼",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Taurus.4.text"
      },
      {
        icon: "👨‍👦",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Aries.5.text"
      }
    ],
    Gemini: [
      {
        icon: "😄",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Gemini.0.text"
      },
      {
        icon: "🤔",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Gemini.1.text"
      },
      {
        icon: "🗣",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Gemini.2.text"
      },
      {
        icon: "👨‍👦",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Gemini.3.text"
      }
    ],
    Cancer: [
      {
        icon: "😖",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Cancer.0.text"
      },
      {
        icon: "💑",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Cancer.1.text"
      },
      {
        icon: "🤗",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Cancer.2.text"
      },
      {
        icon: "👨‍🎨",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Cancer.3.text"
      },
      {
        icon: "🏌️‍♀️",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Cancer.4.text"
      },
      {
        icon: "👨‍👦",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Cancer.5.text"
      }
    ],
    Leo: [
      {
        icon: "😎",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Leo.0.text"
      },
      {
        icon: "👨‍🎨",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Leo.1.text"
      },
      {
        icon: "❤️",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Leo.2.text"
      },
      {
        icon: "🕺",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Leo.3.text"
      },
      {
        icon: "👨‍👦",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Leo.4.text"
      }
    ],
    Virgo: [
      {
        icon: "🕵️‍♂️",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Virgo.0.text"
      },
      {
        icon: "👩‍🎨",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Virgo.1.text"
      },
      {
        icon: "🧶",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Virgo.2.text"
      },
      {
        icon: "❤️",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Virgo.3.text"
      },
      {
        icon: "👨‍👦",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Virgo.4.text"
      }
    ],
    Libra: [
      {
        icon: "👫",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Libra.0.text"
      },
      {
        icon: "🍷",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Libra.1.text"
      },
      {
        icon: "😍",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Libra.2.text"
      },
      {
        icon: "🤷‍♂️",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Libra.3.text"
      },
      {
        icon: "👨‍👦",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Libra.4.text"
      }
    ],
    Scorpio: [
      {
        icon: "😍",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Scorpio.0.text"
      },
      {
        icon: "👩‍🎨",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Scorpio.1.text"
      },
      {
        icon: "❤️",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Scorpio.2.text"
      },
      {
        icon: "👨‍👦",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Scorpio.3.text"
      },
      {
        icon: "🤗",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Scorpio.4.text"
      }
    ],
    Sagittarius: [
      {
        icon: "🎭",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Sagittarius.0.text"
      },
      {
        icon: "👩‍🎨",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Sagittarius.1.text"
      },
      {
        icon: "😍",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Sagittarius.2.text"
      },
      {
        icon: "🛫",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Sagittarius.3.text"
      },
      {
        icon: "🤗",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Sagittarius.4.text"
      },
      {
        icon: "👩‍🏫",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Sagittarius.5.text"
      }
    ],
    Capricorn: [
      {
        icon: "🤔",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Capricorn.0.text"
      },
      {
        icon: "😳",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Capricorn.1.text"
      },
      {
        icon: "😤",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Capricorn.2.text"
      },
      {
        icon: "👨‍👦",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Capricorn.3.text"
      },
      {
        icon: "🧓",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Capricorn.4.text"
      },
      {
        icon: "👨‍🏫",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Capricorn.5.text"
      }
    ],
    Aquarius: [
      {
        icon: "👨‍🎨",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Aquarius.0.text"
      },
      {
        icon: "💑",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Aquarius.1.text"
      },
      {
        icon: "🍃",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Aquarius.2.text"
      },
      {
        icon: "❤️",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Aquarius.3.text"
      },
      {
        icon: "👨‍👦",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Aquarius.4.text"
      }
    ],
    Pisces: [
      {
        icon: "👩‍🎨",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Pisces.0.text"
      },
      {
        icon: "😍",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Pisces.1.text"
      },
      {
        icon: "💑",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Pisces.2.text"
      },
      {
        icon: "🤫",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Pisces.3.text"
      },
      {
        icon: "🤗",
        text: "chronos.app.instruments.widgets.prompts.houses.5.signs.Pisces.4.text"
      }
    ]
  }
}

export default house;

