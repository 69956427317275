type Listener = (data: any) => void;

class Dispatcher {
  private listeners: {
    [type: string]: Listener[];
  } = {};

  add(type: string, listener: Listener) {
    const listeners = this.listeners[type];

    if (listeners) {
      listeners.push(listener);
    } else {
      this.listeners[type] = [listener];
    }
  }

  remove(type: string, listener: Listener) {
    this.listeners[type] = this.listeners[type]?.filter(h => h != listener) ?? [];
  }

  emit(type: string, data?: any) {
    this.listeners[type]?.forEach(h => h(data));
  }
}

export default new Dispatcher();
