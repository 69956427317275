import React from 'react';
import styled, { css } from 'styled-components';
// import DialogView from 'src/pages/Settings/wrappers/DialogView';
import { ReactMemo } from 'src/hooks';

import { OkIcon, CloseIcon } from 'src/assets/icons/system';

type StateType = 'success' | 'fail' | 'info';

export default ReactMemo(function AlertScreen({
  state = 'info',
  title = 'title default',
  subTitle = 'subtitle default',
  children
}: {
  state: StateType;
  title: string;
  subTitle: string;
  children: React.ReactNode | React.ReactNodeArray;
}) {

  return (
    <Container className="success-screen">
      <IconSkin state={state}>
        {state === 'fail' ? <CloseIcon /> : <OkIcon />}
      </IconSkin>
      <h2>{title}</h2>
      <h5>{subTitle}</h5>
      <Controls className="controls">
        {children}
      </Controls>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: var(--workspace-background);

  & h2 {
    margin: 0;
    margin-bottom: 0.375rem;
    font-weight: 600;
    font-size: 2.25rem;
    line-height: 2.625rem;
    text-align: center;
  }

  & h5 {
    margin: 0;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--text-secondary);
    text-align: center;
  }
`;

const Controls = styled.div`
  position: relative;
  display: flex;
  margin-top: 2.25rem;

  & > * + * {
    margin-left: 0.75rem;
  }
`;
const IconSkin = styled.div<{ state: StateType }>`
  display: flex;
  position: relative;
  margin-bottom: 2.25rem;
  justify-content: center;
  align-items: center;
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;

  background-color: var(--accent-blue);
  color: #fff;

  ${props => props.state === 'success' && css`
    background-color: var(--accent-green);

    & svg {
      width: 1.25rem;
      height: 0.937rem;
    }
  `}
  
  ${props => props.state === 'fail' && css`
    background-color: var(--accent-red);

    & svg {
      width: 1.125rem;
      height: 1.125rem;
    }
  `}

`;
