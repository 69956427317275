export const PROCESSOR_OLD_URL = process.env.REACT_APP_PROCESSOR_APP_URL || 'https://app-dev.chronos.mg';

export const HTTP_ERRORS_DESCRIPTION: {[key: string]: {message: string; type: any}} = {
  'PROMO_NOT_FOUND': {
    message: "chronos.app.config.promo.notFound",
    type: 'error'
  },
  'PROMO_ALREADY_USED': {
    message: "chronos.app.config.promo.alreadyUsed",
    type: 'warning'
  },
  'PROMO_HAVE_ALREADY_USED': {
    message: "chronos.app.config.promo.alreadyUsed",
    type: 'warning'
  },
  'PROMO_EXPIRED': {
    message: "chronos.app.config.promo.expired",
    type: 'warning'
  },
  'PROMO_HAVE_DISCOUNT_ALREADY': {
    message: "chronos.app.config.promo.discountAlready",
    type: 'warning'
  },
  'PROMO_NOT_IMPLEMENTED': {
    message: "chronos.app.config.promo.notImplemented",
    type: 'error'
  },
  'PROMO_INCORRECT': {
    message: "chronos.app.config.promo.incorrect",
    type: 'error'
  },
  'PROMO_BAD_DATA': {
    message: "chronos.app.config.promo.badData",
    type: 'error'
  },
  'PROMO_FOR_DOWN': {
    message: "chronos.app.config.promo.forDown",
    type: 'warning'
  },
  'PROMO_FOR_CURRENT_TARIFF': {
    message: "chronos.app.config.promo.forCurrentTariff",
    type: 'error'
  }
};
