import { makeAutoObservable } from 'mobx';
import { Dayjs } from 'dayjs';
import { IWorkDatabase } from 'src/libs';

export default class ConsultationsStore {
  constructor() {
    makeAutoObservable(this);
  }

  workDb: IWorkDatabase | null = null;
  currentPeriodStart: Dayjs | null = null;
  isWeekView: boolean = true;
}