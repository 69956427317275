import React from 'react';
import styled, { css } from 'styled-components';

import ResultEvent from './ResultEvent';
import RuleItem from './RuleItem';

import { IEventResult } from '.';
import { IEvent } from './types';

import { ShevronNarrowIcon as ShevronIcon } from 'src/assets/icons/system';
import { OkIcon, CloseIcon } from 'src/assets/icons/system';
import { useTranslation } from 'src/i18n/useTranslation';

export default function ResultMoreItem({ event, allEvents }: {
  event: IEventResult;
  allEvents: IEvent[];
}) {
  const [rulesOpened, setRulesOpened] = React.useState(false);
  const { t } = useTranslation();
  let maxScore = 0;

  const _event = allEvents.find(e => e.id == event.id) as IEvent;

  const rules = event
    .rules
    .map(r => {
      maxScore = Math.max(maxScore, r.score);
      const rule = _event.rules.find(rule => rule.id === r.id)!;
      return {
        ...r,
        name: rule.name,
        asp: rule.asp,
        dir: rule.dir,
        nat: rule.nat
      };
    })
    .map(r => ({
      ...r,
      width: 100 * r.score / maxScore
    }));

  return <ResultItem rulesOpened={rulesOpened}>
    <header>
      <span>
        {t(_event.name)}
      </span>
    </header>

    <Rules>
      <RulesList>
        {rules.map((r, i) =>
          <ResultEvent
            key={`rule_${i}`}
            green
            amount={r.score}
            name={`${t("chronos.app.instruments.widgets.rectification.rule")} ${i + 1}`}
            width={r.width}
          />
        )}
      </RulesList>
    </Rules>

    {rulesOpened &&
      <RulesMore>
        {rules.map((r, i) =>
          <RulesMoreItem red={!r.width} key={`exp_rule_${i}`}>
            <span>
              {r.width ? <OkIcon /> : <CloseIcon />}
            </span>
            <i></i>
            <RuleItem rule={(r)} />
          </RulesMoreItem>
        )}
      </RulesMore>
    }
    <OpenMoreButton onClick={() => { setRulesOpened(!rulesOpened) }}><ShevronIcon /></OpenMoreButton>
  </ResultItem>;
}

const ResultItem = styled.section<{ recommend?: boolean; rulesOpened?: boolean }>`
  padding: 1rem;
  background: var(--rectification-block-background);
  border: 1px solid var(--rectification-block-border);
  border-radius: 6px;
  margin-bottom: 0.85rem;

  ${props => props.recommend && css`
    border: 2px solid var(--colors-blue);
  `}

  ${props => props.rulesOpened && css`
    & button svg {
      transform: rotate(-90deg);
    }
  `}

  & > header {
    display: flex;
    margin-bottom: 0.7rem;
    align-items: center;
    color: var(--text-primary);
    font-size: 1rem;

    & span {
      margin-right: 0.750rem;
    }
  }
`;

const Rules = styled.div`
  & > header {
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: var(--text-primary);
  }
`;

const RulesList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(3rem, 1fr));
  grid-gap: 0.375rem;
  margin-bottom: 0.875rem;
`;

const RulesMore = styled.ul`
  display: block;
  list-style: none;
  padding: 0 !important;
`;

const RulesMoreItem = styled.li<{ red?: boolean }>`
  display: grid;
  align-items: top;
  grid-template-columns: min-content 3px 1fr;
  grid-gap: 0.5rem;
  margin-bottom: 0.75rem !important;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: var(--text-primary);

  & > span {
    display: flex;
    width: 1.25rem;
    height: 1.25rem;
    padding: 0.312rem;
    border-radius: 50%;
    background-color: var(--colors-green);
    justify-content: center;

    ${props => props.red && css`
      background-color: var(--colors-red);
    `}

    & svg {
      display: block;
      width: 70%;
    }
  }

  & > i {
      height: 100%;
      background-color: var(--colors-green);

      ${props => props.red && css`
          background-color: var(--colors-red);
      `}
  }
`;

const OpenMoreButton = styled.button`
  display: flex;
  margin: 0px auto;
  width: fit-content;
  padding: 0.1rem 0.8rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 13px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: -0.25rem;

  & svg {
    height: 0.95rem;
    transform: rotate(90deg);
    & path {
      stroke: var(--text-third);
    }
  }

  &:hover svg path {
    stroke: var(--text-primary);
  }
`;
