import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

export default class Icon extends React.Component<{
  icon?: any;
  color?: string;
  dot?: boolean;
  text?: string;
}> {
  private readonly _ref = React.createRef<HTMLDivElement>();

  setIcon(icon: any, color: string) {
    const ref = this._ref.current as HTMLDivElement;
    if (icon) {
      const Icon = icon;
      ReactDOM.render(<Icon fill={color || 'var(--icon-third)'} />, ref);
    } else {
      const Null = () => null;
      ReactDOM.render(<Null />, ref);
    }
  }

  render() {
    const { icon, text, dot } = this.props;
    const I = icon;
    return (
      <IconWrap>
        <span className='text-box'>{text}</span>
        <div className='icon-box' ref={this._ref}>{icon && <I />}</div>
        {dot && <span>&bull;</span>}
      </IconWrap>
    );
  }
}

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--icon-third);
  width: 100%;
  height: 100%;
  /* cursor: pointer; */

  & .text-box {
    position: relative;
    display: inline-block;

    @media (max-width: 1280px) {
      font-size: 0.70rem;
    }
  }
  & svg {
    width: 1rem;
    height: 1rem;
    vertical-align: middle;
    pointer-events: none;
    
    @media (max-width: 1280px) {
      width: 0.80rem;
    }
  }
`;
