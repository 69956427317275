import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {Button as ButtonUI} from 'src/libs';
import store from 'src/store';
import { useTranslation } from 'src/i18n/useTranslation';

type AlignType = 'top' | 'left' | 'right' | 'bottom';

export default function AdvancedTooltipWrapper({
  children = null,
  text = '',
  description = '',
  image = '',
  hotkey = '',
  align = 'top',
  delay = 2000
}: {
  children: any;
  text: string;
  description?: string;
  image?: string;
  hotkey?: string;
  align?: AlignType;
  delay?: number;
}) {
  const tooltipEl = React.useRef<HTMLDivElement>(null);
  const [isAdvanced, setAdvanced] = useState(false);
  const [willAdvanced, setWillAdvanced] = useState(false);
  const { t } = useTranslation();

  let timeoutAdvanced: any;

  useEffect(() => {
    setWillAdvanced(
      store.settings.profile.showHint && (!!description || !!image)
    );
  }, []);

  const onMouseEnter = (_: any) => {
    tooltipEl.current!.style.display = 'flex';
    timeoutAdvanced = (description || image) && willAdvanced && setTimeout(() => {
      setAdvanced(true);
    }, delay);
  };

  const onMouseLeave = (_: any) => {
    tooltipEl.current!.style.display = 'none';
    setAdvanced(false);
    clearTimeout(timeoutAdvanced);
  };

  return (
    <Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {children}
      <Tooltip delay={delay} align={align} ref={tooltipEl} isAdvanced={isAdvanced} willAdvanced={willAdvanced}>
        <Image src={image} />
        <Title>
          {t(text)}
          {hotkey && <ButtonUI style={{ 'marginLeft': '10px' }} bordered size="small" color="transparent" >
            {hotkey}
          </ButtonUI>}
        </Title>
        {isAdvanced && <Description>
          {description}
        </Description>}
      </Tooltip>
    </Wrapper>
  );
}

const Wrapper = styled.span`
  position: relative;
  display: inline-block;
`;

const Tooltip = styled.div<{ align: AlignType; isAdvanced: boolean; delay: number; willAdvanced: boolean }>`
  display: none;
  position: absolute;
  border-radius: 6px;
  background-color: var(--notifications-background);
  color: var(--text-primary);
  padding: 0.6rem 0.8rem;
  font-size: 0.75rem;
  text-align:center;
  line-height: 1.2rem;
  flex-direction: column;
  z-index: 100;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0;
    background: var(--bg-400);
    bottom: 0;
    left: 0;
    z-index: -1;
    ${props => props.willAdvanced && css`
      animation: growUp ${props.delay}ms linear;
    `}
  }
  @keyframes growUp {
    from { height: 0; } to { height:100%; }  
  }
  
  ${props => props.isAdvanced && css`
    padding: 1.2rem 1rem;
    width: 250px;
    
    & > img {
      display: block;
    }
    
    &::after {
      display: none;
    }
  `}

  bottom: 100%;
  margin-bottom: 10px;

  ${props => props.align === 'left' && css`
    left: 0px;
  `}
  ${props => props.align === 'right' && css`
    right: 0px;
    margin-left: 10px;
  `}
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  justify-content: center;
  width: max-content;
`;

const Image = styled.img`
  width: 160px;
  height: 90px;
  display: none;
  margin-bottom: 26px;
`;

const Description = styled.p`
  color: var(--text-secondary);
  font-size: 0.75rem;
  line-height: 1.125rem;
`;
