import React, { useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import Overlay from './Overlay';

import { CancelIcon } from 'src/assets/icons/system';

export default function Modal({
  onClose,
  children,
  useEscape = true,
  closable = true,
  scrollable = true,
  hasPadding = true,
  overflowable = false,
  style,
}: {
  children: any;
  onClose?(): void;
  useEscape?: boolean;
  closable?: boolean;
  scrollable?: boolean;
  overflowable?: boolean;
  hasPadding?: boolean;
  style?: any;
}) {
  // const { state, setState } = app.subscribe({}, {
  //   KEYDOWN: (state, msg) => {
  //     if (msg.data === 27 && useEscape && onClose) {
  //       onClose();
  //     }
  //   }
  // });

  useEffect(() => {
    useEscape && window.addEventListener('keydown', closeHandler);

    return () => {
      useEscape && window.removeEventListener('keydown', closeHandler);
    };
  }, []);

  const _onClose = closable && onClose ? onClose : () => {};

  const closeHandler = (ev: KeyboardEvent) => {
    if (ev.code === 'Escape') { _onClose() }
  };

  return (
    <Overlay onClick={_onClose}>
      <Content hasPadding={hasPadding} style={style}>
        {closable && <Close onClick={_onClose} />}
        <Inner
          isScrollable={scrollable}
          hasPadding={hasPadding}
          isOverflowable={overflowable}
        >
          {children}
        </Inner>
      </Content>
    </Overlay>
  );
}

const modalFadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Content = styled.div<{ hasPadding: boolean }>`
  //max-width: 32.500em;
  max-height: 90%;
  
  ${props => props.hasPadding && css`
    padding: 1em 0;
  `}

  display: flex;

  position: relative;

  z-index: 1;

  background-color: var(--bg-700);
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.14);
  border-radius: 8px;

  animation: ${modalFadeIn} 0.2s ease-in-out;
`;

const Inner = styled.div<{ isScrollable: boolean; hasPadding: boolean; isOverflowable: boolean; }>`
  ${props => props.hasPadding && css`
    padding: 2.250em 2.750rem;
  `}

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${props => props.isScrollable && css`
    overflow-x: hidden;
    overflow-y: auto;
  `}

  ${props => props.isOverflowable && css`
    overflow-x: visible;
    overflow-y: visible;
  `}
  
  ::-webkit-scrollbar {
    width: 14px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.35);
    border-radius: 16px;
    border: 4px solid var(--input-background-active);
  }

  ::-webkit-scrollbar-button {
    display: none;
  }
`;

const Close = styled(CancelIcon)`
  width: 1.125rem;
  height: 1.125rem;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  fill: var(--text-primary);
  cursor: pointer;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`;
