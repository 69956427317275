// FIXME:
// @ts-nocheck

import React from 'react';
import styled from 'styled-components';
import { ReactMemo, useKeyHook } from 'src/hooks';

interface IListItem { mySelected: boolean }
interface FilterByString {
  by: string;
  val: string;
}

export default ReactMemo(function SelctableList({
  activeItem = -1,
  selectedClass = 'selected',
  onSelect,
  onClickSelect,
  children
}: {
  activeItem?: number;
  selectedClass? : string;
  onSelect?: (selected: number) => void;
  onClickSelect?: (selected: number) => void;
  children: React.ReactNode;
}) {
  const color = 'var(--bg-element-hover-hover)';

  const mouseEnterHandler = (idx: number) => {
    updateSelectedItems(idx);
  };

  const clickHandler = (idx: number, evt: any) => {
    evt.stopPropagation();
    onClickSelect?.(idx);
  };

  const [activeItemIdx, setActiveItemIdx] = React.useState<number>(activeItem);

  const ajustedItems = React.useMemo(() => React.Children.toArray(children).map((item, idx) =>
  // console.log('selectable item -', item)
    ({
      mySelected: idx === activeItemIdx,
      reactElement: React.cloneElement(item, {
        onMouseEnter: mouseEnterHandler.bind(this, idx),
        onClick: clickHandler.bind(this, idx)
      })
    })
  ), [children]);

  function updateSelectedItems(selected: number) {
    setActiveItemIdx(selected);
    setItems(ajustedItems.map((item, idx) => {
      item.mySelected = idx === selected;
      return item;
    }));
    onSelect?.(selected);
  }

  const [items, setItems] = React.useState<any[]>(ajustedItems);
  useKeyHook(['ArrowDown', 'ArrowUp'], {
    onKeyDown: (key: any) => {
      const currentSelectedIndex = items.findIndex(({ mySelected }) => mySelected);
      if (key === 'ArrowDown') {
        // если выбран последний, то выбираем первый
        if (currentSelectedIndex === -1 || currentSelectedIndex === items.length - 1) {
          updateSelectedItems(0);
        } else {
          updateSelectedItems(currentSelectedIndex + 1);
        }

      } else if (key === 'ArrowUp') {
        // если не выбран, то выбираем последний
        if (currentSelectedIndex === -1 || currentSelectedIndex === 0) {
          updateSelectedItems(items.length - 1);
        } else {
          updateSelectedItems(currentSelectedIndex - 1);
        }
      }
    }
  }, []);

  function render() {
    return items.map((item, idx) => React.cloneElement(item.reactElement, {
      style: { backgroundColor: item.mySelected ? color : 'initial' },
      className: item.mySelected ? selectedClass : ''
    }));
  }

  React.useEffect(() => {
    if (ajustedItems.length === 1) {
      ajustedItems[0].mySelected = true;
    }
    setItems(ajustedItems);

    ajustedItems.forEach((item, idx) => {
      if (item.mySelected) {
        onSelect?.(idx);
      }
    });
  }, [children]);
  return (
    <>
      { render() }
    </>
  );
});
const Container = styled.div`
  position: relative;

`;
