// FIXME:
// @ts-nocheck

import React from 'react';
import styled from 'styled-components';
import {ReactMemo} from 'src/hooks';

import SelectableList from '../SelectableList';
import { PersonIcon } from 'src/assets/icons/system';
import {IShortFormData} from "src/libs";
import { ICard } from 'src/store/Dashboard';

interface IPerson { id: number; name: string; avatar: string; color: string }

interface FilterByString {
  by: string;
  val: string;
}

export default ReactMemo(function PersonMenu({
  items = [],
  onSelect,
  onClickSelect,
  onEmpty,
  onFill,
  filter
}: {
  items?: IShortFormData[] | ICard[] | null;
  onSelect: (person: IShortFormData) => void;
  onClickSelect?: (person: IShortFormData) => void;
  onEmpty?: () => void;
  onFill?: () => void;
  filter?: FilterByString;
}) {
  const filteredList = React.useMemo(() => items.filter(item => item[filter?.by || 'name']?.toLowerCase().includes(filter ? filter.val.toLocaleLowerCase() : '')), [filter, items]);

  React.useEffect(() => {
    if (!filteredList.length) {
      onEmpty?.();
    } else {
      onFill?.();
    }
  }, [filter]);
  return (
    <Container className="person-menu">
      <SelectableList
        onSelect={(idx: number) => onSelect(filteredList[idx])}
        onClickSelect={(idx: number) => onClickSelect(filteredList[idx])}
      >
        {filteredList.map(({ id, name, color, avatar }, idx: number) =>
          <GroupItem key={`group-item-${idx}`}>
            <div className="ava"><PersonIcon style={{ color }}/></div>
            <div className="name">{name}</div>
          </GroupItem>
        )}
      </SelectableList>
    </Container>
  );
});

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 0.438rem 0.375rem;
  border-radius: 0.375rem;
  background-color: var(--bg-100);
`;

const GroupItem = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  height: 2rem;
  padding-left: 2.25rem;
  padding-right: 0.625rem;
  border-radius: 0.25rem;

  .ava {
    position: absolute;
    width: 1.5px;
    width: 1.5px;
    left: 0.5rem;
    top: 50%;
    transform: translateY(-50%);

    svg {
      width: 1.125rem;
      height: 1.125rem;
      margin-right: 0.813rem;
    }
  }

  .name {
    flex-shrink: 1;
    margin-right: 0.438rem;
    font-size: 0.875rem;
    color: var(--text-primary);
    line-height: 1;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
