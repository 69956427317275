import React, { useEffect, useState, Fragment, ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { ReactMemo } from 'src/hooks';

// @ts-ignore
import { Dispositors } from 'src/helpers/Dispositors';
import { IWidgetData } from 'src/pages/Instruments/Widgets/Widget';
import { default as TW } from 'src/ui/Wrappers/TooltipWrapper';
import { objectsIcons } from 'src/icons';
import { IChain, IChainObject, astroObjects } from 'src/libs';

import {
  ArrowLeft,
  ArrowTop,
  ArrowBottom,
  PathCurve,
  PathHorizontal,
  PathVertical,
  ArrowRight
} from 'src/pages/Instruments/Widgets/Dispositors/paths';
import { ObjectType } from '../../../../../helpers/utils';
import { useTranslation } from 'src/i18n/useTranslation';

// FIXME: duplicate
interface IObjIcon {
  objectId: number;
  position: {
    x0: number;
    y0: number;
    x1: number | null;
    y1: number | null;
  };
  element: ReactElement;
}

export default ReactMemo(function SoulFormula(props: {
  data: IWidgetData;
  onChanged(key: string, value: any): void;
}) {
  const [chains, setChains] = useState<IChain[] | null>(null);
  const { t } = useTranslation();
  useEffect(() => {
    setChains(Dispositors(props.data.maps[0].objects, 0).chains);
  }, [props.data.maps]);

  const RetroIcon = () => <Retro>
    <text x="20" y="20" fontSize="10" color="inherit" textAnchor="middle">{'r'}</text>
  </Retro>;

  const highlightObject = (object: any = null) => {
    props.onChanged(
      'highlights',
      object !== null ? getHighlightedObjects(object) : []
    );
  };

  const getHighlightedObjects = (object: any): any[] => {
    const result: any[] = [];

    const chain = chains?.find(chain => object in chain.objects);

    const generateResult = (obj: any) => {
      result.push({
        id: obj,
        type: 'object',
        map: 'natal'
      });

      chain!.objects[obj].prev.forEach(prev => {
        if (result.some(res => res.id === prev)) { return }
        generateResult(prev);
      });
    };

    generateResult(object);
    return result;
  };

  const isOffset = chains?.some(chain => Object.keys(chain.objects).reduce((acc, object) => {
    if (chain.objects[object].center) { return ++acc }
    return acc;
  }, 0) > 2);

  const generateArrow = (elements: any[]) => elements.map(element => {
    const { x0, x1, y0, y1 } = element.position;

    if (x0 === x1 && y0 === y1 || x1 === null || y1 === null) { return }

    const getVerticalPath = (isCenter: boolean) => {
      const content = [];

      const hasBarrier = elements.some(element => isCenter && element.position.x0 === x0 &&
            (element.position.y0 > y0 && element.position.y0 < y1 || element.position.y0 < y0 && element.position.y0 > y1));

      if (hasBarrier) {
        content.push(
          <Cell column={x0 * 2} row={y0 * 2 + 1} key={`curve_with_barrier_${x0}_${y0}`}>
            <Vector>
              <PathCurve direction={y1 < y0 ? 'bottom' : 'top'} />
            </Vector>
          </Cell>,
          <Cell column={x0 * 2} row={y1 * 2 + 1} key={`arrow_with_barrier_${x0}_${y0}`}>
            <Vector>
              <ArrowRight />
              <PathCurve direction={y1 > y0 ? 'bottom' : 'top'} />
            </Vector>
          </Cell>
        );
      }

      const initial = y0 * 2 + 1 - (y1 * 2 + 1) - (y0 > y1 ? 1 : -1);

      for (let i = initial; i !== 0; y0 > y1 ? i-- : i++) {
        const row = y0 * 2 + 1 - i;

        content.push(
          <Cell
            column={!hasBarrier ? isCenter ? x0 * 2 + 1 : x0 * 2 - 1 : 2}
            row={row}
            key={`arrow_${x0}_${y0}_${x0 !== x1 ? x0 * 2 - 1 : 1}_${i}`}
          >
            <Vector>
              {i === initial && y0 > y1 && !hasBarrier && <ArrowTop />}
              <PathVertical />
              {i === initial && y0 < y1 && !hasBarrier && <ArrowBottom />}
            </Vector>
          </Cell>
        );
      }

      return content;
    };

    return (
      <Fragment key={`frag_${x0}_${y0}`}>
        {x0 !== x1 && <Cell column={x0 * 2} row={y0 * 2 + 1} key={`arrow_horiz_${x0}_${y0}`}>
          <Vector>
            {y0 === y1 && <ArrowLeft />}
            <PathHorizontal />
          </Vector>
        </Cell>}
        {y0 !== y1 && x0 !== x1 && <Cell column={x0 * 2 - 1} row={y0 * 2 + 1} key={`curve_${x0}_${y0}`}>
          <Vector>
            <PathCurve />
          </Vector>
        </Cell>}
        {y0 !== y1 && getVerticalPath(x0 === x1)}
      </Fragment>
    );
  });

  // FIXME: duplicate
  const renderChain = (objects: Record<string, IChainObject>): any[] => {
    const elements: IObjIcon[] = [];
    const passed: string[] = [];

    const chainSize = {
      x: 0,
      y: 0
    };

    const centers: string[] = [];

    const firstCenter: string = Object.keys(objects)
      .find(object => objects[object].center)!;

    const sortCenters = (object: string) => {
      if (centers.includes(object)) { return }

      centers.push(object);

      objects[object].prev.forEach((prev: number) => {
        if (objects[prev].center) { sortCenters(prev.toString()) }
      });
    };

    sortCenters(firstCenter);

    const getObjIcon = (
      objectId: number,
      x0: number,
      y0: number,
      center: boolean,
      minor: boolean,
      orbit: number
    ): IObjIcon => {
      const ObjIcon = objectsIcons[objectId];
      const [{ strongs }] = props.data.maps;

      return {
        objectId,
        position: {
          x0, y0, x1: null, y1: null
        },
        element:
          <Cell onMouseEnter={() => highlightObject(objectId)} onMouseLeave={() => highlightObject()} column={x0 * 2 + 1} row={y0 * 2 + 1} key={`icon_${x0}_${y0}`}>
            <TW text={t(astroObjects[objectId].ru)}>
              <Icon center={center} minor={minor} home={orbit + 1 === objectId}>
                <ObjIcon />
                {minor && <RetroIcon />}
                <span>{strongs?.[objectId]}</span>
              </Icon>
            </TW>
          </Cell>
      };
    };

    const generateObject = (
      object: string,
      x0 = 0,
      y0 = 0,
    ) => {
      if (+object === ObjectType.SouthNode) return;
      if (passed.includes(object)) return;

      if (elements.some((el: any) => el.position.x0 === x0 && el.position.y0 === y0)) {
        y0 = chainSize.y + 1;
      }

      passed.push(object);
      elements.push(getObjIcon(Number(object), x0, y0, objects[object].center, objects[object].minor, objects[object].orbit));
      x0++;

      let passedCenters = false;
      objects[object].prev.map((obj: string | number, i: number) => {
        obj = obj.toString();
        if (objects[obj].center) {
          passedCenters = true;
          return;
        }

        y0 += i === 0 || passedCenters ? 0 : 1;
        while (elements.some((el: any) => el.position.x0 - x0 === 1 && el.position.y0 - y0 >= 0)) { y0 += 1 }

        generateObject(obj, x0, y0);
      });

      chainSize.x = chainSize.x < x0 ? x0 : chainSize.x;
      chainSize.y = chainSize.y < y0 ? y0 : chainSize.y;
    };

    centers.map(object => {
      generateObject(object, isOffset ? 1 : 0);
    });

    elements.map(element => {
      objects[element.objectId].prev.forEach(prev => {
        if (prev === ObjectType.SouthNode) return;

        const prevElement = elements.find(el => el.objectId === prev);

        prevElement!.position.x1 = element.position.x0;
        prevElement!.position.y1 = element.position.y0;
      });
    });

    return [
      elements.map(el => el.element),
      generateArrow(elements)
    ];
  };

  const getSizeOfChains = () => {
    let max = 0;
    chains && chains.forEach(chain => {
      Object.keys(chain.objects).forEach(key => {
        if (chain.objects[key].orbit > max) max = chain.objects[key].orbit;
      });
    });
    return max;
  };

  const Centers = (() => {
    const centers = [];
    // const maxSize = isOffset ? 7 : 8;
    const size = getSizeOfChains() + 2 < 7 ? 7 : getSizeOfChains() + 2;
    for (let obj = 2; obj < size; obj++) {
      const Icon = objectsIcons[obj];
      centers.push(
        <Center key={`center_${obj}`}>
          <Icon />
        </Center>
      );
    }
    return centers;
  })();

  const Columns = (() => {
    const columns = [];
    // const maxSize = isOffset ? 7 : 8;
    const size = getSizeOfChains() + 2 < 7 ? 7 : getSizeOfChains() + 2;
    for (let obj = 2; obj < size; obj++) {
      columns.push(
        <Column key={`column_${obj}`} />
      );
    }
    return columns;
  })();

  return (
    <Container>
      <Grid>
        <Header>
          <Center wide={isOffset}>{t("base.center")}</Center>
          {Centers}
        </Header>
        <GridBody>
          <Column wide={isOffset} />
          {Columns}
        </GridBody>
      </Grid>
      {
        chains?.map((chain: IChain, i: number) =>
          <Row key={`chain_${i}`} isOffset={isOffset}>
            {renderChain(chain.objects)}
          </Row>
        )
      }
    </Container>
  );
});

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  height: 100%;
`;

const GridBody = styled.div`
  display: flex;
  height: 100%;
`;

const Column = styled.div<{ wide?: boolean }>`
  display: flex;
  width: 4rem;
  box-sizing: border-box;
  border-right: 1px solid var(--element-neutral);

  &:nth-last-child(1) {
    border-right: none;
  }
  ${props => props.wide && css`
    width: 6rem !important;
  `}
`;

const Header = styled.div`
  display: flex;
  border-bottom: 1px solid var(--element-neutral);
`;

const Center = styled.div<{ wide?: boolean }>`
  display: flex;
  padding: 1rem 0;
  width: 4rem;
  box-sizing: border-box;
  border-right: 1px solid var(--element-neutral);
  justify-content: center;
  
  &:nth-child(1) {
    width: 4rem;
  }
  &:nth-last-child(1) {
    border-right: none;
  }
  ${props => props.wide && css`
    width: 6rem !important;
  `}
  > svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
  overflow: hidden;
`;

const Row = styled.div<{ isOffset: boolean | undefined }>`
  display: grid;
  grid-template-columns: ${props => props.isOffset && '0'} repeat(auto-fill, 2rem);
  margin-bottom: 2rem;
  grid-auto-rows: 2rem;
  margin-left: 1rem;
`;

const Icon = styled.div<{center: boolean; minor: boolean; home: boolean}>`
  display: flex;
  width: 2rem;
  height: 2rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  background: var(--input-background);
  position: relative;

  border: 1px ${props => props.home && !props.center ? 'dashed' : 'solid'} ${props => props.center || props.home ? 'var(--accent-blue)' : 'var(--input-border)'};
  border-radius: 4px;
  font-size: 0.875rem;
 
  & > svg {
    width: 1.125rem;
    color: ${props => props.minor ? 'var(--colors-red)' : 'var(--text-primary)'};
    fill: ${props => props.minor ? 'var(--colors-red)' : 'var(--text-primary)'};
  }
  & > span {
    display: flex;
    position: absolute;
    top: -1px;
    right: 2px;
    font-size: 0.6rem;
    color: ${props => props.minor ? 'var(--colors-red)' : 'var(--text-primary)'};
    user-select: none;
  }
`;

const Cell = styled.div<{column: number; row: number}>`
  display: flex;
  align-items: center;
  grid-column-start: ${props => props.column};
  grid-column-end: ${props => props.column};
  grid-row-start: ${props => props.row};
  grid-row-end: ${props => props.row};
`;

const Vector = styled.svg.attrs(_ => ({
  viewBox: '0 0 20 20',
  width: '2rem',
  height: '2rem',
  fill: 'none',
  stroke: 'currentColor'
}))<{ viewBox: string; width: string; height: string; fill: string ; stroke: string }>``;

const Retro = styled.svg.attrs(_ => ({
  viewBox: '0 0 20 20',
  width: '2rem',
  fill: 'var(--colors-red)',
  color: 'var(--colors-red)'
}))`
  position: absolute;
  stroke-width: 0;
  overflow: initial;
`;
