import { IAuth } from 'src/libs';
import { observer } from 'mobx-react';
import React from 'react';
import { YMInitializer } from 'react-yandex-metrika';
import { useTranslation } from 'src/i18n/useTranslation';
import store from 'src/store';

interface IYandexParams {
  partner: boolean;
  partner_id?: string;
}

const ymID = 49958389;

export default observer(function AppMetrics({
  auth
}: {
  auth: IAuth
}) {
  const partnerId = store.settings.user.profile.partner;

  let partnerParams: IYandexParams =  {
    partner: !!partnerId
  }
  if(partnerId) partnerParams.partner_id = partnerId;

  const t = useTranslation();

  const subscriptions = [
    t.t("chronos.app.ArchivedTariff"),
    t.t("chronos.app.AmateurTariff"),
    t.t("chronos.app.ProfessionalTariff"),
    t.t("chronos.app.UnlimitedTariff")
  ];

  const id = auth.id;
  const subscription = auth.subscriptions ? subscriptions[Math.max(...auth.subscriptions)] : t.t("chronos.app.noTariff");

  return <YMInitializer
    accounts={[ymID]}
    options={{
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
      userParams: {
        UserID: id,
        package: subscription,
        beta: false,
        ...partnerParams
      }
    }}
  />
})