import React from 'react';

import astro from 'src/astro';
import { ReactMemo } from 'src/hooks';
import { signsIcons } from 'src/icons';

import { getLocalTime, SignType, DateTimeInput, TInputSizeType } from 'src/libs';
import { IPlace } from 'src/api';

export default ReactMemo(function BirthDayInput(props: {
  value: string;
  gmt?: number;
  place?: IPlace | null;
  onChange(v: string): void;
  onInvalid?(): void;
  autoFocus?: boolean;
  size?: TInputSizeType;
  onBlur?(e: any): void;
  disabled?: boolean;
  hideIcon?: boolean;
}) {
  const [sign, setSign] = React.useState<SignType | null>(null);

  React.useEffect(() => {
    if (!props.value) return;
    
    astro.birthDaySign(getLocalTime(
      props.value,
      props.gmt ?? 0,
      props.place?.lat as number,
      props.place?.lon as number,
    )).then(setSign);
  }, [props.value, props.gmt, props.place]);

  return (
    <DateTimeInput
      size={props.size || 'medium'}
      type='date'
      value={props.value}
      onChange={props.onChange}
      onInvalid={props.onInvalid}
      utcMode
      hideIcon={props.hideIcon}
      icon={sign !== null ? signsIcons[sign] : null}
      minYear={1200}
      maxYear={2400}
      autoFocus={props.autoFocus}
      selectBeginning={props.autoFocus}
      onBlur={props.onBlur}
      disabled={props.disabled}
    />
  );
});
