import React from 'react';
import styled from 'styled-components';

import Info from './Info';
import Payment from './Payment';

import { CloseIcon } from 'src/assets/icons/system';
import { IGenInfo } from 'src/libs';


interface GenDialogProps {
  title: string;
  info: IGenInfo;
  onBuild(v: boolean): void;
}

enum GenPage {
  Info,
  Payment
}

export default function GenDialog(props: GenDialogProps) {
  const [page, setPage] = React.useState(GenPage.Info);
  // const InstrumentPageContext = React.useContext(InstrumentsContext);

  return (
    <Content>
      {page == GenPage.Payment &&
        <header>
          <CloseIconWrap onClick={() => setPage(GenPage.Info)} />
        </header>
      }
      <main>
        {page == GenPage.Payment ?
          <Payment
            info={props.info}
            onSuccess={() => {
              props.onBuild(false);
              setPage(GenPage.Info);
            }}
          /> :
          <Info
            info={props.info}
            onAdd={() => {
              setPage(GenPage.Payment);
              // if (props.info.prices) {
              //   setPage(GenPage.Payment)
              // } else {
              //   InstrumentPageContext.setShowTariffsPopup(true)
              // }
            }}
            onBuild={() => props.onBuild(true)}
          />
        }
      </main>
    </Content>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--text-primary);

  padding: 1em;
  width: 90%;
  max-width: 28em;

  margin: auto;
  
  border-radius: 0.5em;

  background-color: var(--bg-700);

  header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  main {
    margin: auto;
  }

  z-index: 1;
`;

const CloseIconWrap = styled(CloseIcon)`
  width: 0.85em;

  cursor: pointer;
  color: var(--icon-third);

  box-sizing: content-box;
  transition: fill 0.2s linear;

  &:hover {
    fill: var(--icon-primary);
  }
`;
