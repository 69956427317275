import React from 'react';
import { useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import Markdown from 'react-markdown';

import { Button, INotification, Input, TColorType } from 'src/libs';
import SvgButton from 'src/ui/SvgButton';

import {
  SuccessIcon,
  ErrorIcon,
  WarningIcon,
  InfoIcon,
  StarIcon
} from 'src/assets/icons/notifications';

import { ArrowLeftIcon, CancelIcon } from 'src/assets/icons/system';
import { useTranslation } from 'src/i18n/useTranslation';
import { observer } from 'mobx-react-lite';
import store from 'src/store';

interface IAction {
  title: string;
  color: TColorType;
  action: () => void;
}

// FIXME
const icons: {
  [key: string]: any;
} = {
  warning: (color: string) => <WarningIcon
    fill={color || 'var(--colors-orange)'}
  />,
  success: (color: string) => <SuccessIcon
    fill={color || 'var(--colors-green)'}
  />,
  error: (color: string) => <ErrorIcon
    fill={color || 'var(--colors-red)'}
  />,
  info: (color: string) => <InfoIcon
    fill={color || 'var(--colors-blue)'}
  />
};

export default observer(function MultiNotifications(props: {
  onClose(items: INotification[]): void;
  items: INotification[];
}) {
  const [index, setIndex] = React.useState(0);
  const [items, setItems] = React.useState(props.items);
  const [selectedStar, setSelectedStar] = React.useState(0);
  const [comment, setComment] = React.useState('');

  const { t } = useTranslation();
  const history = useHistory();

  React.useEffect(() => {
    setIndex(0);
    setItems(props.items);
  }, [props.items]);
  const onClose = () => {
    props.onClose(items.slice(0, index + 1));
  };

  React.useEffect(()=> {
    setItems(store.notifications)
  }, [store.notifications])
  const actions: {
    [key: string]: IAction;
  } = {
    open_payments: {
      title: t("chronos.app.payments.goToPayment"),
      color: '#EF5350',
      action: () => {
        history.push('/settings/payments');
        onClose();
      }
    }
  };

  
  const updateItemComment = async (v: string) => {
    await setComment(v);
    setItems(items.map((item, id) => id === index ? {
      ...item,
      value: {...item.value, comment: comment}
    } : item))
  }

  const onPrev = () => setIndex(index - 1);

  const onNext = () => setIndex(index + 1);

  const item = items[Math.min(index, items.length - 1)];

  const _actions = item.actions || [];
  const __actions = _actions.filter(a => a !== 'estimate');

  const stars = () => {
    const star = parseInt(item.value?.value || '0', 10);

    const stars = [];

    for (let i = 1; i <= 10; i++) {
      stars.push(<StarIcon
        key={`star_${i}`}
        fill={
          star === 0 && i <= selectedStar ? '#F1960E' : 
          i <= star ? '#F1960E' : 
          'transparent'
        }
        onMouseEnter={() => {
          setSelectedStar(i);
        }}
        onClick={() => {
          setItems(items.map((item, id) => id === index ? {
            ...item,
            value: {
              value: i.toString(), 
              comment: comment
            }
          } : item));
        }}
      />);
    }

    return (
      <Stars  
        onMouseLeave={() => {
        setSelectedStar(0);
        }}
      >
        {stars}
        {<span style={{ visibility: star ? 'visible' : 'hidden' }}>{star}</span>}
      </Stars>
    );
  };

  return (
    <Wrap>
      <Main>
        <Header>
          <Icon>
            {icons[item.icon || 'warning'](item.color)}
          </Icon>
          <CloseButton onClick={onClose}>
            <span>{t('base.close')} </span>
          <Close>
          </Close>

          </CloseButton>
        </Header>

        <Title>{t(item.title)}</Title>

        <Text source={t(item.text)} />

        {_actions.includes('estimate') && stars()}

        {item.id.includes('nps_2_show_') && item.value && <div>
          <Text>{t("chronos.app.review.title")}</Text>
          <Input value={comment} onChange={updateItemComment} />
          <Button
            key={`btn`}
            onClick={onClose}
            color={'#EF5350'}
            style={{ marginTop: '1rem' }}
          >{t("chronos.app.consultations.notification.9.submit")}
          </Button></div>}
        {__actions.length > 0 &&
          <Buttons>
            {__actions.map(a => {
              const act = actions[a];
              return act &&
                <Button
                  key={`btn_${a}`}
                  onClick={act.action}
                  color={act.color}
                >{act.title}</Button>
              ;
            })}
          </Buttons>
        }
      </Main>

      {items.length > 1 &&
        <Footer>
          <HorGroup>
            {items
              .map((_, i) =>
                <Dot
                  key={`dot_${i}`}
                  style={{ backgroundColor: i === index ? 'var(--colors-blue)' : 'var(--text-third)' }}
                />
              )}
          </HorGroup>
          <HorGroup>
            <SvgButton
              onClick={onPrev}
              icon={<ArrowLeftIcon />}
              disabled={index === 0}
            />
            <ArrowSep />
            <SvgButton 
              onClick={onNext}
              icon={<ArrowLeftIcon style={{ transform: 'rotate(180deg)' }} />}
              disabled={index === items.length - 1}
            />
          </HorGroup>
        </Footer>
      }
    </Wrap>
  );
});

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-10em);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const Wrap = styled.div`
  max-width: 95%;
  padding: 1.375rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  position: fixed;
  left: 1.25em;
  bottom: 1.25em;

  color: var(--text-primary);
  background-color: var(--notifications-background);

  border-radius: 0.5em;

  /* FIXME: light box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2) */

  z-index: 10;

  animation: ${fadeIn} 0.25s ease-in-out;

  transition: all 0.3s linear;
`;

const Header = styled.header`
  display: flex;
  width: 100%;
  justify-content: space-between;
  span {
    color: var(--text-secondary);
    font-size: 0.875rem;
  }
`;

/* Fixme */
const Icon = styled.div`
  width: 1.313rem;
  height: 1.313rem;
  margin-bottom: 0.875rem;

  svg:first-child {
    width: 100%;
    height: 100%;
  }

`;

const Title = styled.h4`
  margin-bottom: 0.313rem;
  color: var(--text-primary);
`;

const Text = styled(Markdown)`
  color: var(--text-secondary);
  font-size: 0.875rem;
`;

const Buttons = styled.div`
  width: 100%;
  margin-top: 0.75rem;
  
  display: flex;
  align-items: center;
`;

const HorGroup = styled.div`
  display: flex;

  svg {
    width: 0.875rem;
    height: 0.875rem;
    padding: 0.313rem;

    fill: var(--text-primary);

    box-sizing: content-box;
  }
`;

const ArrowSep = styled.div`
  width: 1em;
`;

const Dot = styled.div`
  width: 6px;
  height: 6px;
  margin-right: 0.75rem;

  border-radius: 50%;
`;

const Main = styled.main`
  flex: 1;
  width: 100%;
  height: 100%;
`;

const Footer = styled.footer`
  width: 100%;
  margin-top: 0.875rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Stars = styled.div`
  width: 17.3rem;
  margin-top: 0.5em;

  svg {
    margin-right: 0.25em;
    cursor: pointer;
  }

  span {
    min-width: 1.2em;
    font-size: 1.2em;
    color: #F1960E;
    margin-left: 0.25em;
  }

  display: flex;
  align-items: center;
`;

const Close = styled(CancelIcon)`
  width: 0.8rem;
  height: 0.8rem;

  fill: var(--text-primary);


`;

const CloseButton = styled.div`
  fill: var(--text-primary);

  cursor: pointer;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
  span {
    margin-right: 0.5rem;
  }
`;
