import { IComputedRadius, ICustomizationData } from 'src/api';

export const defaultComputedRadius: IComputedRadius = {
  internal: 0.7,
  zodiacsInternal: 0.91,
  zodiacsExternal: 1,
  external: 1,

  internalExt: 0.55,
  zodiacsInternalExt: 0.77,
  zodiacsExternalExt: 0.86
};

export default {
  circle: {
    scale: {
      min: 0.5,
      max: 3,
      step: 0.05
    },

    gap: 250,

    radius: {
      circle: 360,

      houses: 1.16,
      cusps: 1.1,
      external: 1,

      zodiacsExternal: 1,
      zodiacsInternal: 0.91,
      internal: 0.7,
      internalHorar: 0.35,
      internalSoul: 0.1,

      zodiacsExternalExt: 0.86,
      zodiacsInternalExt: 0.77,
      internalExt: 0.55,

      modifier: {
        objects: 1,
        zodiacs: 1,
        objectsExt: 1
      }
    },

    zodiacs: {
      gap: 0.5,
      iconSize: 0.05,
      style: 1
    },

    houses: {
      width: 1,
      length: 1
    },

    objects: {
      iconSize: 0.065, // 0.065
      pointSize: 0.008,
      strongSize: 0.006,
      style: 1,
      location: 0
    },

    aspects: {
      icon: 0.05,
      width: 1
    },

    colorThemes: {
      maps: {
        active: 2
      },
      zodiacs: {
        active: 0
      },
      houses: {
        active: 0
      },
      objects: {
        active: 0
      },
      aspects: {
        active: 0
      }
    }
  }
} as ICustomizationData;
