import React from 'react';
import { IAspect } from 'src/libs';
import styled from 'styled-components';

export interface ILocalTrend {
  actualTo: string;
  affect?: string;
  aspect?: IAspect;
  compensation: string | null;
  items?: (string | {
    marker?: string;
    text?: string;
  })[];
  reason: string | null;
  relevance: string | number;
  trendName: string;
}

export interface ILocalTrends {
  date: string;
  time: string;
  trends?: ILocalTrend[];
  heading: string;
  comment: string;
  compensationIntro: string;
}

const LocalTrends = (props: ILocalTrends) => {
  return (
    <div className="trends">
      <h1>{props.heading}</h1>
      <div className="check-boxes-comment">{props.comment}</div>
      <br/>
      <div className="local-trends">
        {props.trends?.map((t, i) => (
            <div key={`trent_${i}`} className="local-trend">
              {t.trendName &&
                <h2>{t.trendName}:</h2>
              }
              <span className="actual">
                {t.actualTo}
              </span>
              {t.reason &&
                <p className="reason ap-reasons">
                  {t.reason}
                </p>
              }
              <ul className="list-wrap">
                {t.items?.map((item, j) => (
                  <li key={`trent_${i}_item_${j}`} className="list-item">
                    {typeof item === "string" ?
                      item :
                      `${item.marker} ${item.text}`
                    }
                  </li>
                ))
                }
                <br/>
              </ul>

              {t.affect && <Affect className="ap-affects">{t.affect}</Affect>}

              {t.compensation &&
                <p className="compensation">
                  ! {t.compensation}
                </p>
              }
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default LocalTrends;

const Affect = styled.p`
  margin-bottom: 3rem;
`