import { UserRegion, TInputMode, Button, IDropdownOption, Input, InputContainer } from 'src/libs';
import { countries } from 'country-flag-icons';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { getName } from 'country-list';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import api from 'src/api';
import { useTranslation } from 'src/i18n/useTranslation';
import store from 'src/store';
import Alert from 'src/ui/Alert';
import Modal from 'src/ui/Modal';
import { changeLanguage } from 'i18next';
import { OkIcon } from 'src/assets/icons/system';
import { WorldIcon } from 'src/assets/icons/system/32';

const getSvgElement = (src: string) => {
  return <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
    <image xlinkHref={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${src}.svg`} width="24" height="24" />
  </svg>;
}

const countriesList: IDropdownOption[] = countries
  .filter(item => item && getName(item))
  .map(item => {
    return {
      label: getName(item),
      value: item.toLocaleLowerCase(),
      icon: getSvgElement(`${item}`)
  }})
  .sort((item1, item2) => {
    if(!item1.label || !item2.label || item1.label === item2.label) {
      return 0;
    }
    return item1.label > item2.label ? 1 : -1;
  });

const languagesList: IDropdownOption[] = [{
  label: 'chronos.app.settings.sideBar.russian',
  value: 'ru'
},{
  label: 'chronos.mobile.english',
  value: 'en'
},{
  label: 'chronos.mobile.portuguese',
  value: 'pt'
}, {
  label: 'chronos.mobile.spanish',
  value: 'es'
}]

export default function RegionSelectModal({
  onClose
}: {
  onClose(): void;
}) {
  const [country, setCountry] = useState('');
  const [countrySelected, setCountrySelected] = useState(false);
  const [countryError, setCountryError] = useState(false);

  const [hiddenInputValue, setHiddenInputValue] = useState('');
  const [inputMode, setInputMode] = useState('');

  const [language, setLanguage] = useState('');
  const [languageError, setLanguageError] = useState(false);

  const [step, setStep] = useState(0);
  const [showError, setShowError] = useState(false);
  const timeoutId: { current: any } = useRef();
  const inputTimer: { current: any } = useRef();

  const { t } = useTranslation();

  const inputCountryRef = useRef<HTMLDivElement>(null)
  const inputLanguageRef = useRef<HTMLDivElement>(null)
  const hiddenInput = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if ((inputCountryRef && inputCountryRef.current) && (inputLanguageRef && inputLanguageRef.current)) {
      inputCountryRef.current.addEventListener('click', () => makeHiddenInputFocus('country'))
      inputLanguageRef.current.addEventListener('click', () => makeHiddenInputFocus('language'))

      return () => {
        inputCountryRef.current && inputCountryRef.current.removeEventListener('click', () => makeHiddenInputFocus('country'))
        inputLanguageRef.current && inputLanguageRef.current.removeEventListener('click', () => makeHiddenInputFocus('language'))
      }
    }
  }, [inputCountryRef, inputLanguageRef])

  useEffect(() => {
    languageError && setLanguageError(false)
  }, [language])

  const confirmFirstStep = async () => {
    if (!countrySelected || !language) {
      !countrySelected && setCountryError(true)
      !language && setLanguageError(true)
      return
    }

    try {
      const responseData: any = await api.setUserRegion({
        language: language as UserRegion.Language,
        region: country as UserRegion.Region,
      });
      if(responseData?.id) {
        store.settings.user.setRegion(responseData);
        changeLanguage(responseData.language);
      }
      setStep(1);
    } catch {
      setShowError(true);
      if(timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
      timeoutId.current = setTimeout(() => {
        setShowError(false);
      }, 3000);
    }
  };

  const checkMode = (error: boolean): TInputMode => {
    return error ? 'error' : 'normal'
  }

  const onChangeCountryHandler = (country: string) => {
    const idx = countriesList.findIndex(o => o.label?.toLocaleLowerCase().indexOf(country.toLocaleLowerCase()) === 0);
    const selectedCountry = countriesList.find(item => item.value === country)?.value ?? countriesList[idx]?.value;

    if (inputTimer.current) {
      clearTimeout(inputTimer.current);

    }

    inputTimer.current = setTimeout(() => {
      setHiddenInputValue('');
    }, 1000)

    if (selectedCountry) {
      setCountry(selectedCountry);
      setCountrySelected(true);
    } else {
      setCountry('');
      setHiddenInputValue('');
    }

    setCountryError(false);
  }

  const onSelectCountryHandler = (value: any) => {
    setCountrySelected(!!value)

    const countryLanguage = languagesList.find(item => item.value === value)
    if (countryLanguage?.value && !language) setLanguage(countryLanguage.value)
  }

  const onChangeLanguageHandler = (language: string) => {
    const idx = languagesList.findIndex(o => o.label && t(o.label)?.toLocaleLowerCase().indexOf(language.toLocaleLowerCase()) === 0)
    const selectedLanguage = languagesList.find(item => item.value === language)?.value ?? languagesList[idx]?.value

    if (selectedLanguage) {
      setLanguage(selectedLanguage)
    } else {
      setLanguage('');
      setHiddenInputValue('');
    }
  }

  const inputOnChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase()

    setHiddenInputValue(value)

    if (inputMode === 'country') {
      onChangeCountryHandler(value)
    } else {
      onChangeLanguageHandler(value)
    }
  }

  const makeHiddenInputFocus = (mode: string) => {
    setHiddenInputValue('')
    setInputMode(mode)

    if (hiddenInput && hiddenInput.current) {
      hiddenInput.current.focus()
    }
  }

  return <>
    <Modal closable={false}>
      <Container>
      <HiddenInput type="text" value={hiddenInputValue} onChange={inputOnChangeHandler} ref={hiddenInput}/>
        <Inner>
          {step === 0 && <>
            <IconWrapper><WorldIcon /></IconWrapper>
            <Title>{t('chronos.app.selectCountryTitle')}</Title>
            <SubTitle>{t('chronos.app.selectCountryDescription')}</SubTitle>
            <Cells>

              <CustomInputContainer label={t('chronos.app.country')} message={countryError ? t('chronos.app.selectCountry') : ''} containerRef={inputCountryRef}>
                <Input
                  value={country}
                  icon={countriesList.find(c => country === c.value)?.icon}
                  options={countriesList}
                  onChange={onChangeCountryHandler}
                  placeholder={t('chronos.app.selectCountry')}
                  onSelect={onSelectCountryHandler}
                  mode={checkMode(countryError)}
                  onFocus={() => setCountryError(false)}
                />
              </CustomInputContainer>

              <CustomInputContainer label={t('chronos.mobile.language')} message={languageError ? t('chronos.app.selectLanguage') : ''} containerRef={inputLanguageRef}>
                <Input
                  value={language}
                  options={languagesList.map(item => ({
                    ...item,
                    label: item.label ? t(item.label) : '',
                  }))}
                  onChange={onChangeLanguageHandler}
                  placeholder={t('chronos.app.selectLanguage')}
                  mode={checkMode(languageError)}
                  onFocus={() => setLanguageError(false)}
                />
              </CustomInputContainer>

            </Cells>
            <Button style={{width: '100%'}} onClick={confirmFirstStep}>{t('base.confirm')}</Button>
          </>}
          {step === 1 && <>
            <IconWrapper color={'green'}><OkIcon /></IconWrapper>
            <Title>{t('chronos.app.regionSelected')} {getName(country)}</Title>
            <SubTitle>{t('chronos.app.regionSupport')}</SubTitle>
            <Button style={{width: '100%'}} onClick={onClose}>{t('chronos.app.continueApp')}</Button>
          </>}
        </Inner>
      </Container>
    </Modal>
    {showError &&
      <StyledAlert className="fixed-alert">
        <Alert type="warning">
          <p>{t('chronos.app.somethingWentWrong')}</p>
        </Alert>
      </StyledAlert>
    }
  </>
}

const Container = styled.div`
  width: 28rem;
  height: 28rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-secondary);
`

const Inner = styled.div`
  width: 22.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const IconWrapper = styled.div<{color?: string}>`
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  background: ${p => p.color ? `var(--colors-${p.color})` : `var(--bg-300)`};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;

  svg {
    width: 2rem;
    height: 2rem;
  }
`

const Title = styled.div`
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: var(--text-primary);
  margin-bottom: 0.75rem;
  text-align: center;
`

const SubTitle = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 2rem;
  text-align: center;
`

const Cells = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-gap: 0.75rem;
  margin-bottom: 1.125rem;
`

const Label = styled.div`
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
`

const StyledAlert = styled.div`
  position: fixed;
  bottom: 0.5rem;
  max-width: 48rem;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
`;

const CustomInputContainer = styled(InputContainer)`
	position: relative;
`;

const Place = styled.div`
	color: var(--text-secondary);
	border-radius: 0.25em;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	text-align: left;
	align-items: center;
	cursor: pointer;

	&:hover,
	&.selected {
		color: var(--text-primary);
		background-color: var(--bg-element-hover-hover);
	}

  span {
    margin-left: 0.5rem;
  }
`;

const IconContainer = styled.div`
  position: absolute;
  top: 2.2rem;
  right: 0.8rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`

const HiddenInput = styled.input`
  position: absolute;
  opacity: 0;
`
