import React, { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { ChevronRightIcon as ShevronIcon } from 'src/assets/icons/system/24';
import store from 'src/store';

export interface ITab {
  title: string;
  id: string;
  component: any;
}

const isProgression = (str: any) => str === 'prog_natal' || str === 'prog_prog';

const getSelectedTab = (selected?: string, tabs?: ITab[]) => {
  if(selected && tabs?.find(t => t.id === selected)) {
    if (selected === 'prog_prog' && tabs?.find(t => t.id === 'prog_natal')) return 'prog_natal'
    return selected;
  }
  if(store.instruments.widgetsTab && tabs?.find(t => t.id === store.instruments.widgetsTab)) {
    return store.instruments.widgetsTab;
  }
  return tabs ? tabs[0]?.id : null;
}

export default function TabPanel(props: {
  tabs: ITab[];
  selected?: string;
  onSelect?(id: string): void;
  containerStyle?: {},
  contentStyle?: {},
}): any {
  const [selected, setSelected] = useState(getSelectedTab(props.selected, props.tabs));

  const [step, setStep] = useState<number>(0);

  const containerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const currentRef = useRef<HTMLLIElement>(null);
  const prevRef = React.useRef<HTMLDivElement>(null);
  const nextRef = React.useRef<HTMLDivElement>(null);

  let hasProgression = false;

  useEffect(() => {
    const newSelected = getSelectedTab(props.selected, props.tabs);
    newSelected && setSelected(newSelected);
  }, [props.selected]);

  React.useEffect(() => {
    showChevrons();
    showStep();
  }, [props.tabs]);

  const onSelect = (id: string) => {
    setSelected(id);
    props.onSelect?.(id);
  };

  const moveSlider = (step: number) => {
    let x = 0;
    if (step === 1) {
      x = listRef.current!.offsetWidth - containerRef.current!.offsetWidth;
    }

    listRef.current!.style.transform = `translateX(-${x}px)`;
  };
  React.useEffect(() => {
    showChevrons();
    moveSlider(step);
  }, [step]);

  const showChevrons = () => {
    if (step === 0) {
      prevRef.current!.style.display = 'none';
      if (containerRef.current!.offsetWidth &&
        (listRef.current!.offsetWidth > containerRef.current!.offsetWidth)
      ) {
        nextRef.current!.style.display = 'block';
      } else {
        nextRef.current!.style.display = 'none';
      }
    } else if (step === 1) {
      prevRef.current!.style.display = 'block';
      nextRef.current!.style.display = 'none';
    }
  };

  const showStep = () => {
    if (currentRef.current) {
      const left = currentRef.current.offsetLeft;
      const width = currentRef.current.offsetWidth;
      const containerWidth = containerRef.current!.offsetWidth;
      if (containerWidth && (left + width > containerWidth)) {
        setStep(1);
      } else {
        setStep(0);
      }
    }
  };

  return (
    <Container ref={containerRef} style={props.containerStyle || {}}>
      <List ref={listRef}>
        {props.tabs.map((tab) => {
          const isProgressionTab = isProgression(tab.id);
          if (hasProgression && isProgressionTab) return <></>
          if (isProgressionTab) hasProgression = true

          return <Item
            key={tab.id}
            active={tab.id === selected || (isProgressionTab && isProgression(selected))}
            onClick={() => onSelect(tab.id)}
            ref={tab.id === selected ? currentRef : null}
          >
            <span>{tab.title}</span>
          </Item>
        })}
      </List>

      <ChevronInCircle type='left' ref={prevRef} onClick={() => setStep(0)}>
        <ShevronIcon />
      </ChevronInCircle>
      <ChevronInCircle type='right' ref={nextRef} onClick={() => setStep(1)}>
        <ShevronIcon />
      </ChevronInCircle>

      <main style={props.contentStyle || {}}>
        {props.tabs.find((t) => t.id === selected)?.component ??
          'Invalid tab id'}
      </main>
    </Container>
  );
}

const Container = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;

  & > main {
    display: flex;
    flex-direction: column;
    padding-top: 1em;
    border-top: 1px solid var(--element-light);
  }
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  margin-top: 0.25rem;
  list-style: none;
  font-size: 0.875rem;
  color: var(--text-secondary);
  white-space: nowrap;
  width: min-content;
  transition: transform 0.3s;
`;

const Item = styled.li<{ active: boolean }>`
  display: inline-block;
  margin-right: 2.25rem;
  cursor: pointer;

  span {
    display: inline-block;
    max-width: 11rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ${(props) =>
    props.active &&
    css`
      color: var(--text-primary);

      &:after {
        display: block;
        content: '';
        margin-top: 1rem;
        height: 0.125rem;
        width: 100%;
        background: var(--accent-blue);
      }
    `}

  &:last-child {
    margin-right: 0;
  }
`;

const ChevronInCircle = styled.div<{ type: string }>`
  position: absolute;
  top: 0;

  height: 1.5rem;
  width: 1.5rem;
  background-color: var(--bg-100);
  color: var(--text-primary);
  border-radius: 50%;
  display: none;
  cursor: pointer;

  & svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  ${(props) =>
    props.type === 'left' &&
    css`
      left: 0rem;
      transform: scaleX(-1);
    `}

  ${(props) =>
    props.type === 'right' &&
    css`
      right: 0rem;
    `}
`;
