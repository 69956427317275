import React from "react";
import styled from "styled-components";
import "src/theme/common.css";
import { objectsIcons, signsIcons } from "src/icons";
import { IHousesForRender, IPlanetForRender } from "./interfaces";
import {
  getSign,
  getObjectName,
  IObject,
  houseNames,
  signs,
  CircleMode,
} from "src/libs";
import { degToString } from "./utils";
import { isStaticObject } from "../../../../components/Circle/Objects";
import { useTranslation } from "src/i18n/useTranslation";
import { t } from "i18next";

import store from "src/store";

function objectsConstructor(objects: IObject[]) {
  return objects.map((object: IObject, i: number) => {
    const signIndex = getSign(+object.lon);
    const isStatic = isStaticObject(i, object.speed);
    return {
      name: t(getObjectName(i, 'ru')), // FIXME: LANGUAGE
      enSignName: (signs[signIndex].en).toLowerCase(),
      lon: degToString(+object.lon % 30),
      signIndex,
      sign: t(signs[signIndex].ru),
      isRetro: object.speed < 0 && isStatic === false,
      isStatic,
    };
  });
}

function housesConstructor(houses: number[]) {
  const housesResult: IHousesForRender[] = [];

  houses.map((house: number, i: number) => {
    const housSign = getSign(house);
    housesResult.push({
      name: houseNames[i],
      lon: degToString(house % 30),
      sign: t(getObjectName(housSign)),
      signIndex: housSign,
    });
  });
  return housesResult;
}

export default function Objects(props: {
  mode?: CircleMode;
  planets: IObject[];
  houses: number[];
  isTransitsCosmogram?: boolean;
}) {

  const showMinorPlanets = store.activeAstroProfile?.showMinorPlanets;

  let localPlanets = props.planets;

  // отключен показ малых планет и Эрида присутствует в конце списка
  if (!showMinorPlanets && localPlanets.length === 16) {
    localPlanets.splice(-1, 1);
  }

  const planetsForProps = objectsConstructor(localPlanets);
  const housesForProps = housesConstructor(props.houses);
  const objectIconSimbol = (isRetro: boolean, isStatic: boolean) => {
    if(props.mode !== 'directions') {
      if(isRetro) return 'r'
      if(isStatic) return 's'
    }
    return ''
  }
  
  return (
    <Wrapper>
      <ObjectsWrapper>
        <table>
          <thead>
            <tr className="header">
              <td>{t("chronos.app.instruments.widgets.horar.planet")}</td>
              <td>{t("astro.facts.position")}</td>
              <td>{t("chronos.app.instruments.widgets.coordinates.sign")}</td>
            </tr>
          </thead>
          {planetsForProps.map((planet: IPlanetForRender, i: number) => {
            const ObjectIcon = objectsIcons[i];
            const ObjectSignIcon = signsIcons[planet.signIndex];
            return (
              <tbody key={i}>
                <tr>
                  <td>
                    <span>
                      <ObjectIcon />
                      <sub>{objectIconSimbol(planet.isRetro, planet.isStatic)}</sub>
                    </span>
                  </td>
                  <td>
                    <span>{planet.lon}</span>
                  </td>
                  <td>
                    <span>
                      <ObjectSignIcon
                        className={`svgSign ${planet.enSignName}`}
                        style={{
                          color: `var(--circle-zodiacs-elements-${
                            signs[planet.signIndex].element
                          })`,
                        }}
                      />
                    </span>
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </ObjectsWrapper>
      {!props.isTransitsCosmogram && <HousesWrapper>
        <table>
          <thead>
            <tr className="header">
              <td>{t("chronos.app.instruments.widgets.coordinates.house")}</td>
              <td>{t("astro.facts.position")}</td>
              <td>{t("chronos.app.instruments.widgets.coordinates.sign")}</td>
            </tr>
          </thead>
          {housesForProps.map((house: IHousesForRender, i: number) => {
            const HousSignIcon = signsIcons[house.signIndex];
            const signName = signs[house.signIndex].en.toLowerCase();

            return (
              <tbody key={i}>
                <tr>
                  <td>
                    <span className="housName">{house.name}</span>
                  </td>
                  <td>
                    <span>{house.lon}</span>
                  </td>
                  <td>
                    <span>
                      <HousSignIcon
                        className={`svgSign ${t(signName)}`}
                        style={{
                          color: `var(--circle-zodiacs-elements-${
                            signs[house.signIndex].element
                          })`,
                        }}
                      />
                    </span>
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </HousesWrapper>}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  table {
    width: 100%;

    tr {
      height: 2.3rem;
    }
  }

  .header {
    color: var(--text-secondary);
  }
  svg {
    width: 1.3rem;
  }
`;

const ObjectsWrapper = styled.div`
  width: 50%;
  border-right: 1px solid var(--button-border);
`;
const HousesWrapper = styled.div`
  width: 50%;
  margin-left: 1.55rem;
`;
