import { useTranslation } from 'src/i18n/useTranslation';
import React from 'react';
import styled, { StyledComponent } from 'styled-components';

type ParsedItem = string | { [key: string]: string[] | IParsedObject };

export interface IParsedObject {
  others: ParsedItem[];
  pros: ParsedItem[];
  cons: ParsedItem[];
}

export default function Items(props: Partial<IParsedObject>): JSX.Element {
  const { others, pros, cons } = props;
  const { t } = useTranslation();
  const renderItems = (
    ItemComponent: StyledComponent<'li', any>,
    keyPrefix: string,
    items?: ParsedItem[],
    itemProps?: { pros: boolean }
  ) => {
    if (!items?.length) {
      return null;
    }
    return (
      <ul>
        {items?.map((line: ParsedItem) => typeof line === 'string'
          ? <ItemComponent key={line} {...itemProps}>{t(line)}</ItemComponent>
          : Object.keys(line).map((key: string) =>
            <>
              <ItemComponent key={key} {...itemProps}>{t(key)}</ItemComponent>
              <Items key={`${keyPrefix}_${key}`} {...line[key]} />
            </>
          )
        )}
      </ul>
    );
  };

  return <>
    {renderItems(Item, 'o', others)}
    {renderItems(SignItem, 'p', pros, { pros: true })}
    {renderItems(SignItem, 'c', cons)}
  </>;
}

const Item = styled.li`
  &::before {
    content: ''
  }
  
  position: relative;

  & + & {
    margin-top: 0.5em;
  }
`;

const SignItem = styled(Item)`
  padding-left: 30px;

  list-style-type: none;

  &::before {
    content: '';

    width: 22px;
    height: 22px;

    position: absolute;
    top: 0.1em;
    left: 0;

    background-image: ${(props: { pros?: boolean }) =>
    props.pros
      ? 'url("data:image/svg+xml,%3Csvg width=\'22\' height=\'22\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M11 0c6.075 0 11 4.925 11 11s-4.925 11-11 11S0 17.075 0 11 4.925 0 11 0z\' fill=\'%232E2E2E\'/%3E%3Cpath d=\'M16 12h-4v4h-2v-4H6v-2h4V6h2v4h4v2z\' fill=\'%23fff\'/%3E%3C/svg%3E")'
      : 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'22\' height=\'22\'%3E%3Cpath fill=\'%232E2E2E\' d=\'M11 0c6.075 0 11 4.925 11 11s-4.925 11-11 11S0 17.075 0 11 4.925 0 11 0z\'/%3E%3Cpath fill=\'%23FFF\' d=\'M6 10h10v2H6v-2z\'/%3E%3C/svg%3E")'};
  }
`;
