import { makeAutoObservable } from 'mobx';
import crypto from 'crypto-browserify'
import { Buffer } from 'buffer';

export default class MeetingStore {
  apiKey: string = process.env.REACT_APP_ZOOM_API_KEY as string;
  apiSecret: string = process.env.REACT_APP_ZOOM_SECRET_KEY as string;
  
  role: number = 0; //`1` for meeting hosts; `0` for participants & joining webinars
  leaveUrl: string = 'http://localhost:8080';

  private _meetingNumber: number = 2400725242;
  get meetingNumber(): number {
    return this._meetingNumber;
  }
  set meetingNumber(number: number) {
    this._meetingNumber = number;
  }

  private _startUrl: string = '';
  get startUrl() {
    return this._startUrl;
  }
  set startUrl(url: string) {
    this._startUrl = url;
  }

  private _userName: string = "chronos.app.store.dashboard.base.userForTest";
  get userName(): string {
    return this._userName;
  }
  set userName(name: string) {
    this._userName = name;
  }

  private _userEmail: string = '';
  get userEmail(): string {
    return this._userEmail;
  }
  set userEmail(mail: string) {
    this._userEmail = mail;
  }


  private _password: string = '2f15BQ';
  get password(): string {
    return this._password;
  }
  set password(password: string) {
    this._password = password;
  }

  get signature() {
    return this.generateSignature(this.apiKey, this.apiSecret, this.meetingNumber, this.role);
  }

  private generateSignature(apiKey: string, apiSecret: string, meetingNumber: number, role: number) {
    // Prevent time sync issue between client signature generation and zoom 
    const timestamp = new Date().getTime() - 30000
    const msg = Buffer.from(apiKey + meetingNumber + timestamp + role).toString('base64')
    const hash = crypto.createHmac('sha256', apiSecret).update(msg).digest('base64')
    const signature = Buffer.from(`${apiKey}.${meetingNumber}.${timestamp}.${role}.${hash}`).toString('base64')

    return signature
  }
  
  constructor() {
    makeAutoObservable(this);
  }
}