export const degToString = (speed: number) => {
  const absSpeed = Math.abs(speed);
  const deg = Math.floor(absSpeed);
  const min = Math.floor((absSpeed - deg) * 60);
  const minString = `${min < 10 ? "0" : ""}${min}`;
  const sec = Math.floor((absSpeed - deg - min / 60) * 3600);
  const secString = `${sec < 10 ? "0" : ""}${sec}`;
  return `${deg}°${minString}'${secString}"`;
};

export const degToStringRound = (speed: number) => {
  const absSpeed = Math.abs(speed);
  const deg = Math.floor(absSpeed);
  const min = Math.floor((absSpeed - deg) * 60);
  const minString = `${min < 10 ? "0" : ""}${min}`;
  const sec = Math.round((absSpeed - deg - min / 60) * 3600);
  const secString = `${sec < 10 ? "0" : ""}${sec}`;
  return `${deg}°${minString}'${secString}"`;
};