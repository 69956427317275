import React, {useCallback, useEffect, useState} from 'react';
import styled, { css } from 'styled-components';
import { observer } from 'mobx-react';

import store from 'src/store';
import { getDeviceDetect } from 'src/utils';

const { isTablet } = getDeviceDetect();

export default observer(function HeaderContainer(props: {
  children: any;
}) {
  const { indiBanner } = store.settings.banners;

  return (
    <Container
      className='no-print'
      shiftRight={!!store.settings.profile.ui.sidebar}
      top={indiBanner === 'topbar' ? '2.625rem' : '0'}
    >
      {props.children}
    </Container>
  );
})

const Container = styled.header<{ shiftRight?: boolean; top: string }>`
  display: flex;
  align-items: center;
  position: absolute;
  top: ${p => p.top};
  width: 100%;
  box-sizing: border-box;
  z-index: 2;
  transition: padding 0.2s ease;

  ${p => p.shiftRight && isTablet
      ? css`padding: 0.75em 0.875rem 0.75em 264px;`
      : css`padding: 0.75em 0.875rem;`
  }
  
  & > svg {
    width: 1.5rem;
    margin-right: 0.5rem;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }
    
  .vertical {
    border-right: 1px solid var(--text-third);
    height: 1em;
    margin: 0 1em;
  }

  .place {
    color: var(--text-secondary);

    & .not-auto,
    & .custom {
      color: var(--colors-yellow) !important;
    }
  }

  ${isTablet
    ? css`
        position: fixed;
        left: 1rem;
      `
    : css`
        position: absolute;
      `
  }
`;
