import React from 'react';
import styled from 'styled-components';

import { Button } from 'src/libs';

import image from 'src/assets/recti/recti-prev1.png';
import { useTranslation } from 'src/i18n/useTranslation';

export default function Onboarding(props: {
  onClick(v: boolean): void;
}) {
  const { t } = useTranslation();
  return (
    <Container>
      <img src={image}></img>
      <h3>{t("chronos.app.instruments.wantGetTrained")}</h3>
      <p dangerouslySetInnerHTML={{__html: t("chronos.app.instruments.sayRectification")}}></p>
      <Buttons>
        <Button
          color="#4093F4"
          onClick={() => props.onClick(true)}
        >{t("chronos.app.instruments.startLearning")}</Button>
        <Button
          color="transparent"
          onClick={() => props.onClick(false)}
        >{t("chronos.app.instruments.knowEverything")}</Button>
      </Buttons>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding-bottom: 3rem;

  & img {
    max-width: 90%;
    margin-top: 15%;
  }

  & h3 {
    margin-top: 2em;
    font-size: 1.5em;
  }

  & p {
    margin: 2em 0;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
`;
