import React from 'react';
import styled from 'styled-components';

import { history } from 'src/router';

import { Button as ButtonUI} from 'src/libs';
import { useTranslation } from 'src/i18n/useTranslation';

export default function NotFound(): any {
  const { t } = useTranslation();
  return (
    <Container>
      {t("chronos.app.notFound")}
      <ButtonUI onClick={() => { history.push('/dashboard') }}>{t("chronos.app.goToTheMain")}</ButtonUI>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 1.75em;

  & > :last-child {
    margin-top: 2rem;
  }
`;
