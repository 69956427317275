import React, { useEffect } from 'react';
import styled from 'styled-components';

import Closer from 'src/pages/Settings/Closer';
import { HotkeysList } from 'src/components/HotkeysHint/HotkeysList';
import { useKeyHook } from 'src/hooks';

import { FillHotkey } from 'src/assets/icons/system';
import { KeyboardArrowUp as ArrowUp } from 'src/assets/icons/system';
import { useTranslation } from 'src/i18n/useTranslation';

export default function Index(props: {
  onClose(): void;
}) {

  useKeyHook('Escape', {
    onKeyDown: (evt: any) => {
      props.onClose();
    }
  }, []);

  const parseHotkey = (rawHotkey: string) => {
    const splitted = rawHotkey.split(' ');

    //@ts-ignore
    return splitted.map(key => {
      if (key[0] === '!') { return <React.Fragment key={key}><S>{t("chronos.app.components.hotKeysHint.pinch")}</S><Key>{key.slice(1)}</Key></React.Fragment> }
      if (key === '+') { return <S key={key}>+</S> }
      if (key === 'ArrowUp') { return <ArrowUp key={key} /> }
      //@ts-ignore
      if (key === 'ArrowRight') { return <ArrowUp key={key} style={{ transform: 'rotate(90deg)' }} /> }
      //@ts-ignore
      if (key === 'ArrowDown') { return <ArrowUp key={key} style={{ transform: 'rotate(180deg)' }} /> }
      //@ts-ignore
      if (key === 'ArrowLeft') { return <ArrowUp key={key} style={{ transform: 'rotate(270deg)' }} /> }
      return <Key key={key}>{key}</Key>;
    });
  };
  const { t } = useTranslation();
  return (
    <Overlay>
      <Content>
        <OverlaySibiling />
        <Closer onClick={props.onClose} style={{ top: '0', right: '0', position: 'absolute' }} />
        <HotkeyIconWrapper><FillHotkey /></HotkeyIconWrapper>
        <Header>
          <HeaderTitle>
            <p>{t("chronos.app.shortKeys")}</p>
            <Key>Ctrl</Key><S>+</S><Key>/</Key>
          </HeaderTitle>
          <HeaderDescription>
            {t("chronos.app.components.hotKeysHint.headerDescription")}
          </HeaderDescription>
        </Header>

        <Sections>
          {HotkeysList.map(section =>
            <Section key={`section_${section.section}`}>
              <SectionTitle key={`title_${section.section}`}>
                {t(section.section)}
              </SectionTitle>
              {section.keys.map(hotkey =>
                <Hotkey key={hotkey.text}>
                  <Text>
                    {t(hotkey.text)}
                  </Text>
                  <Keys>
                    {parseHotkey(t(hotkey.buttons))}
                  </Keys>
                </Hotkey>
              )}
            </Section>
          )}
        </Sections>
      </Content>
    </Overlay>
  );
}

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 34.375rem;
  column-gap: 5.625rem;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionTitle = styled.p`
  margin: 0 0 1.625rem 0;
  color: var(--text-primary);
  font-size: 1.125rem;
`;

const Hotkey = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  align-items: center;
  
  & svg {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 6px 0 0;
  }
`;

const Text = styled.p`
  margin: 0;
`;

const Keys = styled.div`
  display: flex;
  align-items: center;
  & :nth-last-child(1) {
    margin-right: 0;
  }
`;

const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  
  backdrop-filter: brightness(0.9) blur(3px);

  z-index: 20;
  /* pointer-events: none; */
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  max-width: 80%;
`;
const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
  margin-top: 1.5rem;
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
  color: var(--text-primary);
  
  & p {
    font-size: 1.5rem;
    margin: 0 0.75rem 0 0;
  }
`;

const HeaderDescription = styled.p`
  margin: 0;
`;
const HotkeyIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 2.55rem;
  height: 2.55rem;
  background-color: var(--bg-hotkey);
  border-radius: 0.3rem;
  & > svg {
    width: 1.5rem;
  }
`
const OverlaySibiling = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: var(--background);
  z-index: -1;
  left: 0;
  top: 0;
  filter: blur(10px);
  opacity: 0.8;
`;

const Key = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  min-width: 1.5rem;
  border: 1px solid var(--icon-third);
  border-radius: 0.25rem;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 0.875rem;
  margin: 0 0.375rem 0 0;
  padding: 0 0.375rem;
  color: var(--text-primary);
`;

const S = styled.span`
  margin-right: 0.375rem;
`;
