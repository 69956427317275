import { s3urls } from "src/api";


class EventInputMatches extends HTMLElement {
  schools = {
  0: this.t("chronos.app.instruments.widgetsrectification.eventInput.c"),
  1: this.t("chronos.app.instruments.widgetsrectification.eventInput.pa")
};
  _shadow = this.attachShadow({ mode: 'closed' });
  _matches = [];
  _index = -1;
  get t() {
    return window.translator.t;
  }

  onSelect = ev => { };
  onSupport = () => { };
  onCancel = () => { };
  onFirst = t => { };

  connectedCallback() {
    const style = new CSSStyleSheet();

    style.replaceSync(`
      :host {
        display: none;
        position: absolute;
        background: var(--bg-100);
        box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.11), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
        border-radius: 0.25em;
        width: calc(100% - 0.5em);
        top: 100%;
        margin-top: 0.3em;
        color: var(--text-secondary);
        max-height: 14.5em;
        padding: 0.5em;
        padding-right: 0;
        z-index: 10;
      }

      #matches {
        list-style-type: none;
        margin: 0;
        padding: 0;
        
        padding-right: 0.25em;
        width: 100%;
        overflow-y: scroll;
      }

      #matches::-webkit-scrollbar {
        width: 0.25em;
        background-color: none;
      }

      #matches::-webkit-scrollbar-thumb {
        background-color: #4093F4;
      }

      #matches li {
        background: none;
        border-radius: 0.3em;
        padding: 0.5em;
        margin-bottom: 0.5em;
        cursor: pointer;
        display: flex;
        align-items: center;
      }

      #matches .last-child {
        margin-bottom: 0;
        color: #4093F4;
        cursor: unset;
      }

      #matches li .weight {
        color: #4093F4;
        font-weight: bold;
        margin-right: 0.5em;
      }

      #matches li .match {
        color: var(--text-primary);
      }

      #matches li .school {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #4093F4;
        border-radius: 50%;
        
        margin-left: auto;
        
        min-width: 2em;
        min-height: 2em;

        color: white;
        font-weight: bold;
      }

      #matches li[selected] {
        background: rgba(255, 255, 255, 0.1);
      }

      #support {
        color: inherit;
        text-decoration: underline;
        user-select: none;
      }
    `);

    this._shadow.adoptedStyleSheets = [style];

    this._shadow.innerHTML = /* html*/`
      <ul id="matches"></ul>
    `;
  }

  handleKeyDown(ev) {
    if (ev.keyCode == 13 || ev.keyCode == 9) {
      ev.preventDefault();

      if (!this._matches.length) {
        this.onCancel && this.onCancel();
        return;
      }

      this._selectMatch(this._matches[Math.max(0, this._index)]);
    } else if (ev.keyCode == 40 || ev.keyCode == 38) {
      ev.preventDefault();

      if (!this._matches.length) { return }

      const matches = this._shadow.children.matches.children;

      const prevMatch = matches[this._index];
      prevMatch && prevMatch.removeAttribute('selected');

      this._index = Math.max(0, Math.min(this._index + (ev.keyCode == 38 ? -1 : 1), matches.length - 1));

      const match = matches[this._index];

      if (this._matches[this._index]) { match.setAttribute('selected', '') }

      match.scrollIntoView({ block: 'nearest' });

      if (this._index == matches.length - 1) {
        matches[--this._index].setAttribute('selected', '');
      }
    }
  }

  _reset() {
    this._matches = [];
    this._index = -1;
  }

  _selectMatch(ev) {
    this._reset();
    this.onSelect && this.onSelect(ev);
    this.show(false);
  }

  show(v) {
    this.style.display = v ? 'flex' : 'none';
  }

  find(value = '', events = [
    {
      name: '',
      weight: 0
    }
  ]) {
    const _value = value;
    value = value.toLowerCase();

    this.onFirst && this.onFirst('');

    if (!value.trim()) {
      this.show(false);
      return;
    }

    const { matches } = this._shadow.children;

    matches.innerHTML = '';
    this._reset();

    events
      .forEach(ev => {
        const results = [];
        let start = 0;

        const name = this.t(ev.name)
        const nameLower = name.toLowerCase();

        while (true) {
          const pos = nameLower.indexOf(value, start);
          if (pos == -1) { break }
          results.push(pos);
          start = pos + value.length;
        }

        let findInSynonims = false;
        const synonimsString = this.t(ev.synonims);
        if (synonimsString.indexOf(value) !== -1) {
          findInSynonims = true;
        }

        if (!results.length && !findInSynonims) { return }

        const match = document.createElement('li');

        match.onclick = () => this._selectMatch(ev);

        const items = [];
        let prev = 0;

        results.forEach(pos => {
          items.push(name.substring(prev, pos));
          prev = pos + value.length;
          items.push(`<span class="match">${name.substring(pos, prev)}</span>`);
        });

        items.push(name.substring(prev, name.length));

        const school = ev.school ? `<span class="school">${this.schools[ev.school]}</span>` : '';

        match.innerHTML = `<span class="weight">${ev.weight}</span><div>${items.join('')}</div>${school}`;

        if (this._index == -1) {
          match.setAttribute('selected', '');
          if (name.startsWith(_value)) {
            this.onFirst && this.onFirst(name);
          }
          this._index = 0;
        }

        matches.append(match);
        this._matches.push(ev);
      });

    const notice = document.createElement('li');
    notice.className = 'last-child';
    notice.innerHTML = this.t("chronos.app.instruments.widgetsrectification.eventInput.noEventWriteUs", {href: s3urls.support});
    notice.children.support.onclick = () => this.onSupport && this.onSupport();
    matches.append(notice);

    this.show(true);
  }
}

customElements.define('event-input-matches', EventInputMatches);
