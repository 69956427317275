import { t } from 'i18next';


function getPlace(place, bcr) {
  switch (place) {
    case 'left':
    case 'right': return bcr[place];
    case 'middle': return (bcr.left + bcr.right) / 2;
    default: return 0;
  }
}

export function nextOnboarding(next) {
  window.dispatchEvent(new CustomEvent('onboarding', { detail: {
    type: 'next',
    next
  } }));
}

export function showOnboarding(data) {
  window.dispatchEvent(new CustomEvent('onboarding', { detail: {
    type: data ? 'show' : 'close',
    ...data
  } }));
}

class Onboarding extends HTMLElement {
  _shadow = this.attachShadow({ mode: 'closed' });
  _arrowSize = 8;
  _current = null;
  _showed = new Set();

  connectedCallback() {
    const style = new CSSStyleSheet();

    style.replaceSync(`
      :host {
        --background: #101010;

        position: fixed;
        display: none;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
        z-index: 1000;

        font-family: Apercu Pro;
      }

      #arrow {
        position: absolute;
        background: var(--background);
        border-radius: 1px;
        width: ${this._arrowSize}px;
        height: ${this._arrowSize}px;
        transform: rotate(45deg);
      }

      #content {
        background: var(--background);
        color: white;
        border-radius: 0.3em;
        padding: 1em;
        white-space: nowrap;
        font-size: 0.9em;
      }

      #summary {
        font-size: 1.1em;
      }

      #description {
        margin-top: 0.5em;
        color: rgba(255, 255, 255, 0.6);
        line-height: 1.3em;
      }

      #description .bold {
        font-weight: bold;
        color: rgba(255, 255, 255, 0.87);
      }

      #skip_button {
        display: none;
        margin-top: 1em;
        appearance: none;
        border: none;
        outline: none;
        background: #4093F4;
        border-radius: 0.25em;
        padding: 0.3em 0.6em;
        font-family: Apercu Pro;
        font-size: 0.9em;
        color: white;
        user-select: none;
        cursor: pointer;
      }
    `);

    this._shadow.adoptedStyleSheets = [style];
    this._shadow.innerHTML = /* html*/`
      <div id="arrow"></div>
      <div id="content">
        <div id="summary"></div>
        <div id="description"></div>
        <button id="skip_button">${t("chronos.app.components.consultationsOnboarding.popup.ckip")}</button>
      </div>
    `;

    const { content } = this._shadow.children;
    const { skip_button } = content.children;

    skip_button.onclick = () => {
      this._current && this._current.next && nextOnboarding(this._current.next);
    };

    window.addEventListener('resize', this.update);
    window.addEventListener('onboarding', this.onMessage);
  }

  disconnectedCallback() {
    window.removeEventListener('resize', this.update);
    window.removeEventListener('onboarding', this.onMessage);
  }

  onMessage = ({ detail: msg }) => {
    if (msg.type == 'next') {
      if (this._showed.has(msg.next)) { return }
      this._showed.add(msg.next);
      window.dispatchEvent(new CustomEvent('onboarding', { detail: {
        type: 'check',
        id: msg.next
      } }));
    } else if (msg.type == 'show') {
      this._current = msg;
      this.update();
    } else if (msg.type == 'close') {
      this._current = null;
      this.style.display = 'none';
    }
  };

  update = () => {
    if (!this._current) { return }

    const { target, summary: sum, description: desc, place, next, offsetY = 10, offsetX = 0 } = this._current;

    this.style.display = 'block';

    const { arrow, content } = this._shadow.children;
    const { summary, description, skip_button } = content.children;

    summary.textContent = sum;

    if (desc) {
      description.style.display = 'block';
      description.innerHTML = desc;
    } else
    { description.style.display = 'none' }

    skip_button.style.display = next ? 'block' : 'none';
    skip_button.textContent = next === 'end' ? t("chronos.app.onboarding.finishAducation") : t("chronos.app.components.welcomeInstruments.addSomeCards.continue");

    const bcr = target.getBoundingClientRect();
    const _bcr = this.getBoundingClientRect();

    const ax = getPlace(place || 'middle', bcr) + (offsetX || 0);
    const x = Math.min(Math.max(10, ax - _bcr.width / 2), window.innerWidth - _bcr.width - 10);

    let y = bcr.bottom + offsetY;
    let ay = 0;

    if (y > window.innerHeight * 0.5) {
      y = bcr.top - _bcr.height - offsetY;
      ay = _bcr.height;
    }

    this.style.left = `${x}px`;
    this.style.top = `${y}px`;
    arrow.style.left = `${ax - x - this._arrowSize / 2}px`;
    arrow.style.top = `${ay - this._arrowSize / 2}px`;
  };
}

//@ts-ignore
customElements.define('help-tooltip', Onboarding);
//@ts-ignore
document.documentElement.append(new Onboarding());
