import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'src/i18n/useTranslation';

export interface IStaticDescriptionProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

export const StaticDescriptionBlock: React.FC<IStaticDescriptionProps> = ({
  icon,
  title,
  description,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Header>
        <div className="icon">{icon}</div>
        <div className="title">{title}</div>
      </Header>
      <Description>{t(description)}</Description>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
  color: var(--text-secondary);
  padding: 0 2rem; 
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;

  & .icon {
    margin-right: 0.612rem;
    & svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  & .title {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: var(--text-primary);
  } 
`;
const Description = styled.span`
  display: block;
  padding-right: 2rem;
 `;