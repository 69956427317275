import { makeAutoObservable, runInAction } from 'mobx';
import _, { cloneDeep, isEqual, merge } from 'lodash';

import api, { IProfileSettings, IUISettings } from 'src/api';

const dfltUI: IUISettings = {
  sidebar: true,
  instruments: {
    cardSettingsForms: {
      common: {
        basic: true,
        common: true,
        northern: true,
      },
      natal: {
        basic: true,
      },
      synastry: {
        basic: true,
      },
      prognostics: {
        basic: true,
      },
      horar: {
        basic: true,
        additional: true,
        showAspects: false,
      },
      // FIXME:
      // @ts-ignore
      soul: {
        basic: true,
        additional: true,
      },
    },
  },
};

export default class ProfileStore {
  private _value = {
    activeProfileId: -1,
    activeCustomizationId: -1,
    newInterface: true,
    showHint: false,
    syncMaps: false,
    ui: dfltUI,
  };

  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  async init(userId: number) {
    return new Promise<IProfileSettings>(async resolve => {

      if (this._value.activeProfileId !== -1) return resolve(this._value);

      const value = await api.profileSettings(userId);

      if (value.newInterface === undefined || value.syncMaps === undefined) {
        value.newInterface = true;
        value.syncMaps = true;
      }

      if (value.activeCustomizationId === undefined) {
        value.activeCustomizationId = 2
      }
      
      value.ui = merge(dfltUI, value.ui);

      runInAction(() => {
        this._value = value;
        resolve(this._value);
      });
      
    });
  }

  private async update() {
    return api.updateProfileSettings(this._value);
  }

  async asyncUpdateprofile() {
    this.loading = true;
    const result = await api.updateProfileSettings(this._value);
    runInAction(() => {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    });
    return result;
  }

  get activeProfileId() {
    const id = this._value.activeProfileId;
    return id === -1 ? 0 : id;
  }

  set activeProfileId(v: number) {
    this._value.activeProfileId = v;
    this.asyncUpdateprofile();
  }

  get activeCustomizationId() {
    return this._value.activeCustomizationId
  }

  async setActiveCustomizationId(v: number, withUpdate: boolean = false) {
    this._value.activeCustomizationId = v;
    if (withUpdate) 
      return this.update();
      
      else 
        return this._value
  }

  get showHint() {
    return this._value.showHint;
  }

  set showHint(v: boolean) {
    this._value.showHint = v;
    this.update();
  }

  get syncMaps() {
    return this._value.syncMaps;
  }

  set syncMaps(v: boolean) {
    runInAction(() => {
      this._value.syncMaps = v;
      this.update();
    })
  }

  get newInterface() {
    return this._value.newInterface;
  }

  set newInterface(v: boolean) {
    this._value.newInterface = v;
    this.update();
  }

  setNewInterface(v: boolean, silent?: boolean) {
    this._value.newInterface = v;
  }

  get ui() {
    return cloneDeep(this._value.ui); // Prevent updating observer components on ui direct changing
  }

  updateUi(cb: (ui: IUISettings) => void) {
    const { ui } = this;
    cb(ui);
    const needUpdate = !isEqual(this._value.ui, ui);
    this._value.ui = ui;
    if (needUpdate) {
      this.update();
    }
  }
}
