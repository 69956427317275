import React from 'react';
import styled, { CSSProperties } from 'styled-components';

import { CloseIcon } from 'src/assets/icons/system';

export default function Closer({
  onClick,
  style
}: {
  onClick(): void;
  style: CSSProperties;
}) {

  return (
    <Container onClick={onClick} style={style}>
      <CloseIcon />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  right: calc(68vw - 48rem - 13.5rem);
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: var(--layer-red-color);
  color: var(--accent-red);
  cursor: pointer;

  & > svg {
    width: 1.45rem;
    height: 1.45rem;
  }

  &:hover {
    opacity: 0.6;
  }
`;
