import { astroObjects, getSign, ObjectType, signs, SignType } from 'src/libs';
import React from 'react';
import styled, { css } from 'styled-components';
import {
  IEssential,
  essentials,
  EssentialsType,
  isEastern,
  AdditionEssentials, TriplicityType, getEssentialObject
} from 'src/pages/Instruments/Widgets/Horar/horar';
import { default as TooltipWrapper } from 'src/ui/Wrappers/TooltipWrapper';

import { IWidgetData } from '../Widget';

import { objectsIcons, signsIcons } from 'src/icons';
import { useTranslation } from 'src/i18n/useTranslation';
export default function HorarEssentials(props: {
  data: IWidgetData;
  onChanged(key: string, value: any): void;
}): JSX.Element {
  const { t } = useTranslation();
  const getTableHeaders = (t: Function) => <Column header>
    <Cell>{t("chronos.app.instruments.widgets.horar.planet")}</Cell>
    <Cell>{t("chronos.app.instruments.widgets.coordinates.sign")}</Cell>
    <Cell>{t("chronos.app.instruments.widgets.horar.essentials.possession")}</Cell>
    <Cell>{t("chronos.app.instruments.widgets.horar.essentials.exaltation")}</Cell>
    <Cell>{t("chronos.app.instruments.widgets.horar.essentials.exile")}</Cell>
    <Cell>{t("chronos.app.instruments.widgets.horar.essentials.fall")}</Cell>
    <Cell>{t("chronos.app.instruments.widgets.horar.essentials.triplicity")}</Cell>
    <Cell>{t("chronos.app.instruments.widgets.horar.essentials.therms")}</Cell>
    <Cell>{t("chronos.app.instruments.widgets.horar.essentials.fase")}</Cell>
  </Column>;

  const getEssentialsCells = (essential: IEssential, current: number, lon: number, isDay: boolean) => {
    const EssentialsCells = [];
    for (let e = EssentialsType.House; e <= EssentialsType.Fall; e++) {
      const Icon = objectsIcons[essential[e] as ObjectType ?? -1];

      EssentialsCells.push(
        <TooltipWrapper text={t(astroObjects[essential[e] as ObjectType ?? -1]?.ru) || ''} key={`essential_${current}_${essential[e] as ObjectType ?? ''}_${e}`}>
          <Cell active={current === essential[e]}>
            {Icon && <Icon />}
          </Cell>
        </TooltipWrapper>
      );
    }
    // Triplicity
    const object: ObjectType = (essential[EssentialsType.Triplicity] as TriplicityType)[isDay ? 0 : 1];
    const Icon = objectsIcons[object];

    EssentialsCells.push(
      <TooltipWrapper text={t(astroObjects[object]?.ru) || ''} key={`essential_${current}_${object}_${EssentialsType.Triplicity}`}>
        <Cell active={current === object}>
          {Icon && <Icon />}
        </Cell>
      </TooltipWrapper>
    );

    for (let e = EssentialsType.Terms; e <= EssentialsType.Face; e++) {
      const object: ObjectType = getEssentialObject(essential[e] as AdditionEssentials , lon);
      const Icon = objectsIcons[object];

      EssentialsCells.push(
        <TooltipWrapper text={t(astroObjects[object]?.ru) || ''} key={`essential_${current}_${object}_${e}`}>
          <Cell active={current === object}>
            {Icon && <Icon />}
          </Cell>
        </TooltipWrapper>
      );
    }
    return EssentialsCells;
  };

  const getTableColumns = () => {
    const rows = [];
    const [{ objects }] = props.data.maps;
    for (let p = ObjectType.Sun; p <= ObjectType.Saturn; p++) {
      const ObjectIcon = objectsIcons[p];
      const { lon } = objects[p];

      const sign: SignType = getSign(lon);

      const SignIcon = signsIcons[sign];

      const essential: IEssential = essentials[sign];

      rows.push(
        <Column key={`row_${p}`}>
          <TooltipWrapper text={t(astroObjects[p].ru)} key={`planet_${p}`}>
            <Cell>
              <ObjectIcon />
            </Cell>
          </TooltipWrapper>
          <TooltipWrapper text={t(signs[sign].ru)} key={`sign_${p}`}>
            <Cell element={signs[sign].element}>
              <SignIcon />
            </Cell>
          </TooltipWrapper>
          {getEssentialsCells(essential, p, lon % 30, isEastern(objects[ObjectType.Sun].lon, props.data.maps[0].houses[6]))}
        </Column>
      );
    }
    return rows;
  };

  return (
    <Container>
      {getTableHeaders(t)}
      {getTableColumns()}
    </Container>
  );
}

const Container = styled.div`
  font-size: 0.8em;
  display: flex;
  border-top: 1px solid var(--element-neutral);
`;

const Column = styled.div<{ header?: boolean }>`
  display: flex;
  flex-direction: column;
  color: var(--text-primary);
  border-right: 1px solid var(--element-neutral);
  width: 11%;
  :nth-last-child(1) {
    border-right: none;
  }
  > :nth-last-child(1) {
    border-bottom: none;
    span {
      border-bottom: none;      
    }
  }
  > :nth-child(n + 3) {
    svg {
      fill: var(--icon-third);
    }
  }
  ${props => props.header && css`
    width: 22%;
    > :nth-child(n + 3) {
      color: var(--text-third);      
    }
    span {
      //width: 8rem;
      justify-content: unset;
    }
  `}
`;

const Cell = styled.span<{ active?: boolean; element?: string }>`
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--element-neutral);
  box-sizing: border-box;
  ${props => props.active && css`
    border: 2px solid var(--accent-green) !important;
    > svg {
      fill: currentColor !important;
    }
  `}
  ${props => props.element && css`
    > svg {
      fill: var(--circle-zodiacs-elements-${props.element}) !important;
    }
  `}
  > svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
