interface IHotkeysList {
  section: string;
  keys: IKeys[];
}

interface IKeys {
  text: string;
  buttons: string;
}

export const HotkeysList: IHotkeysList[] = [
  {
    section: "chronos.app.dashboard.maps",
    keys: [
      {
        text: "chronos.app.settings.astro.natalMap",
        buttons: 'N'
      },
      {
        text: "chronos.app.components.hotkeyslist.natalOnTopOtherMaps",
        buttons: '!N'
      },
      {
        text: "astro.synastry",
        buttons: 'S'
      },
      {
        text: "astro.prognostics",
        buttons: 'P'
      },
      {
        text: "chronos.app.components.editForm.mainGroup.cosmogram",
        buttons: 'C' 
      },
      {
        text: "chronos.app.components.hotkeyslist.firstMapInList",
        buttons: '1'
      },
      {
        text: "chronos.app.components.hotkeyslist.mapInList.2",
        buttons: '2'
      },
      {
        text: "chronos.app.components.hotkeyslist.mapInList.3",
        buttons: '3'
      },
      {
        text: "chronos.app.components.hotkeyslist.mapInList.4",
        buttons: '4'
      },
      {
        text: "chronos.app.components.hotkeyslist.mapInList.5",
        buttons: '5'
      },
      {
        text: "chronos.app.components.hotkeyslist.mapInList.showMultipleMaps",
        buttons: "chronos.app.ctrlPlusLMB"
      }
    ]
  },
  {
    section: "chronos.app.components.hotkeyslist.mapInList.workSpace",
    keys: [
      {
        text: "chronos.app.components.hotkeyslist.bringCloser",
        buttons: "chronos.app.ctrlPlusMMBPlusArrowDown"
      },
      {
        text: "chronos.app.components.hotkeyslist.alienate",
        buttons: "chronos.app.ctrlPlusMMBPlusArrowUp"
      },
      {
        text: "chronos.app.components.hotkeyslist.moveRight",
        buttons: "chronos.app.MMBPlusArrowDown"
      },
      {
        text: "chronos.app.components.hotkeyslist.moveLeft",
        buttons: "chronos.app.MMBPlusArrowUp"
      },
      {
        text: "chronos.app.components.hotkeyslist.switchingMaps",
        buttons: 'ArrowLeft ArrowRight'
      },
      {
        text: "chronos.app.components.hotkeyslist.move",
        buttons: "chronos.app.spacePlusLMB"
      }
    ]
  },
  {
    section: "chronos.app.components.hotkeyslist.app",
    keys: [
      {
        text: "chronos.app.tmpMap",
        buttons: 'T'
      },
      {
        text: "chronos.app.components.hotkeyslist.buildMap",
        buttons: 'C'
      },
      {
        text: "chronos.app.components.hotkeyslist.goToMapList",
        buttons: 'L'
      },
      {
        text: "chronos.app.components.hotkeyslist.goToSettings",
        buttons: 'Shift + S'
      }
    ]
  },
  {
    section: "chronos.app.components.hotkeyslist.instruments",
    keys: [
      {
        text: "chronos.app.components.hotkeyslist.timeSwitch",
        buttons: 'D'
      },
      {
        text: "chronos.app.components.hotkeyslist.resetTime",
        buttons: 'Alt + D'
      },
      {
        text: "chronos.app.import",
        buttons: 'Ctrl + K'
      },
      {
        text: "chronos.app.components.hotkeyslist.widget.1",
        buttons: 'Alt + 1'
      },
      {
        text: "chronos.app.components.hotkeyslist.widget.2",
        buttons: 'Alt + 2'
      },
      {
        text: "chronos.app.components.hotkeyslist.widget.3",
        buttons: 'Alt + 3'
      },
      {
        text: "chronos.app.components.hotkeyslist.widget.4",
        buttons: 'Alt + 4'
      },
      {
        text: "chronos.app.components.hotkeyslist.widget.5",
        buttons: 'Alt + 5'
      },
      {
        text: "chronos.app.components.hotkeyslist.widget.6",
        buttons: 'Alt + 6'
      },
      {
        text: "chronos.app.components.hotkeyslist.widget.hideShowAll",
        buttons: 'Alt + W'
      }
    ]
  }
];
