import React from 'react';
import styled from 'styled-components';
import {ReactMemo} from 'src/hooks';

import InputForm from '../InputForm';
import PersonMenu from './PersonMenu';
import { useTranslation } from 'src/i18n/useTranslation';
import { cloneDeep } from 'lodash';
import { show, IPlace, IShortFormData, CircleMode, IFormData } from 'src/libs';
import { ICard } from 'src/store/Dashboard';
import api from 'src/api';

interface IFormWithData extends IShortFormData {
  partnerData?: {
    place: IPlace;
    dateTime: {
      date: string;
      gmt: number;
    }
  }
}


export default ReactMemo(function FilteredPersonMenmu({
  items,
  form,
  onClose,
  onSetForm,
}: {
  items?: ICard[];
  form: any;
  onSetForm?: (form: any, mode?: CircleMode) => void;
  onClose?: () => void;
}) {

  const [searchString, setSearchString] = React.useState<string>('');
  const { t } = useTranslation();
  
  const update = async (partner: IFormWithData) => {
    const partnerForm: IFormData = await api.form(partner.id)
    const currentForm = cloneDeep(form);

    const node = {
      name: partnerForm.name,
      dt: partnerForm.natal.dt,
      gmt: partnerForm.natal.gmt,
      place: partnerForm.natal.place,
      // @ts-ignore
      gender: partnerForm.natal.gender
    };

    const limitPartners = 9
    const overPartners = currentForm.partners.length >= limitPartners

    if (!overPartners) {
      currentForm.partners.push(node)

      const mode = `partner${currentForm.partners.length}` as CircleMode

      onSetForm && onSetForm(currentForm, mode)

      show({
        type: 'success',
        closable: true,
        timeout: 3000,
        text: t("chronos.app.showMessages.partnerChanged")
      });
    }
  }

  const onSelectForm = (partner: IShortFormData) => {
      update(partner)
  }

  const _filter = React.useMemo(() => ({
    by: 'name',
    val: searchString
  }), [searchString]);

  return (
    <Container>
      <InputForm
        placeholder="chronos.app.dashboard.findInList"
        inputDebounce={300}
        onTextInput={(val: string) => {
          setSearchString(val);
        }}
        onFormSubmit={() => {}}
      />
      <PersonMenu
        items={items}
        filter={_filter}
        onSelect={(person: IShortFormData) => {}}
        onClickSelect={(person: IShortFormData) => {
          onSelectForm(person);
          onClose?.();
        }}
      />
    </Container>

  );
});

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 15.313rem;
  padding: 0.438rem 0.375rem;
  border-radius: 0.375rem;
  background-color: var(--bg-100);

  .person-menu {
    padding: 0;
  }
`;
