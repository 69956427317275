import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { ISelectedItem } from 'src/types';

import { ShevronDownIcon, LinkIcon, KeyIcon } from 'src/assets/icons/system';
import { useTranslation } from 'src/i18n/useTranslation';

type SizeType = 'big' | 'medium';

export default function Select(props: {
  options: ISelectedItem[];
  selected: number | number[];
  showSelectedIcon?: boolean;
  size?: SizeType;
  onSelect?(id: number, option: ISelectedItem): void;
  isTop?: boolean;
  isLeft?: boolean;
  hideSelected?: boolean;
  hideKeyIcon?: boolean;
  closeOnClickOutside?: boolean;
  closeOnSelect?: boolean;
  className?: string;
  icon?: ReactElement;
  extraIcon?: ReactElement;
  isActive?: boolean;
  showAsModeButton?: boolean;
}) {
  // if (!props.options.length) { return null }

  const { title = "chronos.app.ui.select.noChosen", Icon, picture } = (props.options.find(({ id }) => typeof(props.selected) === 'number'
    ? id == props.selected
    : props.selected.includes(+id)) as ISelectedItem
  ) || {};

  const ref = React.useRef<HTMLDivElement>(null);
  const [showList, setShowList] = React.useState(false);

  const onSelect = (ev: any, id: number) => {
    ev.stopPropagation();

    if(props.closeOnSelect !== false) {
      setShowList(false);
    }

    const option =  props.options.find(({ id: optionId }) => id === optionId) as ISelectedItem
    const { action } = option;

    action?.(id.toString(), ev);
    props.onSelect?.(id, option);

    // if (typeof action === 'function') {
    //   action(id.toString(), ev);
    // } else {
    //   props.onSelect?.(id, option);
    // }
  };

  React.useEffect(() => {
    if(props.closeOnClickOutside === false) {
      return;
    }
    const onClick = (ev: any) => {
      if (showList && !ref.current?.contains(ev.target)) {
        setShowList(false);
      }
    };

    window.addEventListener('click', onClick);

    return () => {
      window.removeEventListener('click', onClick);
    };
  });

  const { t } = useTranslation();
  
  return (
    <Container
      isTop={props.isTop ?? false}
      size={props.size}
      onClick={() => setShowList(val => !val)}
      hideSelected={props.hideSelected ?? false}
      className={props.className}
      isActive={props.isActive}
      showAsModeButton={props.showAsModeButton}
    >
      {!props.hideSelected &&
        <SelectedContent className="selected-content">
          {props.showSelectedIcon && Icon &&
            <ListItemIcon size="1.125rem">
              <Icon size={18} image={picture} />
            </ListItemIcon>
          }

          {title && <span title={t(title)}>{t(title)}</span>}
        </SelectedContent>
      }

      { props.icon
          ? props.icon
          : <Arrow hideSelected={props.hideSelected ?? false} up={props.isTop ? !showList : showList}><ShevronDownIcon /></Arrow>
      }

      { props.extraIcon
          ? <IconContainer>{props.extraIcon}</IconContainer>
          : null
      }

      {showList &&
        <ListContainer
          ref={ref}
          isTop={props.isTop ?? false}
          className="list-container"
          isLeft={props.isLeft ?? false}
        >
          <List>
            {props.options.map(({ id, title, Icon, picture, link, RightIcon, action, disabled }, idx) => {
              const isDivider = id === 'div';
              return (
                !isDivider ?
                  <ListItem
                    key={`opt_${id}`}
                    isActive={typeof(props.selected) === 'number' ? id == props.selected : props.selected.includes(+id)}
                    onClick={disabled ? () => {} : ev => onSelect(ev, +id)}
                    disabled={disabled ?? false}
                  >
                    {Icon && <ListItemIcon size="1rem"><Icon size={16} image={picture} /></ListItemIcon>}
                    {link
                      ? <>{title && <a href={link} target="_blank">{t(title)}</a>}</>
                      : <>{title && <span style={{marginRight: !!RightIcon ? '2em' : 'unset'}}>{t(title)}</span>}</>
                    }
                    {link && <ListItemInfo><LinkIcon /></ListItemInfo>}
                    {action && !props.hideKeyIcon && <Actions><KeyIcon /></Actions>}
                    {RightIcon && <RightIcon style={{
                      margin: 0,
                      marginLeft: 'auto',
                      width: '1.5rem',
                      height: '1.5rem',
                      color: 'var(--text-third)'
                    }} />}
                  </ListItem>
                  // isDivider
                  : <Divider key={`opt_${idx}`} />
              );
            })}
          </List>
        </ListContainer>
      }
    </Container>
  );
}

const Actions = styled.div`
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: auto;
  justify-content: center;
  align-items: center;
  color: var(--icon-third);

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const ListItemInfo = styled.div`
  position: relative;
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.25rem;
  justify-content: center;
  align-items: center;
  vertical-align: center;
  color: var(--text-third);

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const ListItemIcon = styled.div<{ size: string }>`
  position: relative;
  width: 1rem;
  height: 1rem;
  min-width: 1rem;
  ${props => props.size.length && css`
    width: ${props.size};
    height: ${props.size};
  `}
  margin-right: 0.625rem;
`;

const Container = styled.div<{ size?: SizeType; isTop: boolean; hideSelected: boolean; isActive?: boolean; showAsModeButton?: boolean }>`
  display: flex;
  position: relative;
  flex: 0.95;
  justify-content: space-between;
  align-items: center;

  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-third);
  border: 1px solid var(--input-border);
  border-radius: 6px;
  padding: 0.687rem 0.75rem 0.687rem 0.75rem;
  margin-right: 0.5rem;
  background-color: ${props => props.hideSelected ? 'var(--element-neutral)' : 'var(--dropdown-background)'};
  cursor: pointer;

  ${props => props.size === 'medium' && css`
    padding: 0.625rem 0.9rem 0.625rem;
    border-radius: 5px;
  `}

  ${props => props.hideSelected && css`
    min-width: 2.25rem;
    height: 2.25rem;
    padding: 0 0.3rem;
    justify-content: center;
  `}

  ${props => props.showAsModeButton && props.isActive && css`
    border: 2px solid var(--accent-blue);
  `}

  ${props => props.showAsModeButton && css`
    & svg {
      fill: var(--icon-secondary);
      color: var(--icon-secondary);
      width: 1.125rem;
    }

    box-sizing: border-box;
    border-radius: 0.25rem;
    min-width: 2.375rem;
    height: 2.375rem;
  `}
`;

const SelectedContent = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  max-width: calc(100% - 2.625rem);
  color: var(--text-primary);
  align-items: center;

  & > svg {
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 0.5rem;
    vertical-align: middle;
  }

  & > span {
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Arrow = styled.div<{ up: boolean; hideSelected: boolean }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.625rem;
  height: 100%;
  right: 0;
  top: 0;
  /* transform-origin: middle; */
  transition: transform ease 0.2s;

  & svg {
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: middle;
  }

  ${props => props.hideSelected && css`
    position: unset;
    width: unset;
    right: unset;
  `}

  ${props => props.up && css`
    transform: rotate(180deg);
  `}
`;

const ListContainer = styled.div<{ isTop: boolean, isLeft: boolean }>`
  position: absolute;
  box-sizing: border-box;
  /* width: 100%; */
  background: var(--bg-100);
  padding: 0.25em;
  border-radius: 0.5em;
  ${props => props.isTop ? css`bottom: calc(100% + 0.75em)` : css`top: calc(100% + 0.5em)`};
  ${props => props.isLeft ? 'right: 0;' : 'left: 0;'}
  z-index: 1;
  background-color: var(--bg-100);
  box-shadow: 0px 3.2px 7.2px rgba(0, 0, 0, 0.13), 0px 0.6px 1.8px rgba(0, 0, 0, 0.11);
  border-radius: 6px;
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 0.9em;
`;

const ListItem = styled.li<{ isActive: boolean; disabled: boolean; }>`
  display: flex;
  /* box-sizing: border-box; */
  align-items: center;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;

  color: var(--text-primary);

  padding: 0.9em;
  border-radius: 0.5em;

  & > svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.7rem;
  }

  &:hover svg {
    color: var(--icon-secondary);
  }

  ${props => props.isActive && css`
    color: var(--accent-blue);
    svg {
      fill: var(--accent-blue);
    }
  `}

  & a {
    color: inherit;
    text-decoration: none;
  }

  & span {
    display: inline-block;
    /* max-width: 100%; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :hover {
    background: var(--element-neutral);
  }

  ${props => props.disabled && css`
    opacity: 0.5;
  `}
`;

const Divider = styled.div`
  position: relative;
  width: calc(100% - 1.8rem);
  height: 1px;
  margin: 0 auto;
  background-color: var(--element-neutral);
`;

const IconContainer = styled.div`
  svg {
    fill: none;
    width: 1.5rem;
    height: 1.5rem;
    }
`
