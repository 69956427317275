import React from 'react';
import styled, { css } from 'styled-components';
import _, { cloneDeep } from 'lodash';

import { CircleMode, astroObjects, aspects, IStrongAspect, ObjectType, getObjectName, ElementType, getSign, elements, SignType, AspectType, checkAspect, planetsPower, houseNames, housesPower, getPlanetsInfo } from 'src/libs';

import astro from 'src/astro';
import { aspectsIcons, objectsIcons } from 'src/icons';

import { default as TW } from 'src/ui/Wrappers/TooltipWrapper';
import { IWidgetData, IStrongs } from '../Widget';
import { IWidgetProps } from '../TabPanelWrapper';
import store from 'src/store';
import { AspectsIcon as AspectsTableIcon } from 'src/assets/icons/maps';
import { useTranslation } from 'src/i18n/useTranslation';
import { getDeviceDetect } from 'src/utils';
import {acccesOnlyForRu} from "../../../../helpers/permissions";
import i18n from 'src/i18n/i18n';
import { isPartner } from '../../utils';

const { isTablet } = getDeviceDetect();
const powerHouses = [
  'I',
  'II',
  'III',
  'IV',
  'V',
  'VI',
  'VII',
  'VIII',
  'IX',
  'X',
  'XI',
  'XII'
];

const strongHousesLimit = 4;

function elementBySign(sign: SignType) {
  for (let e = 0; e < 4; e++) {
    if (elements[e].includes(sign)) return e;
  }

  return ElementType.Air;
}

function strongElements(t: Function, data: IWidgetData, strongs: IStrongs, tab?: string) {
  const objects: {
    [key: number]: number;
  } = {
    [ObjectType.Sun]: ElementType.Fire,
    [ObjectType.Mars]: ElementType.Fire,
    [ObjectType.Jupiter]: ElementType.Fire,

    [ObjectType.Venus]: ElementType.Land,
    [ObjectType.Saturn]: ElementType.Land,

    [ObjectType.Moon]: ElementType.Water,
    [ObjectType.Neptune]: ElementType.Water,
    [ObjectType.Pluto]: ElementType.Water,

    [ObjectType.Mercury]: ElementType.Air,
    [ObjectType.Uranus]: ElementType.Air
  };

  const els: any = {
    [ElementType.Land]: 0,
    [ElementType.Air]: 0,
    [ElementType.Water]: 0,
    [ElementType.Fire]: 0
  };

  const elNames: any = {
    0: "Земля",
    1: "Воздух",
    2: "Вода",
    3: "Огонь"
  };

  const printEls = (lbl: string) => {
    // console.log(lbl);
    // Object.keys(els).forEach(key => console.log(elNames[+key], els[+key]));
  };

  let map = tab && isPartner(tab as CircleMode)
    ? data.maps.find(map => map.mode === tab) || data.partnersMaps?.[tab] || data.natalMap
    : data.natalMap;

  map = cloneDeep(map);

  //@ts-ignore
  const addObj = (obj: ObjectType) => els[objects[obj]]++;
  //@ts-ignore
  const addObjBySign = (obj: ObjectType) => els[elementBySign(getSign(map?.objects[obj].lon))]++;
  //@ts-ignore
  els[elementBySign(getSign(map?.houses[0]))]++;
  printEls("1 asc");
  addObjBySign(ObjectType.Sun);
  printEls("2 sun");
  addObjBySign(ObjectType.Moon);
  printEls("3 moon");

  const opts = astro.checkAspectOpts('natal');

  for (let obj = ObjectType.Sun; obj <= ObjectType.Pluto; obj++) {
    if (
      obj != ObjectType.Sun && checkAspect(
        astro.getPairOrbise('natal', ObjectType.Sun, obj, AspectType.Conjunction),
        map?.objects[ObjectType.Sun].lon,
        map?.objects[obj].lon,
        AspectType.Conjunction,
        opts
      ).exists ||
      obj != ObjectType.Moon && checkAspect(
        astro.getPairOrbise('natal', ObjectType.Moon, obj, AspectType.Conjunction),
        map?.objects[ObjectType.Moon].lon,
        map?.objects[obj].lon,
        AspectType.Conjunction,
        opts
      ).exists ||
      checkAspect(
        4,
        map?.houses[0],
        map?.objects[obj].lon,
        AspectType.Conjunction,
        opts
      ).exists
    ) {
      addObj(obj);
    }
  }

  printEls("4");

  const spl = [...strongs.planets];

  while (spl.length) {
    const stable = els[ElementType.Fire] + els[ElementType.Land];
    const unstable = els[ElementType.Water] + els[ElementType.Air];

    const sels = Object.keys(els)
      //@ts-ignore
      .map(k => ({ el: +k, v: els[+k] }))
      .sort((a, b) => b.v - a.v);

    // console.log("6.1", "стаб", stable, "нестаб", unstable);
    // console.log("6.2", "стихии", sels);

    if (stable != unstable && sels[1].v != sels[2].v) {
      const names = [
        "chronos.app.dashboard.strongObjects.earth",
        "chronos.app.dashboard.strongObjects.air",
        "chronos.app.dashboard.strongObjects.wather",
        "chronos.app.dashboard.strongObjects.fire"
      ];
      const stabUnstab = stable > unstable ? t("chronos.app.dashboard.strongObjects.sustainable") : t("chronos.app.dashboard.strongObjects.unstable");
      const name0 = t(names[sels[0].el]);
      const name1 = t(names[sels[1].el]);

      return t("chronos.app.dashboard.strongObjects.StrongElements", {stabUnstab, name0, name1});
    }

    const { p } = spl.shift()!;
    // console.log("6.2.x", "сильная планета", t(astroObjects[p].ru));
    addObj(p);
  }

  return null;
}

export default function StrongObjects(props: IWidgetProps) {
  let map = props.tab && isPartner(props.tab as CircleMode)
    ? props.data.maps.find(map => map.mode === props.tab) || props.data.partnersMaps?.[props.tab] || props.data.natalMap
    : props.data.natalMap;
  
  map = cloneDeep(map);

  const oldSettings = cloneDeep(astro.settings);

  const profilePA = store.astro.profiles.find(p => p.id === 0)!;
  const orbise = astro.settings.maps.natal.orbiseCorrector;
  const floatingOrbise = (store.activeAstroProfile && store.activeAstroProfile.closureConfig) ?? 0;

  // @ts-ignore
  astro.settings = cloneDeep(profilePA);
  astro.settings.maps.natal.orbiseCorrector = orbise;

  // @ts-ignore
  astro.settings.closureConfig = floatingOrbise;

  const strongs = {
    planets: planetsPower(map),
    houses: housesPower(map),
    aspects: astro.strongAspects(map)
  }
  const isSameAspects = (aspect1: { obj1: number, obj2: number }, aspect2: { obj1: number, obj2: number }) => (aspect1.obj1 === aspect2.obj1 && aspect1.obj2 === aspect2.obj2) || (aspect1.obj1 === aspect2.obj2 && aspect1.obj2 === aspect2.obj1);
  // todo Переехать в либу?
  strongs.aspects = strongs.aspects.filter(SAsp => map.aspects.find(a => isSameAspects(SAsp, a)));
  const planetsInfo = getPlanetsInfo(map);
  const includes = (arr: Array<number>, values: Array<number>) => values.some(v => arr.includes(v));

  // Оставляем среди контролирующих планет только те, которые входят в состав контролирующих домов, учитывая лимит
  strongs.houses.houses = strongs.houses.houses.filter((h, i) => i < strongHousesLimit);
  strongs.houses.planets = strongs.houses.planets.filter(p => includes(strongs.houses.houses, planetsInfo[p].controls));

  const fullPoints = _.intersection(
    strongs.houses.planets,
    strongs.planets.map(i => i.p)
  );

  const _aspects = strongs.aspects
    .filter(a => a.weight > 0)
    // .filter(a => !!map.aspects.find(b =>
    //   b.type == a.type &&
    //   (
    //     b.obj1 == a.obj1 && b.obj2 == a.obj2 ||
    //     b.obj1 == a.obj2 && b.obj2 == a.obj1
    //   )
    // )
    // )
    .sort((a, b) => b.weight - a.weight);
  const { t } = useTranslation();
  const sels = strongElements(t, props.data, strongs, props.tab);

  astro.settings = cloneDeep(oldSettings);

  return <>
    {strongs.planets?.length > 0 && <Group>
      <header onClick={ev => {
        if (ev.ctrlKey) planetsPower(map, true);
      }}>{t("chronos.app.instruments.widgets.StrongObjects.strongPlanets")}</header>
      <SingleItemsList>
        {strongs.planets.map((p: any) => {
          const ObjIcon = objectsIcons[p.p];
          return (
            <TW text={t(astroObjects[p.p].ru)} key={`planet_${p.p}`}>
              <SingleIcon data-object-name={astroObjects[p.p].en}>
                <ObjIcon />
              </SingleIcon>
            </TW>
          );
        })}
      </SingleItemsList>
    </Group>}

    {strongs.houses?.houses?.length > 0 && <Group>
      <header onClick={ev => {
        if (ev.ctrlKey) housesPower(map);
      }}>{t("chronos.app.instruments.widgets.StrongObjects.strongHouses")}</header>
      <SingleItemsList>
        {strongs.houses.houses.map((h: any, index: number) =>
          index < strongHousesLimit && <TW text={i18n.language === 'ru' ? `${h + 1} ${t("astro.house")}` : `${t("astro.house")} ${h + 1}`} key={`house_${h}`}>
            <SingleIcon data-object-name={powerHouses[h]}>{powerHouses[h]}</SingleIcon>
          </TW>
        )}
      </SingleItemsList>
    </Group>}

    {fullPoints.length > 0 && <Group>
      <header>{t("chronos.app.instruments.widgets.StrongObjects.fullResonancePoints")}</header>
      <SingleItemsList>
        {fullPoints.map((p: any) => {
          const ObjIcon = objectsIcons[p];
          return (
            <TW text={t(astroObjects[p].ru)} key={`point_${p}`}>
              <SingleIcon data-object-name={astroObjects[p].en}>
                <ObjIcon />
              </SingleIcon>
            </TW>
          );
        })}
      </SingleItemsList>
    </Group>}

    {_aspects.length > 0 && <Group>
      <header>{t("chronos.app.instruments.widgets.StrongObjects.strongsAspects")}</header>
      <TwinItemsList>
        {_aspects.map((item, i) =>
          {
            return (
              <TwinIcon aspect={item} key={`aspect_${i}`}>
                <AspectsTableIcon />
                <AspectsTableIcon />
              </TwinIcon>
            )
          }
        )}
      </TwinItemsList>
    </Group>}

    <Group>
      <header>{t("chronos.app.instruments.widgets.StrongObjects.strongElements")}</header>
      <StrongElements>{sels ? <span>{sels}</span> : <>{t("chronos.mobile.instruments.widgets.strongObjects.complexCore")}</>}</StrongElements>
    </Group>
  </>;
}

function SingleIcon(props: {
  children: React.ReactNode | string;
  className?: string;
}) {
  return <Icon {...props} className={props.className}>{props.children}</Icon>;
}

function TwinIcon(props: {
  children: React.ReactNode[] | string;
  aspect: IStrongAspect;
}) {
  const { aspect } = props;
  const link = aspects[props.aspect.type];
  const AspIcon = aspectsIcons[props.aspect.type];

  const Obj1Icon = objectsIcons[aspect.obj1];
  const Obj2Icon = objectsIcons[aspect.obj2];
  const aspectWithCuspTitle = (aspect: IStrongAspect, objIdx: number) => houseNames[(aspect as any)[`obj${objIdx}`] - ObjectType.House1];
  
  const { t } = useTranslation();
  return <TwinIconC color={`var(--circle-aspects-${aspect.type})`}>
    <TW text={t(getObjectName(aspect.obj1))}>
      <Icon data-object-name={astroObjects[aspect.obj1]?.en || aspectWithCuspTitle(aspect, 1)}>
        {(aspect.obj1 < ObjectType.House1) 
          ? <Obj1Icon /> 
          : <span>{aspectWithCuspTitle(aspect, 1)}</span>
        }
      </Icon>
    </TW>
    <Circle />
    <Line />
    <TW text={acccesOnlyForRu() ? `<span style="color: var(--circle-aspects-${aspect.type})">${t(link.ru)}</span>` : ''}><AspIcon width="1em" /></TW>
    <Line />
    <Circle />
    <TW text={t(getObjectName(aspect.obj2))}>
      <Icon data-object-name={astroObjects[aspect.obj2]?.en || aspectWithCuspTitle(aspect, 2)}>
        {aspect.obj2 < ObjectType.House1 
          ? <Obj2Icon /> 
          : <span>{aspectWithCuspTitle(aspect, 2)}</span>
        }
      </Icon>
    </TW>
  </TwinIconC>;
}

const Group = styled.div`
  margin-bottom: 1.125rem;
  font-size: 1rem;
  color: var(--text-primary);

  & > header {
    margin-bottom: 0.875rem;
  }
`;

const Icon = styled.div`
  display: flex;
  width: 2rem;
  height: 2rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  background: var(--element-neutral);

  border: 1px solid var(--input-border);
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 600;

  & > svg {
    width: 1.125rem;
    color: var(--text-primary);
    fill: var(--text-primary);
  }
  & > span {
    color: var(--text-primary);
  }
`;

const SingleItemsList = styled.div`
  display: grid;
  grid-template-columns: repeat(15, min-content);
  grid-gap: 0.75rem;
`;

const TwinItemsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;     
`;

const TwinIconC = styled('div') <{ color: string }>`
  display: flex;
  align-items: center;
  color: ${props => props.color};

  & svg {
    display: block;
  }
`;

const Circle = styled.span`
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background: currentColor;

  &:first-of-type {
    margin-left: -3px;
  }
  &:last-of-type {
    margin-right: -3px;
  }
`;

const Line = styled.div`
  width: 9px;
  height: 1px;
  background: currentColor;

  &:first-of-type {

  }
  &:last-of-type {

  }
`;

const StrongElements = styled.div`
  font-size: 0.8em;
  color: var(--text-secondary);
`;
