import React from 'react';
import styled, { css, CSSProperties } from 'styled-components';

import { ReactMemo, useCurrency } from 'src/hooks';
import { Badge, Checkbox, IPromocode } from 'src/libs';

import * as TappedBlock from 'src/pages/Settings/wrappers/TappedBlock';
import { ISubscription } from 'src/api';
import { useTranslation } from 'src/i18n/useTranslation';


export default ReactMemo(function TariffView({
  data,
  free = false,
  freeText = '',
  active = false,
  border = false,
  style,
  button,
  onClick,
  activePayType = 1,
  promoData
}: {
  data: ISubscription;
  free?: boolean;
  freeText?: string;
  active?: boolean;
  border?: boolean;
  style?: CSSProperties;
  button: React.ReactNode;
  onClick?(tariff: string | undefined): void;
  activePayType?: number;
  promoData?: IPromocode;
}) {
  const { t } = useTranslation();
  const currency = useCurrency();

  const sum = data.sum[activePayType];

  return (
    <StyledTappedBlock style={style} border={border} active={active} color={data.color} onClick={() => { onClick?.(data.type) }}>
      <h3 className="title">
        {active && <div className="checkbox"><Checkbox checked={true} onChange={() => {}}/></div>}{t(data.name)}
        {sum?.discount > 0 &&
          <div className="badge">
            <Badge state="success" value={`-${sum?.discount}%`} />
          </div>
        }
      </h3>

      {!free ?
        <>
          <div className="price">{sum?.discount ? <ins>{sum?.original}</ins> : sum?.final} {sum?.discount ? sum.final : ''} <span>{currency.symbol}</span></div>
          <div className="price-caption">{t("chronos.app.settings.inMonth")}</div>
        </>
        : <>
          <div className="price">{t("chronos.app.settings.isFree")}</div>
          <div className="price-caption">{freeText}</div>
        </>
      }
      {data.detail && <div className="detail">{t(data.detail)}</div>}
      {button}
    </StyledTappedBlock>
  );
});

const StyledTappedBlock = styled(TappedBlock.Container) <{ color: string | undefined; border: boolean; active: boolean }>`
  min-width: 12rem;
  /* max-width: 12rem; */
  min-height: 11.125rem;
  padding: 1.375rem 1rem 1.5rem 1rem;
  font-weight: 600;

  ${props => props.border && css`
    box-shadow: 0 0 0 2px ${props.color || 'var(--accent-red)'};
  `}
  
  .title {
    display: flex;
    justify-content: flex-start;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 0;
    margin-bottom: 0;
    color: ${props => props.color};

    /* ${props => props.active && css`
      color: var(--accent-red);
    `} */

    .checkbox {
      margin-right: 0.688rem;
    }
    .badge {
      margin-left: auto;
    }
  }

  .price {
    margin-top: 0.562rem;
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: var(--text-primary);

    ins {
      text-decoration: line-through;
      color: var(--text-secondary)
    }
  }

  .price-caption {
    margin-top: 0.25rem;
    font-size: 0.8rem;
    color: var(--text-secondary);
  }

  .detail {
    margin-top: 0.875rem;
    max-width: 90%;
    font-size: 0.875rem;
    line-height: 150%;
    color: var(--text-secondary);
  }

  button {
    align-self: flex-start;
    margin-top: auto;
  }
`;
