import React from 'react';

import {
  IObject,
  ObjectType,
  signs,
  getSign,
  isNotTransits,
  astroObjects,
  circularDistance,
  IChain,
  IPower,
  fillNumber,
  show
} from 'src/libs';

import { objectsIcons } from 'src/icons';

import { CirclePartProps, Group } from './index';
import { IStrongs } from 'src/pages/Instruments/Widgets/Widget';
import { getOrbit } from 'src/utils';
import { hideMapInfoPopup, showMapInfoPopup } from 'src/pages/Instruments/Maps';

import { FortuneIcon } from 'src/assets/icons/astro/objects';
import { distance } from 'src/api';
import { isCompatibility } from 'src/pages/Instruments/utils';
import { isEastern } from 'src/pages/Instruments/Widgets/Horar/horar';
import store from 'src/store';
import _ from 'lodash';
import { WITH_OUTER_OFFSET } from './index';

export interface IArrangedObject extends IObject {
  id: number;
  alon: number;
  order: number;
}
interface ISector extends IObject {
  id: number;
}

export function fortuneLon(objects: IObject[], houses: number[], debug = false) {
  const [ascLon] = houses;
  const sunLon = objects[ObjectType.Sun].lon;
  const moonLon = objects[ObjectType.Moon].lon;

  let xLon = sunLon - (ascLon - 180);
  if (xLon < 0) { xLon += 360 }

  const lon = xLon % 360 >= 180 ? (ascLon + sunLon - moonLon) % 360 : (ascLon + moonLon - sunLon) % 360;
  return lon < 0 ? lon + 360 : lon;
}

const iconPrimary = 'var(--icon-primary)';

function getObjColor(color: string, style: number, hasExt: boolean): string {
  switch (style) {
    case 0: return color; // Красить планеты
    case 1: return iconPrimary; // Красить точки
    case 2: return hasExt ? color : iconPrimary; // Комбинированный
    case 3: return iconPrimary; // Не перекрашивать
    default: return iconPrimary;
  }
}
function getPointColor(color: string, style: number, hasExt: boolean): string {
  switch (style) {
    case 0: return iconPrimary; // Красить планеты
    case 1: return color; // Красить точки
    case 2: return hasExt ? color : iconPrimary; // Комбинированный
    case 3: return iconPrimary; // Не перекрашивать
    default: return iconPrimary;
  }
}
export function getObjectHouses(lon: number, houses: number[]) {
  const result: number[] = [];

  for (let h = 0; h < houses.length; h++) {
    const nextH = (h + 1) % 12;

    const lonH = houses[h];
    let lonNH = houses[nextH];
    let tlon = lon;

    if (lonNH < lonH) {
      if (tlon <= lonNH) {
        tlon += 360;
      }
      lonNH += 360;
    }

    if (tlon >= lonH && tlon < lonNH) {
      result.push(h);
      break;
    }
  }

  return result.sort((a, b) => b - a);
}
export function getArrangeObjects(objects: IObject[], houses: number[], dist: number, horarSigns: number[], orderSize: number, soulStrong: number[], withHigherPlanets?: boolean, showMinorPlanets?: boolean): IArrangedObject[] {

  if (horarSigns.length) {
    const higherMinorPlanets = [ObjectType.Uranus, ObjectType.Neptune, ObjectType.Pluto, 15]; // 15 = Эрида
    const sectors: ISector[][] = [];
    
    for (let i = 0; i < 12 * 3; i++) {
      sectors.push([]);
    }

    objects
      .map((o, id) => ({ id, ...o }))
      .filter(o => {
        const withEris = showMinorPlanets && o.id === 15;
        const allPlanets = (o.id <= ObjectType.Lilith) || withEris;
        const withOutHeigherPlanets = (o.id <= ObjectType.Saturn || (o.id >= ObjectType.NorthNode && o.id <= ObjectType.Lilith)) || withEris;
        return (withHigherPlanets)
          ? allPlanets
          : withOutHeigherPlanets
      } )
      .map(o => {
        let { lon, speed } = o;
        const { id } = o;
        
        const [h] = getObjectHouses(lon, houses);
        const dist = circularDistance(houses[h], houses[(h + 1) % 12], 360);

        lon = (180 + h * 30 + 30 * circularDistance(lon, houses[h], 360) / dist) % 360;
        return {
          id,
          lon,
          speed
        };
      })
      .sort((o1, o2) => o1.lon - o2.lon)
      .forEach(o => {
        let id = Math.floor(o.lon / 10);
        if (id % 3 === 2) {
          let tid = id;
          while (sectors[tid].length >= 3) {
            const first = sectors[tid].shift();
            first && sectors[--tid].push(first);
          }
        } else {
          while (sectors[id].length === 3) {
            id++;
          }
        }

        sectors[id].push(o);
      });

    const aobjects: IArrangedObject[] = [];

    sectors.forEach((s, i) => {
      let lastAlon: number
      s.forEach((o, order) => {
        const minDist = 10;
        let alon = (Math.floor(i / 3) * 30 + Math.floor(i % 3) * 10 + 5) % 360;
        if (higherMinorPlanets.includes(o.id)) {
          if (lastAlon !== null && (alon - lastAlon < minDist)) {
            alon = lastAlon + minDist; 
            if (alon >= 360) {
              alon -= 360; 
            }
          }
          lastAlon = alon; 
        }
        aobjects.push({
          ...o,
          alon,
          order
        });
      });
    });

    return aobjects;
  }

  if (soulStrong.length) {
    const aobjects: IArrangedObject[] = [];

    objects
      .map((obj, id) => {
        const borderDistance = +(obj.lon % 30).toFixed(2);

        const nextBorder = 30 - borderDistance;
        const pastBorder = borderDistance;

        let alon = obj.lon;

        if (nextBorder < dist) {
          alon -= dist - nextBorder;
        }

        if (pastBorder < dist) {
          alon += dist - borderDistance;
        }

        aobjects.push({
          ...obj,
          id,
          alon,
          order: soulStrong[id] || 0
        });
      });

    aobjects
      .forEach((obj, id, arr) => {
        if (aobjects.some(o => o.id !== obj.id && Math.floor(o.lon / 30) === Math.floor(obj.lon / 30) && o.order === obj.order && o.order === 6)) {
          arr[id].order = 7;
        }
        arr.map(o => {
          if (obj.order === o.order && Math.abs(+(obj.alon - o.alon).toFixed(2)) < dist && obj.id !== o.id) {
            const obj1Lon = obj.alon;
            const obj2Lon = o.alon;
            const borderDistance1 = +(obj1Lon % 30).toFixed(2);
            const borderDistance2 = +(obj2Lon % 30).toFixed(2);

            const nextBorder1 = +(30 - borderDistance1).toFixed(2);
            const nextBorder2 = +(30 - borderDistance2).toFixed(2);

            if (borderDistance1 < 15) {
              if (borderDistance1 <= borderDistance2) {
                arr[o.id].alon = obj1Lon + dist;
              } else {
                arr[obj.id].alon = obj2Lon + dist;
              }
            } else if (nextBorder1 <= nextBorder2) {
              arr[o.id].alon = obj1Lon - dist;
            } else {
              arr[obj.id].alon = obj2Lon - dist;
            }
          }
        });
        //
        // if (objectGap) {
        //   let { alon } = arr[objectGap.id];
        //   arr[objectGap.id].alon = alon - (dist + objectGap.distance);
        // }
      });

    return aobjects;
  }

  const aobjects = objects
    .map((o, id) => ({
      ...o,
      id,
      alon: o.lon,
      order: 0
    }))
    .filter((o) => {
      return !showMinorPlanets ? o.id !== 15 : true // показываем / скрываем Эриду - id = 15
    })
    .sort((o1, o2) => o1.alon - o2.alon);

  // FIXME: optimize
  for (let i = 0; i < 100; i++) {
    const moves = aobjects.map(() => ({ lon: 0, order: 0 }));

    for (let j = -1; j < aobjects.length - 1; j++) {
      const left = j === -1 ?
        aobjects[aobjects.length - 1].alon - 360 :
        aobjects[j].alon;
      const right = aobjects[j + 1].alon;
      const d = Math.max(0, (dist - right + left) / 2);
      const currentMove = moves[j === -1 ? aobjects.length - 1 : j];

      if (orderSize > 1 && d > 0 && currentMove.order < orderSize - 1) {
        moves[j === -1 ? aobjects.length - 1 : j].lon -= 0;
        moves[j + 1].lon += 0;
        moves[j + 1].order += currentMove.order + 1;
      } else {
        moves[j === -1 ? aobjects.length - 1 : j].lon -= d;
        moves[j + 1].lon += d;
      }
    }

    aobjects.forEach((a, i) => {
      a.alon += moves[i].lon;
      a.order = moves[i].order;
    });

    if (!moves.some(m => Math.abs(m.lon) >= 0.1)) { break }
  }

  return aobjects;
}


// FIXME: move to astro-lib
export const avgSpeed: {
  [key: number]: number;
} = {
  [ObjectType.Sun]: 1,
  [ObjectType.Moon]: 13,
  [ObjectType.Mercury]: 1.38,
  [ObjectType.Venus]: 1,
  [ObjectType.Mars]: 0.5,
  [ObjectType.Jupiter]: 0.07,
  [ObjectType.Saturn]: 0.03,
  [ObjectType.Uranus]: 0.01,
  [ObjectType.Neptune]: 0.005,
  [ObjectType.Pluto]: 0.005,
  [ObjectType.NorthNode]: 0.18,
  [ObjectType.SouthNode]: 0.18,
  [ObjectType.Chiron]: 0.06,
  [ObjectType.Selena]: 0.06
};

export function isStaticObject(id: ObjectType, speed: number) {
  return Math.abs(speed) > 0 && (Math.abs(speed) < avgSpeed[id] * 0.1);
}

interface ObjectsProps extends CirclePartProps {
  objects: IObject[];
  houses: number[];
  strongs: IStrongs;
  isExt?: boolean;
  isOuter: boolean;
  horarSigns: number[];
  chains?: IChain[];
  soulStrongs?: number[];
  showHigherPlanets?: boolean;
  showMinorPlanets?: boolean;
  t: (v: string, vars?: any) => string;
}

export const _degToString = (speed: number) => {
  const sign = speed >= 0 ? '' : '-';
  const absSpeed = Math.abs(speed);
  const deg = Math.floor(absSpeed);
  const min = Math.floor((absSpeed - deg) * 60);
  const minString = `${min < 10 ? '0' : ''}${min}`;
  const sec = Math.floor((absSpeed - deg - min / 60) * 3600);
  const secString = `${sec < 10 ? '0' : ''}${sec}`;
  return `${sign}${deg}°${minString}'${secString}"`;
};



export default class Objects extends React.Component<ObjectsProps> {
  private readonly _objects: {
    back: React.RefObject<SVGCircleElement>;
    obj: React.RefObject<SVGGElement>;
  }[] = [];
  private readonly arabic: {
    back: React.RefObject<SVGCircleElement>;
    obj: React.RefObject<SVGGElement>;
  }[] = [];

  constructor(props: ObjectsProps) {
    super(props);
    // 15 - Eris asteroid
    for (let i = 0; i <= 15; i++) {
      this._objects.push({
        back: React.createRef<SVGCircleElement>(),
        obj: React.createRef<SVGGElement>(),
      });
    }
    this.arabic.push({
      back: React.createRef<SVGCircleElement>(),
      obj: React.createRef<SVGGElement>(),
    })
  }

  shouldComponentUpdate(newProps: ObjectsProps): boolean {
    this.update(newProps);
    return newProps.mode !== this.props.mode || !!newProps.isEditor || newProps.personalization.name !== this.props.personalization.name;
  }
  

  componentDidMount(): void {
    this.update(this.props);
  }

  update = (newProps: ObjectsProps): void => {
    const { personalization, computedRadius, highlights } = newProps;
  
    const iconSize = newProps.radius * personalization.circle.objects.iconSize;

    // 15 - Eris asteroid
    for (let i = 0; i <= 15; i++) {
      this._objects[i].back.current?.style.setProperty('display', 'none');
      this._objects[i].obj.current?.style.setProperty('display', 'none');
    }


    this.arabic[0].back.current?.style.setProperty('display', 'none');
    this.arabic[0].obj.current?.style.setProperty('display', 'none');

    let objectsRadius = 0;
    let pointRadius = 0;
    let pointRadiusExt = 0;

    let objectsRadiusOuter = 0;
    let pointRadiusOuter = 0;

    let outer = 0;
    let external = 0;
    let internal = 0;

    if (newProps.hasExt && !newProps.hasOuter) {
      if (newProps.isExt) {
        external = newProps.radius * computedRadius.external;
        internal = newProps.radius * computedRadius.zodiacsExternalExt;
        // objectsRadius = newProps.radius * ((computedRadius.external + computedRadius.zodiacsExternalExt) / 2);
      } else {
        external = newProps.radius * computedRadius.zodiacsInternalExt;
        internal = newProps.radius * computedRadius.internalExt;
        // objectsRadius = newProps.radius * ((computedRadius.zodiacsInternalExt + computedRadius.internalExt) / 2);
      }

      pointRadius = newProps.radius * computedRadius.internalExt;
      pointRadiusExt = newProps.radius * computedRadius.zodiacsExternalExt;
    
    } 
    
    if (newProps.hasExt && newProps.hasOuter) {
      if (newProps.isExt) {
        external = newProps.radius * (computedRadius.zodiacsInternal - WITH_OUTER_OFFSET);
        internal = newProps.radius * computedRadius.internalExt + (WITH_OUTER_OFFSET);
      } 
      
      else if (newProps.isOuter) {
        external = newProps.radius * (computedRadius.external);
        internal = newProps.radius * (computedRadius.zodiacsExternalExt);

      } else {
        external = newProps.radius * (computedRadius.internalExt + WITH_OUTER_OFFSET);
        internal = newProps.radius * (computedRadius.internalExt - WITH_OUTER_OFFSET);
        // objectsRadius = newProps.radius * ((computedRadius.zodiacsInternalExt + computedRadius.internalExt) / 2);
      }

      pointRadius = newProps.radius * (computedRadius.internalExt - WITH_OUTER_OFFSET);
      pointRadiusExt = newProps.radius * (computedRadius.internalExt + (WITH_OUTER_OFFSET));
      pointRadiusOuter = newProps.radius * (computedRadius.zodiacsExternalExt)
    }

    if (newProps.hasOuter && !newProps.hasExt) {
      if (newProps.isExt) {
        external = newProps.radius * computedRadius.external;
        internal = newProps.radius * computedRadius.zodiacsExternalExt;
        // objectsRadius = newProps.radius * ((computedRadius.external + computedRadius.zodiacsExternalExt) / 2);
      } else {
        external = newProps.radius * computedRadius.zodiacsInternalExt;
        internal = newProps.radius * computedRadius.internalExt;
        // objectsRadius = newProps.radius * ((computedRadius.zodiacsInternalExt + computedRadius.internalExt) / 2);
      }

      pointRadius = newProps.radius * computedRadius.internalExt;
      pointRadiusExt = newProps.radius * computedRadius.zodiacsExternalExt;
    }

    if (!newProps.hasOuter && !newProps.hasExt) {
      external = newProps.radius * computedRadius.zodiacsInternal;
      internal = newProps.radius * (newProps.mode === 'horar' ? personalization.circle.radius.internalHorar : computedRadius.internal);

      pointRadius = newProps.radius * computedRadius.internal;
      pointRadiusExt = newProps.radius * computedRadius.external;
    }
    

    const orderSize = newProps.mode === 'horar' ?
      3
      : newProps.mode === 'soul'
        ? 1
        : (personalization.circle.objects.location === 1 && !newProps.hasOuter)
          ? 2 //Math.floor((objectsRadius - (newProps.isExt ? pointRadiusExt : pointRadius)) * 2 / (iconSize * 1.6)) || 1
          : 1;
    
    const highlightId = newProps.isExt ? 'object_ext' : newProps.isOuter ? 'object_outer' : 'object';

    let maxOrder: {
      id: number;
      order: number;
      lon: number;
      x: number;
      y: number
    }[] = [];

    getArrangeObjects(
      newProps.objects
        // @ts-ignore
        // .filter((obj, id) => (id !== ObjectType.Chiron || !isNotTransits(newProps.mode)) && (newProps.mode === 'soul' || id !== ObjectType.Selena) )
        .map((obj, id) => {
          if (newProps.mode === 'soul' && id === ObjectType.SouthNode) {
            return {
              ...obj,
              lon: fortuneLon(newProps.objects, newProps.houses)
            };
          }
          return obj;
        }),
      newProps.houses,
      +(iconSize / 4).toFixed(2),
      newProps.horarSigns,
      orderSize,
      newProps.soulStrongs || [],
      newProps.showHigherPlanets,
      newProps.showMinorPlanets
    ).forEach(object => {
      
      const isHigherPlanet = [ObjectType.Uranus, ObjectType.Neptune, ObjectType.Pluto].includes(object.id);
      const isMinorPlanet = [15].includes(object.id); // Eris objectId - 15
      const isFortune = newProps.mode === 'soul' && object.id === ObjectType.SouthNode;
      const speed = isFortune ? 0 : newProps.objects[object.id].speed;
      const realLon = isFortune ? fortuneLon(newProps.objects, newProps.houses.length ? newProps.houses : [0]) : newProps.objects[object.id].lon;

      let color: string;

      try {
        color = newProps.hasExt ? (newProps.isExt ? '#7986CB' : (newProps.isOuter ? '#BA68C8' : '#EF5350')) : `rgba(var(--circle-zodiacs-${signs[getSign(realLon)].element}-rgb-${personalization.circle.colorThemes.zodiacs.active}), 1)`;
      } catch (e) {
        color = 'var(--circle-zodiacs-icons-land)';
        show({
          type: 'warning',
          key: 'LON_DATA_WARNING',
          text: this.props.t("chronos.app.components.circle.incorrectData")
        });
      }

      // console.log('obj color - ', color)

      const opacity = !newProps.highlights || newProps.highlights.includes(object.id) ? 1 : 0.2;

      const gap = (external - internal) / (orderSize + 1) * (1 + object.order);

      objectsRadius = internal + gap;

      if (newProps.mode === 'horar') {
        objectsRadius = external - gap;
        pointRadius = newProps.radius * personalization.circle.radius.internalHorar;

        if (isHigherPlanet || isMinorPlanet) {
          let higherPlanetPointRadius = newProps.radius * computedRadius.zodiacsInternal;
          pointRadius = higherPlanetPointRadius;
          objectsRadius = higherPlanetPointRadius + (isHigherPlanet ? 96 : 96);
        }
      } 
      
      else if (newProps.mode === 'soul') {
        const zodiacsInternal = newProps.radius * computedRadius.zodiacsInternal;
        const internal = newProps.radius * (personalization.circle.radius.internalSoul || 0.1);
        const singleCell = (zodiacsInternal - internal) / 8;
        const gap = singleCell * (object.order + 1 || 1) - singleCell / 2;
        objectsRadius = zodiacsInternal - gap;
        pointRadius = zodiacsInternal - gap - singleCell / 2 - (object.order === 6 ? singleCell : 0);
      }

      

      const p = newProps.point(objectsRadius, object.alon)
      const pointPos = newProps.point(pointRadius, object.lon);

      const back = this._objects[object.id].back.current as SVGCircleElement;
      back.style.display = isNotTransits(newProps.mode) || newProps.mode === 'soul' ? 'none' : 'block';

      back.setAttribute('cx', (p.x - 2).toString());
      back.setAttribute('cy', p.y.toString());

      const _obj = this._objects[object.id].obj.current as SVGGElement;
      _obj.style.display = 'block';
      const _objO = _obj.lastChild as SVGSVGElement;

      // подготовим текст для tooltip
      const tipLines = [`${isFortune ? this.props.t("chronos.app.instruments.widgets.soul.formulaStrength.wheelOfFortune") : this.props.t(astroObjects[object.id].ru)}: ${_degToString(realLon % 30)}`];
      if (!isFortune && newProps.mode !== 'soul') {
        if (!newProps.isExt || newProps.mode !== 'directions') {
          tipLines.push(`${this.props.t("chronos.auth.index.speed")}: ${isFortune ? '' : _degToString(object.speed)}`);
        }
      }
      if (newProps.mode === 'soul') {
        newProps.soulStrongs && tipLines.push(`${this.props.t("chronos.app.widgets.power")}: ${newProps.soulStrongs[object.id] || 0}`);
        newProps.chains && tipLines.push(`${this.props.t("chronos.app.instruments.widgets.soul.formulaStrength.orbit")}: ${getOrbit(newProps.chains, object.id)}`);
      }
      //

      // 
      _objO.onmouseenter = (ev: MouseEvent) => {
        if (store.settings.user.isLimitedAccess) return false;
        newProps.onHover(highlightId, object.id);
        if (newProps.mode != 'solars' && !isCompatibility(newProps.mode)) {
          showMapInfoPopup(ev.target as Element, tipLines, ev);
        }
      };

      _objO.onmouseleave = () => {
        newProps.onHover(highlightId, -1);
        hideMapInfoPopup();
      };

      _obj.setAttribute('opacity', opacity.toString());

      const curve = _obj.childNodes[0] as SVGPathElement;
      const point = _obj.childNodes[1] as SVGCircleElement;
      const pointExt = _obj.childNodes[2] as SVGCircleElement;
      const obj = _obj.childNodes[3] as SVGSVGElement;

      obj.style.color = getObjColor(color, personalization.circle.objects.style, newProps.hasExt);

      obj.setAttribute('x', (p.x - 2).toString());
      obj.setAttribute('y', p.y.toString());

      if (!maxOrder.length) {
        maxOrder.push({
          id: object.id,
          order: object.order,
          lon: object.lon,
          x: (p.x - 2),
          y: p.y
        })
      } else {
        if (maxOrder.some(o => object.order > o.order)) {
          maxOrder.push({
            id: object.id,
            order: object.order,
            lon: object.lon,
            x: (p.x - 2),
            y: p.y
          })
        } else if (maxOrder.some(o => object.order === o.order)) {
          maxOrder.push({
            id: object.id,
            order: object.order,
            lon: object.lon,
            x: (p.x - 2),
            y: p.y
          })
        }
      }

      point.setAttribute('cx', pointPos.x.toString());
      point.setAttribute('cy', pointPos.y.toString());

      point.style.stroke = getPointColor(color, personalization.circle.objects.style, newProps.hasExt);

      point.onmouseenter = (ev: MouseEvent) => {
        if (store.settings.user.isLimitedAccess) return false;
        
        if (newProps.mode != 'solars' && !isCompatibility(newProps.mode)) {
          showMapInfoPopup(ev.target as Element, tipLines, ev);
        }
      }

      point.onmouseleave = () => {
        hideMapInfoPopup();
      };

      if (newProps.hasExt && (newProps.isExt || newProps.isOuter)) {
        const _pointRadius = newProps.isExt ? pointRadiusExt : pointRadiusOuter;
        const pointPosExt = newProps.point(_pointRadius, object.lon);

        pointExt.style.display = 'block';
        pointExt.setAttribute('cx', pointPosExt.x.toString());
        pointExt.setAttribute('cy', pointPosExt.y.toString());
        pointExt.style.stroke = color;
      } else {
        pointExt.style.display = 'none';
      }

      if (Math.abs(object.lon - object.alon) > 1 && newProps.mode !== 'horar' && newProps.mode !== 'soul' && object.order === 0) {
        const endRadius = objectsRadius - iconSize * 0.5;
        const _pointRadius = newProps.isExt ? pointRadiusExt : (newProps.isOuter ? pointRadiusOuter : pointRadius);
        const length = endRadius - (_pointRadius);
        
        const pointPosExt = newProps.point(newProps.isExt ? pointRadiusExt : pointRadiusOuter, object.lon);

        const pB1 = newProps.point(_pointRadius + length / 3, object.lon);
        const pB2 = newProps.point(_pointRadius + length / 2, (object.lon + object.alon) / 2);
        const pB3 = newProps.point(endRadius, object.alon);

        curve.setAttribute('d', `
          M ${((newProps.isExt || newProps.isOuter) ? pointPosExt : pointPos).x}, ${((newProps.isExt || newProps.isOuter) ? pointPosExt : pointPos).y}
          Q ${pB1.x}, ${pB1.y} ${pB2.x}, ${pB2.y}
          T ${pB3.x}, ${pB3.y}
        `);
        curve.style.display = 'block';
        curve.style.stroke = color;
        curve.style.strokeWidth = '0.5';
      } else {
        curve.style.display = 'none';
      }


      const strongPlanets = newProps.strongs.planets;
      const strongHouses = newProps.strongs.houses.planets;

      const fullPoints = _.intersection(
        strongHouses,
        strongPlanets.map(i => i.p)
      );

      const isStrongPlanet = newProps.mode === 'natal' && strongPlanets.some((x: IPower) => x.p === object.id);
      const isStrongHPlanet = newProps.mode === 'natal' && fullPoints.some((p: number) => p === object.id);
      const isStatsPlanet = (speed < (avgSpeed[object.id] * 0.1) && speed > (-avgSpeed[object.id] * 0.1));
      const isDirectionsExtObjects = Boolean(newProps.mode === 'directions' && newProps.isExt);


      const hinfo = obj.childNodes[2] as SVGTextElement;
      const info = obj.childNodes[3] as SVGTextElement;
      const strongHPlanet = obj.childNodes[4] as SVGCircleElement;
      const strongPlanet = obj.childNodes[5] as SVGCircleElement;
      const degInfo = obj.childNodes[6] as SVGTextElement;

      // Для extObjects из derections значки не рисуем
      if (!isDirectionsExtObjects && (isStatsPlanet || speed < 0)) {
        info.textContent = isStatsPlanet ? 's' : 'r';
        info.style.display = 'block';

        // красим только для хорара
        if (newProps.mode === 'horar') {
          info.style.color = isStatsPlanet ? 'var(--accent-blue)' : 'var(--accent-red)';
        }
      } else {
        info.textContent = '';
        info.style.display = 'none';
      }


      info.setAttribute('y', (iconSize * (newProps.mode === 'horar' ? -0.5 : 0.55)).toString());

      if (newProps.mode === 'horar') {
        let value = realLon % 30;

        value = Math.abs(value);

        const degs = hinfo.childNodes[0] as SVGTextElement;
        const mins = hinfo.childNodes[2] as SVGTextElement;

        const degrees = Math.floor(value);

        degs.textContent = fillNumber(degrees, 2);

        value -= degrees;

        mins.textContent = fillNumber(Math.floor(value * 60), 2);

        hinfo.style.display = 'block';
        strongPlanet.style.display = 'none';
        strongHPlanet.style.display = 'none';
        degInfo.style.display = 'none';
        return;
      }

      hinfo.style.display = 'none';

      if (newProps.mode === 'soul') {
        degInfo.style.display = 'block';
        degInfo.textContent = Math.floor(realLon / 360 * 84).toFixed(0);
        strongPlanet.style.display = 'none';
        strongHPlanet.style.display = 'none';
        return;
      }

      strongPlanet.style.display = isStrongPlanet ? 'block' : 'none';
      strongHPlanet.style.display = isStrongHPlanet ? 'block' : 'none';

      if (isStrongPlanet) {
        strongPlanet.setAttribute('cx', (isStrongHPlanet ? -0.125 * iconSize : 0).toString());
      }

      if (isStrongHPlanet) {
        strongHPlanet.setAttribute('cx', (isStrongPlanet ? 0.125 * iconSize : 0).toString());
      }

      degInfo.style.display = 'block';
      degInfo.textContent = Math.ceil(realLon % 30).toString();
    });

    if (this.props.mode === 'horar') {
      const night = isEastern(newProps.objects[ObjectType.Sun].lon, newProps.houses[0]);

      const A: number = newProps.houses[0];
      const B: number = newProps.objects[ObjectType.Moon].lon;
      const C: number = newProps.objects[ObjectType.Sun].lon;
      let formulaLon = A + (!night ? B - C : C - B);

      while (formulaLon > 360) {
        formulaLon -= 360;
      }

      while (formulaLon < 0) {
        formulaLon += 360;
      }

      getArrangeObjects(
        [{
          lon: formulaLon,
          speed: 0
        }],
        newProps.houses,
        +(iconSize / 4).toFixed(2),
        newProps.horarSigns,
        orderSize,
        [],
        true
      ).forEach(object => {
        const realLon = formulaLon;
        let color: string;

        try {
          color = newProps.hasExt ? (newProps.isExt ? '#7986CB' : (newProps.isOuter ? '#BA68C8' : '#EF5350')) : `rgba(var(--circle-zodiacs-${signs[getSign(realLon)].element}-rgb-${personalization.circle.colorThemes.zodiacs.active}), 1)`;
        } catch (e) {
          color = 'var(--circle-zodiacs-icons-land)';
          show({
            type: 'warning',
            key: 'LON_DATA_WARNING',
            text: this.props.t("chronos.app.components.circle.incorrectData")
          });
        }

        const opacity = !newProps.highlights || newProps.highlights.includes(object.id) ? 1 : 0.2;

        let external = 0;
        let internal = 0;

        if (newProps.hasExt) {
          if (newProps.isExt) {
            external = newProps.radius * computedRadius.external;
            internal = newProps.radius * computedRadius.zodiacsExternalExt;
          } else {
            external = newProps.radius * computedRadius.zodiacsInternalExt;
            internal = newProps.radius * computedRadius.internalExt;
          }
        } else {
          external = newProps.radius * computedRadius.zodiacsInternal;
          internal = personalization.circle.radius.internalHorar;
        }

        let fortuneGap = 0;

        maxOrder.sort((a, b) => a.order - b.order).forEach(o => {
          const _p = newProps.point(objectsRadius, object.alon);
          if (
            distance({
              x: _p.x,
              y: _p.y
            }, {
              x: o.x,
              y: o.y
            }) < iconSize ||
            Math.abs(Math.abs(o.lon) - Math.abs(object.lon)) < iconSize) {

            fortuneGap = (o.order + 1) * (iconSize + 12);
          }
        })

        const gap = (external - internal) / (orderSize + 1) * (1 + object.order);
        objectsRadius = external - gap - fortuneGap;
        pointRadius = newProps.radius * personalization.circle.radius.internalHorar;

        const p = newProps.point(objectsRadius, object.alon);
        const pointPos = newProps.point(pointRadius, object.lon);

        const back = this.arabic[0].back.current as SVGCircleElement;

        const _obj = this.arabic[0].obj.current as SVGGElement;
        _obj.style.display = 'block';
        const _objO = _obj.lastChild as SVGSVGElement;

        _objO.onmouseenter = (ev: MouseEvent) => {
          newProps.onHover(highlightId, object.id);
          const lines = [`${this.props.t("chronos.app.instruments.widgets.soul.formulaStrength.wheelOfFortune")}: ${_degToString(realLon % 30)}`];
          showMapInfoPopup(ev.target as Element, lines, ev);
        };

        _objO.onmouseleave = () => {
          newProps.onHover(highlightId, -1);
          hideMapInfoPopup();
        };

        _obj.setAttribute('opacity', opacity.toString());

        const curve = _obj.childNodes[0] as SVGPathElement;
        const point = _obj.childNodes[1] as SVGCircleElement;
        const pointExt = _obj.childNodes[2] as SVGCircleElement;
        const obj = _obj.childNodes[3] as SVGSVGElement;

        obj.style.color = getObjColor(color, personalization.circle.objects.style, newProps.hasExt);

        obj.setAttribute('x', (p.x - 2).toString());
        obj.setAttribute('y', p.y.toString());



        point.setAttribute('cx', pointPos.x.toString());
        point.setAttribute('cy', pointPos.y.toString());

        point.style.stroke = getPointColor(color, personalization.circle.objects.style, newProps.hasExt);

        if (newProps.hasExt && newProps.isExt) {
          const pointPosExt = newProps.point(pointRadiusExt, object.lon);

          pointExt.style.display = 'block';
          pointExt.setAttribute('cx', pointPosExt.x.toString());
          pointExt.setAttribute('cy', pointPosExt.y.toString());
          pointExt.style.stroke = color;
        } else {
          pointExt.style.display = 'none';
        }
        curve.style.display = 'none';
        const hinfo = obj.childNodes[2] as SVGTextElement;
        const info = obj.childNodes[3] as SVGTextElement;
        const strongPlanet = obj.childNodes[4] as SVGCircleElement;
        const strongHPlanet = obj.childNodes[5] as SVGCircleElement;
        const degInfo = obj.childNodes[6] as SVGTextElement;

        let value = realLon % 30;

        value = Math.abs(value);

        const degs = hinfo.childNodes[0] as SVGTextElement;
        const mins = hinfo.childNodes[2] as SVGTextElement;

        const degrees = Math.floor(value);

        degs.textContent = fillNumber(degrees, 2);

        value -= degrees;

        mins.textContent = fillNumber(Math.floor(value * 60), 2);

        hinfo.style.display = 'block';
        strongPlanet.style.display = 'none';
        strongHPlanet.style.display = 'none';
        degInfo.style.display = 'none';
        return;
      });
    }
  };

  render(): JSX.Element {
    const { props } = this;

    const iconSize = props.radius * props.personalization.circle.objects.iconSize;
    const pointSize = props.radius * props.personalization.circle.objects.pointSize;
    const strongSize = props.radius * props.personalization.circle.objects.strongSize;

    const backs: any[] = [];
    const objects: any[] = [];

    for (let id = ObjectType.Sun; id <= 15; id++) {
      backs.push(
        <circle
          key={`object_back_${id}`}
          ref={this._objects[id].back}
          r={iconSize * 0.6}
          fill={props.mode === 'horar' ? 'transparent' : 'var(--workspace-background)'}
        />
      );

      const Icon = (props.mode === 'soul' && id === ObjectType.SouthNode) ? FortuneIcon : objectsIcons[id];

      const objName = {
        [`data${props.isExt ? `-ext-` : `-`}object-name`]: astroObjects[id].en
      };

      objects.push(
        <Group
          key={`${props.mode}_${id}`}
          ref={this._objects[id].obj}
          {...objName}
          onClick={() => props.onHelp('objects', id)}
        >
          <path
            fill="none"
            stroke="currentColor"
            strokeOpacity={1}
          />

          <circle
            r={pointSize}
            stroke="currentColor"
            fill="var(--workspace-background)"
          />

          <circle
            r={pointSize}
            stroke="currentColor"
            fill="var(--workspace-background)"
          />

          <svg overflow="visible">
            {/* Hover */}
            <circle
              r={iconSize * 0.6}
              fill="transparent"
            />

            {/* Icon */}
            <Icon
              x={-iconSize / 2}
              y={-iconSize / 2}
              width={iconSize}
              height={iconSize}
              fill="currentColor"
              stroke="currentColor"
              strokeWidth="0"
            />

            <g>
              <text
                x={-iconSize * 0.3}
                y={iconSize * 1}
                fontSize={iconSize * 0.5}
                fontWeight={700}
                fontFamily={'Apercu Pro'}
                fill="currentColor"
                textAnchor="end"
              />

              <text
                x={-iconSize * 0.1}
                y={iconSize * 1.35}
                fontSize={iconSize * 1}
                fontWeight={500}
                fontFamily={'Apercu Pro'}
                fill="currentColor"
                textAnchor="middle"
              >°</text>

              <text
                x={iconSize * 0.35}
                y={iconSize * 1}
                fontSize={iconSize * 0.5}
                fontWeight={700}
                fontFamily={'Apercu Pro'}
                fill="currentColor"
                textAnchor="middle"
              />

              <text
                x={iconSize * 0.8}
                y={iconSize * 1.175}
                fontSize={iconSize * 0.75}
                fontWeight={750}
                fontFamily={'Apercu Pro'}
                fill="currentColor"
                textAnchor="middle"
              >
                '
              </text>
            </g>

            <text
              x={iconSize * 0.45}
              y={iconSize * 0.55}
              fontSize={iconSize / 2.4}
              fill="currentColor"
              textAnchor="middle"
            />

            <circle
              cy={iconSize * 0.6}
              r={strongSize}
              fill="var(--colors-green)"
            />

            <circle
              cy={iconSize * 0.6}
              r={strongSize}
              fill="var(--colors-purple)"
            />

            <text
              x={iconSize * 0.5}
              y={-iconSize * 0.3}
              fontSize={iconSize * 0.4}
              fill="currentColor"
              fontWeight="bold"
              textAnchor="middle"
            />
          </svg>
        </Group>
      );
    }

    {
      this.props.mode === 'horar' && objects.push(
        <Group
          key={`${`${props.mode}_${this.arabic.length - 1}_fortune`}`}
          ref={this.arabic[0].obj}
          data-object-name="fortune"
        >
          <path
            fill="none"
            stroke="currentColor"
            strokeOpacity={1}
          />

          <circle
            r={pointSize}
            stroke="currentColor"
            fill="var(--workspace-background)"
          />

          <circle
            r={pointSize}
            stroke="currentColor"
            fill="var(--workspace-background)"
          />

          <svg overflow="visible">
            {/* Hover */}
            <circle
              r={iconSize * 0.6}
              fill="transparent"
            />

            {/* Icon */}
            <FortuneIcon
              x={-iconSize / 2}
              y={-iconSize / 2}
              width={iconSize}
              height={iconSize}
              fill="currentColor"
              stroke="currentColor"
              strokeWidth="0"
            />

            <g>
              <text
                x={-iconSize * 0.3}
                y={iconSize * 1}
                fontSize={iconSize * 0.5}
                fontWeight={700}
                fontFamily={'Apercu Pro'}
                fill="currentColor"
                textAnchor="end"
              />

              <text
                x={-iconSize * 0.1}
                y={iconSize * 1.35}
                fontSize={iconSize * 1}
                fontWeight={500}
                fontFamily={'Apercu Pro'}
                fill="currentColor"
                textAnchor="middle"
              >°</text>

              <text
                x={iconSize * 0.35}
                y={iconSize * 1}
                fontSize={iconSize * 0.5}
                fontWeight={700}
                fontFamily={'Apercu Pro'}
                fill="currentColor"
                textAnchor="middle"
              />

              <text
                x={iconSize * 0.8}
                y={iconSize * 1.175}
                fontSize={iconSize * 0.75}
                fontWeight={750}
                fontFamily={'Apercu Pro'}
                fill="currentColor"
                textAnchor="middle"
              >
                '
              </text>
            </g>

            <text
              x={iconSize * 0.45}
              y={iconSize * 0.55}
              fontSize={iconSize / 2.4}
              fill="currentColor"
              textAnchor="middle"
            />

            <circle
              cy={iconSize * 0.6}
              r={strongSize}
              fill="var(--colors-green)"
            />

            <circle
              cy={iconSize * 0.6}
              r={strongSize}
              fill="var(--colors-purple)"
            />

            <text
              x={iconSize * 0.5}
              y={-iconSize * 0.3}
              fontSize={iconSize * 0.4}
              fill="currentColor"
              fontWeight="bold"
              textAnchor="middle"
            />
          </svg>
        </Group>
      )
    }

    return (
      <>
        {/* Clear objects background (from cusps) */}
        {backs}

        {objects}
      </>
    );
  }
}