import { IPromptDataPlanet } from '../../types';

import image from './img/Jupiter.png';
import { JupiterIcon as icon } from 'src/assets/icons/astro/objects';

const planet: IPromptDataPlanet = {
  name: "Jupiter",
  title: "chronos.auth.planets.jupiter.title",
  image,
  icon,
  description: {
    short: "chronos.app.instruments.widgets.prompts.planets.jupiter.description.short",
    astronomy: "chronos.app.instruments.jupiter.astronomy1",
    facts: [
      {
        name: "chronos.app.instruments.widgets.prompts.planets.mars.description.facts.0.name",
        value: "chronos.app.instruments.widgets.prompts.planets.jupiter.description.facts.0.value"
      },
      {
        name: "chronos.app.instruments.widgets.prompts.planets.mars.description.facts.1.name",
        value: "chronos.app.instruments.widgets.prompts.planets.jupiter.description.facts.1.value"
      },
      {
        name: "chronos.app.instruments.widgets.prompts.planets.sun.description.facts.0.name",
        value: "chronos.app.instruments.widgets.prompts.planets.jupiter.description.facts.2.value"
      },
      {
        name: "chronos.app.instruments.widgets.prompts.planets.mars.description.facts.3.name",
        value: "79"
      },
      {
        name: "chronos.app.instruments.widgets.prompts.planets.mars.description.facts.4.name",
        value: "chronos.app.instruments.widgets.prompts.planets.jupiter.description.facts.4.value"
      },
      {
        name: "chronos.app.instruments.widgets.prompts.planets.mars.description.facts.5.name",
        value: "chronos.app.instruments.widgets.prompts.planets.jupiter.description.facts.5.value"
      }
    ],
    astrology: "chronos.app.instruments.jupiter.astrology1"
  },
  interpretation: {
    signs: {
      general: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.general",
      Aries: [
        {
          icon: "🤗",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Aries.0.text"
        },
        {
          icon: "😎",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Aries.1.text"
        },
        {
          icon: "✊",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Aries.2.text"
        },
        {
          icon: "🧗‍♀️",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Aries.3.text"
        },
        {
          icon: "😃",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Aries.4.text"
        }
      ],
      Taurus: [
        {
          icon: "🤔",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Taurus.0.text"
        },
        {
          icon: "💰",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Taurus.1.text"
        },
        {
          icon: "💸",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Taurus.2.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Taurus.3.text"
        },
        {
          icon: "🎼",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Taurus.4.text"
        },
        {
          icon: "🤩",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Taurus.5.text"
        }
      ],
      Gemini: [
        {
          icon: "🤔",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Gemini.0.text"
        },
        {
          icon: "😎",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Gemini.1.text"
        },
        {
          icon: "🙁",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Gemini.2.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Gemini.3.text"
        },
        {
          icon: "😪",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Gemini.4.text"
        }
      ],
      Cancer: [
        {
          icon: "🤔",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Cancer.0.text"
        },
        {
          icon: "👨‍🏫",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Cancer.1.text"
        },
        {
          icon: "💆‍♀️",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Cancer.2.text"
        },
        {
          icon: "👨‍👩‍👧‍👦",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Cancer.3.text"
        },
        {
          icon: "🤭",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Cancer.4.text"
        },
        {
          icon: "😎",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Cancer.5.text"
        }
      ],
      Leo: [
        {
          icon: "😎",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Leo.0.text"
        },
        {
          icon: "💪",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Leo.1.text"
        },
        {
          icon: "🤝",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Leo.2.text"
        },
        {
          icon: "🤦‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Leo.3.text"
        },
        {
          icon: "🤑",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Leo.4.text"
        }
      ],
      Virgo: [
        {
          icon: "🔎",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Virgo.0.text"
        },
        {
          icon: "🤔",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Virgo.1.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Virgo.2.text"
        },
        {
          icon: "🙋‍♀️",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Virgo.3.text"
        }
      ],
      Libra: [
        {
          icon: "👫",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Libra.0.text"
        },
        {
          icon: "😤",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Libra.1.text"
        },
        {
          icon: "🤷‍♀️",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Libra.2.text"
        },
        {
          icon: "😔",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Libra.3.text"
        },
        {
          icon: "📈",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Libra.4.text"
        }
      ],
      Scorpio: [
        {
          icon: "😎",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Scorpio.0.text"
        },
        {
          icon: "🤔",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Scorpio.1.text"
        },
        {
          icon: "🤐",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Scorpio.2.text"
        },
        {
          icon: "😤",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Scorpio.3.text"
        }
      ],
      Sagittarius: [
        {
          icon: "🤩",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Sagittarius.0.text"
        },
        {
          icon: "📈",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Sagittarius.1.text"
        },
        {
          icon: "🙅‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Sagittarius.2.text"
        },
        {
          icon: "😎",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Sagittarius.3.text"
        },
        {
          icon: "🤔",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Sagittarius.4.text"
        }
      ],
      Capricorn: [
        {
          icon: "😩",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Capricorn.0.text"
        },
        {
          icon: "🤭",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Capricorn.1.text"
        },
        {
          icon: "🤩",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Capricorn.2.text"
        },
        {
          icon: "😤",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Capricorn.3.text"
        }
      ],
      Aquarius: [
        {
          icon: "📉",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Aquarius.0.text"
        },
        {
          icon: "🤷‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Aquarius.1.text"
        },
        {
          icon: "✊",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Aquarius.2.text"
        },
        {
          icon: "💼",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Aquarius.3.text"
        },
        {
          icon: "😤",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Aquarius.4.text"
        }
      ],
      Pisces: [
        {
          icon: "🤗",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Pisces.0.text"
        },
        {
          icon: "🤝",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Pisces.1.text"
        },
        {
          icon: "✊",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Pisces.2.text"
        },
        {
          icon: "💸",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Pisces.3.text"
        },
        {
          icon: "🙇‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.jupiter.interpretation.signs.Pisces.4.text"
        }
      ]
    }
  }
};

export default planet;


