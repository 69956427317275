import React from 'react';
import styled from 'styled-components';

import Modal from 'src/ui/Modal';
import { Button } from 'src/libs';

import { ReactComponent as WelcomeIcon } from 'src/assets/illustrations/welcome.svg';
import { useTranslation } from 'src/i18n/useTranslation';

export default function Welcome(props: {
  onClose(): void;
}): any {
  const [showVideo, setShowVideo] = React.useState(false);
  const { t } = useTranslation();
  return (
    <Modal
      closable={showVideo}
      onClose={props.onClose}
    >
      <Main>
        {/* {showVideo ? (
          <video
            width="640"
            height="480"
            src={`${store.cloudUrl}/videos/welcome.mp4`}
            autoPlay
          />
        ) : ( */}
        <>
          <WelcomeIcon />
          <h1>{t("chronos.app.components.welcome")}</h1>
          <p>{t("chronos.app.components.welcome.gift")} <span>7 {t("chronos.app.day.plural")}</span>!{/* А если ты впервые пользуешься астропроцессором, то мы подготовили ознакомительное видео*/}</p>
          <Buttons>
            {/* <Button
                text="Ознакомиться"
                onClick={() => setShowVideo(true)}
                primary
                backgroundColor="#4093F4"
              />
              <Button
                text="Разберусь так"
                onClick={props.onClose}
              />*/}
            <Button
              onClick={props.onClose}
              color="#4093F4"
            >{t("chronos.app.components.welcome.understandably")}</Button>
          </Buttons>
        </>
        {/* )} */}
      </Main>
    </Modal>
  );
}

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  
  color: var(--text-primary);

  p {
    margin-bottom: 2em;
    margin-top: 1em;
    color: var(--text-third);

    span {
      color: var(--colors-red);
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  
  justify-content: center;
`;
