import React from 'react';
import styled, { css } from 'styled-components';

import SettingsGroup from 'src/pages/Settings/wrappers/SettingsGroupWrap';
import TariffView from 'src/pages/Settings/views/TariffView';
import { MessageBlock } from 'src/pages/Settings/sections/payments/PaymentDetails';
import Closer from 'src/pages/Settings/Closer';
import ButtonGroup from 'src/ui/ButtonGroup';
import { Button, CircleLoader } from 'src/libs';

import {
  CloseSmallIcon,
  ArrowSmallLeftIcon,
  ShevronDownIcon,
  OkIcon
} from 'src/assets/icons/system';

import api, { ISubscription } from 'src/api';
import { observer } from 'mobx-react';
import store from 'src/store';
import { history, routes } from 'src/router';
import { useTranslation } from 'src/i18n/useTranslation';

import { getDeviceDetect } from 'src/utils';

const { isTablet } = getDeviceDetect();


export default observer(function TariffsPopup({
  onClose
}: {
  onClose: () => void;
}) {

  const { t } = useTranslation();

  const { promo: promoStore } = store;
  const { payments: paymentStore } = store.settings;
  const {
    payTypes,
    activePayType,
    adjustedTariffs,
    userLastActiveTariffId
  } = paymentStore;

  // console.log('userLastActiveTariffId - ', userLastActiveTariffId)

  const [dataReady, setDataReady] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (adjustedTariffs.length) {
      setDataReady(true);
      return;
    }

    paymentStore.getPaymentInfo(true)
    .then(() => {
      setDataReady(true);
    })
  }, []);

  const toSettings = () => history.push(routes.AppSettings.path);

  return (
    <TariffsPopupContainer onClick={() => onClose()}>
      <Closer
        onClick={() => onClose()}
        style={isTablet
          ? {
            top: '1.5rem',
            right: '1.5rem',
          }
          : { top: '2.875rem' }
        }
      />
      <div className="wrapper" onClick={(evt) => evt.stopPropagation()}>
        <SettingsGroup>
          <MessageBlock>
            <h1>Откройте больше возможностей</h1>
            <p>Откройте все возможности астропроцессора, выбрав наиболее подходящий тариф</p>
            <ButtonGroup items={payTypes} active={activePayType} onSelect={data => {
                paymentStore.setDiscount(data === 12);
                paymentStore.setActiveType(data);
              }} />
          </MessageBlock>
        </SettingsGroup>

        <SettingsGroup>
          <div className="tariff-list">
            {
              dataReady 
              ? adjustedTariffs
                .filter(({ id }) => id !== 1)
                .map((tariff: ISubscription) => {
                  return <TariffView
                    data={tariff}
                    activePayType={paymentStore.activePayType!}
                    free={promoStore.freeTariffId === tariff.id}
                    freeText={ t("chronos.app.settings.payment.freeTariffPeriodInfo", { freeTariffPeriodInfo: promoStore.freeTariffPeriodInfo, tariffSum: tariff.sum })}
                    style={{ minHeight: '19.375rem' }}
                    border={true}
                    button={
                        <Button
                          onClick={() => toSettings()}
                          size="medium"
                          color={tariff.color}
                          bordered={false}
                        >
                        { tariff.id === userLastActiveTariffId ? t("chronos.app.settings.renewSubscription") : t("base.choose") }
                        </Button>
                    }
                    key={`${tariff.id}_s`}
                  />
                })
                
                : <StyledCircleLoader size='2rem'/>
            }
          </div>
        </SettingsGroup>

        <SettingsGroup>
          <MessageBlock>
            <Button onClick={() => toSettings()} color="transparent">{t("chronos.app.settings.tariffs.compareTariffs")} <ArrowSmallLeftIcon /></Button>
          </MessageBlock>
        </SettingsGroup>



      </div>

    </TariffsPopupContainer>
  );
});

const TariffsPopupContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  
  background-color: var(--layer-color);
  backdrop-filter: blur(16px);

  .wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 45%;
    margin: auto;
    z-index: 2;
  }

  .tariff-list {
    position: relative;
    display: flex;
    gap: 1.5rem;
  }
`;

const StyledCircleLoader = styled(CircleLoader)`
  margin: 0 auto;
`;
