import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'src/i18n/useTranslation';
import AmountIcon from './AmountIcon';

export default function ResultEvent(props: {
  green?: boolean;
  red?: boolean;
  amount: number;
  name: string;
  width: number;
}) {
  const { t } = useTranslation();
  return (
    <ResultEventC>
      <Bar green={props.green} red={props.red} width={props.width}>
        <span></span>
      </Bar>
      <Info>
        <AmountIcon amount={props.amount} />
        <aside>{t(props.name)}</aside>
      </Info>
    </ResultEventC>
  );
}

const ResultEventC = styled.div`
  display: block;

  &:hover nav {
    color: var(--text-primary);
  }

  &:hover aside {
    display: block;
  }
`;

const Bar = styled('div') <{ width: number; green?: boolean; red?: boolean }>`
  width: 100%;
  height: 5px;
  border: 1px solid var(--rectification-block2-border);
  background: var(--rectification-block2-background);
  border-radius: 12px;
  position: relative;

  & span {
    width: ${props => props.width}%;
    display: block;
    height: 5px;
    position: absolute;
    border-radius: 12px;
    background: var(--colors-blue); 
    border-radius: 12px;

    ${props => props.green && css`
      background: var(--colors-green);
    `}

    ${props => props.red && css`
      background: var(--colors-red);
    `}
  }
`;

const Info = styled.nav`
  display: block;
  position: relative;
  align-items: center;
  text-align: center;
  margin-top: 0.2rem;

  & aside {
    display: none;
    width: fit-content;
    position: absolute;
    top: 1.7rem;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 0.45rem 0.6rem;
    border-radius: 4px;
    background: var(--notifications-background);
    color: var(--text-primary);
    white-space: nowrap;
    font-size: 0.75rem;
    line-height: 0.75rem;
  }

  &:hover aside {
    display: block;
  }
`;
