import React, { AnchorHTMLAttributes } from 'react';
import styled from 'styled-components';
import { astroObjects, IObject } from 'src/libs';

import { IPromptDataSign } from '../types';

import planets from '../data/planets';
import { useTranslation } from 'src/i18n/useTranslation';


const getNextPlanetId = (planetId: number, planets: IObject[]): any => {
  let newPlanetId = planetId + 1;
  if (newPlanetId > planets.length - 1) { newPlanetId = 0 }
  if (newPlanetId >= 10) { return getNextPlanetId(newPlanetId, planets) }
  return newPlanetId;
};

const getPreviousPlanetId = (planetId: number, planets: IObject[]): any => {
  let newPlanetId = planetId - 1;
  if (newPlanetId <= 0) { newPlanetId = planets.length - 1 }
  if (newPlanetId >= 10) { return getPreviousPlanetId(newPlanetId, planets) }
  return newPlanetId;
};


const PlanetsSlider: React.FC<{
  objects?: IObject[];
  currentPlanetId?: number;
  currentObject?: IPromptDataSign;
  onChanged?(key: string, value: any): void;
  type?: string;
}> = ({
  objects,
  currentPlanetId,
  currentObject,
  onChanged = () => {},
  type = 'planets'
}) => {
  const { t } = useTranslation();
  const changePlanet = (planetId: number) => {
    onChanged('prompt', {
      category: 'objects',
      id: planetId
    });
  };


  if (type === 'signs') {
    return <Container background={currentObject!.image}></Container>;

  } if (type === 'planets') {
    const previousPlanetId = getPreviousPlanetId(currentPlanetId!, objects!);
    const nextPlanetId = getNextPlanetId(currentPlanetId!, objects!);

    const previousPlanet = planets[astroObjects[previousPlanetId].en];
    const currentPlanet = planets[astroObjects[currentPlanetId!].en];
    const nextPlanet = planets[astroObjects[nextPlanetId].en];
    
    return <Container background={currentPlanet.image}>
      <SmallPlanet onClick={() => changePlanet(previousPlanetId)} title={t(previousPlanet.name)}><img src={previousPlanet.image} /></SmallPlanet>
      <SmallPlanet onClick={() => changePlanet(nextPlanetId)} title={t(nextPlanet.name)}><img src={nextPlanet.image} /></SmallPlanet>
    </Container>;
  }
  return <></>;


};

const Container = styled.div<{background: string}>`
  display: flex;
  padding: 0rem 3rem;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  height: 28rem;
  width: 100%;
  background-image: url('${props => props.background}');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`;

const SmallPlanet = styled.div`
  display: flex;
  width: 2.25rem;
  height: 2.25rem;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  cursor: pointer;

  & > img {
    width: 90%;
  }
`;

export default PlanetsSlider;
