import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { ReactMemo } from 'src/hooks';
import dayjs from 'dayjs';

import HorarAnalysis from 'src/pages/Instruments/Widgets/Horar/Analysis';
import HorarEssentials from 'src/pages/Instruments/Widgets/Horar/Essentials';
import HorarLight from 'src/pages/Instruments/Widgets/Horar/Light';
import TabPanelWrapper from './TabPanelWrapper';

import WidgetComponent, { WidgetTypes, IWidgetData } from './Widget';
import AspectsTable from './AspectsTable';
import Coordinates from './Coordinates'
import ModeSelectorTabs from './AspectsTable/ModeSelectorTabs';
import OrbiseCorrector, { OrbiseCorrectorTitle } from './OrbiseCorrector';
import StrongObjects from './StrongObjects/index';
import HouseFormulas from './HouseFormulas/index';
import Analyze from './Analyze';
import DevPlan from './DevPlan';
import AutoPrediction from './AutoPrediction';
import Interpretation from './Interpretation';
import Rectification from './Rectification';
import Description from './Description';
import HorarEvents from './Horar/Events';
import DispositorsWidget from './Dispositors';
import DispositorsSettings from './Dispositors/DispositorsSettings';
import FormulaStrength from './Soul/FormulaStrength';
import EphemerisFormulas from './Soul/EphemerisFormulas';
import FormulaYears from './Soul/FormulaYears';
import SoulFormula from './Soul/SoulFormula';
import SoulInterpretation from './Soul/Interpretation';
import HorarSpeed from './Horar/Speed';
import TablePredictionWidget from './TablePrediction/index';

import Prompts from './Prompts';

import * as widgetIcons from 'src/assets/icons/widgets';
import { InfoIcon } from 'src/assets/icons/notifications';

import { getDeviceDetect } from 'src/utils';

const { isTablet } = getDeviceDetect();
import { useTranslation } from 'src/i18n/useTranslation';
import { isCompatibility, isPartner } from '../utils';

import { observer } from 'mobx-react';
import store from 'src/store';

export const widgets: {
  [key: string]: {
    Widget: any;
    icon: any;
    title: string;
    HeaderWidget?: any;
    single?: boolean;
    WidgetSettings?: any;
  };
} = {
  'aspects-table': {
    Widget: AspectsTable,
    icon: widgetIcons.AspectsTableIcon,
    title: "chronos.app.widgets.title.aspectsTable"
  },
  'coordinates-of-planets-and-houses': {
    Widget: Coordinates,
    icon: widgetIcons.CoordinatesIcon,
    title: "chronos.app.widgets.title.coordinates",
  },
  'orbise-corrector': {
    Widget: OrbiseCorrector,
    icon: widgetIcons.OrbiseCorrectorIcon,
    title: "chronos.app.widgets.title.orbiseCorrector",
    HeaderWidget: OrbiseCorrectorTitle
  },
  'strong-objects': {
    Widget: ({data}: {data: IWidgetData}) => <TabPanelWrapper data={data} Widget={StrongObjects} />,
    icon: widgetIcons.StrongObjectsIcon,
    title: "chronos.app.widgets.title.strongObjects"
  },
  'house-formulas': {
    Widget: ({data}: {data: IWidgetData}) => <TabPanelWrapper data={data} Widget={HouseFormulas} />,
    icon: widgetIcons.HouseFormulasIcon,
    title: "chronos.app.widgets.title.houseFormulas"
  },
  'analyze': {
    Widget: ({data, onChanged}: {data: IWidgetData, onChanged: (key: string, value: any) => void}) => <TabPanelWrapper data={data} Widget={Analyze} onChanged={onChanged} />,
    icon: widgetIcons.AnalyzeIcon,
    title: "chronos.app.widgets.title.analyze"
  },
  'devplan': {
    Widget: DevPlan,
    icon: widgetIcons.DevPlanIcon,
    title: "chronos.app.devPlan",
    single: true
  },
  'autoprediction': {
    Widget: AutoPrediction,
    icon: widgetIcons.AutoPredictionIcon,
    title: "chronos.app.widgets.title.autoPrediction",
    single: true
  },
  'interpretation': {
    Widget: Interpretation,
    icon: widgetIcons.InterpretationIcon,
    title: "chronos.app.prompts.interpretation",
    single: true
  },
  'rectification': {
    Widget: Rectification,
    icon: widgetIcons.RectificationIcon,
    title: "chronos.app.widgets.title.rectification",
    single: true
  },
  'dispositors': {
    Widget: ({data}: {data: IWidgetData}) => <TabPanelWrapper data={data} Widget={DispositorsWidget} />,
    WidgetSettings: DispositorsSettings,
    icon: widgetIcons.DispositorsIcon,
    title: "chronos.app.widgets.title.dispositors",
  },
  'description': {
    Widget: Description,
    icon: InfoIcon,
    title: "base.description",
    single: true
  },
  'prompt': {
    Widget: Prompts,
    icon: InfoIcon,
    title: "chronos.app.widgets.title.prompts",
    single: true
  },
  'horar-events': {
    Widget: HorarEvents,
    icon: widgetIcons.HorarEventsIcon,
    title: "chronos.app.widgets.title.horarEvents"
  },
  'horar-speed': {
    Widget: HorarSpeed,
    icon: widgetIcons.HorarSpeedIcon,
    title: "chronos.app.widgets.title.horarSpeed"
  },
  'horar-essentials': {
    Widget: HorarEssentials,
    icon: widgetIcons.HorarEssentialsIcon,
    title: "chronos.app.widgets.title.horarEssentials"
  },
  'horar-analysis': {
    Widget: HorarAnalysis,
    icon: widgetIcons.HorarAnalysisIcon,
    title: "chronos.app.widgets.title.horarAnalysis"
  },
  'horar-light': {
    Widget: HorarLight,
    icon: widgetIcons.HorarLightIcon,
    title: "chronos.app.widgets.title.horarLights"
  },
  'formula-strength': {
    Widget: FormulaStrength,
    icon: widgetIcons.FormulaStrengthIcon,
    title: "chronos.app.widgets.title.formulaStrength"
  },
  'ephemeris-formulas': {
    Widget: EphemerisFormulas,
    icon: widgetIcons.EphemerisFormulasIcon,
    title: "chronos.app.widgets.title.ephemerisFormulas"
  },
  'formula-years': {
    Widget: FormulaYears,
    icon: widgetIcons.FormulaYearsIcon,
    title: "chronos.app.widgets.title.formulaYears"
  },
  'interpretation-soul': {
    Widget: SoulInterpretation,
    icon: widgetIcons.InterpretationSoulIcon,
    title: "chronos.app.prompts.interpretation",
    single: true
  },
  'formula-soul': {
    Widget: SoulFormula,
    icon: widgetIcons.FormulaSoulIcon,
    title: "astro.formulaSoul"
  // },
  // 'table-prediction2': {
  //   Widget: ({data}: {data: IWidgetData }) => <TablePredictionWidget data={data} />,
  //   icon: widgetIcons.TablePredictionIcon,
  //   title: "chronos.app.widgets.title.tablePrediction"
  }
};
const widgetsModes = ['aspects-table', 'strong-objects', 'house-formulas', 'analyze', 'dispositors'];

export default function Widgets(props: {
  widgets: {id: WidgetTypes, isActive?: string | boolean}[];
  data: IWidgetData;
  onClose(id: WidgetTypes): void;
  onChanged(key: string, value: any): void;
}) {
  const isAlone = props.widgets.length === 1;
  
let single: undefined | any;
if(props.widgets) {
  const hasTablePrediction = props.widgets.some(w => w.id === 'table-prediction2' as WidgetTypes);

  [single] = hasTablePrediction ? [undefined] : props.widgets
  .filter(w => widgets[w.id].single && w.isActive)
  .sort((w1, w2) => {
    if(!w1.isActive || !w2.isActive || w1.isActive === w2.isActive) {
      return 0;
    }
    return dayjs(w1.isActive.toString()).isBefore(w2.isActive.toString()) ? 1 : -1;
  });
}

  const { t } = useTranslation();
  const isShortWidth = props.data.widgetMode === 'transits' && Boolean(props.widgets.find(item => item.id === 'aspects-table'));

  const _widgets = React.useMemo(() =>
    (single ? [single] : props.widgets)
      .map((w, idx) => {
        const { Widget, icon, title, HeaderWidget, WidgetSettings } = widgets[w.id];
        let widgetProps: any = {
          data: props.data,
          onChanged: props.onChanged
        };
        if (w.id === 'prompt') { widgetProps = {
          ...widgetProps,
          onClose: () => props.onClose(w.id)
        }; }

        return (
          <WidgetComponent
            key={`widget-component-${w.id}-${idx}`}
            title={t(title)}
            icon={icon}
            onClose={() => props.onClose(w.id)}
            headerWidget={HeaderWidget &&
              <HeaderWidget
                onChanged={props.onChanged}
                data={props.data}
              />
            }
            widgetSettings={WidgetSettings}
            single={single?.id == w.id}
            prompt={w.id == 'prompt'}
            type={w.id}
          >
            {
              widgetsModes.includes(w.id)
              // && (props.data.modes.length > 1)
              && (props.data.modes.some(isCompatibility) || props.data.modes.some(isPartner) || w.id === 'aspects-table')
              && !(w.id !== 'aspects-table' && props.data.modes.some(isCompatibility))
                ? <ModeSelectorTabs data={props.data} modes={props.data.modes} current={props.data.widgetMode} onChange={props.onChanged} />
                : null
            }
            <Widget key={`widget-${w.id}`} {...widgetProps} widgetMode={props.data.widgetMode} />
          </WidgetComponent>
        );
      }),
  [props]
  );

  return (
    !!_widgets.length && <Container className="wiWi no-print" id={'widget-container'} hasWidgets={!!_widgets.length} isTablet single={!!single} isAlone={isAlone} isShortWidth={isShortWidth}>{_widgets}</Container>
  )
};

const Container = styled.div<{ isAlone: boolean; single: boolean; hasWidgets: boolean; isShortWidth?: boolean; isTablet?: boolean }>`
  display: flex;
  position: absolute;
  flex-direction: column;
  right: 0;

  // z-index: 3;
  overflow-y: auto;
  /* overflow-x: hidden !important; */
  padding: 0 7px;
  
  @media (max-height: 700px) {
    width: 58vh;
  }
  @media (min-width: 1400px) {
    width: 58vh;
  }
  width: 34%;
  
  ${props => props.hasWidgets && isTablet && css`
    @media (max-width: 1194px) {
      width: 39%;
    }

    @media (max-width: 1180px) {
      width: 39%;
    }

    @media (max-width: 1080px) {
      width: 43%;
    }

    @media (max-width: 1024px) {
      width: 45%;
      max-width: 45%;
    }

  `}

  ${props => (props.isShortWidth && props.isAlone) && css`
    /* overflow-y: hidden !important; */
    /* min-width: 27%; */
  `}

  ${props => !props.single && css`
    padding-bottom: 3rem;
  `}

  margin-top: 4.5rem;
  height: calc(100vh - 4.5rem);
  box-sizing: border-box;
  margin-bottom: auto;

  ${props => !props.single && css`
    margin-right: 0.5em;

    // z-index: 1;
    overflow-y: auto;

    & > :first-child {
      /* margin-top: 3.5em; */
    }


    & > :nth-child(n) {
      margin-bottom: 1em;
    }

    & > :last-child {
      margin-bottom: 4em;
    }
  `}


`;
