import { IPromptDataAspect } from '../../types';

import { SemisquareIcon as icon } from 'src/assets/icons/astro/aspects';

const aspect: IPromptDataAspect = {
  type: "Semisquare",
  deg: 45,
  name: "chronos.app.instruments.widgets.prompts.aspects.semisquare.name",
  icon,
  description: {
    list: [
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Semisquare.0"
      }
    ]
  }
};

export default aspect;