import React from 'react';
import styled, { css } from 'styled-components';
import dayjs from 'dayjs';

import { Loader, fillNumber, localTime, signs } from 'src/libs';

import { months } from 'src/utils';

import { IWidgetData } from '../Widget';

import horar, { HorarEventType, IHorarEvent } from './horar';
import { aspectsIcons, objectsIcons, signsIcons } from 'src/icons';

import { degToString } from 'src/api';
import { useTranslation } from 'src/i18n/useTranslation';
import { ArrowLeftIcon } from 'src/assets/icons/system';
import { ChevronRightIcon } from 'src/assets/icons/system/24';

export default function HorarEvents(props: {
  data: IWidgetData;
  onChanged(key: string, value: any): void;
}) {
  const [section, setSection] = React.useState(1);
  const [events, setEvents] = React.useState<IHorarEvent[] | null>(null);
  const { t } = useTranslation();
  React.useEffect(() => {
    const calculate = async () => {
      const { gmt } = props.data.form.horar!;

      const events = await horar(dayjs(localTime(props.data.form.horar!)));

      events.forEach(ev => ev.m = ev.m.add(gmt ?? 0, 'hours'));

      setEvents(events);
    };

    calculate();
  }, [props.data.form.horar]);

  if (!events) {
    return <Loader />;
  }

  const m = dayjs(props.data.form.horar?.dt);

  const groups: {
    month: string;
    group: IHorarEvent[];
  }[] = [];

  let month = '';
  let group: IHorarEvent[] = [];

  events.forEach(ev => {
    if (section === 1 ? ev.m.isBefore(m) : ev.m.isAfter(m)) { return }

    const mo = `${t(months[ev.m.month()])} ${ev.m.year()}`;

    if (mo !== month) {
      if (month) {
        groups.push({ month, group });
      }
      month = mo;
      group = [];
    }

    group.push(ev);
  });

  if (month && group.length) {
    groups.push({ month, group });
  }
 
  
  return (
    <Container>
      <header>
        <Section isActive={section === 1} onClick={() => setSection(1)}>{t("chronos.app.instruments.widgets.horar.events.followingEvents")}</Section>
        <Section isActive={section === -1} onClick={() => setSection(-1)}>{t("chronos.app.instruments.widgets.horar.events.previousEvents")}</Section>
      </header>

      {groups.map(g =>
        <Group key={g.month}>
          <label>{g.month}</label>
          {g.group.map(gi => {
            let w: any = null;

            const ObjIcon = objectsIcons[gi.obj];
            const SignIcon = signsIcons[gi.sign] as React.ElementType;

            switch (gi.type) {
              case HorarEventType.Border: {
                w = <>
                  <ObjWrapper>
                    <ObjIcon />
                    {(gi?.speed || 0) < 0 && <sub>{'r'}</sub>}
                  </ObjWrapper>

                  {gi.dir === -1 && <ArrowRightStyled />}

                  <SignWrapper>
                    <SignIcon style={{ color: `var(--circle-zodiacs-elements-${signs[gi.sign].element})` }}/>
                  </SignWrapper>

                  {gi.dir === 1 && <ArrowRightStyled />}

                  <span>{`(${degToString(section * (gi?.dist || 0), {isInternational: false})})`}</span>
                </>;
                break;
              }

              case HorarEventType.Direction: {
                w = <>
                  <ObjWrapper>
                    <ObjIcon />
                    {(gi?.speed || 0) < 0 && <sub>{'r'}</sub>}
                  </ObjWrapper>

                  <ChevronRightStyled title={t("chronos.app.instruments.widgets.horar.changesDirection", { name: gi.dir === -1 ? t("chronos.app.widgets.horar.retrograde") : t("chronos.app.widgets.horar.direct") })} />
                  <Direction>{gi.dir === -1 ? 'r' : 'd'}</Direction>

                  <SignWrapper>
                    <SignIcon style={{
                      color: `var(--circle-zodiacs-elements-${signs[gi.sign].element})`,
                      marginLeft: '0.5em'
                    }}/>
                  </SignWrapper>
                  <span>{degToString(gi?.dist || 0, {isInternational: false})}</span>
                </>;
                break;
              }

              case HorarEventType.Aspect: {
                const ObjIcon = objectsIcons[gi.obj1!.id];
                const OppIcon = objectsIcons[gi.obj2!.id];
                const AspIcon = aspectsIcons[gi.asp!] as React.ElementType;

                w = <>
                  <ObjWrapper>
                    <ObjIcon />
                    {gi.obj1!.speed < 0 && <sub>{'r'}</sub>}
                  </ObjWrapper>

                  {section === 1 && <ArrowRightStyled title={t("chronos.app.widgets.horar.formsAnAspect")} />}

                  <ObjWrapper>
                    <OppIcon />
                    {gi.obj2!.speed < 0 && <sub>{'r'}</sub>}
                  </ObjWrapper>

                  {section === -1 && <ArrowRightStyled title={t("chronos.app.widgets.horar.formsAnAspect")} />}

                  <SignWrapper>
                    <AspIcon style={{
                      color: `var(--circle-aspects-${gi.asp || 0})`,
                      margin: '0 0.5em'
                    }}/>
                  </SignWrapper>
                  <span>{degToString(gi.obj1!.lon % 30, {isInternational: false})}</span>
                  <span>{`(${degToString(section * (gi?.dist || 0), {isInternational: false})})`}</span>
                </>;
                break;
              }
            }

            // FIXME: format
            return (
              <GroupItem key={gi.m.toISOString()}>
                <span>{fillNumber(gi.m.date())}</span>
                <span>{gi.m.format('HH:mm:ss')}</span>
                {w}
              </GroupItem>
            );
          })}
        </Group>
      )}
    </Container>
  );
}

const Container = styled.div`
  & > header {
    display: flex;
  }
`;

const Section = styled.section<{ isActive: boolean }>`
  font-size: 0.9em;
  margin-right: 2em;
  padding-bottom: 0.75em;
  
  user-select: none;
  cursor: pointer;

  color: var(--text-secondary);
  border-bottom: 3px solid transparent;

  ${props => props.isActive && css`
    color: var(--text-primary);
    border-bottom: 3px solid var(--accent-blue);
  `}
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--element-neutral);

  & > label {
    color: var(--text-secondary);
    font-size: 0.85em;
    margin-top: 1em;
  }

  & > :last-child {
    border-bottom: none;
  }
`;

const GroupItem = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--element-neutral);
  padding-top: 1em;
  font-size: 0.8em;
  padding-bottom: 1em;

  & > :nth-child(2) {
    margin-left: 1em;
    margin-right: 3em;
  }

  & > :last-child {
    margin-left: 1em;
  }
`;

const ObjWrapper = styled.div`
  position: relative;

  sub {
    position: absolute;
    bottom: 0;
    right: -0.2em;
    vertical-align: sub;
    color: var(--text-secondary);
  }

  svg {
    width: 1.75em;
    color: var(--text-primary);
  }
`;

const SignWrapper = styled.div`
  /* margin-left: 0.5em; */
  svg {
    width: 1.75em;
  }
`;

const Direction = styled.div`
  font-size: 1.2em;
  margin-top: -0.25em;
`;

const ArrowStyle = css`
  width: 1.25em;
  margin: 0 0.5em;
  color: var(--text-secondary);
`;

const ChevronRightStyled = styled(ChevronRightIcon)<{ title: string; }>`
  width: 0.75em;
  color: var(--text-secondary);
  margin-left: 1em;
  margin-right: 0.25em;
`;

const ArrowLeftStyled = styled(ArrowLeftIcon)<{ title: string; }>`
  ${ArrowStyle}
`;

const ArrowRightStyled = styled(ArrowLeftIcon)<{ title?: string; }>`
  transform: rotate(180deg);
  ${ArrowStyle}
`;
