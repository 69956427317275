import { IPromptDataPlanet } from '../../types';

import image from './img/Uranus.png';
import { UranusIcon as icon } from 'src/assets/icons/astro/objects';

const planet: IPromptDataPlanet = {
  name: "Uranus",
  title: "chronos.auth.planets.uranus.title",
  image,
  icon,
  description: {
    short: "chronos.app.instruments.widgets.prompts.planets.uran.description.short",
    astronomy: "chronos.app.instruments.uranus.astronomy1",
    facts: [
      {
        name: "chronos.app.instruments.widgets.prompts.planets.mars.description.facts.0.name",
        value: "chronos.app.instruments.widgets.prompts.planets.uran.description.facts.0.value"
      },
      {
        name: "chronos.app.instruments.widgets.prompts.planets.mars.description.facts.1.name",
        value: "chronos.app.instruments.widgets.prompts.planets.uran.description.facts.1.value"
      },
      {
        name: "chronos.app.instruments.widgets.prompts.planets.sun.description.facts.0.name",
        value: "chronos.app.instruments.widgets.prompts.planets.uran.description.facts.2.value"
      },
      {
        name: "chronos.app.instruments.widgets.prompts.planets.mars.description.facts.3.name",
        value: "chronos.app.instruments.widgets.prompts.planets.mars.description.facts.3.value"
      },
      {
        name: "chronos.app.instruments.widgets.prompts.planets.mars.description.facts.4.name",
        value: "chronos.app.instruments.widgets.prompts.planets.uran.description.facts.4.value"
      },
      {
        name: "chronos.app.instruments.widgets.prompts.planets.mars.description.facts.5.name",
        value: "chronos.app.instruments.widgets.prompts.planets.uran.description.facts.5.value"
      }
    ],
    astrology: "chronos.app.instruments.uranus.astrology1",
    overview: "chronos.app.instruments.uranus.overview"
  },
  interpretation: {
    signs: {
      general: "",
      Aries: [
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        }
      ],
      Taurus: [
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        }
      ],
      Gemini: [
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        }
      ],
      Cancer: [
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        }
      ],
      Leo: [
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        }
      ],
      Virgo: [
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        }
      ],
      Libra: [
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        }
      ],
      Scorpio: [
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        }
      ],
      Sagittarius: [
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        }
      ],
      Capricorn: [
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        }
      ],
      Aquarius: [
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        }
      ],
      Pisces: [
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🕵️‍♂️",
          text: "chronos.app.instruments.widgets.prompts.planets.uran.interpretation.signs.Aries.0.text"
        }
      ]
    }
  }
};

export default planet;
