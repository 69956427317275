import React from 'react';
import styled from 'styled-components';

import Modal from 'src/ui/Modal';
import { Button } from 'src/libs';

import { WarningIcon } from 'src/assets/icons/system';
import { useTranslation } from 'src/i18n/useTranslation';

export default React.memo(function Confirmation(props: {
  title: string;
  children: any;
  submitText?: string;
  onCancel(): void;
  onSubmit(): void;
}) {
  const { t } = useTranslation();
  return (
    <Modal
      closable={false}
      useEscape={false}
    >
      <Wrap>
        <Warning />

        <Title>{props.title}</Title>

        {props.children}

        <Buttons>
          <Button
            onClick={props.onCancel}
            color="transparent"
          >{t("base.cancel")}</Button>
          <Button
            onClick={props.onSubmit}
            color="red"
          >{props.submitText || t("chronos.app.components.ok")}</Button>
        </Buttons>
      </Wrap>
    </Modal>
  );
});

const Wrap = styled.div`
`;

const Warning = styled(WarningIcon)`
  width: 2.313rem;
  height: 2.313rem;
  
  fill: var(--colors-red);
`;

const Title = styled.h2`
  margin-top: 0.5em;
  color: var(--text-primary);
`;

const Buttons = styled.div`
  width: 100%;
  margin-left: -0.75em;
  
  display: flex;
  align-items: center;

  & :first-child {
    margin-right: 0.5em;
  }
`;
