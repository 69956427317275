import React from 'react';
import styled from 'styled-components';
import { getObjectHouses, getSign, astroObjects, signs as astroSigns } from 'src/libs';

import { housesIcons, ScrollContainer, CloseIcon, HeaderContainer, PlanetHeader, SubHeader, TextIcon, Menu, TextBlock, FooterComponent } from './index';
import StickyHeader from './elements/StickyHeader';
import PlanetsSlider from './elements/PlanetsSlider';
import FactsSlider from './elements/FactsSlider';
import CardsSlider from './elements/CardsSlider';
import TW from 'src/ui/Wrappers/TooltipWrapper';

import planets from './data/planets';
import houses from './data/houses';
import signs from './data/signs';

import { IWidgetData } from './../Widget';
import { IPromptDataPlanet, IPromptDataPlanetName } from './types';
import { useTranslation } from 'src/i18n/useTranslation';
import { StaticDescriptionBlock } from './elements/StaticDescriptionBlock';

const menuItems = [
  { id: 'first', name: "base.description" },
  { id: 'ip', name: "chronos.app.prompts.interpretation" }
];

function Planets(props: {
  data: IWidgetData;
  onClose: () => void;
  onChanged: (key: string, value: any) => void;
  onScroll: (position: string | number) => void;
}, ref: any) {
  const { t, U } = useTranslation();
  const planetId = props.data.prompt!.id;
  const planet: IPromptDataPlanet = planets[astroObjects[planetId].en];
  const planetName: IPromptDataPlanetName = planet.name;
  const planetTitle = planet.title;

  const planetsFromMap = props.data.maps[0].objects;
  const planetFromMap = planetsFromMap[planetId];
  const housesFromMap = props.data.maps[0].houses;

  let houseId, houseName, house;
  if (housesFromMap.length) {
    houseId = getObjectHouses(planetFromMap.lon, housesFromMap, true)[0]!;
    houseName = `${houseId + 1} ${t("astro.house", U)}`;
    house = houses[houseId];
  }

  const signId = getSign(planetFromMap.lon);
  const signNameEn = astroSigns[signId].en;
  const sign = signs[signNameEn];
  const signName = sign.name;
  const signTitle = sign.title;

  const PlanetIcon = planet.icon;
  const SignIcon = sign.icon;

  return <>

    <StickyHeader menuItems={menuItems} title={planetTitle} onClose={props.onClose} scrollTo={props.onScroll} icon={<PlanetIcon />} scrollRef={ref} />

    <ScrollContainer ref={ref}>
      <a id="first-block"></a>
      <CloseIcon onClick={props.onClose}></CloseIcon>
      <HeaderContainer >
        <PlanetHeader>
          <h2>{<PlanetIcon />}{t(planetTitle)}</h2>
          <SubHeader>
            <div>{<SignIcon />} {t(signTitle)}</div>
            {housesFromMap.length && <div><TextIcon>{(housesIcons as any)[houseId ?? '']!}</TextIcon> {houseName}</div>}
          </SubHeader>
          <PlanetsSlider objects={planetsFromMap} currentPlanetId={planetId} onChanged={props.onChanged} />
        </PlanetHeader>
        <Menu id="menu-block">
          <ul>
            <li className="active" onClick={() => props.onScroll('#first-block')}>{t("base.description")}</li>
            <li onClick={() => props.onScroll('#ip-block')}>{t("chronos.app.prompts.interpretation")}</li>
            <li onClick={() => {}} style={{ 'cursor': 'default' }}><TW text={t("base.functionalityInDev")}>{t("base.configurations")}</TW></li>
          </ul>
        </Menu>
      </HeaderContainer>

      <TextBlock>
        <span dangerouslySetInnerHTML={{ __html: t(planet.description.short) }}></span>
      </TextBlock>

      <TextBlock>
        <h3>{t("chronos.app.prompts.planetDescription")}</h3>
        <span dangerouslySetInnerHTML={{ __html: t(planet.description.astronomy) }}></span>
      </TextBlock>

      <TextBlock full>
        <FactsSlider items={planet.description.facts} />
      </TextBlock>

      <TextBlock>
        <span dangerouslySetInnerHTML={{ __html: t(planet.description.astrology) }}></span>
      </TextBlock>

      <TextBlock full id="ip-block">
      {planet.description.overview ? (
      <StaticDescriptionBlock 
      icon={<SignIcon />}
      title={`${t(planet.title)} ${t("base.prepositions.in")} ${t(sign.nameIn)}`}
      description={t(planet.description.overview)}
    />
  ) : (
    <CardsSlider 
      items={planet.interpretation.signs[signName]} 
      icon={<SignIcon />} 
      title={`${t(planet.title)} ${t("base.prepositions.in")} ${t(sign.nameIn)}`} 
    />
  )}
</TextBlock>

      <TextBlock>
        <span dangerouslySetInnerHTML={{ __html: t(planet.interpretation.signs.general) }}></span>
      </TextBlock>

      {!!housesFromMap.length && <TextBlock full>
        <CardsSlider items={house?.planets[planetName]!} icon={<PlanetIcon />} title={`${t(planetTitle)} ${t("base.prepositions.in")} ${(housesIcons as any)[houseId ?? '']!} ${t("astro.house.pre")}`} />
      </TextBlock>}

      {!!housesFromMap.length && <TextBlock>
        <span dangerouslySetInnerHTML={{ __html:t( house?.description.short!) }}></span>
      </TextBlock>}

      <FooterComponent />

    </ScrollContainer>
  </>;
}


export default React.forwardRef(Planets);

