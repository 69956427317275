export type IRule = {
  id: number;
  name: string;
  checked?: boolean;
  asp?: string;
  dir?: string;
  nat?: string;
};

export type IEvent = {
  id: number;
  enabled: boolean;
  name: string;
  rules: IRule[];
  date?: string;
  weight: number;
  school?: number;
  isDeath?: boolean;
};

export const intervals: {
  [key: number]: string;
} = {
  600: "chronos.app.instruments.widgets.rectification.types.10min",
  1800: "chronos.app.instruments.widgets.rectification.types.30min"
};

export const steps: {
  [key: number]: string;
} = {
  60: "chronos.app.instruments.widgets.rectification.types.1min",
  // 1: '45 секунд',
  // 2: '30 секунд',
  // 3: '15 секунд',
  10: "chronos.app.instruments.widgets.rectification.types.10sec",
  // 5: '5 секунд',
  1: "chronos.app.instruments.widgets.rectification.types.1sec"
};
