import React from 'react';
import styled from 'styled-components';

export default function TooltipWrapper(props: {
  children: any;
  text: string;
}) {
  const tooltipEl = React.useRef<HTMLDivElement>(null);

  const onMouseMove = (e: any) => {
    tooltipEl.current!.style.left = `${e.pageX + 10}px`;
    tooltipEl.current!.style.top = `${e.pageY + 10}px`;
  };

  const onMouseOver = (e: any) => {
    tooltipEl.current!.style.display = 'block';
  };

  const onMouseLeave = (e: any) => {
    tooltipEl.current!.style.display = 'none';
  };

  let _text = props.text;

  _text = _text.replace(/<purple>/, '<span style=\'color: var(--accent-usual)\'>');
  _text = _text.replace(/<\/purple>/, '</span>');
  _text = _text.replace(/<orange>/, '<span style = \'color: var(--colors-orange)\'> ');
  _text = _text.replace(/<\/orange>/, '</span> ');

  return (
    <span onMouseMove={onMouseMove} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
      {props.children}
      <Tooltip ref={tooltipEl} dangerouslySetInnerHTML={{ __html: _text }}></Tooltip>
    </span>
  );
}

const Tooltip = styled.div`
    display: none;
    position: fixed;
    border-radius: 4px;
    background-color: var(--notifications-background);
    color: var(--text-primary);
    padding: 0.20rem 0.4rem 0.15rem 0.4rem;
    font-size: 0.75rem;
    text-align:center;
    line-height: 1.2rem;
    z-index: 100;
`;
