import { IPromptDataAspect } from '../../types';

import { MinusOrPlusIcon as icon } from 'src/assets/icons/astro/aspects/';

const aspect: IPromptDataAspect = {
  type: "MinusOrPlus",
  deg: 100,
  name: "chronos.app.instruments.widgets.prompts.aspects.minusOrPlus.name",
  icon,
  description: {
    list: [
      {
        text: "chronos.app.aspects.novile.description"
      }
    ]
  }
};

export default aspect;


