import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'src/i18n/useTranslation';
import { IGenInfo } from 'src/libs';

interface CounterProps {
  title: string;
  index: number;
  onChange(v: number): void;
  info: IGenInfo;
}

export default function Counter(props: CounterProps) {
  const price = props.info?.prices?.[props.index];
  const { t } = useTranslation();
  const onChange = (diff: number) => () => {
    const newIndex = props.index + diff;
    if (newIndex >= 0 && newIndex < props.info?.prices?.length) {
      props.onChange(newIndex);
    }
  };

  return (
    <Container>
      <label>{t(props.title)}</label>
      <div>
        <div onClick={onChange(-1)}>-</div>
        <span>{price?.[0]}</span>
        <div onClick={onChange(1)}>+</div>
      </div>
    </Container>
  );
}

const Container = styled.div` 
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  & > label {
    color: var(--text-secondary);
  }

  & > div {
    display: flex;
    font-size: 1.15em;
    color: var(--text-primary);

    & > span {
      text-align: center;
      font-size: 1.75em;
      min-width: 1.5em;
    }

    & > div {
      font-size: 1.5em;
      color: var(--text-third);
      user-select: none;
      cursor: pointer;
    }
  }
`;
