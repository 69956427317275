import React from 'react';
import styled from 'styled-components';

export const MenuItem = styled.div<{ name?: string; }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  height: 2.25rem;
  padding: 0 0.325rem;
  box-sizing: border-box;
  margin-bottom: 0.375rem;

  & > i {
    display: none;
    margin-left: 0.675rem;
  }

  & > button {
    display: none;
    width: auto !important;
    margin-left: auto;  
  }

  & > svg {
    color: var(--text-third);
    width: 1.5rem;
  }
`;