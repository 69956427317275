import { IPromptDataAspect } from '../../types';

import { SextileIcon as icon } from 'src/assets/icons/astro/aspects';

const aspect: IPromptDataAspect = {
  type: "Sextile",
  deg: 60,
  name: "chronos.app.instruments.widgets.prompts.aspects.sextile.name",
  icon,
  description: {
    list: [
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Sextile.0"
      },
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Sextile.1"
      },
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Sextile.2"
      },
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Sextile.3"
      },
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Sextile.4"
      },
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Sextile.5"
      },
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Sextile.6"
      }
    ]
  }
};

export default aspect;
