import React, { lazy, Suspense } from 'react';
import styled, { css } from 'styled-components';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ILinkItem } from 'src/types';

import { history } from 'src/router';
import store from 'src/store';

import Notify from 'src/ui/Wrappers/Notify';
import { Input, Button, Badge, IDropdownOption, Loader } from 'src/libs';

import { useTranslation } from 'react-i18next';

import { ChronosLogo } from 'src/assets/icons/system';
import { PavelIcon } from 'src/assets/icons/profiles';
import { MaaIcon } from 'src/assets/icons/profiles';
import { switcherHook } from 'src/hooks';
import { getDeviceDetect } from 'src/utils';
import { acccesforRuAndEn, acccesOnlyForRu } from 'src/helpers/permissions';
import Modal from 'src/ui/Modal';
import Export from './Export';
import api, { s3urls } from 'src/api';

const UserSection = lazy(
  () => import('src/pages/Settings/Sidebar/UserSection')
);
const { isTablet, isMobile } = getDeviceDetect();

const iconStyle = { width: '1rem' };
const icons: { [key: string]: any } = {
  0: <PavelIcon style={iconStyle} />,
  1: <MaaIcon style={iconStyle} />,
  default: <ChronosLogo style={iconStyle} />,
};

export default observer(function SideBar() {
  const {
    sidebar: { menu },
    profile: { activeProfileId },
  } = store.settings;
  
  const { loading: profileLoading } = store.settings.profile;
  
  const { section } = useParams<{ section: string }>();
  const showExport = switcherHook(false);
  const { t } = useTranslation();

  const onSelectProfile = (id: number) => {
    store.setActiveAstroProfileId(id, Boolean('fromUser'));
  };

  const profiles: IDropdownOption[] = React.useMemo(() => {
    return store.astro.profiles.map((p) => ({
      value: p.id,
      label: t(p.title),
      icon: icons[p.id in icons ? p.id : 'default'],
    }));
  }, [store.astro.profiles])
  


  return (
    <>
      <SideBarContainer>
        <Wrapper>
          <Suspense fallback={<div>loading...</div>}>
            <UserSection />
          </Suspense>

          <ProfileSelector className='profile-selector'>
            {profiles && (!profileLoading) &&
              <Input
                size="medium"
                options={profiles}
                value={activeProfileId}
                onChange={(val: number) => onSelectProfile(val)}
                detectMobile={() => isMobile}
              />
            }
            {(profileLoading) && <Loader size='2rem' />}
          </ProfileSelector>

          <Menu>
            {menu
              .filter(
                ({ path }) => !(path === 'consultations' && !acccesforRuAndEn())
              )
              .map(
                ({ id, title, Icon, badge, path }: ILinkItem, index: number) =>
                  id === 'div' ? (
                    <MenuDivider key={`md_${index}`} className="separator" />
                  ) : (
                    <MenuItem
                      name={`${id}`}
                      isActive={path === section}
                      key={`mi_${id}`}
                      onClick={() => {
                        if (id == 'export') {
                          showExport.on();
                          return;
                        }

                        if (path === 'support') {
                          const otherWindow = window.open();
                          otherWindow!.opener = null;
                          // @ts-ignore
                          otherWindow!.location = s3urls.support;
                          return;
                          // return store.setSupportNotifyShow(true)
                        }
                        store.settings.sidebar.setActiveMenu(path);
                        history.push(path);
                      }}
                    >
                      {Icon && <Icon />}
                      {<ins>{t(title)}</ins>}
                      {badge && (
                        <MenuBadge>
                          <Badge size="medium" value={`${badge}`} />
                        </MenuBadge>
                      )}
                    </MenuItem>
                  )
              )}
          </Menu>

          {store.supportNotifyShow && (
            <Notify
              type="warning"
              style={{ left: '-1px', bottom: '-1px' }}
              title={t('chronos.app.sidebar.writeToUs')}
              content={
                <p>
                  {t('chronos.app.sidebar.supportInfo')}{' '}
                  <a
                    target="_blank"
                    href="mailto:support@chronos.mg"
                    rel="noreferrer"
                  >
                    support@chronos.mg
                  </a>
                </p>
              }
              buttons={
                <Button size="medium" onClick={() => {}}>
                  <a
                    target="_blank"
                    href="mailto:support@chronos.mg"
                    rel="noreferrer"
                  >
                    {t('base.write')}
                  </a>
                </Button>
              }
              onClose={() => {
                store.setSupportNotifyShow(false);
              }}
            />
          )}
        </Wrapper>
      </SideBarContainer>

      {showExport.value && (
        <Modal onClose={showExport.off}>
          <Export onClose={showExport.off} />
        </Modal>
      )}
    </>
  );
});

const SideBarContainer = styled.aside`
  display: flex;
  position: fixed;
  flex-direction: column;
  box-sizing: border-box;
  width: ${isTablet ? '27vw' : '22vw'};
  height: 100vh;
  padding: 2.25rem 0;
  background-color: var(--workspace-background);
  color: var(--text-primary);

  z-index: 2;

  &:after {
    position: absolute;
    content: '';
    width: 1px;
    height: calc(100vh - 4.5rem);
    right: 0;
    background-color: var(--element-neutral);
  }
`;

const Wrapper = styled.div`
  position: relative;
  max-width: ${isTablet ? '100%' : '90%'};
  width: ${isTablet ? 'auto' : '15rem'};
  min-width: ${isTablet ? 'auto' : '15rem'};
  align-self: flex-end;
  margin-right: ${isTablet ? '0.5rem' : '1.5rem'};
`;

const ProfileSelector = styled.div`
  position: relative;
  margin-top: 1.5rem;

  & input[type='text'] {
    padding-top: 0.875rem;
  }
`;

const Menu = styled.ul`
  position: relative;
  width: 100%;
  margin: 0;
  margin-top: 0.437rem;
  padding: 0;
  list-style: none;
`;

const MenuItem = styled.li<{ isActive: boolean; name?: string }>`
  display: flex;
  align-items: center;
  padding: 1rem 0;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1rem;
  color: var(--text-secondary);
  cursor: pointer;
  user-select: none;

  & > svg {
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: middle;
    margin-right: 1.25rem;
    color: var(--icon-third);
  }

  & > ins,
  & > a {
    text-decoration: none;
    vertical-align: middle;
    color: inherit;
  }

  ${(props) =>
    props.isActive &&
    css`
      color: var(--text-primary);
      svg {
        color: var(--icon-secondary);
      }
    `}
`;

const MenuBadge = styled.div`
  position: relative;
  display: flex;
  margin-left: auto;
  margin-right: 1px;
`;

const MenuDivider = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--element-neutral);
`;
