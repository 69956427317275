import React from 'react';
import styled from 'styled-components';

export default function ({
  children,
  onClick
}: {
  children: React.ReactNode | Array<React.ReactNode>;
  onClick?(payload: any): void;
}) {

  return (
    <Container className="tapped-block" onClick={ payload => { onClick?.(payload) } }>
      {children}
    </Container>
  );
}

export const Container = styled.section`
  display: flex;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  flex: 1;
  background-color: var(--element-neo);
  border-radius: 5px;
  box-shadow: 0 0 0px 1px var(--element-neutral);
  cursor: pointer;

  transition: all ease .3s;

  &.active {
    border-width: 2px;
    border-color: var(--accent-blue);
  }
`;
