import styled, { css } from "styled-components";
import { Button } from "src/libs";
import { useTranslation } from "src/i18n/useTranslation";
import { WarningIcon } from "src/assets/icons/system";

interface ModalProps {
  countCalc: number;
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}
export default function ModalAlert({
  countCalc,
  isOpen,
  onConfirm,
  onCancel,
}: ModalProps) {
  if (!isOpen) return null;
  const t = useTranslation();

  return (
    <Container>
      <Modal>
        <Content>
          <IconHeader>
            <WarningIcon />
          </IconHeader>
          <header>Прерывание загрузки</header>
          <span>{`Процесс загрузки таблицы прогнозов будет прерван, загруженные данные будут удалены (${Math.round(
            countCalc
          )}% выполнено)`}</span>
          <Buttons>
            <nav>
              <Button onClick={onCancel}>Отмена</Button>
            </nav>
            <span onClick={onConfirm}>Все равно перейти</span>
          </Buttons>
        </Content>
      </Modal>
    </Container>
  );
}

const Container = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(29, 29, 29, 0.8);
  z-index: 20;
  display: flex;
`;

const Modal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 41.56rem;
  min-height: 16.625rem;
  background: var(--modal-bg);
  backdrop-filter: brightness(0.9) blur(3px);
  border-radius: 12px;
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.5rem;
  height: 4.5rem;
  background: var(--accent-blue);
  border-radius: 50%;
  color: var(--text-primary);
  box-shadow: 0px 6.4px 14.4px rgba(0, 0, 0, 0.26),
    0px 1.2px 3.6px rgba(0, 0, 0, 0.34);

  & > svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const IconHeader = styled(Icon)<{ color?: string }>`
  color: var(--colors-white);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  ${(props) =>
    props.color &&
    css`
      background: ${props.color};
    `}
`;

const Content = styled.div`
  text-align: center;
  width: 25rem;
  margin-top: 1.5rem;

  & > header {
    margin-bottom: 0.675rem;
    color: var(--text-primary);
    font-size: 1.5rem;
    line-height: 1.75rem;
  }

  & > span {
    display: block;
    margin-bottom: 1rem;
    color: var(--text-secondary);

    & i {
      color: var(--accent-blue);
      font-style: normal;
    }
  }

  & > nav {
    & > span {
      margin-right: 0.875rem;
      font-size: 0.875rem;
      color: var(--text-secondary);
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  & :first-child {
    margin-right: 1em;
  }
  align-items: center;
  & > span {
    display: block;
    cursor: pointer;
    align-items: center;
  }
`;
