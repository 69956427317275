import React from 'react';
import styled from 'styled-components';

export default function Label({
  background = 'black',
  color = 'white',
  value = 0,
  className
}: {
  background?: string;
  color?: string;
  value: number;
  className?: string;
}) {
  return (
    <Container
      background={background}
      color={color}
      className={className}
    >
      {value}
    </Container>
  );
}

const Container = styled.span<{ background: string; color: string }>`
    padding: 0.25rem 0.375rem 0.2rem 0.375rem;
    font-size: 0.75rem;
    background: ${props => props.background};
    color: ${props => props.color};
    border-radius: 4px;
`;
