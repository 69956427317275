import { IPromptDataAspect } from '../../types';

import { NovileIcon as icon } from 'src/assets/icons/astro/aspects';

const aspect: IPromptDataAspect = {
  type: 'Novile',
  deg: 40,
  name: "chronos.app.aspects.novile",
  icon,
  description: {
    list: [{ text: "chronos.app.aspects.novile.description" }]
  }
};

export default aspect;


