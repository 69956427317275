import React, {useEffect, useState} from 'react';
import ContextMenuButton from './ContextMenuButton';
import DropDown from './DropDown';
import {MenuItem, contextButtonPositionType} from "./types";

interface IContextMenuProps {
    groupsItems: Array<MenuItem[]>;
    forceClose?: number;
}

export default React.memo(function ContextMenuUi(props: IContextMenuProps) {
    const [contextButtonPosition, setContextButtonPosition] = useState<contextButtonPositionType>(null)

    useEffect(() => {
        props.forceClose && setContextButtonPosition(null);
    }, [props.forceClose])

    return (
        <>
            <ContextMenuButton onClick={position => setContextButtonPosition(position)}/>
            {
                contextButtonPosition && <DropDown contextButtonPosition={contextButtonPosition}
                                           groupsItems={props.groupsItems}
                                           onClose={() => setContextButtonPosition(null)}/>
            }
        </>
    );
})