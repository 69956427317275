import { IPromptDataAspect } from '../../types';

import { ConjunctionIcon as icon } from 'src/assets/icons/astro/aspects';

const aspect: IPromptDataAspect = {
  type: "Conjunction",
  deg: 0,
  name: "chronos.app.instruments.widgets.prompts.aspects.conjunction.name",
  icon,
  description: {
    list: [
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Conjunction.0"
      },
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Conjunction.1"
      },
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Conjunction.2"
      },
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Conjunction.3"
      },
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Conjunction.4"
      },
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Conjunction.5"
      }
    ]
  }
};

export default aspect;
