import React, {useEffect} from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import { Button, Checkbox } from 'src/libs';
import Modal from 'src/ui/Modal';

import { PersonIcon } from 'src/assets/icons/system';
import { useTranslation } from 'src/i18n/useTranslation';

interface FormProps {
  modes: string[];
  title?: string;
  onClose(): void;
  onSubmit(name: string, modes: string[]): void;
}

export function toggleList<T>(list: T[], value: T): T[] {
  if (list.includes(value)) {
    return list.filter(i => i !== value);
  }
  return [...list, value];

}

const checkboxes = [
  {
    mode: 'synastry',
    label: "astro.synastry"
  },
  {
    mode: 'prognostics',
    label: "astro.prognostics"
  },
  {
    mode: 'horar',
    label: "astro.horar"
  }
];

export default function SaveAsForm(props: FormProps): any {
  const [name, setName] = React.useState('');
  const [modes, setModes] = React.useState(props.modes);
  const { t } = useTranslation();
  useEffect(() => {
    if (props.title) setName(`${t("base.copy.nom")} ${t(props.title)}`)
  }, [])

  const onModes = (m: string) => setModes(toggleList(modes, m));

  const nameError = name.trim().length === 0;

  return (
    <Modal
      closable={true}
      scrollable={false}
      hasPadding={false}
      onClose={props.onClose}
    >
      <Container nameError={nameError}>
        <Avatar>
          <PersonIcon fill="var(--icon-third)" />
        </Avatar>

        <NameInput
          value={name}
          onChange={ev => setName(ev.target.value)}
          placeholder={t("base.inputName")}
        />

        <div className="hr" />

        <p>{t("chronos.app.addCardInList")}</p>

        <RadioSet>
          {checkboxes.map(c => props.modes.includes(c.mode) &&
            <div key={c.mode}>
              <Checkbox checked={modes.includes(c.mode)} onChange={() => onModes(c.mode)} />
              <span>{t(c.label)}</span>
            </div>
          )}
        </RadioSet>

        <Buttons>
          <Button
            onClick={props.onClose}
            color="transparent"
          >{t("base.cancel")}</Button>
          <Button
            onClick={() => {
              props.onSubmit(name, modes)
              props.onClose()
            }}
            disabled={nameError}
          >{t("base.save")}</Button>
        </Buttons>
      </Container>
    </Modal>
  );
}

const Container = styled.div<{ nameError: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
 
  color: var(--text-secondary);

  height: 100%;

  background-color: var(--bg-400);
  padding: 3em;
  border-radius: 8px;

  & > .hr {
    margin-top: 1em;
    width: 100%;
    border-top: 1px solid var(--${props => props.nameError ? 'colors-red' : 'element-neutral'});
  }
`;

const Avatar = styled.div`
  display: flex;
  padding: 1.5em;
  background-color: var(--bg-500);
  border-radius: 50%;

  svg {
    width: 1.5em;
  }

  margin-right: auto;
  margin-bottom: 2em;
`;

const NameInput = styled.input`
  appearance: none;
  outline: none;
  border: none;
  background: transparent;
  width: 100%;

  font-size: 1.25em;
  color: var(--text-primary);
`;

const Buttons = styled.div`
  display: flex;
  align-items: flex-start;

  & :first-child {
    margin-right: 0.5em;
  }
`;

const RadioSet = styled.div`
  display: flex;
  margin-top: 0.5em;
  margin-bottom: 2em;

  & > div {
    display: flex;
    align-items: center;
    margin-right: 2em;

    & > :last-child {
      margin-left: 1em;
    }
  }  
`;
