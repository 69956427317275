import { astroObjects, getLocalTime, getSign, ObjectType, signs } from 'src/libs';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { degToString } from 'src/api';
import astro from 'src/astro';
import { objectsIcons, signsIcons } from 'src/icons';
import { arabicCalc, isEastern, PlanetaryDays, Weekday } from 'src/pages/Instruments/Widgets/Horar/horar';
import { housesIcons } from 'src/pages/Instruments/Widgets/Prompts';
import store from 'src/store';

import { IWidgetData } from '../Widget';
import FixedStarsView from '../FixedStarsView';
import { useTranslation } from 'src/i18n/useTranslation';

export const arabicSigns = ['Asc', 'II', 'III', 'IC', 'V', 'VI', 'Dsc', 'VIII', 'IX', 'MC', 'XI', 'XII'];

const chaldeanRow: ObjectType[] = [
  ObjectType.Saturn,
  ObjectType.Jupiter,
  ObjectType.Mars,
  ObjectType.Sun,
  ObjectType.Venus,
  ObjectType.Mercury,
  ObjectType.Moon
];

export default function HorarAnalysis(props: {
  data: IWidgetData;
  onChanged(key: string, value: any): void;
}): JSX.Element {
  const personalization = store.settings.personalization.current;

  const [planetaryDay, setPlanetaryDay] = useState<ObjectType | null>(null);
  const planetaryDayRef = useRef<string | null>(null);

  const [planetaryHour, setPlanetaryHour] = useState<ObjectType | null>(null);
  const planetaryHourRef = useRef<string | null>(null);

  const [fixedStarsRows, setFixedStarsRows] = useState<JSX.Element[] | null>([]);

  const [activeArabicSign, setActiveArabicSign] = useState<number>(0);

  const { t } = useTranslation();

  useEffect(() => {
    const calc = async () => {
      // setFixedStarsRows(getStars());

      const { gmt, place: { lon, lat } } = props.data.form.horar!;
      const dt = getLocalTime(props.data.form.horar?.dt as string, gmt, lat, lon);

      let riseDT = await astro.riseObject(
        ObjectType.Sun,
        dt,
        lon || 0,
        lat || 0
      );

      let setDT = await astro.setObject(
        ObjectType.Sun,
        riseDT,
        lon || 0,
        lat || 0
      );

      let nextDayRise = await astro.riseObject(
        ObjectType.Sun,
        dt,
        lon || 0,
        lat || 0,
        true
      );

      const dtObj = {
        year: dayjs(dt).year(),
        month: dayjs(dt).month(),
        date: dayjs(dt).date()
      };

      const tempCurrentDay = dayjs(riseDT).set('year', dtObj.year).set('month', dtObj.month).set('date', dtObj.date).toDate();
      const tempNextDay = dayjs(nextDayRise).set('year', dtObj.year).set('month', dtObj.month).set('date', dtObj.date).toDate();

      if (+new Date(dt) <= +new Date(tempCurrentDay) && +new Date(dt) >= +new Date(tempNextDay)) {
        nextDayRise = await astro.riseObject(
          ObjectType.Sun,
          dayjs(dt).add(1, 'seconds').toISOString(),
          lon || 0,
          lat || 0,
          true
        );

        riseDT = await astro.riseObject(
          ObjectType.Sun,
          nextDayRise,
          lon || 0,
          lat || 0,
        );

        setDT = await astro.setObject(
          ObjectType.Sun,
          riseDT,
          lon || 0,
          lat || 0
        );
      } else if (+new Date(tempCurrentDay) === +new Date(tempNextDay)) {
        riseDT = await astro.riseObject(
          ObjectType.Sun,
          dayjs(dt).add(-1, 'days').toISOString(),
          lon || 0,
          lat || 0,
        );

        setDT = await astro.setObject(
          ObjectType.Sun,
          dayjs(dt).add(-1, 'days').toISOString(),
          lon || 0,
          lat || 0
        );
      }

      if (!riseDT || !setDT || !nextDayRise) {
        setPlanetaryHour(null);
        setPlanetaryDay(null);
        planetaryDayRef.current = null;
        planetaryHourRef.current = null;
        return;
      }
      setDT = dayjs(setDT).add(1, 'seconds').toISOString();

      console.log('Восход', riseDT);
      console.log('Закат', setDT);
      console.log('Восход следующего дня', nextDayRise);

      const isNight = +new Date(dt) > +new Date(setDT) && +new Date(dt) <= +new Date(nextDayRise) || +new Date(dt) < +new Date(riseDT);
      const dayLength = +new Date(setDT) - +new Date(riseDT) + 1000;
      const nightLength = +new Date(nextDayRise) - +new Date(setDT);
      const hourLength = (isNight ? nightLength : dayLength) / 12;

      const planetaryDay = PlanetaryDays[dayjs(+new Date(dt) < +new Date(riseDT) ? dayjs(riseDT).add(-1, 'days') : riseDT).day() as Weekday];

      const currentHour = +new Date(riseDT) !== +new Date(nextDayRise) ? Math.floor((+new Date(dt) - (isNight ? +new Date(setDT) : +new Date(riseDT))) / hourLength) : 0;

      const startChaldean = chaldeanRow.indexOf(planetaryDay);

      const planetaryHourRaw = (startChaldean + currentHour) % chaldeanRow.length;

      planetaryHourRef.current = objectsIcons[chaldeanRow[planetaryHourRaw]];
      setPlanetaryHour(chaldeanRow[planetaryHourRaw]);

      planetaryDayRef.current = objectsIcons[planetaryDay];
      setPlanetaryDay(planetaryDay);
    };

    calc();
  }, [props.data.form.horar]);

  const antises = useMemo(() => {
    const rows = [];
    const [{ houses, objects }] = props.data.maps;
    let colNumber = 0;

    for (let p = ObjectType.Sun; p <= ObjectType.Saturn; p++) {
      const ObjectIcon = objectsIcons[p] as React.ElementType;

      const antisDiff = 90 - objects[p].lon;
      const antisObjectLon = 90 + antisDiff > 360
        ? 90 + antisDiff - 360
        : 90 + antisDiff < 0
          ? 90 + antisDiff + 360
          : 90 + antisDiff;

      const accuracyAspectHouses: number[] = [];

      houses.map((house, id) => {
        Math.abs(antisObjectLon - house) <= 1 && accuracyAspectHouses.push(id);
      });

      const accuracyAspectObjects: number[] = [];

      for (let o = ObjectType.Sun; o <= ObjectType.Saturn; o++) {
        Math.abs(antisObjectLon - objects[o].lon) <= 1 && accuracyAspectObjects.push(o);
      }

      const sign = getSign(antisObjectLon);

      const SignIcon = signsIcons[sign] as React.ElementType;

      rows.push(
        <Cell key={`antis_${p}`} className={(colNumber % 2) === 0 ? 'first-col' : ''}>
          <ObjectIcon style={{ marginRight: '0.875rem' }} />
          {t(astroObjects[p].ru)}
          {accuracyAspectHouses.length !== 0 && <House>
            {accuracyAspectHouses.map(house => housesIcons[house])}
          </House>}
          {accuracyAspectObjects.map((object, i) => {
            const Icon = objectsIcons[object] as React.ElementType;
            return <Icon key={`object_icon_${i}`} style={{ fill: 'var(--text-secondary)', marginLeft: '0.875rem' }} />;
          })}
          <Sign>
            {degToString(antisObjectLon % 30, {isInternational: false})}
            <SignIcon style={{ fill: `rgb(var(--circle-zodiacs-${signs[sign].element}-rgb-${personalization.circle.colorThemes.zodiacs.active}))` }} />
          </Sign>
        </Cell>
      );
      
      colNumber++;
  

    }

    return rows;
  }, [props.data.maps]);

  
  const arabicDots = useMemo(() => {
    const rows = [];
    const [{ houses, objects }] = props.data.maps;

    const additionalHouse = houses[activeArabicSign];

    let colNumber = 0;

    for (const calc of arabicCalc) {
      let night = false;
      if (calc.night) night = isEastern(objects[ObjectType.Sun].lon, houses[0]);
      const { formula } = calc;

      const getValue = (variable: [string, number]): number => variable[0] === 'house' ? houses[(variable[1] + activeArabicSign) % 12] : objects[variable[1]].lon;

      const A: number = getValue(formula.a);
      const B: number = getValue(formula.b);
      const C: number = getValue(formula.c);

      let formulaLon = A + (!night ? B - C : C - B);

      while (formulaLon > 360) {
        formulaLon -= 360;
      }

      while (formulaLon < 0) {
        formulaLon += 360;
      }

      const sign = getSign(formulaLon);

      const SignIcon = signsIcons[sign] as React.ElementType;

      rows.push(
        <Cell key={`arabic_${calc.name}`} className={(colNumber % 2) === 0 ? 'first-col' : ''}>
          {t(calc.name)}
          <Sign>
            {degToString(formulaLon % 30, {isInternational: false})}
            <SignIcon style={{ fill: `rgb(var(--circle-zodiacs-${signs[sign].element}-rgb-${personalization.circle.colorThemes.zodiacs.active}))` }} />
          </Sign>
        </Cell>
      );

      colNumber++
    }

    return rows;
  }, [activeArabicSign, props.data.maps]);

  const PlanetaryDayIcon: React.ElementType = planetaryDayRef.current as React.ElementType;
  const PlanetaryHourIcon: React.ElementType = planetaryHourRef.current as React.ElementType;
  
  return (
    <Container>
      {planetaryDay !== null && planetaryHour !== null && <Planetary>
        <Group>
          <GroupHeader>
            {t("chronos.app.instruments.widgets.horar.analysis.planetDay")}
          </GroupHeader>
          <GroupValue>
            {planetaryDayRef.current && <PlanetaryDayIcon />}
            {t(astroObjects[planetaryDay].ru)}
          </GroupValue>
        </Group>
        <Group>
          <GroupHeader>
            {t("chronos.app.instruments.widgets.horar.analysis.planetHour")}
          </GroupHeader>
          <GroupValue>
            {planetaryHourRef.current && <PlanetaryHourIcon />}
            {t(astroObjects[planetaryHour].ru)}
          </GroupValue>
        </Group>
      </Planetary>}
      <Section>
        <SectionTitle>
          {t("chronos.app.instruments.widgets.horar.analysis.antis")}
        </SectionTitle>
        <SectionBody>
          {antises}
        </SectionBody>
      </Section>
      { //@ts-ignore //props.data.maps[0].fixedStars && props.data.maps[0].showFixedStars && 
        <HorarFixedStars 
          title={t("chronos.app.instruments.widgets.horar.analysis.stars")}
          houses={props.data.maps[0].houses}
          objects={props.data.maps[0].objects}
          fixedStars={props.data.maps[0].fixedStars || []}
          // @ts-ignore
          show={props.data.maps[0].showFixedStars}
          soulStrongs={props.data.maps[0].strongs || []}
          mode={props.data.maps[0].mode}
        />
      }
      <Section>
        <SectionTitle>
          {t("chronos.app.instruments.widgets.horar.analysis.arabicDots")}
        </SectionTitle>
        <SectionTabs>
          {arabicSigns.map((sign, i) => <Tab key={`arabic_tab_${i}`} active={activeArabicSign === i} onClick={() => setActiveArabicSign(i)}>{sign}</Tab>)}
        </SectionTabs>
        <SectionBody>
          {arabicDots}
        </SectionBody>
      </Section>
    </Container>
  );
}

const Container = styled.div`
  font-size: 0.8em;
  display: flex;
  max-width: 40rem;
  flex-direction: column;
  border-top: 1px solid var(--element-neutral);
`;

const Planetary = styled.div`
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid var(--element-neutral);
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 4.375rem;
`;

const GroupHeader = styled.span`
  margin-bottom: 0.875rem;
`;

const GroupValue = styled.span`
  display: flex;
  align-items: center;
  > svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.875rem;    
  }
`;

const Section = styled.div`
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--element-neutral);
  
  &:nth-last-child(1) {
    border-bottom: none;
  }
`;

const SectionTitle = styled.span``;

const SectionBody = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const House = styled.span`
  display: inline-block;
  font-size: 1.125rem;
  color: var(--text-third);
  flex-shrink: 0;
`;

const Sign = styled.div`
  display: flex;
  color: var(--text-secondary);
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  span {
    font-size: 0.875rem;
    line-height: 1rem;
    white-space: nowrap;
  }
  
  > svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const Cell = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  width: calc(50% - 1rem);
  box-sizing: border-box;
  padding: 1rem 0;
  
  &.first-col {
    padding-right: 1rem;
    margin-right: 1rem;
    border-right: 1px solid var(--element-neutral);
  }
  
  & > svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

const SectionTabs = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
`;

const Tab = styled.span<{active: boolean}>`
  position: relative;
  color: var(--text-primary);
  cursor: pointer;
  border-radius: 6px;
  padding: 0.5rem 0.25rem;
  width: 1.5rem;
  text-align: center;
  
  &:hover {
    background-color: var(--element-neutral-hover);
  }
  ${props => props.active && css`
    background: var(--element-neutral);
  `}
`;

const StarName = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;

  & .star-name {
    display: flex;
    flex-direction: column;
  }

  span {
    font-size: 0.875rem;
    line-height: 1rem;
  }

  .name2 {
    margin-top: 0.2rem;
    font-size: 0.800rem;
    color: var(--text-third);
  }

  & > svg {
    width: 0.8rem;
    height: 0.8rem;
    flex-shrink: 0;
  }
`;

const HorarFixedStars = styled(FixedStarsView)`
  padding-top: 1rem;
  padding-bottom: 0.5rem;
`;