import { astroObjects, ObjectType } from 'src/libs';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { avgSpeed } from 'src/components/Circle/Objects';
import { getAccidentalValue, getEssentialsValue } from 'src/pages/Instruments/Widgets/Horar/horar';
import { default as TooltipWrapper } from 'src/ui/Wrappers/TooltipWrapper';

import { IWidgetData } from '../Widget';

import { objectsIcons } from 'src/icons';
import { nowISOString } from 'src/api';
import { useTranslation } from 'src/i18n/useTranslation';

const PercentMultiplier = 100;
const FractionDigits = 4;

export default function HorarSpeed(props: {
  data: IWidgetData;
  onChanged(key: string, value: any): void;
}): JSX.Element {
  const [tableRows, setTableRows] = useState<JSX.Element[] | null>(null);
  const { t } = useTranslation();
  const getSpeedText = (speed: number): string => {
    const avgPercent = 100;

    if (speed > avgPercent) return t("chronos.app.widgets.speed.fast");
    if (speed === avgPercent) return t("chronos.app.widgets.speed.medium");
    if (speed < avgPercent && speed !== 0) return t("chronos.app.widgets.speed.slow");
    if (speed === 0) return t("chronos.app.widgets.speed.motionless");
    return t("chronos.app.widgets.speed.motionless");
  };

  useEffect(() => {
    const init = async () => {
      setTableRows(await getTableRows());
    };

    const getTableRows = async () => {
      const rows = [];
      for (let p = ObjectType.Sun; p <= ObjectType.Saturn; p++) {
        const { speed } = props.data.maps[0].objects[p];

        const ObjectIcon = objectsIcons[p];

        const ObjectSpeed = parseInt((speed / avgSpeed[p] * PercentMultiplier).toFixed(0), 10);
        const speedDifference = (Math.abs(speed) - avgSpeed[p] > 0 ? '+' : '') + (Math.abs(speed) - avgSpeed[p]).toFixed(FractionDigits);

        const accidentalValue = await getAccidentalValue(p, props.data.maps[0].objects, props.data.maps[0].houses, props.data.form.horar?.dt || nowISOString());
        const essentialValue = getEssentialsValue(p, props.data.maps[0].objects, props.data.maps[0].houses);

        rows.push(
          <TableRow key={`row_${p}`}>
            <Cell>
              <ObjectIcon/>
              {t(astroObjects[p].ru)}
            </Cell>
            <Cell>
              <span>
                {speedDifference}
              </span>
              {` ${ObjectSpeed}%`}
              <TooltipWrapper text={getSpeedText(Math.abs(ObjectSpeed))} >
                <Circle speed={ObjectSpeed}/>
              </TooltipWrapper>
            </Cell>
            <Cell>
              <ins>
                <ToolTip dangerouslySetInnerHTML={{ __html: t(accidentalValue.description) + t(essentialValue.description) }} />
                {accidentalValue.points + essentialValue.points}
              </ins>
            </Cell>
          </TableRow>
        );
      }
      return rows;
    };
    init();
  }, [props.data.form.horar?.dt, props.data.maps]);

  return (
    <Container>
      <TableHeader>
        <Cell>
          {t("astro.planets")}
        </Cell>
        <Cell style={{ justifyContent: 'center' }}>
          {t("chronos.auth.index.speed")}
        </Cell>
        <Cell>
          {t("chronos.app.widgets.power")}
        </Cell>
      </TableHeader>
      {tableRows}
    </Container>
  );
}

const Container = styled.div`
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--element-neutral);
`;

const getCircleColor = (speed: number): string => {
  const avgPercent = 100;

  if (speed > avgPercent) return '--accent-green';
  if (speed === avgPercent) return '--colors-yellow';
  if (speed < avgPercent && speed !== 0) return '--colors-red';
  if (speed === 0) return '--icon-third';
  return '--icon-third';
};

const Circle = styled.span<{ speed: number }>`
  display: block;
  margin-left: 1rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: var(${props => getCircleColor(Math.abs(props.speed))});
`;

const ToolTip = styled.span`
  display: none;
  position: absolute;
  border-radius: 4px;
  background-color: var(--bg-100);
  color: var(--text-primary);
  padding: 0.5rem;
  font-size: 0.75rem;
  line-height: 1.2rem;
  z-index: 100;
  right: 1rem;
  width: 10rem;
  top: 0;
  border: 1px solid var(--element-neutral);
  
  ul {
    margin: 0;
    margin-bottom: 1rem;
    padding-left: 1rem;
    text-align: left;
    :nth-last-child(1) {
      margin-bottom: 0;
    }
  }
`;

const TableHeader = styled.div`
  display: flex;
  color: var(--text-third);
  padding: 0.625rem 0;
  > :nth-child(1) {
    width: 50%;
    justify-content: flex-start;
  }
`;

const TableRow = styled.div`
  border-bottom: 1px solid var(--element-neutral);
  display: flex;
  padding: 0.625rem 0;
  
  > :nth-child(1) {
    width: 50%;
    justify-content: flex-start;
  }
  :nth-last-child(1) {
    border-bottom: none;
  }
  > :nth-last-child(1) {
    position: relative;    
  }
`;

const Cell = styled.span`
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  > svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.75rem;
  }
  span {
    &:nth-child(1) {
      margin-right: auto;
    }
  }

  &:nth-last-child(1) {
    & ins {
      text-decoration: none;
      
      &:hover {
        color: var(--text-primary);

        span {
          display: block;
        }
    }
    }
  }
`;
