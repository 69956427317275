import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import * as fflate from 'fflate';

const getJsonPath = async (zipPath: string) => {
  return new Promise(async (resolve) => {
    const compressed = await fetch(zipPath).then(res => res.arrayBuffer());
    fflate.gunzip(new Uint8Array(compressed), {}, (_, data) => {
      resolve(new TextDecoder().decode(data))
    });
  });
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    backend: {
      loadPath: `${process.env.REACT_APP_S3}/i18n/5/${process.env.REACT_APP_NODE_ENV === 'production' ? 'master' : 'dev'}/{{lng}}.zip`,
      request: async (_options: any, url: string, _payload: any, callback: any) => {
        const data = await getJsonPath(url);
        callback(null, {
          status: 200,
          data
        })
        return data;
      },
      crossDomain: true,
      allowMultiLoading: true,
    },
    supportedLngs: ['en', 'ru', 'es', 'pt'],
    fallbackLng: 'ru',
    load: 'currentOnly',
    ns: ['common'],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['localStorage'],
      lookupLocalStorage: 'i18nextLng',
    },
    react: {
      useSuspense: false,
    }
  });

export default i18n;

export const languagesList = ['ru', 'en', 'pt', 'es'];