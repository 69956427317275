import React from 'react';
import styled, { css } from 'styled-components';

import { ReactMemo } from 'src/hooks';

import CloseButton from 'src/ui/CloseButton';
import { IHighlight } from '..';
import { IPrompt } from './Prompts/types';
import { IFormData, IMapDescription, Icon, IMap, IStrongAspect, IPower, ObjectType, CircleMode } from 'src/libs';

import { cloneDeep, isEqual } from 'lodash';
import { useTranslation } from 'src/i18n/useTranslation';

import { SettingsIcon } from 'src/assets/icons/system';

import store from 'src/store';
import { IPrognosticsMapsState } from 'src/store/InstrumentsStore';
import { IHighlightsData } from 'src/components/Circle';
import { TWidgetCircleMode } from './AspectsTable/ModeSelectorTabs';

export type WidgetTypes = 'aspects-table' | 'orbise-corrector' | 'strong-objects' | 'house-formulas' | 'prompt';

export interface IStrongs {
  planets: IPower[];
  houses: {
    houses: number[];
    planets: number[];
    power: number[];
  };
  aspects: IStrongAspect[];
}
export interface IWidgetData {
  form: IFormData;
  maps: IMap[];
  natalMap: IMap;
  partnersMaps: {[key: string]: IMap} | null;
  modes: CircleMode[];
  widgetMode: TWidgetCircleMode;
  highlights: IHighlight[] | IHighlightsData;
  pinnedAspects: number[];
  description: IMapDescription | null;
  descriptionSoul: ObjectType | null;
  prompt: IPrompt | null;
  doubleMapNatal: IPrognosticsMapsState;
  strongs: IStrongs;
}

export default ReactMemo(function Widget(props: {
  children: React.ReactNode;
  title: string;
  icon: any;
  headerWidget?: any;
  single?: boolean;
  type: string;
  prompt?: boolean;
  widgetSettings?: any;
  onClose(): void;
}) {
  const [settings, setSettings] = React.useState<any>(null);
  const SettingsComponent = props.widgetSettings;
  const { t } = useTranslation();

  const onSubmit = async () => {

    const userProfile = store.astro.profiles.find(p => p.mutable)!; // TODO // по mutable=true находим Мой профиль
    const currentProfile = cloneDeep(store.activeAstroProfile!);

    // здесь сбрасываем профиль на Мой профиль
    if (currentProfile.id !== userProfile.id) {
      currentProfile.id = userProfile.id;
      currentProfile.title = userProfile.title;
      currentProfile.mutable = true;
    }

    const updatedProfileId = await store.astro.updateProfile({
      ...currentProfile!,
      widgets: {
        ...currentProfile?.widgets,
        [props.type]: settings
      }
    });

    store.settings.profile.activeProfileId = updatedProfileId;

    setSettings(null);
  };

  const isChanged = React.useMemo((): boolean => {
    return !isEqual(settings, store.activeAstroProfile?.widgets['dispositors'])
  }, [settings, store.activeAstroProfile]);

  if (props.prompt) { return props.children }

  return (
    <Container single={props.single || false} type={props.type || ''}>
      
      <header>
        { !settings 
          ? <span>
              <Icon icon={props.icon} />
              {props.title}
            </span> 
          
          : <span>
              <SettingsIcon />
              {`${props.title} - ${t("chronos.app.widgetSettings")}`}
            </span>
        }
        {props.headerWidget}
        <CloseButton className='close-button' onClick={props.onClose} />
      </header>

      { settings 
        ? <Content>
            <SettingsComponent
              id={props.type!}
              settings={settings}
              onChange={setSettings}
            />
            <Buttons>
              <StyledButton onClick={onSubmit} disabled={!isChanged}>{t("chronos.app.widget.confirmChanges")}</StyledButton>
              <StyledButton onClick={() => setSettings(null)}>{t("base.cancel")}</StyledButton>
            </Buttons>
          </Content> 
        
        : <Content>{props.children}</Content>
      }
      { props.widgetSettings && !settings && 
        <SettingsButton onClick={() => setSettings(cloneDeep(store.activeAstroProfile!.widgets[props.type]))}>
          <SettingsIcon />{t("chronos.app.widgetSettings")}
        </SettingsButton>}
    
    </Container>
  );
});

const Container = styled.div<{ single: boolean; type: string }>`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  background-color: var(--workspace-widget-background);
  border-radius: 0.25rem;
  box-shadow: var(--workspace-box-shadow);
  box-sizing: border-box;
  ${props => props.type !== `table-prediction` && css`
    padding: 0 1rem 1rem;
  `}
    ${props => props.type !== `table-prediction2` && css`
    padding: 0 1rem 1rem;
  `}
  color: var(--text-primary);

  width: 100%;

  & > header {
    display: flex;
    align-items: center;
    background: var(--workspace-widget-background);
    position: sticky;
    top: 0px;
    height: 3.5rem;

    ${props => props.single && css`
      padding-top: 3rem;
    `}

    span {
      display: flex;
      align-items: center;

      color: var(--text-primary);
      white-space: nowrap;

      & svg {
        width: 1em;
        margin-right: 0.5em;
        fill: var(--icon-secondary);
      }
    }

    @media print {
      display: none;
    }
  }

  ${props => props.single && css`
    min-height: 100%;
    overflow: scroll;
    width: 100%;
    padding: 0 3rem 3rem 3rem;
    box-sizing: border-box;
    margin-right: 0.5em;

    & > header {
      font-size: 1.5rem;
    }

    & > div {
      color: var(--text-secondary);
      line-height: 1.5;
    }

    @media print {
      overflow: visible;
    }
  `}

  ${props => props.type === `table-prediction` && css`
    z-index: 15 !important;
        background: var(--workspace-widget-background);
        background-color: var(--workspace-widget-background);
    & > header {
    display: none;
    }

position: fixed;
top: 50%;
left: 50%;
// transform: translate(-50%, -50%);
// margin-left: calc(-23rem - 12px);
margin-top: -21rem;
margin-left: calc(-35rem - 12px);
width: 24rem;
height: 42rem;
    `}

      ${props => props.type === `table-prediction2` && css`
    z-index: 15 !important;
//         background: var(--workspace-widget-background);
        background-color: var(--workspace-widget-background);
//     // & > header {
//     // display: none;
//     // }

// position: fixed;
// top: 50%;
// left: 50%;
// // transform: translate(-50%, -50%);
// // margin-left: calc(-23rem - 12px);
margin-top: -21rem;
margin-left: calc(-35rem - 12px);
// width: 24rem;
// height: 42rem;

padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.75rem;
  position: fixed;
  width: 70rem;
  height: 42rem;
  top: 50%;
  left: 50%;
  // transform: translate(-50%, -50%);
  // z-index: 1;
  background: var(--workspace-widget-background);
  transition: margin-left 0.2s ease;










    

  white-space: nowrap;
  th {
    span {
      display: inline-block
    }
    
    div {
      display: inline-block;
      cursor: pointer;
    }
  }
  
  svg {
    width: 2.5em;
    height: 1.5em;
    fill: var(--text-primary);
    }
    
    & > header {
      display: flex;
      align-items: center;
      position: sticky;
      top: 0px;
      height: 3.5rem;
      border-bottom: 1px solid var(--element-neutral);
      margin-bottom: 1em;
      
    span {
      display: flex;
      align-items: center;

      color: var(--text-primary);
      white-space: nowrap;
      filter: brightness(1) !important;

      & svg {
        width: 1.5em;
        margin-right: 0.5em;
      fill: var(--text-primary);
        filter: brightness(1) !important;
      }
    }
  }
    `}

  & > :last-child {
    user-select: text;
    /* height: 100%;
    overflow: auto;
    padding: 2em 0;
    display: flex;
    flex-direction: column; */
  }
`;

const Content = styled.div`
  position: relative;
  color: var(--text-secondary);
`;

const SettingsButton = styled.button`
  width: 12.7rem;
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  padding: 0.625rem;
  margin-bottom:0.25rem;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  outline: none;
  background: none;
  color: inherit;
  border: 1px solid var(--element);
  user-select: none !important;

  & > svg {
    display: block;
    width: 1.5rem;
    color: var(--text-primary);
    opacity: 0.37;
  }

  &:hover {
    background: var(--element-neutral);

    & svg {
      opacity: 1;
    }

    & i {
      color: var(--text-primary);
    }
  }

  &:last-of-type{
    margin-bottom:0rem;
  }
`;

const Buttons = styled.div`
padding-top: 1rem;
  display: flex;
  align-items: baseline;
  margin-bottom: 1rem;
`

const StyledButton = styled.button`
  margin-right: 0.5rem;
  display: grid;
  grid-template-columns: min-content 1fr;
  white-space: nowrap;
  align-items: center;
  padding: 0.625rem;
  margin-bottom:0.25rem;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  outline: none;
  background: none;
  color: inherit;
  border: 1px solid var(--element);
  user-select: none;

  & > svg {
    display: block;
    width: 1.5rem;
    color: var(--text-primary);
    opacity: 0.37;
  }

  &:hover {
    background: var(--accent-blue);

    & svg {
      opacity: 1;
    }

    & i {
      color: var(--text-primary);
    }
  }

  &:last-of-type{
    margin-bottom: 0rem;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: default;

    &:hover {
      background: none;
    }
  }
`;
