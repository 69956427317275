import React from 'react';
import { useDeviceDetect } from 'src/hooks';

const APP_URL_REG_EXP = /(https?\:.*)app(.*\.chronos\.mg.*)/;

export default function AppMobile() {
  const { isMobile, isTablet } = useDeviceDetect();
  
  if (isMobile && !isTablet && process.env.development !== '-dev') {
    if(APP_URL_REG_EXP.test(window.location.href)) {
      let mobileAppUrl = window.location.href.replace(APP_URL_REG_EXP, '$1m$2');
      
      if(mobileAppUrl !== window.location.href) {

        // для шары
        const url = new URL(mobileAppUrl);
        // если это ссылка на карту астропроцессора, то преобразуем ее на url карты мобильного апп
        if (url.searchParams.has('share') && url.searchParams.has('id') && mobileAppUrl.includes('instruments')) {
          mobileAppUrl = `${url.origin}/workspace/${url.searchParams.get('id')}?share=`;
        }

        window.location.href = mobileAppUrl;
      }
    }
  }

  if (isTablet) {
    const el = document.querySelector('html');
    el && (el.style.fontSize = '16px');
  }

  return null;
}