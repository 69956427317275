import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';

import api, { toDateTimeString } from 'src/api';
import astro from 'src/astro';
import { ReactMemo } from 'src/hooks';

import { IEvent } from './types';

import { ReactComponent as AvatarIcon } from 'src/assets/recti/avatar-orange.svg';

import Settings from './Settings';
import EventList from './EventList';
import ResultList from './ResultList';

import eventsList from './events';

import { nextOnboarding, showOnboarding } from 'src/lib/onboarding';
import Onboarding from './Onboarding';

import onboarding from './onboarding.cfg';

import { IWidgetData } from '../Widget';
import GenDialog from '../GenDialog';

import store from 'src/store';
import { IGenInfo, Loader, fromDateTime, show, toDateTime } from 'src/libs';
import { useTranslation } from 'src/i18n/useTranslation';
import { isTester } from 'src/helpers/autotests';

const worker = new Worker(new URL('./rectification.ts', import.meta.url));

function valueOfDate(m: string) {
  return dayjs(m).valueOf();
}

export interface IRuleResult {
  id: number;
  score: number;
}

export interface IEventResult {
  id: number;
  score: number;
  rules: IRuleResult[];
  percentAmountEvents?: number;
}

export interface ITimestamp {
  key: string;
  score: number;
  events: IEventResult[];
  scoreKey: string;
}

export interface IInterval {
  begin: ITimestamp;
  end: ITimestamp | null;
  timestamp: number;
}

interface IEventList {
  [key: number]: {
    name: string;
    school?: number;
  };
}

interface IRuleList {
  [key: number]: {
    name: string;
    asp: string;
    nat: string;
    dir: string;
  };
}

function dfltSchool() {
  return store.settings.profile.activeProfileId === 1 ? 0 : 1;
}

export default ReactMemo(function Rectification(props: {
  data: IWidgetData;
  onChanged(key: string, value: any): void;
}) {
  const form = {
    id: props.data.form.id,
    ...props.data.form.natal
  };

  const [info, setInfo] = React.useState<IGenInfo | null>(null);
  const [data, setData] = React.useState<{
    school?: number;
    educated?: boolean;
    interval?: number;
    step?: number;
    selected?: string;
    original?: any;
    events?: any[];
    builded?: boolean;
  } | null>(null);

  const [askOnboarding, setAskOnboarding] = React.useState(false);
  const [school, setSchool] = React.useState(dfltSchool());
  const [interval, setInterval] = React.useState(0);
  const [step, setStep] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  const [events, setEvents] = React.useState<IEvent[]>([]);
  const [allEvents, setAllEvents] = React.useState<IEvent[]>([]);
  const [intervals, setIntervals] = React.useState<IInterval[]>([]);
  const [builded, setBuilded] = React.useState(false);
  const [building, setBuilding] = React.useState(false);
  const [showSupportForm, setShowSupportForm] = React.useState(false);
  const [zeroEvents, setZeroEvents] = React.useState<number[]>([]);

  const progRef = React.useRef<HTMLElement>(null);

  const birth = data?.original ? fromDateTime(data.original.date, data.original.time) : form.dt;

  const timestamp = valueOfDate(birth);

  const { t } = useTranslation();

  const showLoader = !data || loading
  const email = store.settings.user.profile.email ?? ''

  React.useEffect(() => {
    worker.onmessage = ev => {
      const { data } = ev;

      if (
        !['PROGRESS', 'COMPLETE'].includes(data.type) ||
        data.type === 'COMPLETE' && !data.data
      ) {
        // type: 'RPC', method: 'ready'
        // console.log('INVALID WORKER MESSAGE', ev, data);
        return;
      }

      if (data.type === 'PROGRESS') {
        if (progRef.current) {
          progRef.current.style.width = `${data.data}%`;
        }
        return;
      }

      const { timestamps } = data.data;

      if (timestamps.length === 0) {
        setZeroEvents(data.data.zeroEvents);
        setBuilded(true);
        setBuilding(false);
        return;
      }

      const intervals: IInterval[] = [];

      let interval: IInterval = {
        begin: timestamps[0],
        end: null,
        timestamp: 0
      };

      timestamps
        .forEach((t: any) => {
          if (t.scoreKey === interval.begin.scoreKey) {
            interval.end = t;
          } else {
            intervals.push(interval);
            interval = {
              begin: t,
              end: null,
              timestamp: 0
            };
          }
        });

      intervals.push(interval);

      setIntervals(intervals
        .map(i => {
          i.timestamp = i.end ?
            new Date((valueOfDate(i.begin.key) + valueOfDate(i.end.key)) / 2).valueOf() :
            valueOfDate(i.begin.key);
          return i;
        })
        .sort((a, b) =>
          b.begin.score - a.begin.score ||
          b.timestamp - timestamp - (a.timestamp - timestamp) ||
          a.timestamp - timestamp
        )
      );

      setBuilded(true);
      setBuilding(false);
    };

    setAllEvents(Object
      .entries(eventsList.events)
      .map(([id, ev]) => ({
        id: +id,
        name: ev.name,
        enabled: true,
        weight: ev.weight ?? 5,
        school: ev.school ?? 0,
        synonims: ev.synonims,
        isDeath: ev.isDeath,
        rules: ev.rules.map(id => ({
          id,
          name: (eventsList.rules as IRuleList)[id].name,
          asp: (eventsList.rules as IRuleList)[id].asp,
          dir: (eventsList.rules as IRuleList)[id].dir,
          nat: (eventsList.rules as IRuleList)[id].nat
        }))
      }))
    );

    const onBoarding = (ev: Event) => {
      const { detail: msg } = ev as CustomEvent;
      if (msg.id === 'end') {
        onboarding.show = false;
        showOnboarding();
      }
    };

    window.addEventListener('onboarding', onBoarding);

    return () => {
      window.removeEventListener('onboarding', onBoarding);
      showOnboarding();
    };
  }, []);

  React.useEffect(() => {
    onBuild(false);
  }, []);

  React.useEffect(() => {
    setData(props.data.form.rectification!)
    // checkRectification()
  }, [props.data.form]);

  React.useEffect(() => {
    if (events.length >= 3) {
      build(events);
    }
  }, []) // TODO: не добавлять сюда events - иначе при каждом добавлении будет строиться

  React.useEffect(() => {
    if (events.length >= 3) {
      updateRectification(events, null, null, builded);
    }
  }, [builded])

  const updateRectification = (events: IEvent[], _interval: number | null = null, _step: number | null = null, _builded: boolean | null = null) => {
    const rectification = {
      school: data?.school,
      educated: data?.educated,
      selected: events.length ? data?.selected : undefined,
      original: events.length ? data?.original : undefined,
      builded: _builded ?? false,
      interval: _interval ?? interval,
      step: _step ?? step,
      events: events.map(ev => ({
        id: ev.id,
        enabled: ev.enabled,
        date: ev.date,
        weight: ev.weight,
        rules: ev.rules.map(r => ({
          id: r.id,
          checked: r.checked
        }))
      }))
    };

    api.updateRectification(form.id, rectification);

    props.onChanged('rectification', {
      done: true,
      data: rectification
    });
  };

  const build = (events: IEvent[]) => {
    setBuilding(true);
    setBuilded(false);
    setZeroEvents([]);
    setIntervals([]);

    if (!data?.selected) {
      updateRectification(events, null, null);
    }

    worker.postMessage({
      profile: {
        ...cloneDeep(astro.settings),
        housesSystem: props.data.form.housesSystem
      },
      date: birth,
      gmt: form.gmt,
      lat: form.place.lat,
      lon: form.place.lon,
      step,
      interval,
      school: data?.school ?? dfltSchool(),
      events: events.filter(e => e.enabled).map(e => ({
        id: e.id,
        date: e.date || '',
        weight: e.weight,
        rules: e.rules.filter(r => r.checked).map(r => r.id)
      }))
    });
  };

  const selectRect = async (sel: number, restore: boolean) => {
    const selected = toDateTimeString(new Date(intervals[sel].timestamp).toISOString());

    try {
      const result = await api.selectRectification(form.id, {
        restore,
        selected
      });

      setData(data => data && {
        ...data,
        selected: restore ? undefined : selected
      });

      window.dispatchEvent(new CustomEvent('chronosRectSelected'));

      return result
    } catch (error: any) {
      show({
        type: 'error',
        text: error?.message ?? 'Ошибка при выборе интервала',
        timeout: 3000
      })
    }
  };

  const onSetSchool = (id: number) => {
    updateRectification(events);
    setSchool(id);
  };

  const onSetEvents = (events: IEvent[]) => {
    updateRectification(events);
    setEvents(events);
  };

  const onSetInterval = (value: number) => {
    setInterval(value);
    updateRectification(events, value, null);
  };

  const onSetStep = (value: any) => {
    setStep(value);
    updateRectification(events, null, value);
  };

  const onAskOnboarding = (value: boolean) => {
    onboarding.show = value;

    setAskOnboarding(false);

    if (value) { // && !isMobile()
      requestAnimationFrame(() => {
        nextOnboarding('event-input-input');
      });
    }

    api.closeNotifications([
      {
        id: 'edu_rectification',
        value
      }
    ]);
  };

  const unsetBuilded = () => {
    setBuilded(false);
  }

  const onBuild = async (confirmed = true) => {
    const currentForm = await api.form(props.data.form.id)
    if (!currentForm) return

    const resp = await api.rectification(confirmed, currentForm);

    props.onChanged('rectification', resp);

    if (resp.done && resp.data) {
      const { data } = resp;

      setInfo(null);
      setData(data);

      setAskOnboarding(isTester(email) ? false : !data?.educated);
      setSchool(data?.school ?? dfltSchool());
      setInterval(data?.interval || 600);
      setStep(data?.step || 60);

      setEvents(data?.events?.map((ev: any) => ({
        id: +ev.id,
        enabled: ev.enabled,
        name: (eventsList.events as IEventList)[ev.id].name || '',
        date: ev.date,
        weight: ev.weight,
        rules: ev.rules.map((r: any) => ({
          id: r.id,
          name: (eventsList.rules as IRuleList)[r.id].name || '',
          asp: (eventsList.rules as IRuleList)[r.id].asp || '',
          dir: (eventsList.rules as IRuleList)[r.id].dir || '',
          nat: (eventsList.rules as IRuleList)[r.id].nat || '',
          checked: r.checked
        })),
        school: (eventsList.events as IEventList)[ev.id].school ?? 0
      })) || []);
    } else {
      // Force update
      setInfo({
        ...resp as IGenInfo,
        timestamp: Date.now()
      });
      setData(null);
    }

    setLoading(false)
  };

  const checkRectification = async () => {
    const resp = await api.rectification(false, props.data.form);
    if (!resp.done) { //|| store.settings.user.isLimitedAccess
      //@ts-ignore
      setInfo({
        ...resp,
        timestamp: Date.now()
      });
      console.log('rectification info -', resp)
      setData(null);
    }
  };


  if (building) {
    return (
      <ProgressWrap>
        <Progress><span ref={progRef} /></Progress>
        <div>{t("chronos.app.aspects.novile.treatment")}</div>
      </ProgressWrap>
    );
  }

  if (allEvents.length === 0) return <LoaderWrap><Loader /></LoaderWrap>;

  if (askOnboarding) { return <Onboarding onClick={onAskOnboarding} /> }

  if (info) {
    return (
      <GenDialog
        title={t("chronos.app.widgets.title.rectification")}
        info={info!}
        onBuild={onBuild}
      />
    );
  }

  if (showLoader) {
    return (
      <LoaderWrap>
        <Loader />
      </LoaderWrap>
    )
  }

  const _allEvents = allEvents.sort((a, b) => school === a.school ? -1 : 1);

  const dt = toDateTime(form.dt);

  return (
    <Container>
      <Header>
        <AvatarIcon style={{fill: '#F59300'}} />{dt.date}, {dt.time.substr(0, 5)}, {form.place.name}
      </Header>

      <Settings
        interval={interval}
        setInterval={onSetInterval}
        step={step}
        setStep={onSetStep}
        builded={builded}
        unsetBuilded={unsetBuilded}
        events={events}
        school={school}
        setSchool={onSetSchool}
      />

      {builded && intervals.length ?
        <ResultList
          allEvents={_allEvents}
          intervals={intervals}
          selectRect={selectRect}
          selected={data?.selected || ''}
        /> :
        <>
          {zeroEvents.length > 0 && <p style={{ color: '#EF5350', fontStyle: 'italic' }}>{t("chronos.app.instruments.index.specifyDate")}</p>}
          <EventList
            allEvents={_allEvents}
            events={events}
            setEvents={onSetEvents}
            build={build}
            onSupport={() => setShowSupportForm(true)}
            zeroEvents={zeroEvents}
            form={props.data.form}
          />
        </>
      }

      {/* {showSupportForm && <SupportForm
        isAuthorized={true}
        onClose={() => { setShowSupportForm(false); }}
      />} */}
    </Container>
  );
});

const LoaderWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 70vh;
`;

const Container = styled.div`
  & warning-test {
    position: absolute;
    bottom: 4rem;
    right: 1.5rem;
    width: calc(100% - 3rem);
    z-index: 2;

    & > span {
      margin: 0 1em;
    }

    & > button {
      padding: 1em;
    }

    & > button:last-child {
      margin-left: 1em;
    }

    & > article {
      margin: 1em 0;
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.875rem;
  font-size: 1.125rem;
  line-height: 1;
  color: var(--text-primary);

  & svg {
    width: 1.215em;
    height: 1.215em;
    margin-right: 0.5rem;
  }
`;

const ProgressWrap = styled.div`
  margin-top: 40vh;
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Progress = styled.div`
  width: 100%;
  height: 5px;
  border: 1px solid var(--rectification-block2-border);
  background: var(--rectification-block2-background);
  border-radius: 12px;
  position: relative;
  margin-bottom: 1em;

  & span {
    display: block;
    height: 5px;
    position: absolute;
    top: -1px;
    border-radius: 12px;
    background: var(--colors-blue);
  }
`;
