import React from 'react';
import { getSign, signs as astroSigns, astroObjects, IObject } from 'src/libs';

import { housesIcons, ScrollContainer, CloseIcon, HeaderContainer, PlanetHeader, SubHeader, Menu, TextBlock, TextIconWithoutMargin, FooterComponent, SignHeader } from './index';
import StickyHeader from './elements/StickyHeader';
import PlanetsSlider from './elements/PlanetsSlider';
import FactsSlider from './elements/FactsSlider';

import { GlobalIcon } from 'src/assets/icons/system';
import { HouseIcon } from 'src/assets/icons/system/24';

import { IWidgetData } from './../Widget';
import { IPromptDataSign, IPromptDataSignName, ICardPlanetItem } from './types';

import planets from './data/planets';
import houses from './data/houses';
import signs from './data/signs';
import CardsSlider from './elements/CardsSlider';
import { useTranslation } from 'src/i18n/useTranslation';


interface IObjectWithId extends IObject {
  id?: number;
}

function getObjectsBySign(objects: IObjectWithId[], signId: number) {
  return objects
    .filter((object: IObjectWithId, index: number) => {
      object.id = index;
      return index < 10;
    })
    .filter((object: IObjectWithId) => {
      const signId_ = getSign(object.lon);
      return signId === signId_;
    });
}


function getHousesBySign(housesLons: number[], signId: number) {
  const result = [];

  for (let i = 0; i < housesLons.length; i++) {
    const nextI = i < housesLons.length - 1 ? i + 1 : 0;
    const lon = housesLons[i];
    const nextLon = housesLons[nextI] > lon ? housesLons[nextI] : housesLons[nextI] + 360;
    for (let g = lon; g <= nextLon; g += 2) {
      if (getSign(g) === signId) {
        const id = housesLons.findIndex(house => house === lon);
        result.push({
          ...houses[id],
          id
        });
        break;
      }
    }
  }
  return result;
}

function Signs(props: {
  data: IWidgetData;
  onClose: () => void;
  onChanged: (key: string, value: any) => void;
  onScroll: (position: string | number) => void;
}, ref: any) {

  const signId = props.data.prompt!.id;
  const sign: IPromptDataSign = signs[astroSigns[signId].en];
  const signName: IPromptDataSignName = sign.name;
  const signTitle = sign.title;
  const { t } = useTranslation();
  const planetsFromMap = props.data.maps[0].objects;
  const planetsInSign = getObjectsBySign(planetsFromMap, signId);
  const planetsInSignString = planetsInSign
    .map((object: IObjectWithId) => t(astroObjects[object.id!].ru))
    .join(', ');

  const planetsForSlider: ICardPlanetItem[] = planetsInSign.map(planet_ => {
    const planet = planets[astroObjects[planet_.id!].en];
    return {
      img: planet.image,
      title: t(planet.title),
      icon: planet.icon,
      text: planet.description.short
    };
  });
  const SignIcon = sign.icon;

  const housesFromMap = props.data.maps[0].houses;
  let housesInSign;
  let housesInSignString;
  if (housesFromMap.length) {
    housesInSign = getHousesBySign(housesFromMap, signId);
    housesInSignString = housesInSign
      .map(house => `${house.id + 1} ${t("astro.house")}`)
      .join(', ');
  }


  const menuItems = [{ id: 'first', name: t("base.description") }];
  planetsForSlider.length > 0 && menuItems.push({ id: 'planets', name: t("astro.planets") });
  menuItems.push({ id: 'ip', name: t("chronos.app.prompts.interpretation") });

  return <>
    <StickyHeader menuItems={menuItems} title={t(signTitle)} onClose={props.onClose} scrollTo={props.onScroll} icon={<SignIcon />} scrollRef={ref} />

    <ScrollContainer ref={ref}>
      <a id="first-block"></a>
      <CloseIcon onClick={props.onClose}></CloseIcon>
      <HeaderContainer>
        <SignHeader>
          <h2><SignIcon />{t(signTitle)}</h2>
          <SubHeader>
            {planetsInSign.length > 0 && <div><GlobalIcon /> {planetsInSignString}</div>}
            {housesInSignString && <div><HouseIcon /> {housesInSignString}</div>}
          </SubHeader>
          <PlanetsSlider currentObject={sign} type="signs" />
        </SignHeader>
        <Menu id="menu-block">
          <ul>
            <li className="active" onClick={() => props.onScroll('#first-block')}>{t("base.description")}</li>
            {planetsForSlider.length > 0 && <li onClick={() => props.onScroll('#planets-block')}>{t("astro.planets")}</li>}
            <li onClick={() => props.onScroll('#ip-block')}>{t("chronos.app.prompts.interpretation")}</li>
          </ul>
        </Menu>
      </HeaderContainer>

      <TextBlock>
        <span dangerouslySetInnerHTML={{ __html: t(sign.description.short) }}></span>
      </TextBlock>

      <TextBlock full>
        <FactsSlider items={sign.description.facts} />
      </TextBlock>

      <TextBlock>
        <span dangerouslySetInnerHTML={{ __html: t(sign.description.full) }}></span>
      </TextBlock>

      {planetsForSlider.length > 0 && <TextBlock full id="planets-block">
        <CardsSlider type="planets" items={planetsForSlider} icon={<GlobalIcon/>} title={`${t("astro.planetsIn")} ${t(sign.nameIn)}`} />
      </TextBlock>}

      {housesInSign && housesInSign.map(house => {
        const houseIcon = <TextIconWithoutMargin>{housesIcons[house.id]}</TextIconWithoutMargin>;
        return <TextBlock full key={house.id} id="ip-block">
          <CardsSlider items={house.signs[sign.name]} icon={houseIcon} title={`${t(signTitle)} ${t("base.prepositions.in")} ${housesIcons[house.id]} ${t("astro.house.pre")}`} />
        </TextBlock>;
      })}

      <FooterComponent />

    </ScrollContainer>
  </>;
}

export default React.forwardRef(Signs);
