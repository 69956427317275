import React from 'react';
import styled, { css } from 'styled-components';

import { toDateTime } from 'src/libs';

import ResultEvent from './ResultEvent';
import AmountIcon from './AmountIcon';
import { IInterval } from '.';

import { IEvent } from './types';

import { PlusIcon as OpenIcon } from 'src/assets/icons/system';
import { ArrowLeftIcon, ShevronIcon } from 'src/assets/icons/system';
import { useTranslation } from 'src/i18n/useTranslation';


function isMobile() { return false };

export function intervalName(index: number) {
  const { t } = useTranslation();
  return index == 0 ?
    isMobile() ? <span>{t("chronos.app.instruments.resultitem.recommendedTimeShort")}</span> : <span>{t("chronos.app.instruments.resultitem.recommendedTimeFull")}</span>
    :
    <span>{t("chronos.app.instruments.resultitem.interval")} {index + 1}</span>;
}

export default function ResultItem(props: {
  index: number;
  interval: IInterval;
  openMore(): void;
  selectRect(i: number, restore: boolean): Promise<any>;
  isCurrent: boolean;
  allEvents: IEvent[];
}) {
  const { begin, end, timestamp } = props.interval;
  const { t } = useTranslation();
  const maxScore = begin.events.reduce((p, e) => Math.max(p, e.score), 0);

  const returnTime = isMobile() ? <ArrowLeftIcon /> : t("chronos.app.instruments.resultitem.returnTime");

  const beginTime = toDateTime(begin.key).time;
  const endTime = end ? toDateTime(end.key).time : '';

  const onSelect = async() => {
    try {
      await props.selectRect(props.index, props.isCurrent);
    } catch (e) {
      console.error('Error select rectification', e);
    }
  };

  return <ResultItemC recommend={props.index == 0}>
    <header>
      <span><a onClick={props.openMore}>{intervalName(props.index)} <ShevronIcon /></a></span>
      <button
        onClick={onSelect}
        style={{
          background: props.isCurrent ? 'var(--colors-blue)' : 'inherit',
          width: 'fit-content'
        }}
      >
        {props.isCurrent ? returnTime : <><span className='icon'>+</span>&nbsp;<span>{t("chronos.app.instruments.resultitem.inCard")}</span></>}
      </button>
    </header>
    <Interval>
      <span>{beginTime}</span>
      {end &&
        <>
          <span><ArrowRightIcon /></span>
          <span>{endTime}</span>
          <span>{!isMobile() && t("chronos.app.instruments.widgets.rectification.result")}{toDateTime(new Date(timestamp).toISOString()).time}</span>
        </>
      }
    </Interval>
    <Events>
      <header>{t("chronos.app.widgets.title.horarEvents")} <AmountIcon amount={begin.score} /></header>
      <EventsList>
        {begin
          .events
          .map((ev, i) => <ResultEvent
            key={`result_${i}`}
            amount={ev.score}
            name={props.allEvents.find(e => e.id == ev.id)!.name}
            width={ev.percentAmountEvents || 0}
          />)
        }
      </EventsList>
    </Events>
  </ResultItemC>;
}

const ResultItemC = styled.section<{ recommend?: boolean }>`
  padding: 1rem;
  background: var(--rectification-block-background);
  border: 1px solid var(--rectification-block-border);
  border-radius: 6px;
  margin-bottom: 0.85rem;

  ${props => props.recommend && css`
    border: 2px solid var(--colors-blue);
  `}

  & > header {
    display: flex;
    margin-bottom: 0.7rem;
    align-items: center;
    color: var(--text-primary);

    & [data-mobile] {
      display: none !important;
    }
    
    & > span {
      flex: 1;
      font-size: 1.125rem;

      & a {
        color: var(--colors-blue);
        cursor: pointer;

        & svg {
          margin-left: 0;
          width: 1.5rem;
          transform: rotate(90deg);
          vertical-align: middle;
        }

        :hover {
          text-decoration: underline;
        }
      }
    }

    & button {
      position: relative;
      display: flex;
      align-items: center;
      margin-left: auto;
      padding: 0.25rem 0.5rem;
      outline: none;
      background: var(--rectification-block-background);
      border: 1px solid var(--rectification-block-border);
      border-radius: 4px;
      background: none;
      font-size: 0.75rem;
      line-height: 1;
      color: var(--rectification-block-color);

      cursor: pointer;

      & .icon {
        font-size: 1rem;
        font-weight: 500;
        fill-opacity: 0.5;
      }

      &:hover .icon {
        fill-opacity: 1;
      }
    }
  }
`;

const Interval = styled.div`
  display: flex;
  margin-bottom: 0.75rem;
  font-size: 0.875rem;
  line-height: 0.875rem;
  align-items: center;
  color: var(--text-secondary);

  & span {
    margin-right: 0.7rem;
  }
  & span:last-child {
    color: var(--text-primary);
  }

  & svg {
    width: 0.875rem;
  }
`;

const Events = styled.div`
  & > header {
    margin-bottom: 0.65rem;
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: var(--text-primary);
  }
`;

const EventsList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(3rem, 1fr));
  grid-gap: 0.375rem;
`;

const ArrowRightIcon = styled(ArrowLeftIcon)`
  transform: rotate(180deg);
`