import { IPromptDataAspect } from '../../types';

import { OppositionIcon as icon } from 'src/assets/icons/astro/aspects';

const aspect: IPromptDataAspect = {
  type: "Opposition",
  deg: 180,
  name: "chronos.app.instruments.widgets.prompts.aspects.opposition.name",
  icon,
  description: {
    list: [
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Opposition.0"
      },
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Opposition.1"
      },
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Opposition.2"
      },
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Opposition.3"
      },
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Opposition.4"
      },
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Opposition.6"
      },
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Opposition.7"
      },
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Opposition.8"
      },      
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Opposition.9"
      },
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Opposition.10"
      }
    ]
  }
};

export default aspect;
