import { ObjectType } from 'src/libs';

export const Ephemeris = [
  {
    type: "chronos.app.instruments.widgets.soul.efemerisFormulas.personal",
    planets: [
      ObjectType.Sun,
      ObjectType.Moon,
      ObjectType.Mercury,
      ObjectType.Venus,
      ObjectType.Mars
    ]
  },
  {
    type: "chronos.app.instruments.widgets.soul.efemerisFormulas.social",
    planets: [
      ObjectType.Jupiter,
      ObjectType.Saturn
    ]
  },
  {
    type: "chronos.app.instruments.widgets.soul.efemerisFormulas.transuranium",
    planets: [
      ObjectType.Pluto,
      ObjectType.Uranus,
      ObjectType.Neptune
    ]
  }
];
