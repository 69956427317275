import { makeAutoObservable } from 'mobx';
import store from 'src/store'

const LS_BANNERS_NAME = 'banners';
const LS_INDI_BANNER_NAME = 'indiBanner';

type LS_INDI_BANNERS_TYPE = typeof LS_INDI_BANNER_NAME;

export default class BannersStore {
  private _indiBanner: 'main' | 'topbar' | 'closed';

  constructor() {
    makeAutoObservable(this);
    this._indiBanner = this.getBannersFromLS().indiBanner;
  }

  get indiBanner() {
    //@ts-ignore TOFIX TODO
    if (store.settings.user.profile.hasIndiSubscription) {
      return 'closed';
    }
    return this._indiBanner;
  }

  viewMainIndiBanner(withoutLS?: boolean) {
    this._indiBanner = "topbar";
    !withoutLS && this.setBannerToLS(LS_INDI_BANNER_NAME, this._indiBanner);
  }

  closeIndiBanner() {
    this._indiBanner = "closed";
    this.setBannerToLS(LS_INDI_BANNER_NAME, this._indiBanner);
  }

  setBannerToLS(banner: LS_INDI_BANNERS_TYPE, status: string) {
    localStorage.setItem(LS_BANNERS_NAME, JSON.stringify({
      ...this.getBannersFromLS(),
      [banner]: status
    }))
  }

  getBannersFromLS() {
    let defaultStatus = 'main';

    const defaultObject = { [LS_INDI_BANNER_NAME]: defaultStatus };
    return JSON.parse(localStorage.getItem(LS_BANNERS_NAME) || JSON.stringify(defaultObject));
  }
}