import React from 'react';

import { aspects, astroObjects, signs } from 'src/libs';

import InterpretationList from './InterpretationList';
import { IWidgetData } from './Widget';
import { IMapDescription } from 'src/api';
import { useTranslation } from 'src/i18n/useTranslation';

function getHelpTitle(desc: IMapDescription) {
  const { t } = useTranslation();
  switch (desc.category) {
    case 'signs': return t(signs[desc.id].ru);
    case 'objects': return t(astroObjects[desc.id].ru);
    case 'aspects': return t(aspects[desc.id].ru);
    case 'houses': return `${+desc.id + 1} ${t("astro.house")}`;
  }
  return 'unknown';
}

export default function Description(props: {
  data: IWidgetData;
  onChanged(key: string, value: any): void;
}): any {
  const { description } = props.data;

  return (
    <InterpretationList
      title={getHelpTitle(description!)}
      items={description!.lines}
    />
  );
}
