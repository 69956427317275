import { IPromptDataSign } from '../../types';

import { CancerIcon as icon } from 'src/assets/icons/astro/signs';
import image from './img/cancer.png';

const sign: IPromptDataSign = {
  name: "Cancer",
  title: "chronos.auth.planets.moon.sign",
  icon,
  image,
  nameIn: "chronos.app.instruments.widgets.prompts.signs.cancer.nameIn",
  description: {
    short: "chronos.app.instruments.concer.descriptionShort1",
    facts: [
      {
        name: "astro.facts.element",
        value: "chronos.app.instruments.widgets.prompts.houses.4.description.facts.4.value"
      },
      {
        name: "chronos.app.instruments.widgets.prompts.signs.aries.description.facts.1.name",
        value: "chronos.auth.planets.moon.title"
      },
      {
        name: "astro.facts.cross",
        value: "astro.facts.cardinal"
      },
      {
        name: "chronos.app.instruments.widgets.prompts.signs.aries.description.facts.3.name",
        value: "chronos.app.instruments.widgets.prompts.signs.cancer.description.facts.3.value"
      }
    ],
    full: "chronos.app.instruments.concer.descriptionFull1"
  }
};

export default sign;
