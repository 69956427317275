import React from 'react';

export default function Svg({
  path,
  size = undefined,
  viewBox = undefined,
  fill = undefined,
  // isBad = false,
  className = undefined
}: {
  path: string;
  opacity?: number;
  size?: number;
  viewBox?: string;
  fill?: string;
  // isBad?: boolean;
  className?: string;
}) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      // viewBox={isBad ? "0 0 24 24" : "0 0 64 64"}
      viewBox={viewBox ? viewBox : '0 0 64 64'}
    >
      <path
        d={path}
        fill={fill? `${fill}` : 'currentColor'}
      />
    </svg>
  );
}
