import React from 'react';
import styled from 'styled-components';

import DropDown from 'src/ui/DropDown';
import { SettingsIcon } from 'src/assets/icons/system';

import { IEvent, intervals, steps } from './types';

import onboarding from './onboarding.cfg';

import { showOnboarding, nextOnboarding } from 'src/lib/onboarding';
import SchoolSelector, { schools } from './SchoolSelector';
import { useTranslation } from 'src/i18n/useTranslation';

export default function Settings(props: {
  interval: number;
  setInterval: Function;
  step: number;
  setStep: Function;
  builded: boolean;
  unsetBuilded: Function;
  events: IEvent[];
  school: number;
  setSchool(id: number): void;
}) {
  let events, interval, step, school, settings;
  const ref = React.useRef(null);
  let lng = localStorage.getItem('i18nextLng');
  const { t } = useTranslation();
  const newSteps = {};

  React.useEffect(() => {
    lng = localStorage.getItem('i18nextLng');
    if (props.builded) {
      onboarding.show && showOnboarding({
        target: ref.current,
        summary: t("chronos.app.instruments.widgets.rectifications.settings.ifYouNeedToAddMore"),
        description: t("chronos.app.instruments.widgets.rectifications.settings.ifYouNeedToAddMore.description"),
        next: 'end',
        offsetX: 3
      });
    }
  }, []);

  if (props.builded) {
    events = <span>{t("chronos.app.instruments.widgets.rectifications.settings.events")} <i>{props.events.length}</i></span>;
    interval = <span>{t("chronos.app.instruments.resultitem.interval")} <i>{t(intervals[props.interval])}</i></span>;
    step = <span>{t("chronos.app.instruments.widgets.rectifications.settings.step")} <i>{t(steps[props.step])}</i></span>;
    school = <span>{t("chronos.app.instruments.widgets.rectifications.settings.school")} <i>
      {props.events.some(e => e.school != (props.events[0]?.school ?? -1)) ?
        t("chronos.app.instruments.widgets.rectifications.settings.mix") :
        t(schools[props.events.every(e => e.school == 0) ? 0 : 1].title)
      }
    </i></span>;
    settings = <div ref={ref}><SettingsIcon onClick={() => {
      props.unsetBuilded();
      onboarding.show && nextOnboarding('end');
    }} /></div>;
  } else {
    interval = <>
      <span style={{marginRight: '0'}}>{t("chronos.app.instruments.resultitem.interval")}</span>
      <DropDown
        options={intervals}
        width='8rem'
        value={props.interval}
        onChange={e => props.setInterval(e)}
      />
    </>;
    step = <>
      <span style={{marginRight: '0'}}>{t("chronos.app.instruments.widgets.rectifications.settings.step")}</span>
      <DropDown
        options={steps}
        width='8rem'
        value={props.step}
        onChange={e => props.setStep(e)}
      />
    </>;
    school = <>
      <span>{t("chronos.app.instruments.widgets.rectifications.settings.school")}</span>
      <SchoolSelector
        school={props.school}
        onSelect={props.setSchool}
      />
    </>;
  }

  return <SettingsC>
    {events}
    {interval}
    {step}
    {school}
    {settings}
  </SettingsC>;
}

const SettingsC = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.875rem;
  color: var(--text-third);

  & > * {
    margin: 0 0.5rem;
    font-size: 0.875rem;
  }

  & > div {
    min-width: initial;
  }

  & i {
    font-style: normal;
    color: var(--text-primary);
  }

  & svg {
    width: 1.5rem;
    margin-left: 0.5rem;
    fill: var(--text-third);
    cursor: pointer;
  }

  & svg:hover {
    fill: var(--text-primary);
  }
`;
