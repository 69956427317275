import { ObjectType, CircleMode, IAspect, getSign } from "src/libs";
import { objectsIcons } from "src/icons";
import { IHighlight } from "src/pages/Instruments";
import { isCompatibility, isPartner } from "../../utils";
import { TWidgetCircleMode } from "./ModeSelectorTabs";
import Icon from './Icon';

export const axes = [1, 2, 3, 5, 6, 10];
export const axesObjects = [ObjectType.House1, ObjectType.House2, ObjectType.House3, ObjectType.House5, ObjectType.House6, ObjectType.House10];

export const axesPairs: {
  [key: number]: [number, number];
} = {
  [ObjectType.House1]: [1, 2],
  [ObjectType.House2]: [2, 2],
  [ObjectType.House3]: [3, 2],
  [ObjectType.House5]: [4, 2],
  [ObjectType.House6]: [5, 2],
  [ObjectType.House10]: [6, 2],

  [ObjectType.House7]: [1, 1],
  [ObjectType.House8]: [2, 1],
  [ObjectType.House9]: [3, 1],
  [ObjectType.House11]: [4, 1],
  [ObjectType.House12]: [5, 1],
  [ObjectType.House4]: [6, 1]
};

export function headerIcon(i: number) {
  return (
    <Icon
      icon={i <= ObjectType.Chiron ? objectsIcons[i] : null}
      text={i <= ObjectType.Chiron ? '' : axes[i - ObjectType.Chiron - 1].toString()}
    />
  );
}

export function isPrognostics(m: string) {
  return ['directions', 'solars', 'transits', 'prog_natal'].includes(m);
}

export function cellClassName(i: number, dir: 'h' | 'v') {
  const diff = i - ObjectType.Chiron;
  // в switch кроме 11 и 12, были добавляны для автотестов 
  switch (i) {
    case 19: return `${dir}-axis House10`
    case 18: return `${dir}-axis House6`
    case 17: return `${dir}-axis House5`
    case 16: return `${dir}-axis House3`
    case 15: return `${dir}-axis House2`
    case 14: return `${dir}-axis House1`
    case 12: return 'lilith' 
    case 11: return 'south-node'
    case 10: return 'NorthNode'
    case 9: return 'Pluto'
    case 8: return 'Neptune'
    case 7: return 'Uranus'
    case 6: return 'Saturn'
    case 5: return 'Jupiter'
    case 4: return 'Mars'
    case 3: return 'Venus'
    case 2: return 'Mercury'
    case 1: return 'Moon'
    case 0: return 'Sun'
    default: return diff < 0 ? '' : diff ? `${dir}-axis` : 'chiron';
  }
}

export function getCellIndex(i: ObjectType) {
  const gap = ObjectType.House1 - ObjectType.Chiron - 1;

  if (i <= ObjectType.Chiron) {
    return {
      id: i + 1,
      w: 1
    };
  } if (i >= ObjectType.House1) {
    const p = axesPairs[i];

    return {
      id: p[0] + ObjectType.House1 - gap,
      w: p[1]
    };
  }

  // const p = axesPairs[i];
  //
  // return {
  //   id: p[0] + ObjectType.House1,
  //   w: p[1]
  // };
}

export function highlightAspect(map: CircleMode, aspect: IAspect, hasExt: boolean = false, hasOuter: boolean = false, comatibilityReversed: boolean = false): IHighlight[] {
  if (!aspect) { return [] }

  const type: any = (obj: ObjectType, isExt = false, isOuter = false) => ({
    id: obj >= ObjectType.House1 ? obj - ObjectType.House1 : obj,
    type: `${obj >= ObjectType.House1 ? 'house' : 'object'}${isExt && !isOuter ? '_ext' : isOuter ? '_outer' : ''}`
  });

  let result: IHighlight[] = [];

  // обычный аспект
  if(!('obj3' in aspect)) {
    result = [
      {
        id: aspect.id,
        type: 'aspect',
        map
      },
      {
        ...type(aspect.obj1, hasOuter ? hasExt : false),
        map
      },
      {
        ...type(aspect.obj2, hasExt, hasOuter),
        map
      },
      {
        type: 'sign',
        id: getSign(aspect.lon1),
        map
      },
      {
        type: 'sign',
        id: getSign(aspect.lon2),
        map
      }
    ] as IHighlight[]
    // аспект на аспект или (транзиты в синастрии в режиме 2)
  } else {  
    const ErisId = 15;
    result = aspect.obj3 === ErisId // Если в транзитных объектах приходит Eris, то не подсвечиваем такие аспекты
    ? [] 
    :[
      {
        id: aspect.id,
        type: 'aspect',
        map
      },
      ((aspect.obj1! >= 0)
        ? {
            ...type(aspect.obj1),
            map
          }
        : undefined
      ),
      ( aspect.obj2! >= 0
        ? {
            ...type(aspect.obj2, true), //TODO второй аргумент - hasExt. Его считаем для аспекта с домом если это синастрическая прогностика
            map
          }
        : undefined
      ),
      
      {
        ...type(aspect.obj3, false, true),
        map
      },
      
      (aspect.obj1! >= 0
        ? {
            type: 'sign',
            id: getSign(aspect.lon1),
            map
          }
        : undefined
      ),
      
      (aspect.obj2! >= 0
        ? {
            type: 'sign',
            id: getSign(aspect.lon2),
            map
          } 
        : undefined
      ),
      {
        type: 'sign',
        id: getSign(aspect.lon3!),
        map
      }
    ].filter(h => h) as IHighlight[]
  }

  return result;
}

export function hasExt(mode: CircleMode) {
  return isCompatibility(mode) || isPartner(mode) || ['directions', 'solars', 'transits', 'prog_natal', 'prog_prog'].includes(mode);
}

export function extractMode(mode: TWidgetCircleMode | CircleMode, div: string = ''): CircleMode | undefined {
  return mode.split(div)?.[0] as CircleMode;
}