import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, IPromocodeSubscriptionAction, show } from 'src/libs';
import TariffView from 'src/pages/Settings/views/TariffView';
import SettingsGroup from 'src/pages/Settings/wrappers/SettingsGroupWrap';
import ButtonGroup from 'src/ui/ButtonGroup';

import { 
  CloseSmallIcon, 
  ArrowSmallLeftIcon, 
  ShevronDownIcon, 
  OkIcon 
} from 'src/assets/icons/system';
import SelectedFormation from './SelectedFormation';
import SelectedTariff from './SelectedTariff';
import { observer } from 'mobx-react';
import api, { ISubscription, s3urls } from 'src/api';
import { useTranslation } from 'src/i18n/useTranslation';
import store from 'src/store';
import Confirmation from "../../../../components/Confirmation";
import { routes } from 'src/router';
import { getDeviceDetect } from 'src/utils';
import dayjs from 'src/dayjs';


const { isTablet } = getDeviceDetect();

export default observer(function PaymentDetails({
  visible = 0
}: {
  visible: number;
}) {
  const { payments: paymentStore } = store.settings;
  const {
    payTypes,
    activePayType,
    adjustedTariffs,
    selectedTariff,
    selectedFormationVisible,
    successPaymentScreen,
    failPaymentScreen,
    selectedPayType,
    tariffDetails,
    userTariffId,
    autoPayment,
  } = paymentStore;

  const { promo: promoStore } = store;

  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [tariffViewId, setTariffViewId] = useState<number | null>(null);
  const [count, setCount] = useState<number>(0);

  const promoTariffIds = promoStore.tariffWithDiscountIds;
  const { t } = useTranslation();

  const promoDiscountInfo = React.useMemo((): { [key: string]: any } | null => {
    const promoAction = ((store.promo.tariffDiscountPromo?.actions || store.promo.tariffCncl) as IPromocodeSubscriptionAction);

    if (!promoAction) return null;

    let monthDiff: number | null;
    let dayDiff: number | null;

    if (promoAction.discountStart && promoAction.discountEnd) {
      const [discountStart, discountEnd] = [dayjs(promoAction.discountStart), dayjs(promoAction.discountEnd)];
      monthDiff = discountEnd.diff(discountStart, 'month');
      dayDiff = discountEnd.diff(discountStart, 'day');
    } else {
      monthDiff = promoAction.periodType === 'months' ? promoAction.period : null;
      dayDiff = promoAction.periodType === 'days' ? promoAction.period : null;
    }

    return {
      ...promoAction,
      monthDiff,
      dayDiff
    }
  }, [store.promo.tariffDiscountPromo, store.promo.tariffCncl]);


  React.useEffect(() => {
    // console.log('UseEffect activePayType - ', promoDiscountInfo)
    if (count > 0) return;
    paymentStore.setActiveType(promoDiscountInfo?.monthDiff === 12 ? 12 : 1);
    setCount(count + 1);

  }, [promoDiscountInfo]);

  const onCancelConfirmation = () => {
    setConfirmation(false)
  }

  const onSubmitConfirmation = (fn: any) => {
    setConfirmation(false)
    fn();
  }

  const switchAutoPayment = () => {
      api.autopaymentChange(!autoPayment)
          .then(result => {
            paymentStore.setAutoPayment(result);
          });
  }

  const changeTariff = (tariffId: number) => {
    userTariffId !== tariffId && paymentStore.setActiveTariff(tariffId);
  }

  function onAnimationDone(evt: React.AnimationEvent) {
    // вернем 'видимость' в первоначальное состояние
    if (evt.animationName === 'slide-out') { paymentStore.setPaymentDetailsVisible(0) }
  }

  function onBackButtonClick() {
    if (selectedTariff) {
      paymentStore.setActiveTariff(null);
    } else {
      document.title = `${t(routes.AppSettings.title.prefix)} - ${t(routes.AppSettings.title.payments)}`;
      paymentStore.setPaymentDetailsVisible(-1);
      setTimeout(() => {
        paymentStore.setSelectedFormationVisible(false);
        paymentStore.cancelFormationsSelection();
      }, 500);
    }
  }

  const onPromocodeSwitch = () => {
    if (promoStore.tariffCncl.id && (!promoDiscountInfo || promoDiscountInfo?.monthDiff === paymentStore.activePayType)) {
      promoStore.switchPromo('tariff');
    } else {
      show({
        text: 'Промокод не может быть применен, так как период действия скидки по нему, не совпадает с указанным периодом оплаты подписки.',
        type: 'warning',
        closable: true,
        timeout: 5000
      })
    }
  }

  const onPayTypeSelect = (data: number) => {
    paymentStore.setDiscount(data === 12);
    paymentStore.setActiveType(data);

    // если есть промокод со скидкой на тариф и его period не совпадает с выбранным
    if (
        store.promo.tariffCncl.id &&
        !store.promo.tariffCncl.canceled && (!promoDiscountInfo?.recurrent && promoDiscountInfo?.monthDiff !== data)
      ) {
        store.promo.switchPromo('tariff', !Boolean('showMessage'));
        show({
          text: 'Промокод был отменен, так как период действия скидки по нему, не совпадает с указанным периодом оплаты подписки.',
          type: 'info',
          closable: true,
          timeout: 5000
        })
    }
    else if ((store.promo.tariffCncl.canceled && (promoDiscountInfo?.monthDiff === data || (promoDiscountInfo?.dayDiff && data !== 12)))) {
      store.promo.switchPromo('tariff');
    }

    // else if (store.promo.tariffCncl.canceled && promoDiscountInfo?.monthDiff === data) {
    //   store.promo.switchPromo('tariff', !Boolean('showMessage'));
    // }
  }

  return (
    <Container visible={visible} className="payment-details" onAnimationEnd={onAnimationDone}>
      {!successPaymentScreen && !failPaymentScreen && <BackButton onClick={onBackButtonClick}><ShevronDownIcon /><span>{t("chronos.app.goBack")}</span></BackButton>}

      {selectedTariff
        ? <SelectedTariff tariff={selectedTariff} payType={selectedPayType} />
        : selectedFormationVisible
          ? <SelectedFormation />
          : <>
            <SettingsGroup>
              <MessageBlock>
                <h1>{t("chronos.app.settings.paymentDetails.chooseTariff")}</h1>
                <p>{t("chronos.app.settings.paymentDetails.updatePersonalSettings")}</p>
                <ButtonGroup items={payTypes} active={activePayType} onSelect={onPayTypeSelect} />
              </MessageBlock>
            </SettingsGroup>
            <SettingsGroup>
              <TariffsWrapper>
                {
                  adjustedTariffs
                    // .filter(({ id }) => id !== 1)
                    .map((tariff: ISubscription) => {
                      const isPromoDiscount = promoStore.tariffWithDiscountIds.includes(tariff.id);
                      const promoDiscount = promoStore.tariffDiscountValue

                      const onClickHandlerSwitchAutoPayment = () => {
                        if (autoPayment && promoTariffIds.includes(userTariffId) && promoStore.tariffWithDiscountRecurrent) {
                          setConfirmation(true)
                        } else {
                          switchAutoPayment()
                        }
                      }

                      const onClickHandlerChooseOtherTariff = () => {
                        if (promoTariffIds.includes(userTariffId) && promoStore.tariffWithDiscountRecurrent) {
                          setConfirmation(true)
                          setTariffViewId(tariff.id)
                        } else {
                          changeTariff(tariff.id)
                        }
                      }

                      return <TariffView
                        data={tariff}
                        activePayType={paymentStore.activePayType as number}
                        promoData={promoTariffIds.includes(tariff.id) ? promoStore.tariffDiscountPromo : undefined}
                        free={promoStore.freeTariffId === tariff.id}
                        freeText={t("chronos.app.settings.payment.freeTariffPeriodInfo", { freeTariffPeriodInfo: promoStore.freeTariffPeriodInfo, tariffSum: tariff.sum })}
                        style={{ minHeight: '19.375rem' }}
                        border={true}
                        button={
                          // если это текущий тариф
                          userTariffId === tariff.id
                            ? <TextButton onClick={() => {
                              setTariffViewId(tariff.id)
                              onClickHandlerSwitchAutoPayment()
                            }}>
                              {autoPayment ? t("chronos.app.settings.unsubscribe") : <span>{t("chronos.app.settings.renewSubscription")}</span>}
                              {autoPayment && <CloseSmallIcon />}
                            </TextButton>
                            : <Button
                              onClick={() => onClickHandlerChooseOtherTariff()}
                              size="medium"
                              color={tariff.color}
                              bordered={false}
                            >
                              {t("base.choose")}
                            </Button>
                        }
                        key={`${tariff.id}_s`}
                      />
                    })
                }
              </TariffsWrapper>
              {confirmation && <Confirmation
                title={t("chronos.app.attention")}
                submitText={t("chronos.app.settings.payment.tariff.changeTariff")}
                onSubmit={() => {
                  userTariffId === tariffViewId
                    ? onSubmitConfirmation(switchAutoPayment)
                    : onSubmitConfirmation(() => changeTariff(tariffViewId!))
                }}
                onCancel={() => {
                  onCancelConfirmation()
                }}
              >
                <ConfirmationText>{t("chronos.app.settings.payment.tariff.changeTariff.confirmationText")}</ConfirmationText>
              </Confirmation>}
            </SettingsGroup>
            {/* блок промоакций включен, если есть скидочный */}
            {promoStore.tariffCncl.id
              ? <SettingsGroup>
                <PromoInfoBlock canceled={promoStore.tariffCncl.canceled}>
                  <span>{t(promoStore.tariffCncl.text)}</span>
                  <Button size="small" onClick={onPromocodeSwitch}
                  >
                    {promoStore.tariffCncl.canceled ? t("chronos.app.settings.payment.tariff.changeTariff.return") : t("chronos.app.settings.payment.tariff.changeTariff.reset")}
                  </Button>
                </PromoInfoBlock>
              </SettingsGroup>
              : null
            }
            <SettingsGroup>
              <MessageBlock>
                <h3>{t("chronos.app.settings.payment.tariff.changeTariff.aboutTariffs")} <ArrowSmallLeftIcon /></h3>
              </MessageBlock>
            </SettingsGroup>
            <SettingsGroup>
              {
                tariffDetails?.length
                  ? tariffDetails.map(({ title, note, rows }: { title: string; note: string; rows: Array<any> }) =>
                    <Table cellPadding="0" cellSpacing="0" key={`${title}_table`}>
                      <caption>{t(title)}{note && <ins>{t(note)}</ins>}</caption>
                      <tbody>
                        {
                          rows.map(row =>
                            <tr key={`${row.title}_row`}>
                              <td>{t(row.title)}{row.note && <ins>{t(row.note)}</ins>}</td>
                              {
                                row.support.map((item: any, i: number) =>
                                  <td key={`${t(row.title)}_${i}`}>
                                    <Label bgColor={i} opacity={item}><OkIcon /></Label>
                                  </td>)
                              }
                            </tr>
                          )
                        }
                      </tbody>
                    </Table>
                  )
                  : null
              }
            </SettingsGroup>
            <SettingsGroup>
              <MessageBlock>
                <h2>{t("chronos.app.settings.paymentDetails.thereWereQuestions")}</h2>
                <p>{t("chronos.app.settings.paymentDetails.writeInSupport")}</p>
                <Button size="medium" onClick={() => { window.open(s3urls.support, '_blank') }}>{t("chronos.app.settings.paymentDetails.askTheQuastion")}</Button>
              </MessageBlock>
            </SettingsGroup>
          </>
      }
    </Container>
  );
});


const Container = styled.div<{ visible: number }>`
  position: absolute;
  box-sizing: border-box;
  width: 100vw;
  min-height: 100vh;
  left: ${isTablet ? '-27vw' : '-22vw'};
  top: 0;
  padding: 6.25rem calc((100vw - 67.25rem) / 2);
  background-color: var(--workspace-background);
  z-index: 10;
  transform: translateX(-100%);
  transition: transform ease 0.3s;
  overflow-y: hidden;
  visibility: hidden;

  ${props => props.visible === 0 && css`
    max-height: 100vh;
    transform: translateX(200%);
    animation: none;
  `}

  ${props => props.visible === 1 && css`
    animation: slide-in 0.5s both;
  `}

  ${props => props.visible === -1 && css`
    animation: slide-out 0.5s both;
  `}

  @keyframes slide-in {
    0% {
      max-height: 100vh;
      transform: translateX(100%);
      visibility: hidden;
    }

    100% {
      max-height: 500vh;
      transform: translateX(0%);
      visibility: initial;
    }
  }

  @keyframes slide-out {
    0% {
      max-height: 500vh;
      transform: translateX(0%);
      visibility: initial;
    }

    100% {
      max-height: 100vh;
      transform: translateX(-100%);
      visibility: hidden;
    }
  }
`;
export const TextButton = styled.button`
  display: flex;
  position: relative;
  align-items: center;
  padding: 0.7rem 0;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: var(--accent-red);
  background-color: transparent;
  cursor: pointer;

  &:hover {
    opacity: 0.78;
  }

  span {
    color: var(--text-primary);
  }

  & svg {
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: middle;
    margin-left: 0.4rem;
  }
`;
export const BackButton = styled.div`
  position: absolute;
  top: 1.5rem;
  ${isTablet
      ? css`left: auto;`
      : css`transform: translateX(-200%);`
  }
  color: var(--text-third);
  cursor: pointer;

  & svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    vertical-align: middle;
    transform: rotate(90deg);
  }

  & span {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    vertical-align: middle;
  }

  &:hover {
    color: var(--text-secondary);
  }
`;

export const TariffsWrapper = styled.div`
  display: flex;
  position: relative;
  /* grid-template-columns: repeat(3, 1fr); */
  grid-gap: 1.5rem;
`;

const Table = styled.table`
  position: relative;
  width: calc(100% - 4rem);
  margin: 0 auto;

  & + table {
    margin-top: 1.125rem;
  }

  & caption {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.312rem;
    color: var(--text-primary);
    text-align: left;
  }

  td {
    min-width: 30%;
    padding: 1rem 0;
    border-bottom: 1px solid var(--element-neutral);

    &:first-child {
      width: 14.375rem;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1rem;
      color: var(--text-secondary);
    }

    &:nth-child(3) div {
      margin: 0 auto;
    }
    &:nth-child(4) div {
      margin-left: auto;
    }
  }

  tr:last-child td {
    border-bottom: none;
  }

  ins {
    display: inline-block;
    margin-left: 0.5rem;
    padding: 0.375rem 0.437rem;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: var(--text-third);
    text-decoration: none;

    background-color: var(--element-neutral);
    border-radius: 3px;
  }
`;

const Label = styled.div<{ bgColor: number; opacity: number }>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;

  ${props => props.bgColor === 0 && css`
    background-color: var(--accent-blue);
  `}

  ${props => props.bgColor === 1 && css`
    background-color: var(--accent-red);
  `}

  ${props => props.bgColor === 2 && css`
    background-color: var(--accent-magenta);
  `}

  ${props => props.opacity === -1 && css`
    opacity: 0;
  `}

  ${props => props.opacity === 0 && css`
    opacity: 0.3;
  `}

  & svg {
    width: 0.75rem;
    height: 0.562rem;
    color: #fff;
  }
`;

export const MessageBlock = styled.div`
  position: relative;

  h1 {
    position: relative;
    margin: 0;
    font-weight: 600;
    font-size: 2.25rem;
    line-height: 2.625rem;
    color: var(--text-primary);
    text-align: center;
  }

  h1 + p {
    margin: 0.5rem auto 1.5rem auto;
    max-width: 30rem;
    font-size: 0.875rem;
    line-height: 1.312rem;
    color: var(--text-secondary);
    text-align: center;
  }

  h2 {
    margin: 0;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.75rem;
    text-align: center;
  }

  h2 + p {
    margin: 0.5rem auto 1.5rem auto;
    font-weight: 600;
    font-size: 1;
    line-height: 1.5rem;
    color: var(--text-secondary);
    text-align: center;
  }

  h3 {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
    color: var(--text-secondary);
    text-align: center;

    svg {
      width: 1.5rem;
      height: 1.5rem;
      color: var(--icon-third);
      vertical-align: middle;
      transform: rotate(-90deg);
    }
  }

  & > button {
    display: block;
    margin: 0 auto;

    &:hover {
      opacity: 0.6;
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
      transform: rotate(180deg);
      vertical-align: middle;
      color: inherit;
    }
  }

`;

const PromoInfoBlock = styled.div<{ canceled: boolean }>`
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  padding: 0.325rem 0.625rem;
  background-color: var(--element);
  border-radius: 0.25rem;
  font-size: 1rem;
  line-height: 1;

  &::before {
    display: inline-block;
    position: relative;
    content: '';
    width: 0.375rem;
    height: 0.375rem;
    margin-right: 0.438rem;
    vertical-align: middle;
    border-radius: 50%;
    background-color: var(--accent-green);
  }

  span {
    ${props => props.canceled && css`
      text-decoration: line-through;
    `}
    &:first-letter {
      text-transform: uppercase;
    }
  }

  button {
    margin-left: 0.5rem;
    background: transparent;
    border: none;
    color: var(--accent-red);
    opacity: 0.7;

    ${props => props.canceled && css`
      color: var(--accent-green);
    `}

    &:hover {
      opacity: 1;
      background: transparent;
    }
  }
`;

const ConfirmationText = styled.p`
  margin: 1em 0;
  color: var(--text-third);
  font-size: 0.875rem;
`;
