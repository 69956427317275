import { makeAutoObservable, runInAction } from 'mobx';
import { cloneDeep } from 'lodash';

import SettingsStore from 'src/pages/Settings/store/index';

import api from 'src/api';
import { IAstroSettings } from 'src/libs';
import StarsData from 'src/pages/Settings/sections/stars.json';

import store from 'src/store'

export default class AstroStore {

  topStore = new SettingsStore();

  private _profiles: IAstroSettings[] | null = null;

  private _defaultStarList = {
    showWithObjects: true,
    list: [
      'Algol',
      'Aldebaran',
      'Antares',
      'Betelgeuse',
      'Regulus',
      'Sirius'
    ]
  }
  private _horarStars: string[] = StarsData.filter(({isHorar}) => isHorar).map(({astroName}) => astroName);

  constructor() {
    makeAutoObservable(this);
  }

  init(userId: number, force: boolean = false) {
    
    return new Promise<IAstroSettings[]>(async resolve => {
      
      if (this._profiles?.length && !force) return resolve(this._profiles);

      const profiles = await api.astroSettings(userId);

      runInAction(() => {
        this._profiles = (profiles.some(p => p.mutable)
          ? profiles
          : [
              {
                ...cloneDeep(profiles.find(p => p.id === 0)!),
                id: -1,
                title: "chronos.app.astroStore.myProfile",
                mutable: true,
              },
              ...profiles
          ])
          .map((p: IAstroSettings) => {
              
              if (!p.widgets) {
                p.widgets = {
                  dispositors: {
                    planets: [
                      {
                        id: 'Chiron',
                        label: "astro.chiron",
                        isActive: true
                      },
                      {
                        id: 'Lilith',
                        label: "astro.lilith",
                        isActive: true
                      },
                      {
                        id: 'Nodes',
                        label: "astro.nodes",
                        isActive: true
                      },
                      {
                        id: 'Selena',
                        label: "astro.selena",
                        isActive: true
                      },
                      { id: 'Eris',
                        label: 'astro.eris',
                        isActive: p.id === 1 // включена только для школы МАА
                      }
                    ]
                  }
                };
              
              } else if (!p.widgets.dispositors.planets.find((planet: any) => planet.id === 'Eris')) {
                p.widgets.dispositors.planets = [
                  ...p.widgets.dispositors.planets,
                  { id: 'Eris', label: 'astro.eris', isActive: p.id === 1 }
                ]
              }

              if (!p.startingPoint) {
                p.startingPoint = 'asc'
              }

              if (!p.rotationDir) {
                p.rotationDir = 'ccw'
              }

              // если нет настроек для высших планет и это профиль какой то из школ
              if (p.showHigherPlanets === undefined && [0,1].includes(p.id)) {
                p.showHigherPlanets = true;
              }

              if (p.showMinorPlanets === undefined && [0, 1].includes(p.id)) {
                p.showMinorPlanets = true;
              }

              // отключаем показ транзитной карты в синастрических совместимостях для школ
              // if (p.showSynastryTransits === undefined && [0, 1].includes(p.id)) {
              //   p.showSynastryTransits = false;
              // }

              if(!p.fixedStars) {
                // московская академия астрологии
                if (p.id === 1) {
                  p.fixedStars = {
                    natal: { list: [...this._horarStars], showWithObjects: true },
                    synastry: { list: [...this._horarStars], showWithObjects: true },
                    horar: { list: [...this._horarStars], showWithObjects: true }
                  }
                // школа павла андреева
                } else if (p.id === 0) {
                  p.fixedStars = {
                    natal: { list: [...this._defaultStarList.list], showWithObjects: true },
                    synastry: { list: [...this._defaultStarList.list], showWithObjects: true },
                    horar: { list: [...this._horarStars], showWithObjects: true }
                  }
                }
              }

              if (p.hideTransitAspects === undefined) {
                // московская академия астрологии
                if (p.id === 1) {
                  p.hideTransitAspects = false;
                  // школа Павла Андреева
                } else if (p.id === 0) {
                  p.hideTransitAspects = true;
                }
              }


              if (p.closureConfig === undefined) {
                // московская академия астрологии
                if (p.id === 1) {
                  p.closureConfig = 0;
                  // школа Павла Андреева
                } else if (p.id === 0) {
                  p.closureConfig = 1;
                }
              }

              if (p.planetConnectionConfig === undefined) {
                // московская академия астрологии
                if (p.id === 1) {
                  p.planetConnectionConfig = false;
                  // школа Павла Андреева
                } else if (p.id === 0) {
                  p.planetConnectionConfig = true;
                }
              }

              // if(p.widgets.dispositors)


              return p;
          });
        resolve(this._profiles);
      });
    });
  }

  get profiles() {
    return this._profiles || [];
  }

  async updateProfile(profile: IAstroSettings, newValues?: { idCustomization: number }): Promise<number> {
    
    let _profile = { ...profile };

    _profile.idCustomization = newValues?.idCustomization ?? _profile.idCustomization;


    if (_profile.mutable) {
      // _profile.fixedGmt = {}
      _profile = await api.updateAstroSettings(_profile);
      
      // если сохраняем, первый раз в Мой профиль. При изменении настроек карты, например.
      if (profile.id == -1) {
        await this.init(store.settings.user.auth.id, Boolean('force'));
      }
    }

    runInAction(() => {
      this._profiles = this._profiles!.map(p => p.id === _profile.id ? _profile : p)
      // console.log('updated Profile idCustomization -', cloneDeep(this._profiles))
    });
    
    return _profile.id;
  }
}
