import { IPromptDataPlanet } from '../../types';

import Sun from './sun';
import Moon from './moon';
import Jupiter from './jupiter';
import Mars from './mars';
import Mercury from './mercury';
import Neptune from './neptune';
import Pluto from './pluto';
import Saturn from './saturn';
import Uranus from './uranus';
import Venus from './venus';


const planets: {
  [id: string]: IPromptDataPlanet;
} = {
  Sun,
  Moon,
  Jupiter,
  Mars,
  Mercury,
  Neptune,
  Pluto,
  Saturn,
  Uranus,
  Venus
};

export default planets;
