import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { observer } from 'mobx-react';

import { CircleMode, IMap, PrognosticsMode, RelocationsMode } from 'src/libs';

import { IWidgetData } from '../Widget';

import { ChevronRightIcon } from 'src/assets/icons/system/24';
import { useTranslation } from 'src/i18n/useTranslation';
import { getModeMetaData, isCompatibility, compatibilityToPartner } from '../../utils';
import store from 'src/store';

export type TWidgetCircleMode = CircleMode | `compatibility${number}-transit-partner` | `compatibility${number}-transit-natal`;

const ModeSelectorTabs: React.FC<{
  modes: CircleMode[];
  current: TWidgetCircleMode;
  data: IWidgetData;
  onChange(key: string, value: any): void;
}> = observer(({
  modes,
  current,
  data,
  onChange = () => {}
}) => {
  const [step, setStep] = useState<number>(0);

  const containerRef = React.useRef<HTMLDivElement>(null);
  const dummyRef = React.useRef<HTMLDivElement>(null);
  const listRef = React.useRef<HTMLUListElement>(null);
  const currentRef = React.useRef<HTMLLIElement>(null);
  const prevRef = React.useRef<HTMLDivElement>(null);
  const nextRef = React.useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const { prognosticsNatal, compatibilityTransits, compatibilityReversed } = store.instruments.mapIndicatorCurrent;

  React.useEffect(() => {
    dummyRef.current!.style.height = `${containerRef.current!.offsetHeight}px`;
  }, []);

  React.useEffect(() => {
    setStep(0);
  }, [modes]);

  React.useEffect(() => {
    showStep();
  }, [current]);

  React.useEffect(() => {
    showChevrons();
    moveSlider(step);
  }, [step]);

  const showChevrons = () => {
    if(!prevRef.current || !nextRef.current || !listRef.current || !containerRef.current) return;
    
    if (step === 0) {
      prevRef.current.style.display = 'none';
      if (listRef.current.offsetWidth > containerRef.current.offsetWidth) {
        nextRef.current.style.display = 'block';
      } else {
        nextRef.current.style.display = 'none';
      }
    } else if (step === 1) {
      prevRef.current.style.display = 'block';
      nextRef.current.style.display = 'none';
    }
  };

  const showStep = () => {
    if (currentRef.current) {
      const left = currentRef.current.offsetLeft;
      const width = currentRef.current.offsetWidth;
      const containerWidth = containerRef.current!.offsetWidth;
      if (left + width > containerWidth) { setStep(1) }
      else { setStep(0) }
    }
  };

  const moveSlider = (step: number) => {
    let x = 0;
    if (step === 1) {
      x = listRef.current!.offsetWidth - containerRef.current!.offsetWidth;
    }

    listRef.current!.style.transform = `translateX(-${x}px)`;
  };

  const handleClick = (mode: TWidgetCircleMode, i?: number) => {
    setTimeout(() => {
      showStep();
      showChevrons();
    }, 100);
    onChange('aspects-table-mode', mode);
  };

  const _tabs = React.useMemo((): JSX.Element[] => {
    
    const result: JSX.Element[] = data.maps.map((map: IMap, i: number) => {
      const name = getModeMetaData(map.mode, data.form).title;

      return ( 
        <>
          <Item
            key={`${map.mode}_${i}`}
            active={map.mode === current}
            ref={map.mode === current ? currentRef : null}
            onClick={() => { handleClick(map.mode, i) }}
          >
            <span>
              { t(name!) }
              { Boolean((prognosticsNatal as any)[map.mode as PrognosticsMode]) && ` - ${t('astro.natal') }` }
            </span>
          </Item>
        </>
      );

    });

    // находим режим текущей совместимости
    const compatibilityMode: CircleMode | undefined = modes.find(mode => isCompatibility(mode as CircleMode));
    const showPartnerTransits = Boolean(data.form?.syn_prognostics);
    
    const transitPartnerKey: TWidgetCircleMode = `${compatibilityMode}-transit-partner` as TWidgetCircleMode;
    const transitNatalKey: TWidgetCircleMode = `${compatibilityMode}-transit-natal` as TWidgetCircleMode;

    if (
      compatibilityMode 
      && showPartnerTransits
      && compatibilityTransits[compatibilityMode].transitMode === 1
    ) {

      const natalTitle = t('astro.natal');
      const partnerTitle = getModeMetaData(compatibilityToPartner(compatibilityMode), data.form).title;

        result.push(
          <>
            <Item
              key={transitNatalKey}
              active={transitNatalKey === current}
              ref={transitNatalKey === current ? currentRef : null}
              onClick={() => { handleClick(transitNatalKey) }}
            >
            <span>
              { `${t('Транзит')} - ${natalTitle}` }
            </span>
          </Item>
          <Item
                key={transitPartnerKey}
                active={(transitPartnerKey) === current}
                ref={transitPartnerKey === current ? currentRef : null}
                onClick={() => { handleClick(transitPartnerKey) }}
              >
              <span>
                { `${t('Транзит')} - ${partnerTitle}` }
              </span>
            </Item>
          </>
        )
    }

    // сбросим на совместимость, если отключены транзиты
    if (
      compatibilityMode 
      && [transitPartnerKey, transitNatalKey].includes(current) // если текущий режим - субрежим совместимости (-transit-partner или -transit-natal)
      && !showPartnerTransits // если отключены транзиты совместимости
    ) {
      handleClick(compatibilityMode!)
    }

    return result;

  }, [data, prognosticsNatal, compatibilityTransits, compatibilityReversed])

  return <>
    <Container ref={containerRef}>
      <List ref={listRef}>
        {_tabs}
      </List>

      <ChevronInCircle type="left" ref={prevRef} onClick={() => setStep(0)}><ChevronRightIcon /></ChevronInCircle>
      <ChevronInCircle type="right" ref={nextRef} onClick={() => setStep(1)}><ChevronRightIcon /></ChevronInCircle>
    </Container>
    <div ref={dummyRef}></div>
  </>;
});

const Container = styled.div`
  width: 100%;
  position: absolute;
  overflow: hidden;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  margin-top: 0.25rem;
  list-style: none;
  font-size: 0.875rem;
  color: var(--text-secondary);
  white-space: nowrap;
  width: min-content;
  transition: transform 0.3s;
`;

const Item = styled.li<{active: boolean}>`
  display: inline-block;
  margin-right: 2.25rem;
  cursor: pointer;

  span {
    display: inline-block;
    max-width: 8rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ${props => props.active && css`
    color: var(--text-primary);

    &:after{
      display: block;
      content: '';
      margin-top: 1rem;
      height: 0.125rem;
      width: 100%;
      background: var(--accent-blue);
    }
  `}

  &:last-child {
    margin-right: 0;
  }
`;

const ChevronInCircle = styled.div<{type: string}>`
  position: absolute;
  top: 0;

  height: 1.5rem;
  width: 1.5rem;
  background-color: var(--bg-100);
  color: var(--text-primary);
  border-radius: 50%;
  display:none;
  cursor: pointer;

  & svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  ${props => props.type === 'left' && css`
    left: 0rem;
    transform: scaleX(-1);
  `}

  ${props => props.type === 'right' && css`
    right: 0rem;
  `}
`;

export default ModeSelectorTabs;
