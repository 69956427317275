import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';
import styled, { css } from 'styled-components';
import { Button, IFormData, fillNumber } from 'src/libs';
import { checkGenChanged, getDeviceDetect } from 'src/utils';
import { ITimeLineProps } from './TimeLine';
import Notify from 'src/ui/Wrappers/Notify';
import { useTranslation } from 'src/i18n/useTranslation';

const { isTablet } = getDeviceDetect();

export interface IParts {
  day: number,
  month: number,
  year: number,
  hour: number,
  minute: number,
  second: number,
}

export const daysInMonth = (year: number, month: number) => {
  return new Date(year, month, 0).getDate();
}

export default function TimeLineTablet({
  mode,
  onChange,
  value,
  form,
  isNatal,
  onHide,
	openInTemporaryMap,
}: ITimeLineProps){
  const ref = useRef<HTMLDivElement>(null);
  const d = dayjs.utc(value);
	const { t } = useTranslation();
	const [notification, setNotification] = useState(false);
  const [changedValue, setChangedValue] = useState<string | null>(null);

  const parts = {
    day: d.date(),
    month: d.month() + 1,
    year: d.year(),
    hour: d.hour(),
    minute: d.minute(),
    second: d.second(),
  };

  const part = !mode ? 0 : parts[mode];

  useLayoutEffect(() => {
    if(!mode) {
      return;
    }

    align();

    const onScroll = () => { 
      const block = ref.current!;
      const center = block.offsetWidth / 2;
  
      let min = Infinity;
      let nearestItem: HTMLDivElement | undefined = undefined;
      Array.from(block.children).forEach((item_, i) => {
        const item = item_ as HTMLDivElement;
        const delta = Math.abs((block.scrollLeft + center) - item.offsetLeft )
        if(delta < min) {
          min = delta;
          nearestItem = item;
        }
      })

      if(nearestItem !== undefined){
        const part = +(nearestItem as HTMLDivElement).textContent!.trim();
        const unit = mode === 'day' ? 'date' : mode;
        const val = mode === 'month' ? part - 1 : part;
        const currentValue = dayjs.utc(value).set(unit, val).toISOString();
        
        const {
          gen,
          natal: { dt, place: _place }
        } = form;
    
        if (isNatal && checkGenChanged(gen, currentValue, dt, _place, _place)) {
          setNotification(currentValue !== value);
          setChangedValue(currentValue);
        } else {
          onChange(currentValue);
        }
      }
    }

    ref.current!.addEventListener('scroll', onScroll);

    return () => {
      if(ref.current) {
        ref.current!.removeEventListener('scroll', onScroll);
      }
    }
  }, [mode]);

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('wheel', onWheel);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('wheel', onWheel);
    }
  }, [mode]);

  const moveLineToItem = (item: HTMLDivElement) => {
    const left = item.offsetLeft;
    ref.current!.scrollLeft = left - window.innerWidth / 2 + item.offsetWidth / 2;
  }

  const onWheel = (ev: any) => {
    if (!mode) return;
    move(Math.sign(ev.deltaY));
  };

  const onKeyDown = _.throttle((ev: any) => {
    if (!mode) { return }
    if (ev.key === 'Escape') onHide();
    if (ev.key === 'ArrowLeft') {
      move(-1);
    };
    if (ev.key === 'ArrowRight') {
      move(1);
    };
  }, 100);

  const move = (dir: number) => {
    const block = ref.current!;
    const someItem = Array.from(block.children)[0] as HTMLDivElement;
    block.scrollLeft += dir * someItem.offsetWidth;
  }

  const openTemporaryMap = () => {
    if(changedValue) {
      openInTemporaryMap(changedValue);
      setChangedValue(null);
    }
    setNotification(false);
  }

  const applyChanges = () => {
    if(changedValue) {
      onChange(changedValue);
      setChangedValue(null);
    }
    setNotification(false);
  }

  const align = () => {
    Array.from(ref.current!.children).forEach(item_ => {
      const item = item_ as HTMLDivElement;
      if(+item.textContent!.trim() === +part) {
        moveLineToItem(item);
      }
    });
  }

  const onCloseNotification = () => {
    align();
  }

  const handleClick = (ev: any) => {
    ev?.target && moveLineToItem(ev.target);  
  }

  let min = 0;
  let max = 59;

  switch(mode){
    case 'day':
      min = 1;
      max = daysInMonth(parts.year, parts.month);
      break;
    case 'month':
      min = 1;
      max = 12;
      break;
    case 'year':
      min = 1200;
      max = 2100;
      break;
    case 'hour':
      min = 0;
      max = 23;
      break;
  }

  const elements = useMemo(() => {
    const items: number[] = [];

    for(let i = min; i <= max; i++) {
      items.push(i);
    }

    return items.map(item => (
      <Item key={`${mode}_${item}`} active={+item === +part} onClick={handleClick}>
        <span>{fillNumber(item)}</span>
      </Item>
    ));
  }, [min, max, part, mode]);

  return (
		<>
			<Container ref={ref} style={{display: mode ? 'block' : 'none'}}>
				{elements}
			</Container>
			{	notification
          ? <Notify
              className='notification'
							type={'warning'}
							title={t("chronos.app.timeControl.buildingsWillBurn")}
							content={t("chronos.app.timeControl.ifUoyChangeDataWarning")}
							buttons={
								<>
									<Button 
										style={{ background: `var(--colors-blue)` }} 
										size="small" 
										onClick={() => openTemporaryMap()}
									>
										{t("chronos.app.instruments.openInTmpMap")}
									</Button>
									<Button
										size="small"
										color="transparent"
										style={{ background: 'transparent', border: 'none', marginLeft: '1rem' }}
										onClick={() => applyChanges() }
									>
										{t("chronos.auth.confirmationEmail.changeData")}
									</Button>
								</>
							}

							onClose={() => onCloseNotification()}
							style={{ top: '-16rem', left: '38%' }}
						/>
          : null
      }
		</>
  );
}

const Container = styled.div`
  position: absolute;
  display: none;

  height: 2rem;
  overflow-x: auto;
  padding: 0 calc(50vw - 1rem);
  box-sizing: border-box;
  white-space: nowrap;

  bottom: 4.5rem;
  left: 0;
  width: 100%;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Item = styled.div<{active?: boolean}>`
  display: inline-block;
  text-align: center;
  transition: transform 0.3s;

  ${p => p.active && css`
    background-color: var(--accent-blue);
    border-radius: 1rem;
    min-height: 1.5rem;

    align-items: center;
    font-size: 1rem;

    &:hover {
      opacity: 0.9;
    }
  `}

  span {
    color: var(--text-primary);
    display: inline-block;
    cursor: pointer;
    user-select: none;
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.5rem;
    transition: transform 0.2s linear; 
    padding: 0.05rem 1rem;

    ${!isTablet && css`
      &:hover {
        opacity: 0.6;
      }
    `}
  }
`;