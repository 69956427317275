import { IPromptDataSign } from '../../types';

import Aquarius from './aquarius';
import Aries from './aries';
import Cancer from './cancer';
import Capricorn from './capricorn';
import Gemini from './gemini';
import Leo from './leo';
import Libra from './libra';
import Pisces from './pisces';
import Sagittarius from './sagittarius';
import Scorpio from './scorpio';
import Taurus from './taurus';
import Virgo from './virgo';


const signs: {
  [id: string]: IPromptDataSign;
} = {
  Aquarius,
  Aries,
  Cancer,
  Capricorn,
  Gemini,
  Leo,
  Libra,
  Pisces,
  Sagittarius,
  Scorpio,
  Taurus,
  Virgo
};

export default signs;
