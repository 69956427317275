import React from 'react';
import { getSign, signs as astroSigns, astroObjects, IObject, getHouseObjects, ObjectType } from 'src/libs';

import { housesIcons, ScrollContainer, CloseIcon, HeaderContainer, Header, SubHeader, Menu, TextBlock, FooterComponent, TextIcon } from './index';
import StickyHeader from './elements/StickyHeader';
import FactsSlider from './elements/FactsSlider';

import { GlobalIcon } from 'src/assets/icons/system';
import { SignIcon } from 'src/assets/icons/system/24';

import { IWidgetData } from './../Widget';
import { ICardPlanetItem, IPromptDataHouse } from './types';

import planets from './data/planets';
import houses from './data/houses';
import signs from './data/signs';
import CardsSlider from './elements/CardsSlider';
import { useTranslation } from 'src/i18n/useTranslation';

const menuItems = [
  { id: 'first', name: "base.description" },
  { id: 'planets', name: "astro.planets" }
];

interface IObjectWithId extends IObject {
  id?: number;
}

function getObjectsByHouse(objects: IObjectWithId[], houseId: number, houses: number[]) {
  const objects_ = objects.filter((object: IObjectWithId, index: number) => {
    object.id = index;
    return index < 10;
  });

  return getHouseObjects(ObjectType.House1 + houseId, houses, objects_, true);
}

function Houses(props: {
  data: IWidgetData;
  onClose: () => void;
  onChanged: (key: string, value: any) => void;
  onScroll: (position: string | number) => void;
}, ref: any) {
  const { t } = useTranslation();
  const housesFromMap = props.data.maps[0].houses;
  const planetsFromMap = props.data.maps[0].objects;

  const houseId = props.data.prompt!.id;
  const house: IPromptDataHouse = houses[houseId];
  const houseName = `${houseId + 1} ${t("astro.house")}`;
  const houseIcon = housesIcons[houseId];

  const planetsInHouse = getObjectsByHouse(planetsFromMap, houseId, housesFromMap);
  const planetsInHouseString = planetsInHouse
    .map((objectId: number) => t(astroObjects[objectId].ru))
    .join(', ');

  const planetsForSlider: ICardPlanetItem[] = planetsInHouse.map(planetId => {
    const planet = planets[astroObjects[planetId].en];
    return {
      img: planet.image,
      title: t(planet.title),
      icon: planet.icon,
      text: planet.description.short
    };
  });

  const signsInHouse = [getSign(housesFromMap[houseId])];
  const signsInHouseString = signsInHouse
    .map((signId: number) => {
      const signNameEn = astroSigns[signId].en;
      const sign = signs[signNameEn];
      return sign.name;
    })
    .join(', ');


  return <>
    <StickyHeader menuItems={menuItems} title={houseName} onClose={props.onClose} scrollTo={props.onScroll} icon={houseIcon} scrollRef={ref} />

    <ScrollContainer ref={ref}>
      <a id="first-block"></a>
      <CloseIcon onClick={props.onClose}></CloseIcon>
      <HeaderContainer>
        <Header>
          <h2><TextIcon>{houseIcon}</TextIcon>{houseName}</h2>
          <SubHeader>
            {planetsInHouse.length > 0 && <div><GlobalIcon /> {planetsInHouseString}</div>}
            <div><SignIcon /> {signsInHouseString}</div>
          </SubHeader>
        </Header>
        <Menu id="menu-block">
          <ul>
            <li className="active" onClick={() => props.onScroll('#first-block')}>{t("base.description")}</li>
            {planetsInHouse.length > 0 && <li onClick={() => props.onScroll('#planets-block')}>{t("astro.planets")}</li>}
          </ul>
        </Menu>
      </HeaderContainer>

      <TextBlock>
        <span dangerouslySetInnerHTML={{ __html: t(house.description.short) }}></span>
      </TextBlock>

      <TextBlock full>
        <FactsSlider items={house.description.facts} />
      </TextBlock>

      <TextBlock>
        <h3>{t("astro.houseDescription")}</h3>
        <span dangerouslySetInnerHTML={{ __html: t(house.description.full) }}></span>
      </TextBlock>

      {planetsForSlider.length > 0 && <TextBlock full id="planets-block">
        <CardsSlider type="planets" items={planetsForSlider} icon={<GlobalIcon/>} title={`${t("astro.planetsIn")} ${houseName}${t("astro.base.endings.pre")}`} />
      </TextBlock>}

      <FooterComponent />

    </ScrollContainer>
  </>;
}

export default React.forwardRef(Houses);

