import React from 'react';
import styled, { css } from 'styled-components';

import { ReactMemo } from 'src/hooks';
import { EditIcon, PlusIcon  } from 'src/assets/icons/system';

interface StyleProps {
  isActive?: boolean;
  activeStyle?: 'color' | 'border' | 'fill';
  hasBorder?: boolean;
  disabled?: boolean;
  isHighlighted?: boolean;
  icon?: React.ComponentType;
}
interface Props extends StyleProps {
  id: string;
  onClick?(ev: any): void;
  hasData?: boolean;
  onEdit?(id: string | null): void;
  onReplace?(from: string, to: string): void;
  onHold?(): void;
  onHoldEnd?(): void;
  isLocked?: boolean;
  hasHoverEffect?: boolean;
  limitedAccess?: boolean;
}

export default ReactMemo(function InstrumentButton(props: Props) {
  const timer = React.useRef(0);
  const mouseDown = React.useRef(false);
  const holded = React.useRef(false);

  const onDragStart = (ev: any) => {
    mouseDown.current = false;
    ev.dataTransfer.setData('id', props.id);
  };

  const onDrop = (ev: any) => {
    ev.preventDefault();
    holded.current = false;
    mouseDown.current = false;
    props.onReplace?.(props.id, ev.dataTransfer.getData('id'));
  };

  const hasPE = !props.isLocked && Boolean(props.onEdit);
  const hasPlus = hasPE && !props.hasData;
  const hasEdit = Boolean(hasPE && props.hasData && props.isActive);
  const Icon = props.icon;

  const onMouseUp = (ev: any) => {
    if (!mouseDown.current) { return }

    mouseDown.current = false;

    clearTimeout(timer.current);

    if (holded.current) {
      holded.current = false;
      props.onHoldEnd?.();
    } else if (hasPlus || hasEdit) {
      !props.disabled && props.onEdit?.(props.id);
    } else {
      props.onClick?.(ev);
    }
  };

  const onMouseDown = () => {
    mouseDown.current = true;

    if (props.onHold) {
      timer.current = window.setTimeout(() => {
        holded.current = true;
        props.onHold?.();
      }, 250);
    }
  };

  React.useEffect(() => {
    window.addEventListener('mouseup', onMouseUp);

    return () => {
      window.removeEventListener('mouseup', onMouseUp);
    };
  });

  return (
    <Container
      isActive={!!props.isActive}
      activeStyle={props.onReplace ? 'border' : props.activeStyle || 'fill'}
      hasBorder={!!props.hasBorder}
      hasEdit={hasPlus || hasEdit}
      hasHoverEffect={props.hasHoverEffect}
      onMouseDown={onMouseDown}
      disabled={props.disabled}
      isHighlighted={props.isHighlighted || false}
    >
      {props.onReplace && !props.isLocked &&
        <p
          draggable={!!props.onReplace}
          onDragStart={onDragStart}
          onDragOver={ev => ev.preventDefault()}
          onDrop={onDrop}
        />
      }
      {Icon && <Icon />}
      {hasPlus && <Action><PlusIcon /></Action>}
      {hasEdit && <Action><EditIcon /></Action>}
    </Container>
  );
});

const Container = styled.div<StyleProps & { hasEdit: boolean; hasHoverEffect?: boolean }>`
  position: relative;
  height: 2.375rem;

  ${props =>
    !props.hasEdit &&
    css`
      width: 2.375rem;
    `}

  ${props =>
    props.hasEdit &&
    css`
      padding: 0 0.4rem;
    `}

  border-radius: 0.25rem;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  border: ${props =>
    props.hasBorder ? '1px solid rgba(255, 255, 255, 0.06)' : 'none'
};
  background-color: var(--element-neutral);
  ${props => props.hasHoverEffect && css`
    :hover {
        border-color: rgba(255, 255, 255, 0.12);
        background-color: var(--element-neutral-hover);
      }
    `}
  &:active {
    border-color: rgba(255, 255, 255, 0.24);
    background-color: var(--element-neutral-focus);
  }

  backdrop-filter: brightness(0.9) blur(3px);

  box-sizing: border-box;

  ${props => props.disabled && css`
    opacity: 0.25;
    cursor: default;

    :hover {
      opacity: 0.25;
    }
  `}

  & svg {
    fill: var(--icon-secondary);
    color: var(--icon-secondary);
    width: 1.125rem;
  }

  ${props => props.isActive && css`
    & svg {
      fill: var(--icon-primary);
    }

    ${props.activeStyle === 'fill' && css`
      background-color: var(--accent-blue) !important;
      & svg {
        fill: var(--colors-white) !important;
      }
    `}

    border: ${props.activeStyle == 'border' ?
    '2px solid var(--accent-blue)'
    : 'none'
};
  `}

  ${props =>
    props.isHighlighted &&
    css`
      & svg {
        fill: var(--accent-blue);
      }
    `}

  & > p {
    position: absolute;
    background-color: transparent;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    border: none;
  }
`;

const Action = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: var(--icon-third);
    width: 1.1rem;
  }
  
  margin-left: 0.35rem;
`;
