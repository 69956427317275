import { makeAutoObservable } from 'mobx';
import { ILinkItem } from 'src/types';
import store from 'src/store'

import { 
  PaymentIcon, 
  DataBaseIcon, 
  NewsIcon 
} from 'src/assets/icons/system';

import { DownloadIcon, ConsultationsIcon, AstroprocessorIcon } from 'src/assets/icons/system/24';
import { InfoIcon } from 'src/assets/icons/notifications';
import { acccesOnlyForRu } from 'src/helpers/permissions';


export default class SideBarSettings {
  private _menu: Array<ILinkItem> = [
    { id: 'm_0', path: '/settings/payments', title: 'settings.payments', Icon: PaymentIcon },
    { id: 'm_1', path: '/settings/personal', title: 'settings.personal', Icon: DataBaseIcon },
    { id: 'm_2', path: '/settings/maps', title: 'settings.maps', Icon: AstroprocessorIcon },
    { id: 'm_2_1', path: '/settings/consultations', title: 'settings.consultations', Icon: ConsultationsIcon },
    { id: 'div', path: '', title: 'divider' },
    { id: 'm_3', path: '/settings/changes', title: 'settings.changes', Icon: NewsIcon },
    { id: 'export', path: '', title: 'settings.downloadCopy', Icon: DownloadIcon },
    { id: 'div', path: '', title: 'divider' },
    { id: 'm_4', path: 'support', title: 'settings.support', Icon: InfoIcon }
  ];

  get menu() {
    // не показывем export карты, если тариф Базовый
    return this._menu
      .filter(({ id }) => ([undefined, 0, 2].includes(store.settings.payments.userTariffId) ? id !== 'export' : true))
  }

  activeMenu = 'payments';

  constructor() {
    makeAutoObservable(this);
  }

  setActiveMenu(active: string): void {
    this.activeMenu = active;
  }
}
