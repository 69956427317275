import { useTranslation } from 'src/i18n/useTranslation';
import React from 'react';
import styled from 'styled-components';

export default function UnderConstruction() {
  const { t } = useTranslation();
  return (
    <Container>
      <section>
        <svg viewBox="0 0 379 112.3"><path d="M61.7 45.9l52.4 66.4h9.4L66.4 40 97.9 0H25.5L57 40 0 112.3h105.4l-43.3-55-31.9 40.6h44.5L68 89.7H46l16.1-20.4 27.5 34.9H15.8l45.9-58.3zM41.3 8.2h40.8L61.7 34 41.3 8.2zm90.2 50.4c0-10.4 6.4-17.5 16.6-17.5 8.8 0 14.8 5.1 15.2 13h-8.9c-.5-3.4-2.7-5.6-6.2-5.6-4.4 0-7.2 3.7-7.2 10.1 0 6.5 2.8 10.2 7.2 10.2 3.2 0 5.6-2 6.2-5.6h8.9c-.4 7.8-6.1 13.1-15.1 13.1-10.5-.3-16.7-7.4-16.7-17.7zm47.2 16.8h-9.4V30.2h9.1v17.3h.4c1.5-4 5-6.4 10.1-6.4 7.1 0 11.7 4.8 11.7 12.7v21.6h-9.4V55.5c0-4.2-2.2-6.6-6-6.6s-6.5 2.6-6.5 6.9v19.6zm29.4-33.9h9.1v5.9h.4c1.2-4.2 4.3-6.4 8.1-6.4 1.8 0 3.4.5 4.6 1.2l-2.8 7.8c-.9-.4-1.9-.8-3.2-.8-3.8 0-6.8 2.8-6.8 7v19.2h-9.4V41.5zm23.6 17.1c0-10.5 6.4-17.5 16.7-17.5s16.7 7 16.7 17.5c0 10.4-6.4 17.5-16.7 17.5s-16.7-7.1-16.7-17.5zm23.8-.1c0-5.9-2.4-10.3-7.1-10.3s-7.2 4.4-7.2 10.3 2.4 10.3 7.2 10.3c4.7 0 7.1-4.4 7.1-10.3zm25.1 16.9h-9.4V41.5h9v6h.4c1.5-3.9 5.2-6.4 10.2-6.4 7.1 0 11.7 4.9 11.7 12.7v21.6H293V55.5c0-4.1-2.2-6.6-6-6.6s-6.4 2.6-6.4 6.9v19.6zm27.8-16.8c0-10.5 6.4-17.5 16.7-17.5s16.7 7 16.7 17.5c0 10.4-6.4 17.5-16.7 17.5-10.3-.1-16.7-7.1-16.7-17.5zm23.8-.1c0-5.9-2.4-10.3-7.1-10.3s-7.2 4.4-7.2 10.3 2.4 10.3 7.2 10.3c4.7 0 7.1-4.4 7.1-10.3zm35.2-6.8c-.5-2.2-2.4-4-5.7-4-2.9 0-5.2 1.3-5.2 3.4 0 1.6 1.1 2.7 4.3 3.4l6.1 1.2c6.6 1.3 9.8 4.3 9.8 9.2 0 6.7-6.3 11.2-15.1 11.2-9 0-14.5-4-15.4-10.5l9.2-.5c.6 2.8 2.8 4.2 6.2 4.2 3.3 0 5.5-1.4 5.5-3.4 0-1.7-1.4-2.8-4.3-3.4l-5.9-1.2c-6.6-1.3-9.8-4.6-9.8-9.7 0-6.5 5.7-10.5 14.4-10.5 8.6 0 13.6 3.9 14.4 10.1l-8.5.5z"></path></svg>
        <h1>{t("chronos.app.components.underConstruction.technicalWork")}</h1>
        <p>{t("chronos.app.components.underConstruction.technicalWork.apologies")}</p>
      </section>
    </Container>
  );
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  background-color: #1f1f1f;
  
  section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    z-index: 1;
  }

  svg {
    height: 2.875em;
    margin-bottom: 1.875rem;
    fill: #ffffff;
    opacity: 0.4;
  }

  h1 {
    color: white;
    font-size: 3rem;
    margin-bottom: 1.215rem;
  }

  p {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.6);
  }
`;
