import { Avatar, Button, IPayType, PaymentCardPicker } from 'src/libs';
import { observer } from 'mobx-react';
import React from 'react';
import api, { ISubscription, s3urls } from 'src/api';
import { getUtmLs } from 'src/helpers/localStorage';
import { getIokaLink } from 'src/helpers/payments';
import { useCurrency } from 'src/hooks';
import { useTranslation } from 'src/i18n/useTranslation';
import AlertScreen from 'src/pages/Settings/sections/payments/AlertScreen';
import DialogView from 'src/pages/Settings/wrappers/DialogView';
import { routes } from 'src/router';
import store from 'src/store';
import styled from 'styled-components';
import { IPaymentCardMethod, initPaymentMethod, paymentMethods } from './helpers';


export default observer(function SelectedTariff({
  tariff,
  payType
}: {
  tariff: ISubscription;
  payType?: IPayType;
}) {
  const { t } = useTranslation();

  const { user: userStore, payments: paymentStore } = store.settings;
  const { avatarUrl } = userStore.profile;
  const { promo: promoStore } = store;
  const {
    successPaymentScreen,
    failPaymentScreen,
    isDowngrade,
    confirmUpgradeTariff,
    robocassaUser,
    expiryDate,
    userTariffId,
    activePayType,
		subscriptions,
  } = paymentStore;

  const isExpired = new Date(expiryDate) < new Date();
  const [isTariffFree] = React.useState<boolean>(promoStore.freeTariffId === tariff.id);
  const [tariffDiscountInfo, setTariffDiscountInfo] = React.useState<string>('');
  const [tariffWithDiscountIds, setTariffWithDiscountIds] = React.useState<number[]>([]);
  const [paymentMethod, setPaymentMethod] = React.useState<IPaymentCardMethod>(initPaymentMethod(userStore?.region?.region));
  const currency = useCurrency(paymentMethod.code, paymentMethod.country);
  const currSum = () => {
		const subscription =  subscriptions.find(subscription => tariff.id === subscription.id && paymentMethod.code === subscription.currencyCode)

		if (subscription) {
			return subscription.sum[activePayType as number]
		} else {
			return tariff.sum[activePayType as number]
		}
	}

  React.useEffect(() => {
    const { id: tariffId } = tariff;
    const eventName =
      tariffId === 2 ? 'astro-sub_Base_open_page' :
        (tariffId === 3 ? 'astro-sub_Adv_open_page' :
          (tariffId === 4 ? 'astro-sub_Prof_open_page' : 'astro-sub_Arch_open_page'))
    //@ts-ignore
    window.dataLayer.push({ 'event': eventName, 'user_id': `${userStore.id}` });
  }, []);

  React.useEffect(() => {
    if (promoStore.shortTariffDiscountInfo.length) {
      setTariffDiscountInfo(promoStore.shortTariffDiscountInfo);
      setTariffWithDiscountIds([...promoStore.tariffWithDiscountIds]);
    }
  }, [
    promoStore.shortTariffDiscountInfo,
    promoStore.tariffWithDiscountIds
  ]);

  const onSuccess = () => {
    paymentStore.getPaymentInfo(true);
    paymentStore.getPaymetList(true);
    paymentStore.setSuccesPaymentScreen(true);

    //@ts-ignore
    window.dataLayer.push({ 'event': 'astro-sub_payment_Success', 'user_id': `${userStore.id}` });
  }

  const onCancel = () => {
    paymentStore.setFailPaymentScreen(true);
  }

  async function pay(method: 'auth' | 'charge') {
    //@ts-ignore
    window.dataLayer.push({ 'event': 'astro-sub_payment_start', 'user_id': `${userStore.id}` });

    await api.payment({
      type: 'subscribe',
      subscription: tariff.id,
      period: payType?.value,
      country: paymentMethod.country,
      language: localStorage.getItem('i18nextLng'),
      backUrl: getIokaLink('back'),
      failUrl: getIokaLink('fail'),
      successUrl: getIokaLink('success'),
      comment: getUtmLs(),
    }, method, {onSuccess, onCancel});
  }

  async function change() {
    try {
      const result = await api.subscriptionChange([tariff.id]);
      paymentStore.setPayInfo(result);
      paymentStore.setSuccesPaymentScreen(true);
    } catch (e) {
      paymentStore.setFailPaymentScreen(true);
    }
  }

  async function paymentAction() {
    // меняем на меньший тариф
    if (userTariffId && !isExpired && isDowngrade) {
      robocassaUser ? pay('auth') : change();
      // меням на больший или продлеваем
    } else if (userTariffId && !isExpired && !isDowngrade) {
      paymentStore.setConfirmUpgradeTariff(true);
      // покупаем первый раз
    } else if (!userTariffId) {
      pay('charge');
    }
  }

  const getLinks = () => {
		switch(paymentMethod.country) {
			case 'kz':
			case 'us':
				return (
					<>
          <a target="_blank" href={s3urls.privacyPolicyKZ}>{t("chronos.app.payments.paymentAgree.2")}</a> {t("base.union")} <a target="_blank" href={s3urls.termsOfUseKZ}>{t("chronos.app.settings.payment.selectedFormations.termOfUse")}</a>
          </>
				)
			default:
				return (
					<>
          <a target="_blank" href={s3urls.privacyPolicyRU}>{t("chronos.app.payments.paymentAgree.2")}</a> {t("base.union")} <a target="_blank" href={s3urls.termsOfUseRU}>{t("chronos.app.settings.payment.selectedFormations.termOfUse")}</a>
          </>
				)
		}
	}

  return (
    <DialogView>
      <StyledAvatar color={tariff.color ?? ''}>
        <Avatar size={64} image={avatarUrl!} />
      </StyledAvatar>
      <h2>{t("chronos.app.settings.payment.selectedFormations.chosenTariff")} <ins style={{ color: tariff.color }}>{t(tariff.name)}</ins></h2>
      <p>{t("chronos.app.settings.payment.selectedFormations.verificationOfInformation")} {isDowngrade ? t("chronos.app.settings.payment.selectedFormations.transitions") : t("chronos.app.settings.payment.selectedFormations.payment")}</p>
      <StyledBlock>
        <h5>{t("chronos.app.settings.payment.selectedFormations.tariff")}</h5>
        <h6>{t(tariff.name)}</h6>
        <p>{tariff.detail && t(tariff.detail)}</p>
      </StyledBlock>
      { !promoStore.freeTariffPromo
        ? <StyledBlock>
          <h5>{t("chronos.app.settings.payment.selectedFormations.payment.nom")}</h5>
          <h6>{payType && t(payType?.title)}</h6>
          <p>{payType && t(payType?.desc)}</p>
        </StyledBlock>
        : null
      }
      {Boolean(promoStore.tariffCncl.id && tariffWithDiscountIds.includes(tariff.id)) && Boolean(tariffDiscountInfo) &&
        <StyledBlock>
          <h5>{t("chronos.app.settings.selectedFormations.promo")}</h5>
          <h6 className='discount-info'>
            <span style={{ textDecoration: promoStore.tariffCncl.canceled ? 'line-through' : 'none' }}>
              {tariffDiscountInfo}
            </span>
            <Button
              size='small'
              onClick={() => promoStore.switchPromo('tariff')}
              style={{ color: promoStore.tariffCncl.canceled ? 'var(--colors-green)' : 'var(--colors-red)' }}
            >
              {promoStore.tariffCncl.canceled ? t("chronos.app.settings.payment.tariff.changeTariff.return") : t("chronos.app.settings.payment.tariff.changeTariff.reset")}
            </Button>
          </h6>
          <p>{t("chronos.app.settings.payment.selectedFormations.oneTimePromoInfo")}</p>
        </StyledBlock>
      }

      <PaymentCardPicker items={paymentMethods}
												 value={paymentMethod}
												 onChange={(value: IPaymentCardMethod) => setPaymentMethod(value)}
												 t={t}
												 showInfo/>

      <SummaryBlock>
        {!isDowngrade &&
          <>
            <h5>{t("chronos.app.settings.payment.selectedFormations.total")}</h5>
            <h6>
              {
                isTariffFree
                  ? '0'
                  : currSum().discount > 0
                      ? <><ins>{currSum().original * (activePayType as number)}</ins> {currSum().final * (activePayType as number)}</>
                      : currSum().original * (activePayType as number)
              } {currency.symbol}</h6>
          </>
        }
        <Button onClick={paymentAction}>{isDowngrade ? t("chronos.app.settings.payment.SelectedTariff.goToTariff") : t("chronos.app.payments.goToPayment")}</Button>
        <p>{t("chronos.app.settings.payment.SelectedTariff.youAgree")}&nbsp;
        {getLinks()}
        </p>
      </SummaryBlock>

      {successPaymentScreen &&
        <AlertScreen state="success" title={isDowngrade ? t("chronos.app.settings.payment.SelectedTariff.transferWasSuccessful") : t("chronos.app.settings.selectedFormations.paymentWasSuccessful")} subTitle={t("chronos.app.settings.selectedFormations.welcome")}>
          <Button onClick={() => {
            paymentStore.setPaymentDetailsVisible(0);
            paymentStore.cancelTariffSelection();
            // history.push(routes.Dashboard.path);
            setTimeout(() => { window.location.href = routes.Dashboard.path }, 0);
          }}>{t("chronos.app.settings.payment.selectedFormations.startEnjojing")}</Button>
        </AlertScreen>
      }

      {failPaymentScreen &&
        <AlertScreen state="fail" title={t("chronos.app.settings.selectedFormations.somethingWentWrong")} subTitle={t("chronos.app.settings.selectedFormations.paymentFailed")}>
          <Button onClick={() => { paymentStore.setFailPaymentScreen(false) }}>{t("chronos.app.settings.payment.selectedFormations.repeatPayment")}</Button>
          <Button onClick={() => { paymentStore.setPaymentDetailsVisible(-1); paymentStore.cancelTariffSelection() }} color="transparent" bordered={true}>{t("chronos.app.settings.payment.selectedFormations.backToSettings")}</Button>
        </AlertScreen>
      }

      {confirmUpgradeTariff &&
        <AlertScreen state="success" title={t("chronos.app.settings.selectedTariff.switchToTariff")} subTitle={t("chronos.app.settings.selectedTariff.currentSubscriptionWillBeReset")}>
          <Button onClick={() => { paymentStore.setConfirmUpgradeTariff(false) }}>{t("base.cancel")}</Button>
          <Button onClick={() => {
            paymentStore.setConfirmUpgradeTariff(false);
            robocassaUser ? pay('auth') : pay('charge');
          }} color="transparent" bordered={true}>{t("base.confirm")}</Button>
        </AlertScreen>
      }
    </DialogView>
  );
});

const StyledAvatar = styled.div<{ color: string }>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 4.875rem;
  height: 4.875rem;
  border: 2px solid ${props => props.color};
  border-radius: 50%;
  overflow: hidden;
`;
const StyledBlock = styled.div`
  position: relative;
  padding: 1.125rem 0;
  border-bottom: 1px solid var(--element-neutral);

  h5 {
    margin: 0 0 0.5rem;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: var(--text-secondary);
  }

  h6 {
    display: flex;
    justify-content: space-between;
    margin: 0;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
    color: var(--text-primary);

    &:first-letter {
      text-transform: uppercase;
    }

    & + p {
      margin: 0.5rem 0 0;
      font-size: 0.875rem;
      line-height: 150%;
      color: var(--text-secondary);
    }

    button {
      position: relative;
      transform: translateY(-50%);
      background: var(--layer-red-color);
      color: var(--accent-red);
      border: none;
      opacity: 0.7;

      &:hover {
        opacity: 1;
        background: var(--layer-red-color);
      }
    }
  }
`;

const SummaryBlock = styled.div`
  position: relative;
  padding: 1rem 0 1.125rem;
  width: 100%;

  h5 {
    margin: 0 0 0.75rem;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: var(--text-secondary);
  }

  h6 {
    margin: 0 0 2rem;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.75rem;

    ins {
      text-decoration: line-through;
      color: var(--text-secondary);
    }
  }

  button {
    width: 100%;
    margin-bottom: 1.125rem;

    & + p {
      margin: 0;
      font-size: 0.75rem;
      line-height: 1.125rem;
      text-align: center;
      color: var(--text-third);

      a {
        color: inherit;
      }

      a:hover {
        text-decoration: none;
      }
    }
  }
`;
