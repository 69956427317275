import React from 'react';
import styled, { css } from 'styled-components';

import { MoreIcon } from 'src/assets/icons/system';

import Tooltip from 'src/components/TooltipWrapper';

import EventEdit from './EventEdit';
import { IEvent } from './types';

import { showOnboarding } from 'src/lib/onboarding';

import onboarding from './onboarding.cfg';
import { useTranslation } from 'src/i18n/useTranslation';

export default function Event(props: {
  index: number;
  event: IEvent;
  allEvents: IEvent[];
  onDelete(): void;
  onSave(ev: IEvent): void;
  onEnabled(v: boolean): void;
  last: boolean;
  failed: boolean;
}) {
  const moreRef = React.useRef(null);

  const { enabled } = props.event;

  const [openedNav, setOpenedNav] = React.useState(false);
  const [edited, setEdited] = React.useState(false);

  const checkedRulesLength = props.event.rules.reduce((sum, rule) => sum + (rule.checked ? 1 : 0), 0);
  const weightDefault = props.allEvents.find(item => item.id === props.event.id)?.weight || 0;
  const weightDelta = props.event.weight - weightDefault;
  const { t } = useTranslation();

  React.useEffect(() => {
    const onBoarding = (ev: Event) => {
      const msg = (ev as CustomEvent).detail;
      if (msg.id == 'event-more') {
        showOnboarding({
          target: moreRef.current,
          summary: t("base.expandedSettings"),
          description: t("base.expandedSettingsInfo")
        });
      }
    };

    const documentClick = (e: MouseEvent) => {
      setOpenedNav(false);
    };

    document.addEventListener('click', documentClick);

    props.last && window.addEventListener('onboarding', onBoarding);

    return () => {
      document.removeEventListener('click', documentClick);
      props.last && window.removeEventListener('onboarding', onBoarding);
    };
  }, []);

  function openNav() {
    setTimeout(() => {
      setOpenedNav(true);
      onboarding.show && showOnboarding();
    }, 0);
  }

  function onSave(event: IEvent) {
    setEdited(false);
    props.onSave(event);
  }

  if (edited) {
    return <EventEdit
      onClose={() => { setEdited(false) }}
      onSave={onSave}
      onDelete={props.onDelete}
      allEvents={props.allEvents}
      type="edit"
      event={props.event}
      index={props.index}
      rulesOpened={true}
    />;
  }

  return <Container failed={props.failed}>
    <EventC disabled={!enabled}>
      <span>{props.index + 1}</span>
      <article>
        <header>
          {t(props.event.name)}
          <WeightContainer>
            <Tooltip text={t("chronos.app.eventRate")}>
              <Weight>{props.event.weight || 0}</Weight>
            </Tooltip>
            {weightDelta > 0 && <WeightDelta green>+{weightDelta}</WeightDelta>}
            {weightDelta < 0 && <WeightDelta red>{weightDelta}</WeightDelta>}
          </WeightContainer>

        </header>
        <i>
          {props.event.date} · {t("chronos.app.enabledRules")}: {checkedRulesLength} {t("base.from")} {props.event.rules.length}
        </i>
      </article>
      <div className="rec-event-more" ref={moreRef} onClick={openNav}><MoreIcon /></div>
      {openedNav &&
        <nav className="rec-event-nav">
          <button onClick={() => props.onEnabled(!enabled)}>{enabled ? t("base.disable") : t("base.turnOn")}</button>
          <button onClick={() => setEdited(true)}>{t("base.edit")}</button>
          <button onClick={() => props.onDelete()}><del>{t("chronos.app.delete")}</del></button>
        </nav>
      }
    </EventC>
  </Container>;
}

const Container = styled.div<{ failed: boolean }>`
  padding: 1rem;
  background: var(--rectification-block-background);
  border: 1px solid ${props => props.failed ? '#EF5350' : 'var(--rectification-block-border)'};
  border-radius: 5px;
  font-size: 1rem;
  line-height: 1rem;
  margin-bottom: 1rem;
  color: var(--rectification-block-color);
`;

const EventC = styled.section<{ disabled: boolean }>`
  position: relative;
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-gap: 1rem;
  align-items: top;

  & > span {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    align-items: center;
    justify-content: center;
    background: var(--rectification-block2-background);
    border: 1px solid var(--rectification-block2-border);
    border-radius: 4px;
    font-size: 0.75rem;
    color: var(--rectification-block2-color);
  }

  & article {
    & > header {
      font-size: 1rem;
      line-height: 1.5rem;
      color: var(--rectification-block2-color);
      margin-bottom: 0.2rem;
    }

    & > i {
      font-style: normal;
      font-size: 0.875rem;
      color: var(--rectification-block-color);
    }

    opacity: ${props => props.disabled ? 0.5 : 1};
  }

  & svg {
    height: 1rem;
    fill: var(--text-third);
    cursor: pointer;

    &:hover {
      fill: var(--text-primary);
    }
  }

  & nav {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 10;
    width: 12rem;
    padding: 5px;
    border-radius: 6px;
    background-color: var(--notifications-background); 
    border: 1px solid var(--rectification-block2-border);

    & button {
      width: 100%;
      padding: 12px;
      text-align: left;
      background: none;
      border: none;
      outline: none;
      font-size: 0.875rem;
      line-height: 0.875rem;
      color: var(--rectification-block2-color);
      cursor: pointer;

      &:hover {
        background: var(--rectification-block2-background-hover);
      }

      & del {
        text-decoration: none;
        color: var(--colors-red) !important;
      }
    }
  }
`;

const WeightContainer = styled.span`
  white-space: nowrap;
`;

const Weight = styled.span`
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  text-align: center;

  margin-left: 0.3rem;
  border-radius: 50%;
  font-size: 0.725rem;
  background: var(--rectification-block-border);
  vertical-align: top;
`;

const WeightDelta = styled.span<{ red?: boolean; green?: boolean }>`
  margin-left: 0.4rem;
  font-size: 0.725rem;
  vertical-align: middle;

  ${props => props.red && css`
    color: var(--colors-red);
  `}

  ${props => props.green && css`
    color: var(--colors-green);
  `}

  & > svg {
    width: 0.725rem;
    vertical-align: middle;
    fill: var(--colors-green);
    pointer-events: none;

    ${props => props.red && css`
      fill: var(--colors-red);
      transform: scaleY(-1);
    `}
  }
`;
