import { IPromptDataAspect } from '../../types';

import Binovile from './Binovile';
import Biquintile from './Biquintile';
import Conjunction from './Conjunction';
import MinusOrPlus from './MinusOrPlus';
import Novile from './Novile';
import Opposition from './Opposition';
import Quincunx from './Quincunx';
import Quintile from './Quintile';
import Semiquintile from './Semiquintile';
import Semisextile from './Semisextile';
import Semisquare from './Semisquare';
import Sesquiquadrate from './Sesquiquadrate';
import Sextile from './Sextile';
import Square from './Square';
import Tredecile from './Tredecile';
import Trine from './Trine';

const aspects: {
  [key: number]: IPromptDataAspect;
} = {};

aspects[Binovile.deg] = Binovile;
aspects[Biquintile.deg] = Biquintile;
aspects[Conjunction.deg] = Conjunction;
aspects[MinusOrPlus.deg] = MinusOrPlus;
aspects[Novile.deg] = Novile;
aspects[Opposition.deg] = Opposition;
aspects[Quincunx.deg] = Quincunx;
aspects[Quintile.deg] = Quintile;
aspects[Semiquintile.deg] = Semiquintile;
aspects[Semisextile.deg] = Semisextile;
aspects[Semisquare.deg] = Semisquare;
aspects[Sesquiquadrate.deg] = Sesquiquadrate;
aspects[Sextile.deg] = Sextile;
aspects[Square.deg] = Square;
aspects[Tredecile.deg] = Tredecile;
aspects[Trine.deg] = Trine;

export default aspects;
