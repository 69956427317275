import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import store from 'src/store';
import styled from 'styled-components';
import Objects from './Objects';
import { IWidgetData } from '../Widget';
import TabPanel, { ITab } from 'src/components/TabPanel';
import { useTranslation } from 'src/i18n/useTranslation';

import { compatibilityToPartner, getModeMetaData, isCompatibility } from '../../utils';
import { CircleMode, isPrognostics, isRelocation, isSynastry } from 'src/libs';

export default observer(function Coordinates(props: { 
  data: IWidgetData, 
  onChanged(key: string, value: any): void; 
}) {
  const { t }  = useTranslation();
  const [selected, setSelected] = useState<string>(props.data.widgetMode);
  const { instruments: InstrumentsStore } = store;

  const Tabs = React.useMemo(() => {
    const { maps, modes = [], form } = props.data;
    const isSingleMode = modes.length === 1;
    
    const synastryMode: boolean = isSynastry(modes[0]);
    const hasCompatibilityMode: boolean = modes.some(isCompatibility);
    const compatibilityMode: CircleMode = modes.find((mode) => isCompatibility(mode)) as CircleMode;
    const prognosticMode: boolean = isPrognostics(modes[0]);
    const relocationMode: boolean = isRelocation(modes[0]);

    const { prognosticsExt, prognosticsNatal } = InstrumentsStore.mapIndicatorCurrent;
    const isHasSynastryPrognostics = Boolean(props.data.form?.syn_prognostics);
    const { compatibilityTransits, compatibilityReversed } = InstrumentsStore.mapIndicatorCurrent;
    
    // по-умолчанию - натал
    let tabs: ITab[] = [{
      title: t("astro.natal"),
      id: 'natal',
      component: Objects({
        planets: !compatibilityReversed ? maps[0].objects : maps[0].objectsExt!,
        houses: !compatibilityReversed ? maps[0].houses : maps[0].housesExt!,
      }),
    }];

    let selectedTab = selected;

    // Синастрия
    if (synastryMode && !hasCompatibilityMode) {
      tabs = modes.map((mode, modeIndex) => {
        return {
          title: t(getModeMetaData(maps[modeIndex].mode, form)!.title ?? ''),
          id: maps[modeIndex].mode,
          component: Objects({
            planets: maps[modeIndex].objects ?? [],
            houses: maps[modeIndex].houses ?? [],
          }),
        };
      });
    } else if (hasCompatibilityMode) {
      if (isSingleMode) {
        const partnerMode = compatibilityToPartner(modes[0]);
        tabs.push({
          title: t(getModeMetaData(partnerMode, props.data.form)!.title ?? ''),
          id: partnerMode,
          component: Objects({
            planets: !compatibilityReversed ? maps[0].objectsExt! : maps[0].objects!,
            houses: !compatibilityReversed ? maps[0].housesExt! : maps[0].houses!,
          }),
        });

        // selectedTab = partnerMode
        
        if (isHasSynastryPrognostics) {
          tabs.push({
            title: t('Транзит'),
            id: 'transit',
            component: Objects({
              planets: maps[0].objectsOuter!,
              houses: maps[0].housesOuter!,
            })
          });
          // selectedTab = 'transit';
        }

      } else {
        const _modes = modes
          .map((mode) => isCompatibility(mode) ? compatibilityToPartner(mode) : mode)
          .filter((mode, index, modes) => modes.indexOf(mode) === index);

        tabs = _modes.map((mode, modeIndex) => {
          return {
            title: t(getModeMetaData(mode, form)!.title ?? ''),
            id: mode,
            component: Objects({
              planets: (!compatibilityReversed ? maps[modeIndex].objects : maps[modeIndex].objectsExt!) || [],
              houses: (!compatibilityReversed ? maps[modeIndex].houses : maps[modeIndex].housesExt!) || [],
            })
          };
        });

        // добавляем вкладку с транзитом из совместимости
        (isHasSynastryPrognostics) && 
          modes.forEach((mode, idx) => {
            if (isCompatibility(mode)) {
              tabs.push({
                title: t('Транзит'),
                id: 'transit',
                component: Objects({
                  planets: maps[idx].objectsOuter!,
                  houses: maps[idx].housesOuter!,
                })
              });
            }
          })
      }

    }

    // прогностика
    if (prognosticMode) {

      const prognosticsTabs = modes.map((mode: CircleMode, idx: number) => {
        return {
          title: t(getModeMetaData(maps[idx].mode, form)!.title ?? ''),
          id: mode,
          component: Objects({
            mode,
            planets: ((prognosticsNatal as any)[mode] ? maps[idx].objectsExt : maps[idx].objects) ?? [], // если в индикаторе карты отключен Natal, то
            houses: ((prognosticsNatal as any)[mode] ? maps[idx].housesExt : maps[idx].houses) ?? [],
          }),
        }
      });
      

      if (prognosticsExt && modes.some(mode => (prognosticsNatal as any)[mode] === true)) {
        tabs = [...tabs, ...prognosticsTabs];
      } else {
        tabs = [...prognosticsTabs];
        selectedTab = modes[0];
      }
    }

    // релокации
    if (relocationMode) {
      tabs = modes.map((mode, modeIndex) => {
        const title = (form?.relocation as any)?.[mode]?.place.name.split(',')[0];

        if (mode === 'relocation_natal') {
          return {
            title: t("astro.natal"),
            id: maps[modeIndex].mode,
            component: Objects({
              mode,
              planets: maps[0].objects,
              houses: maps[0].houses,
            }),
          };
        }

        return {
          title: title,
          id: maps[modeIndex].mode,
          component: Objects({
            mode,
            planets: maps[modeIndex].objectsExt ?? [],
            houses: maps[modeIndex].housesExt ?? [],
          }),
        };
      });
    }


    return (
      <TabPanel
        key={(new Date()).getMilliseconds()}
        tabs={tabs}
        selected={selectedTab}
        onSelect={setSelected}
      />
    );

  }, [props.data.form, props.data.maps, props.data.modes, InstrumentsStore.mapIndicatorCurrent, selected]);

  return <Container>{Tabs}</Container>;
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
