import { nextOnboarding, showOnboarding } from 'src/lib/onboarding.js';
import onboarding from '../onboarding.cfg';

class EventInputTile extends HTMLElement {
  _value = 1;
  _shadow = this.attachShadow({ mode: 'closed' });
  
  get _t() {
    return window.translator;
  }

  minValue = 1;
  maxValue = 5;
  onClose = () => { };
  onEdit = () => { };
  onValueChanged = v => { };

  connectedCallback() {
    const style = new CSSStyleSheet();
    style.replaceSync(`
      :host {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        border-radius: 0.25em;
        background: #4093F4;
        color: white;
        padding: 0.4em 0.5em;
        font-weight: bold;
        user-select: none;
        width: fit-content;
        height: 2em;
        max-width: 50%;
      }

      #content {
        overflow-x: hidden;

        cursor: pointer;
        font-size: 0.9em;
        white-space: nowrap;
        max-width: 180px;
      }

      #value_container {
        display: none;
        margin-right: 0.5em;
      }

      #value {
        margin: 0 0.4em;
      }

      .btn {
        cursor: pointer;
        color: rgba(255, 255, 255, 0.37);
      }

      #close {
        margin-left: 0.5em;
        font-size: 0.8em;
      }

      @media (max-width: 480px) {
        :host {
          max-width: 100%;
          width: 100%;
          margin: 0 !important;
          margin-bottom: 0.25em !important;
        }
        
        #content {
          margin: 0 auto;
        }
      }
    `);
    this._shadow.adoptedStyleSheets = [style];

    this._shadow.innerHTML = /* html*/`
      <span id="value_container">
        <span id="minus" class="btn">&minus;</span>
        <span id="value"></span>
        <span id="plus" class="btn">&plus;</span>
      </span>
      <span id="content"></span>
      <span id="close" class="btn">&#x2716;</span>
    `;

    const { value_container, content, close } = this._shadow.children;
    const { minus, value, plus } = value_container.children;

    const changeValue = diff => {
      const v = Math.max(this.minValue, Math.min(this.maxValue, this._value + diff));
      if (v != this._value) {
        value.textContent = this._value = v;
        this.onValueChanged && this.onValueChanged(v);
        onboarding.show && nextOnboarding('event-input-date');
      }
    };

    minus.onclick = () => changeValue(-1);
    plus.onclick = () => changeValue(1);
    content.onclick = () => this.onEdit && this.onEdit();
    close.onclick = () => this.onClose && this.onClose();

    window.addEventListener('onboarding', this.onBoarding);
  }

  disconnectedCallback() {
    window.removeEventListener('onboarding', this.onBoarding);
  }

  onBoarding = ({ detail: msg }) => {
    if (this.hasAttribute('valuable')) {
      if (msg.id == 'event-input-weight') {
        requestAnimationFrame(() => {
          const { value_container } = this._shadow.children;
          const { value } = value_container.children;
          showOnboarding({
            target: value,
            offsetY: 30,
            next: 'event-input-date',
            summary: this._t.t("chronos.app.instruments.widgetsrectification.eventInput.selectCoefficient"),
            description: this._t.t("chronos.app.instruments.widgetsrectification.eventInput.selectCoefficient.description")
          });
        });
      } else if (msg.id == 'event-input-tile') {
        requestAnimationFrame(() => {
          const { content } = this._shadow.children;
          showOnboarding({
            target: content,
            offsetY: 30,
            next: 'event-input-enter',
            summary: this._t.t("chronos.app.instruments.widgetsrectification.eventInput.toMakeChanges"),
            description: this._t.t("chronos.app.instruments.widgetsrectification.eventInput.toMakeChanges.description")
          });
        });
      }
    }
  };

  show(v) {
    this.style.display = v ? 'flex' : 'none';
  }

  setValue(v) {
    this._value = v;

    const { value_container } = this._shadow.children;

    value_container.style.display = v ? 'flex' : 'none';

    if (v) {
      value_container.children.value.textContent = v;
    }
  }

  setContent(v) {
    const { content } = this._shadow.children;
    content.textContent = v;
  }
}

customElements.define('event-input-tile', EventInputTile);
