import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import NavigationPoints, { selectNavigationPoint } from './NavigationPoints';

import { ChevronRightIcon } from 'src/assets/icons/system/24';
import spaceImage from 'src/assets/illustrations/backgrounds/space.jpg';

import { ICardsSliderProps } from '../types';
import { useTranslation } from 'src/i18n/useTranslation';


const CardsSlider: React.FC<ICardsSliderProps> = ({
  icon,
  title,
  items,
  interval = 9,
  showPoints,
  type = 'simple'
}) => {
  const [current, setCurrent] = useState(0);
  const listRef = useRef(null);
  const barRef = useRef(null);
  const pointsRef = React.createRef<HTMLUListElement>();
  const { t } = useTranslation();
  
  useEffect(() => {
    const timerRef = setTimeout(nextSlide, interval * 1000);
    return () => {
      clearTimeout(timerRef);
    };
  }, [current]);

  useEffect(() => {
    moveSlide(0);
  }, [items]);

  const nextSlide = () => {
    let nextI = current + 1;
    if (nextI === items.length) { nextI = 0 }
    moveSlide(nextI);
  };

  const prevSlide = () => {
    let nextI = current - 1;
    if (nextI < 0) { nextI = items.length - 1 }
    moveSlide(nextI);
  };

  const moveSlide = (nextI: number) => {
    const listEl = listRef.current! as HTMLElement;
    const nextEl = listEl.children[nextI] as HTMLElement;
    const barEl = barRef.current! as SVGElement;
  
    
    barEl.classList.remove('animate');
    setTimeout(() => barEl.classList.add('animate'), 0);

    pointsRef.current && selectNavigationPoint(pointsRef, nextI);

    listEl.style.transform = `translateX(calc(-${nextEl.offsetLeft}px + 3rem))`;
    setCurrent(nextI);
  };

  return <Container>
    <Header interval={interval}>
      <div className="icon">{icon}</div>
      <div className="title">{title}</div>
      <div className="nav" style={{ display: items.length > 1 ? 'block' : 'none' }}>
        <svg className="border" viewBox="0 0 56 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="55" height="31" rx="5.5" />
          <rect ref={barRef} className="bar animate" x="0.5" y="0.5" width="55" height="31" rx="5.5" />
        </svg>
        <div className="buttons">
          <ChevronRightIcon onClick={prevSlide} />
          <ChevronRightIcon onClick={nextSlide} />
        </div>
      </div>
    </Header>

    <ul ref={listRef}>
      {items.map((item: any, i: number) => {
        const Icon = item.icon

        if (type === 'planets') {
          return <PlanetItem key={i} background={spaceImage}>
            <div className="img"><img src={item.img} /></div>
            <div className="text">
              <h5><Icon /> {item.title}</h5>
              {t(item.text)}
            </div>
          </PlanetItem>;
        }
        return <SimpleItem key={i} >
          <div className="icon">{item.icon}</div>
          <div className="text">{t(item.text)}</div>
        </SimpleItem>;

      })}
    </ul>

    {showPoints && <NavigationPoints ref={pointsRef} length={items.length} moveTo={moveSlide} />}

  </Container>;
};

/*
<SimpleItem key={i} background={spaceImage}>
      <div className="icon">{item.icon}</div>
      <div className="text">{item.text}</div>
    </SimpleItem>
*/

const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
  color: var(--text-secondary);

  & > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    white-space: nowrap;
    transition: transform 0.5s;
  }
`;

const Header = styled.header<{ interval: number }>`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  padding: 0 3rem;

  & .icon {
      margin-right: 0.612rem;
    & svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  & .title {
      font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: var(--text-primary);
  }

  & .nav {
      position: relative;
      margin-left: auto;

    & .buttons {
      display: flex;
      padding: 0.25rem;

      & svg {
      cursor: pointer;
        height: 1.5rem;

        &:hover{
      color: var(--text-primary);
        }

        &:first-child {
      transform: rotate(180deg);
        }
      }
    }

    @keyframes cards-animation {
      from {
      stroke-dashoffset: 10.2rem;
      }
      to {
      stroke-dashoffset: 0rem;
      }
    }

    & > svg.border {
      position: absolute;
      width: 100%;
      height: 100%;
      pointer-events: none;

      & rect {
      stroke: var(--input-border);
      }

      & rect.bar {
      stroke: var(--accent-blue);
        stroke-dasharray: 10.2rem;
        stroke-dashoffset: 10.2rem;

        &.animate{
      animation: cards-animation ${props => props.interval}s linear infinite;
        }
      }
    }

  }
`;

const Item = styled.li`
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 1rem;
  padding: 1.125rem;

  border-radius: 5px;
  white-space: initial;
  box-sizing: border-box;

  &:first-child {
      margin-left: 3rem;
  }
`;

const SimpleItem = styled(Item)`
  width: 16.5rem;
  min-height: 16.5rem;

  background: var(--element-neo);
  border: 1px solid var(--element-neutral);

  .icon {
    color: var(--accent-blue);
    font-size: 1.75rem;
    padding-top: 0.25rem;
  }

  .text {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;

const PlanetItem = styled(Item) <{ background: string }>`
  width: 21rem;
  min-height: 27.5rem;

  background-image: url('${props => props.background}');
  background-position: center center;
  background-size: cover;
  color: rgba(255, 255, 255, 0.6);

  .img {
    width: 12.75rem;
    padding: 0.75rem;

    & img {
      width: 100%;
    }
  }

  .text {
    font-size: 1rem;
    line-height: 1.5rem;

    h5 {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      margin-bottom: 0.675rem;

      font-weight: normal;
      font-size: 1rem;
      color: var(--colors-white);

      & svg {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.675rem;

      }
    }
  }
`;


export default CardsSlider;
