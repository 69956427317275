import { IPromptDataAspect } from '../../types';

import { SquareIcon as icon } from 'src/assets/icons/astro/aspects';

const aspect: IPromptDataAspect = {
  type: "Square",
  deg: 90,
  name: "chronos.app.instruments.widgets.prompts.aspects.square.name",
  icon,
  description: {
    list: [
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Square.0"
      },
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Square.1"
      },  
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Square.2"
      },
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Square.3"
      },
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Square.4"
      },
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Square.5"
      },   
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Square.6"
      },
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Square.7"
      },
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Square.8"
      },
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Square.9"
      }
    ]
  }
};

export default aspect;