import dayjs from 'dayjs';
import {goToMap, history} from 'src/router';
import { makeAutoObservable, runInAction } from 'mobx';
import { orderBy } from 'lodash';
import { copyToClipboard } from 'src/utils';
import { IShortFormData } from 'src/api';
// FIXME:
// @ts-ignore
import { TableMode, SortCriterion, plainColumn, CardContextMenuData, ContextMenuAction, ModalData } from '../TableView/react-table';

import { 
  ChronosApi, 
  IFormData, 
  show, 
  IFormLabel, 
  IFolder, 
  toDateTimeString, 
  CircleMode, 
  IAstroHousesSystems, 
  toLocaleISOString, 
  HousesSystem 
} from 'src/libs';
import { defaultTranslator, ITranslator } from 'src/i18n/useTranslation';
import { t } from 'i18next';

const api = new ChronosApi(process.env.REACT_APP_BACKEND as string, process.env.REACT_APP_AUTH as string);

export interface ICard {
  // обязательные общие
  id: number;
  name: string;
  color: string;
  isGroup: boolean;
  isPinned: boolean;
  isTemporary: boolean;
  labels: IFormLabel[];
  searchContent: string;

  // в зависимости от isGroup
  date?: string;
  dateTime?: string;
  place?: string;
  gender?: string;
  avatarUrl?: string | null;
  items?: number;
  folderId?: number | null;
  createAt?: string;
  modified?: string;
  partner?: IShortFormData;
  cosmogram?: boolean;
  synastry?: boolean;
  natal?: any;
  prognostics?: boolean;
  horar?: boolean;
  relocation?: boolean;
  access?: any;

  // для сортировок
  dateSorted?: number; //date milliseconds
  modifiedSorted?: number; // date milliseconds
}

export interface IExtendedCard extends ICard {
  isAddCardRow: boolean;
  focused: boolean;
}

export interface IExtendedLabel extends IFormLabel {
  active: boolean;
}

export interface IGroupCard extends ICard {
  active: boolean;
}


export default class DashboardStore {

  constructor() {
    makeAutoObservable(this);
  }
  private t = defaultTranslator;
  
  async init(t: ITranslator) {
    this.t = t;
    this.loadForms();
  }

  async loadForms() {
    if(this.forms?.length) return;
    
    this.folders = await api.folders();
    this.labels = await api.labels();
    const allForms = await api.forms();

    const cards: ICard[] = [];
    const groupCards = this.folders.map(folder => this.folderToGroupCard(folder));

    allForms?.forEach((form: IShortFormData) => {
      // добавляем карточки в группы
      if(form.folderId) {
        const groupIndex = groupCards.findIndex(({id}) => id === form.folderId);
        if (groupCards[groupIndex]) {
          groupCards[groupIndex].items! += 1;
        } else {
          console.log('group not found - ', form.folderId, form.name, form.id);
        }
      }

      if (form.labels.length) {
        // @ts-ignore
        form.labels = form.labels.map(id => {
          // @ts-ignore
          const label = this.labels.find(l => l.id === id);
          return { ...label };
        });
      }

      cards.push(this.shortFormDataToCard(form));
    });

    runInAction(() => { 
      this.groups = groupCards;
      this.cards = cards;
      this.forms = cards;
      this.sortCriterium = JSON.parse(localStorage.getItem('sort')!);
    });
  }

  private _cards: ICard[] = [];

  // общий список группы + формы-карты
  get cards(): ICard[] {
    if(this._openedGroupId) {
      const filteredCards = this._cards.filter(({ folderId, searchContent, isTemporary }) => !isTemporary && folderId === this._openedGroupId && searchContent.includes(this.searchString));
      // @ts-ignore
      return orderBy(filteredCards, ...this.sortParams);
    } else {
      const filteredCards = this._cards.filter(({ isTemporary, folderId, searchContent }) => {
        const cardsSearchResult = searchContent.includes(this.searchString);
        return !isTemporary && (this.searchString || !folderId) && cardsSearchResult
      });
      const filterdGroups = this._groups.filter(({ searchContent }) => searchContent.includes(this.searchString));
      // @ts-ignore
      return [...orderBy(filterdGroups, ...this.sortParams), ...orderBy(filteredCards, ...this.sortParams)];
    }
  }

  private _sortCriteria: SortCriterion | null = null;

  private _sortedFieldsMap: any = {
    date: 'dateSorted',
    modified: 'modifiedSorted'
  }

  set sortCriterium(criterion: SortCriterion) {
    this._sortCriteria = criterion;
    localStorage.setItem('sort', JSON.stringify(criterion));
  }

  get sortCriterium() {
    return this._sortCriteria;
  }

  get sortParams(): string[][] {
    // получим реальное поле для сортировки или оставим то, что пришло
    const field = this._sortedFieldsMap[this.sortCriterium?.by] || this.sortCriterium?.by || ''
    return [
      ['isPinned', field, 'modifiedSorted'],
      ['desc', this.sortCriterium?.order || '', 'desc']
    ];
  }

  private _searchString: string = '';

  set searchString(val: string) {
    this._searchString = val;
  }
  get searchString() {
    return this._searchString;
  }

  set cards(cards: ICard[]) {
    this._cards = cards;
  }

  private _forms: ICard[] | null = null;

  set forms(forms: ICard[] | null) {
    this._forms = forms;
  }

  get forms(): ICard[] | null {
    if (this._forms !== null) { return this._forms }
    return null
  }
  private _groups: ICard[] = [];
  get groups(): ICard[] {
    return this._groups;
  }
  set groups(groups: ICard[]) {
    this._groups = groups;
  }

  private _folders: IFolder[] = [];
  get folders() : IFolder[] {
    return this._folders;
  }
  set folders(folders: IFolder[]) {
    this._folders = folders;
  }

  private _labels: IFormLabel[] = [];
  get labels() {
    return this._labels;
  }
  set labels(labels: IFormLabel[]) {
    this._labels = labels;
  }

  async addNewLabel(label: IFormLabel) {
    runInAction(() => {
      this._labels = [...this._labels, label];
    })
  }

  updateLabel(label: IFormLabel) {
    const updatedIndex = this._labels.findIndex(({id}) => id === label.id);
    this._labels[updatedIndex] = label;
    // console.log('updated global label -', updatedIndex, this._labels[updatedIndex])
    this._labels = [...this._labels];
  }

  removeLabel(label: IFormLabel) {
    const labelIndex = this._labels.findIndex(({ id }) => id === label.id);
    this._labels.splice(labelIndex, 1);
    this._labels = [...this._labels];
  }

  removeLabelFromCards(label: IFormLabel) {
    this._cards?.forEach((card: ICard) => {
      if (card.labels?.length) {
        const labelIndex = card.labels.findIndex(({ id }) => id === label.id);
        if (labelIndex >= 0) {
          card.labels.splice(labelIndex, 1);
        }
      }
    });
    this.resetCards();
  }

  resetCards(forms?: ICard[]) {
    this._cards = [...(forms || this._cards)];
  }

  getFormById(cardId: number) {
    return this._cards?.find(({id}) => id === cardId)!;
  }

  getGroupCardById(groupId: number) {
    return this._groups?.find(({ id }) => id === groupId)!;
  }

  updateCard(cardId: number, data: ICard) {
    const card = this.getFormById(cardId);
    const updatedCard = { ...card, ...data};
    const cardIndex = this._cards.findIndex(({id}) => id === cardId);

    if (cardIndex !== -1) {
      this._cards[cardIndex] = updatedCard;
      this._cards = [...this._cards];
    }

    if (this._forms) {
      const form = this._forms?.find(f => f.id === cardId);
      if (form) {
        form.isPinned = data.isPinned ?? form.isPinned;
        form.color = data.color ?? form.color;
        this._forms = [...this._forms];
      }
    }
  }

  updateModifiedCard(form: IShortFormData) {
    const card = this.getFormById(Number(form.id));
    if (!card) return;
    this.updateCard(Number(form.id), this.mergeFormDataWithCard(card, form));
  }

  updateGroup(groupId: number, data: IGroupCard) {
    const group = this.getGroupCardById(groupId);
    const cardIndex = this._groups.findIndex(({ id }) => id === groupId) ?? -1;
    const updatedGroup = { ...group, ...data };
    this._groups[cardIndex] = updatedGroup;
    this._groups = [...this._groups];

    const { isPinned: is_pinned, name: title, color } = updatedGroup;
    api.updateFolder(groupId, { is_pinned, title, color } as IFolder);
  }

  dropGroup(groupId: number) {
    const groupIndex = this._groups.findIndex(({ id }) => id === groupId) ?? -1;
    this._groups.splice(groupIndex, 1);
    this._groups = [...this._groups];

    api.deleteFolder(groupId);
  }

  fetchForm(formId: number) {
    return api.form(formId, 0)
  }

  async updateForms(form: IFormData) {
    if (form.id === -1) {
      const { id: newCardId, forms } = await api.addForm({
        ...form,
        natal: {
          ...form.natal,
          place: {
            ...form.natal.place,
            lat: form.natal.place.lat || 0,
            lon: form.natal.place.lon || 0,
          },
        },
      });
      const newForm = (forms as IShortFormData[]).find(({ id }) => id === newCardId)!;
      runInAction(() => {
        this._cards.push(this.shortFormDataToCard(newForm));
        this._forms = this._cards
      })
      goToMap(newCardId)
    } else {
      const { form: updtForm } = await api.updateForm(form);
      runInAction(() => {
        const updatedFormIndex = this._cards.findIndex(({ id }) => Number(id) === Number(form.id));
        this._cards[updatedFormIndex] = this.shortFormDataToCard(updtForm);
      })
    }
    // this.resetCards();
  }

  async dropForm(cardId: number) {
    try {
      await api.deleteForm(cardId);
      runInAction(() => {
        const deletedIndex = this._cards.findIndex(({ id }) => id === cardId);
        if (deletedIndex > -1) {
          this._cards.splice(deletedIndex, 1);
          this._forms = [...this._cards]; 
        }
      })
      this.resetCards();
      await this.init(this.t);
    } catch (e) {
      console.log('Ошибка удаления карты -', e);
    }
  }

  _tableColumns: plainColumn[] = [
    { title: "chronos.app.components.editForm.name", id: 'name', active: true },

    { title: "chronos.app.dashboard.maps", id: 'maps', active: true },
    { title: "chronos.app.store.dashboars.birthDateAndTime", shortTitle: "chronos.app.settings.dateTime", id: 'date', active: true },
    { title: "chronos.app.components.zet.birthPlace", shortTitle: "chronos.app.components.editForm.mainGroup.place", id: 'place', active: true },
    { title: "chronos.app.dashboard.labels", id: 'labels', active: true },

    { title: "chronos.app.components.zet.gender", id: 'gender', active: false },
    // { title: 'Партнер', id: 'partner', active: false },
    // { title: 'Дата создания', id: 'createDate', active: false },
    { title: "chronos.app.store.dashboard.dateOfChanges", id: 'modified', active: false },

    { title: "chronos.app.instruments.widgets.houseFormulas.filter", id: 'filter', active: true }
  ];

  get tableColumns() {
    return this._tableColumns;
  }

  set tableColumns(order: plainColumn[]) {
    this._tableColumns = order;
  }

  // колонки, которые можно выбрать и добавить
  get selectableColumns() {
    return this._tableColumns.filter(({ id }) => !['name', 'filter'].includes(id));
  }
  get tableActiveColumns() {
    return this._tableColumns.filter(({ active }) => active)
      .map((column, idx) => {
        if (this.isGridTableMode) {
          return { ...column, hidden: idx !== 0 }; // в грид режиме скрываем все колонки кроме первой 'name'
        }
        return column;

      });
  }

  // при драге колонки
  updateTableColumnOrder(source: string, target: string) {
    const sourceIndex = this._tableColumns.findIndex(({ id }) => id === source);
    const targetIndex = this._tableColumns.findIndex(({ id }) => id === target);
    [this._tableColumns[targetIndex], this._tableColumns[sourceIndex]] = [this._tableColumns[sourceIndex], this._tableColumns[targetIndex]];
  }
  // при перемещении колонок стрелками из меню
  moveTableColumn(columnId: string, dir: 'left' | 'right') {
    const movedColumnIndex = this._tableColumns.findIndex(({id}) => id === columnId)
    if (dir === 'left') {
      [this._tableColumns[movedColumnIndex - 1], this._tableColumns[movedColumnIndex]] = [this._tableColumns[movedColumnIndex], this._tableColumns[movedColumnIndex -1]];
    } else {
      [this._tableColumns[movedColumnIndex + 1], this._tableColumns[movedColumnIndex]] = [this._tableColumns[movedColumnIndex], this._tableColumns[movedColumnIndex + 1]];
    }
  }

  // добавляем/удаляем колонку
  switchColumn(columnId: string, active: boolean) {
    const columnIndex = this._tableColumns.findIndex(({ id }) => id === columnId);
    this._tableColumns[columnIndex].active = active;
    this._tableColumns = [...this._tableColumns];
  }


  async onContextMenuAction(action: ContextMenuAction, payload?: any) {
    // console.log(`action - ${action}, payload:`, payload);

    switch (action) {
      case 'pin-card': {
        const { cardId } = payload;
        const { isPinned } = this.getFormById(cardId);
        api.setFormPin(cardId, !isPinned);
        this.updateCard(cardId, { isPinned: !isPinned } as ICard);
        break;
      }
      case 'pin-group': {
        const { cardId: groupId } = payload;
        const { name: title, color, isPinned } = this.getGroupCardById(groupId);
        this.updateGroup(groupId, { isPinned: !isPinned } as IGroupCard);
        api.updateFolder(groupId, { is_pinned: !isPinned, title, color } as IFolder);
        break;
      }
      case 'color-card': {
        const { cardId, color } = payload;
        this.updateCard(cardId, { color } as ICard);
        api.setFormColor(cardId, color);
        break;
      }
      case 'color-group': {
        const { cardId: groupId, color } = payload;
        const { name: title, isPinned: is_pinned } = this.getGroupCardById(groupId);
        this.updateGroup(groupId, { color } as IGroupCard);
        api.updateFolder(groupId, { is_pinned, title, color } as IFolder);
        break;
      }
      case 'photo-add': {
        this.showModal({ component: 'photo-editor', payload: { cardId: payload.cardId, file: payload.file } });
        break;
      }
      case 'photo-edit': {
        const card = this.contextMenuOwnerCard; // можно использовать пока открыто контекстное меню
        this.showModal({ component: 'photo-editor', payload: { cardId: payload.cardId, file: card?.avatarUrl } });
        break;
      }
      case 'avatar-update': {
        const { cardId, avatarUrlData: base64, avatarUrl } = payload;
        this.updateCard(cardId, { avatarUrl } as ICard);
        await api.updateFormAvatar(cardId, base64);
        break;
      }
      case 'avatar-delete': {
        const { cardId, avatarUrlData: base64, avatarUrl } = payload;
        this.updateCard(cardId, { avatarUrl: null } as ICard);
        api.deleteFormAvatar(payload.cardId);
        break;
      }
      case 'card-label': {
        const { cardId, action: subAction, label } = payload;
        const card = this.getFormById(cardId);

        if (subAction === 'delete') {
          const updatedLabels = [...card.labels].filter(({ id }) => id !== label.id);
          this.updateCard(cardId, { labels: updatedLabels } as ICard);
          api.deleteFormLabel(cardId, label.id);
          console.log('delete label -', cardId, label);
        }
        else if (subAction === 'add') {
          const updatedLabels = [...card.labels, label];
          this.updateCard(cardId, { labels: updatedLabels } as ICard);
          api.addFormLabel(cardId, label.id);
          console.log('add label -', cardId, label);
        }
        else if (subAction === 'create') {
          const newLabel = await api.addLabel(label) as IFormLabel;
          const updatedLabels = [...card.labels, newLabel];
          this.addNewLabel(newLabel);
          this.updateCard(cardId, { labels: updatedLabels } as ICard);
          console.log('create label -', cardId, label);
        }
        else if (subAction === 'change') {
          const changedLabelIndex = card.labels.findIndex(({ id }) => id === label.id);
          card.labels[changedLabelIndex] = label;
          const updatedLabels = [...card.labels];
          this.updateCard(cardId, { labels: updatedLabels } as ICard);
          this.updateLabel(label);
          api.updateLabel(label.id, label);
          console.log('change label -', cardId, label);
        }
        else if (subAction === 'delete-global') {
          // если удаляемый лейбл есть в карточках, то удаляем его из кароточек
          if (label.active) {
            this.removeLabelFromCards(label);
          }
          this.removeLabel(label);
          api.deleteLabel(label.id);
          console.log('delete-global label -', cardId, label);
        }

        break;
      }
      case 'group-drop': {
        const { cardId: groupId } = payload;
        this.showModal({ component: 'drop-group-confirm', payload: { cardId: groupId } });
        break;
      }
      case 'card-group': {
        this.cardGroupActionHandler(payload);
        break;
      }
      case 'card-share': {
        this.showModal({ component: 'share-form', payload: { cardId: payload.cardId } });
        break;
      }
      case 'link-copy': {
        copyToClipboard(window.location.href)
          .then(() => {
            show({
              type: 'success',
              closable: true,
              timeout: 3000,
              text: t("chronos.app.components.shareForm.copyToClipboard.success")
            });
          })
          .catch(() => {
            show({
              type: 'error',
              closable: true,
              timeout: 3000,
              text: t("chronos.app.settings.profileSteps.noAccessToClipboard")
            });
          });
        break;
      }
      case 'card-edit': {
        const { cardId } = payload;
        this.showModal({ component: 'edit-form', payload: { cardId } });
        break;
      }
      case 'card-add': {
        this.showModal({ component: 'edit-form', payload: { cardId: -1 } });
        break;
      }
      case 'card-drop': {
        const { cardId } = payload;
        this.showModal({ component: 'drop-card-confirm', payload: { cardId } });
        break;
      }
    }
  }

  async cardGroupActionHandler(payload: { cardId: number; action: string; groupId?: number; group?: IGroupCard }) {
    const { cardId, action, group, groupId } = payload;
    if (group) var { id: folderId = undefined, name: title = '', color = '', isPinned: is_pinned = false } = group;
    // const card = this.getFormById(cardId);
    const groupCard = this.getGroupCardById((folderId || groupId)!);

    switch (action) {
      case 'create': {
        //@ts-ignore
        const newFolder = await api.addFolder({ title, color, is_pinned } as IFolder);
        this._groups.push(this.folderToGroupCard(newFolder as IFolder));
        this._groups = [...this._groups];
        break;
      }
      case 'add': {
        const folderUid = (groupId || folderId)!;
        this.updateCard(cardId, { folderId: folderUid } as ICard);
        this.updateGroup(folderUid, { items: groupCard.items! + 1 } as IGroupCard);
        await api.updateFormFolder(cardId, folderUid);
        break;
      }
      case 'delete': {
        this.updateCard(cardId, { folderId: null } as ICard);
        this.updateGroup(group!.id, { items: groupCard.items! - 1 } as IGroupCard);
        // FIXME:
        // @ts-ignore
        await api.updateFormFolder(cardId, null);
        break;
      }
      case 'delete-global': {
        api.deleteFolder(payload.cardId);
        break;
      }

    }
  }

  private folderToGroupCard(f: IFolder): ICard {
    return {
      id: f.id,
      name: f.title,
      color: f.color,
      isGroup: true,
      isTemporary: false,
      isPinned: f.is_pinned,
      labels: [],
      items: 0,
      searchContent: `${f.title}`.toLocaleLowerCase()
    } as ICard;
  }

  private shortFormDataToCard(f: IShortFormData): ICard {
    return {
      id: Number(f.id),
      isGroup: false,
      name: f.name,
      color: f.color,
      isPinned: f.isPinned,
      isTemporary: f.isTemporary,
      labels: f.labels || [],
      folderId: f.folderId,
      searchContent: `${f.name}${toDateTimeString(f.dateTime)}${f.place}}`.toLocaleLowerCase(),

      date: f.dateTime,
      place: f.place,
      gender: f.gender?  f.gender === 'male' ? t("chronos.app.store.dashboard.base.male") : t("chronos.app.store.dashboard.base.female"): '',
      avatarUrl: f.avatarUrl,

      modified: new Date(f.modified).toLocaleDateString(),
      cosmogram: f.cosmogram,
      natal: true, // f.natal,
      synastry: f.synastry,
      prognostics: f.prognostics,
      horar: f.horar,
      access: f.access,
      relocation: f.relocation,

      dateSorted: Date.parse(f.dateTime), // milliseconds
      modifiedSorted: Date.parse(f.modified), // milliseconds
    };
  }

  private mergeFormDataWithCard(card: ICard, f: any): any & ICard {
    const { dateTime, cosmogram } = f;
    return {
      access: f.access,
      date: dateTime,
      place: f.natal?.place?.name || f.place,
      gender: f.natal?.gender === 'male' ? "chronos.app.store.dashboard.base.male" : "chronos.app.store.dashboard.base.female",
      cosmogram,

      synastry: Boolean(f.synastry),
      prognostics: Boolean(f.prognostics),
      horar: Boolean(f.horar),
      relocation: Boolean(f.relocation),

      modified: new Date(f.modified).toLocaleDateString(),
      modifiedSorted: Date.parse(f.modified),
      dateSorted: Date.parse(dateTime),

      searchContent: `${card.name}${toDateTimeString(dateTime)}${f.natal?.place.name ?? f.place}}`.toLocaleLowerCase()
    }
  }

  //
  private _tableMode: TableMode = (localStorage.getItem('dashboardViewMode') as TableMode) || 'grid';

  get tableMode() {
    return this._tableMode;
  }

  set tableMode(mode: TableMode) {
    this._tableMode = mode;
    localStorage.setItem('dashboardViewMode', mode);
  }

  get isGridTableMode() {
    return this._tableMode === 'grid';
  }

  get isRowTableMode() {
    return this._tableMode === 'row';
  }


  /* Context menu */
  private _cardContextMenuData: CardContextMenuData = null;

  get cardContextMenuData(): CardContextMenuData {
    return this._cardContextMenuData;
  }

  setCardContextMenuData(data: CardContextMenuData) {
    this._cardContextMenuData = data;
  }

  get contextMenuOwnerCard() {
    return this.getFormById(this._cardContextMenuData.cardId)
      || this.getGroupCardById(this._cardContextMenuData.cardId);
  }

  // модальное окно
  private _modalData: ModalData = null;

  get modalData() {
    return this._modalData;
  }

  showModal(data: ModalData) {
    this._modalData = {
      component: data.component,
      options: Object.assign({ overlay: true }, data.options),
      payload: data.payload
    };
  }

  hideModal() {
    this._modalData = null;
  }

  // открытие группы
  private _openedGroupId: number | null = null; // { id: 777, name: 'Новая группа', color: '#73B230'}

  get openedGroup() {
    return this._groups.find(({ id }) => id === this._openedGroupId);
  }

  set openedGroupId(groupId: number | null) {
    this._openedGroupId = groupId;
  }

  // быстрое редактирование
  cellsEditMode = false;

  cellEditModeChange(value: boolean) {
    this.cellsEditMode = value;
  }
}


