import React from 'react';
import styled, { css, CSSProperties } from 'styled-components';

import { ReactMemo } from 'src/hooks';

import { MinusIcon } from 'src/assets/icons/system';
import { PlusSlimIcon as PlusIcon } from 'src/assets/icons/system';

export default function Counter({
  value,
  steps,
  onChange,
  style
}: {
  value?: number;
  steps?: Array<number>;
  onChange(val: number): void;
  style?: CSSProperties;
}) {
  const [counter, setCounter] = React.useState(value || steps?.[0] || 0);
  function toStep(dir: number) {
    let nextValue = counter;

    if (steps?.length) {
      const idx = steps.findIndex(it => it === nextValue);
      nextValue = steps[idx + dir];
      nextValue === undefined && (nextValue = steps[idx]);
    } else {
      nextValue = counter + dir;
    }

    setCounter(nextValue);
    onChange(nextValue);
  }

  return (
    <Container style={style}>
      <button onClick={() => { toStep(-1) }}><MinusIcon/></button>
      <div>{counter}</div>
      <button onClick={() => { toStep(1) }}><PlusIcon/></button>
    </Container>
  );
}

const Container = styled.figure`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    flex: 1;
    font-size: 48px;
    line-height: 52px;
    text-align: center;
    min-width: 110px;
  }

  & > button {
    display: flex;
    position: relative;
    appearance: none;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 100%;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:focus{
      outline: none;
    }

    &:first-child svg {
      width: 24px;
      height: 24px;
    }

    & svg {
      width: 1.5rem;
      height: 1.5rem;
      color: var(--text-primary);
    }

    &:hover svg {
      color: var(--text-secondary);
    }
  }
`;
