import { Loader, astroObjects, fillNumber, localTime, ObjectType, signs } from 'src/libs';
import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { ArrowLeftIcon } from 'src/assets/icons/system';

import { aspectsIcons, objectsIcons, signsIcons } from 'src/icons';
import PlanetsTabs from 'src/pages/Instruments/Widgets/Horar/PlanetsTabs';


import { months } from 'src/utils';

import { IWidgetData } from '../Widget';

import horar, { HorarEventType, IHorarEvent } from './horar';
import dayjs from 'dayjs';
import { degToString } from 'src/api';
import { useTranslation } from 'src/i18n/useTranslation';
import { ChevronRightIcon } from 'src/assets/icons/system/24';

const objectsTabs: ObjectType[] = [];

enum TypeConnections {
  Transfer,
  Pick,
  Prohibition
}

interface IConnector {
  type: TypeConnections;
  id: ObjectType;
}

for (let i = ObjectType.Sun; i <= ObjectType.Saturn; i++) {
  objectsTabs.push(i);
}

interface IFilteredEvent {
  ev1: IHorarEvent;
  ev2?: IHorarEvent;
  connector?: IConnector;
  isPossible?: boolean;
}

export default function HorarLight(props: {
  data: IWidgetData;
  onChanged(key: string, value: any): void;
}) {
  const [activeObject1, setActiveObject1] = useState<ObjectType | null>(null);
  const [activeObject2, setActiveObject2] = useState<ObjectType | null>(null);
  const [events, setEvents] = useState<IHorarEvent[] | null>(null);
  const [filteredEvents, setFilteredEvents] = useState<IFilteredEvent[]>([]);
  const { t } = useTranslation();
  useEffect(() => {
    const calculate = async () => {
      const { gmt } = props.data.form.horar!;

      const events = await horar(dayjs(localTime(props.data.form.horar!)));

      events.forEach(ev => ev.m.add(gmt!, 'hours'));
      events.sort((a, b) => a.m.isAfter(b.m) ? 1 : -1);
      events.forEach((ev, i) => ev.id = i);

      setEvents(events);
    };

    calculate();
  }, [props.data.form.horar]);

  const m = useMemo(() => dayjs(props.data.form.horar?.dt).add(-props.data.form.horar?.gmt!, 'hours'), [props.data.form.horar?.dt]);

  useEffect(() => {
    setFilteredEvents([]);
    if (!events || activeObject1 === null || activeObject2 === null) return;

    const [{ objects }] = props.data.maps;

    const evs: IFilteredEvent[] = [];
    const futureEvents: IHorarEvent[] = [];
    const pastEvents: IHorarEvent[] = [];

    events.map(ev => {
      if (ev.m.isAfter(m)) {
        futureEvents.push(ev);
      } else {
        pastEvents.push(ev);
      }
    });
    pastEvents.sort((a, b) => a.m.isAfter(b.m) ? -1 : 1);

    const isSpeedDifferent = (obj1: ObjectType, obj2: ObjectType, connectorId: ObjectType): boolean => {
      const connectorSpeed = Math.abs(objects[connectorId].speed);
      const obj1Speed = Math.abs(objects[obj1].speed);
      const obj2Speed = Math.abs(objects[obj2].speed);

      return obj1Speed > connectorSpeed && obj2Speed > connectorSpeed || obj1Speed < connectorSpeed && obj2Speed < connectorSpeed;
    };

    const getConnectorType = (obj1: ObjectType, obj2: ObjectType, connectorId: ObjectType): TypeConnections => {
      const connectorSpeed = Math.abs(objects[connectorId].speed);
      const obj1Speed = Math.abs(objects[obj1].speed);
      const obj2Speed = Math.abs(objects[obj2].speed);

      if (connectorSpeed > obj1Speed && connectorSpeed > obj2Speed) return TypeConnections.Transfer;
      if (connectorSpeed < obj1Speed && connectorSpeed < obj2Speed) return TypeConnections.Pick;

      if (connectorId <= ObjectType.Mars) return TypeConnections.Transfer;
      return TypeConnections.Pick;
    };

    const emptyBetween = (ev1: IHorarEvent, ev2: IHorarEvent, connectorId: ObjectType): boolean => {
      for (const ev of events.slice(ev1.id! + 1, ev2.id)) {
        if (ev.obj1 && ev.obj2 && [ev.obj1.id, ev.obj2.id].includes(connectorId)) {
          console.log('Планета С:', t(astroObjects[connectorId].ru));
          console.log('Сделала аспект с планетой Д:', t(astroObjects[connectorId === ev.obj1.id ? ev.obj2.id : ev.obj1.id].ru));
          return false;
        }
      }
      return true;
    };

    const thirdAspect = (ev1: IHorarEvent, ev2: IHorarEvent, connectorId: ObjectType): boolean => {
      const startIndex = ev1.id! >= futureEvents[0].id! ? ev1.id! + 1 : futureEvents[0].id;
      const targetPlanet = ev2.obj1!.id === connectorId ? ev2.obj2!.id : ev2.obj1!.id;

      for (const ev of events.slice(startIndex, ev2.id)) {
        if (ev.obj1 && ev.obj2 && [ev.obj1.id, ev.obj2.id].includes(targetPlanet)) {
          console.log('Последняя планета Б:', t(astroObjects[targetPlanet].ru));
          console.log('Сделала аспект с планетой Д:', t(astroObjects[targetPlanet === ev.obj1.id ? ev.obj2.id : ev.obj1.id].ru));
          return false;
        }
      }
      return true;
    };

    const notBeforeAspect = (ev1: IHorarEvent, ev2: IHorarEvent, connectorId: ObjectType): boolean => {
      const startIndex = futureEvents[0].id!;
      const endIndex = ev1.id!;
      const obj1 = ev1.obj1!.id === connectorId ? ev1.obj2!.id : ev1.obj1!.id;
      const obj2 = ev2.obj1!.id === connectorId ? ev2.obj2!.id : ev2.obj1!.id;

      for (const ev of events.slice(startIndex, endIndex)) {
        if (ev.obj1 && ev.obj2 && ([ev.obj1.id, ev.obj2.id].includes(obj1) || [ev.obj1.id, ev.obj2.id].includes(obj2))) {
          console.log('До аспектов в будущем были соединения между А или Б с планетой Д');
          console.log(t(astroObjects[ev.obj1.id].ru));
          console.log(t(astroObjects[ev.obj2.id].ru));
          return false;
        }
      }
      return true;
    };

    let aspectIndex = null;
    // Ищем аспект между планетами
    for (const index in futureEvents) {
      if (aspectIndex !== null) break;

      const ev = futureEvents[index];
      // Если есть аспект между двумя планетами
      if (ev.obj1 && ev.obj2 && [ev.obj1.id, ev.obj2.id].includes(activeObject1) && [ev.obj1.id, ev.obj2.id].includes(activeObject2)) {
        aspectIndex = Number(index);
      }
    }

    let connectorId = null;
    let secondId = null;
    let firstEvent = null;
    let secondEvent = null;

    let isTransfer = false;

    for (const index in aspectIndex !== null ? futureEvents.slice(0, aspectIndex) : futureEvents) {
      const ev = futureEvents[index];

      if (ev.obj1 && ev.obj2 && ([ev.obj1.id, ev.obj2.id].includes(activeObject1) || [ev.obj1.id, ev.obj2.id].includes(activeObject2)) && !firstEvent) {
        connectorId = [activeObject1, activeObject2].includes(ev.obj1.id) ? ev.obj2.id : ev.obj1.id;
        secondId = [ev.obj1.id, ev.obj2.id].includes(activeObject1) ? activeObject2 : activeObject1;
        firstEvent = ev;
      }

      if (firstEvent && secondId !== null && connectorId !== null) {
        if (ev.obj1 && ev.obj2 && [ev.obj1.id, ev.obj2.id].includes(secondId) && [ev.obj1.id, ev.obj2.id].includes(connectorId)) {
          secondEvent = futureEvents[index];
          if (
            emptyBetween(firstEvent, secondEvent, connectorId)
            && thirdAspect(firstEvent, secondEvent, connectorId)
            && notBeforeAspect(firstEvent, secondEvent, connectorId)
          ) {
            break;
          } else {
            secondEvent = null;
          }
        }
      }
    }

    if (
      firstEvent
      && secondEvent
      && connectorId !== null
      // && isSpeedDifferent(activeObject1, activeObject2, connectorId)
    ) {
      isTransfer = true;
      evs.push({
        ev1: firstEvent,
        ev2: secondEvent,
        connector: {
          id: connectorId,
          type: getConnectorType(activeObject1, activeObject2, connectorId)
        }
      });
    }

    if (!isTransfer) {
      firstEvent = null;
      secondEvent = null;
      connectorId = null;
      secondId = null;
      for (const index in aspectIndex !== null ? futureEvents.slice(0, aspectIndex) : futureEvents) {
        const ev = futureEvents[index];

        if (ev.obj1 && ev.obj2 && ([ev.obj1.id, ev.obj2.id].includes(activeObject1) || [ev.obj1.id, ev.obj2.id].includes(activeObject2))) {
          connectorId = [activeObject1, activeObject2].includes(ev.obj1.id) ? ev.obj2.id : ev.obj1.id;
          secondId = [ev.obj1.id, ev.obj2.id].includes(activeObject1) ? activeObject2 : activeObject1;
          secondEvent = futureEvents[index];
        }

        for (const index in pastEvents) {
          const ev = pastEvents[index];

          if (secondEvent && secondId !== null && connectorId !== null) {
            if (ev.obj1 && ev.obj2 && [ev.obj1.id, ev.obj2.id].includes(secondId) && [ev.obj1.id, ev.obj2.id].includes(connectorId)) {
              firstEvent = pastEvents[index];
              if (
                emptyBetween(firstEvent, secondEvent, connectorId)
                && thirdAspect(firstEvent, secondEvent, connectorId)
              ) {
                break;
              } else {
                firstEvent = null;
              }
            }
          }

          if (ev.obj1 && ev.obj2 && ([ev.obj1.id, ev.obj2.id].includes(activeObject1) || [ev.obj1.id, ev.obj2.id].includes(activeObject2)) && !secondEvent) {
            secondEvent = pastEvents[index];
            connectorId = [activeObject1, activeObject2].includes(ev.obj1.id) ? ev.obj2.id : ev.obj1.id;
          }
        }

        if (
          firstEvent
          && secondEvent
          && connectorId !== null
          // && isSpeedDifferent(activeObject1, activeObject2, connectorId)
        ) {
          isTransfer = true;
          evs.push({
            ev1: firstEvent,
            ev2: secondEvent,
            connector: {
              id: connectorId,
              type: getConnectorType(activeObject1, activeObject2, connectorId)
            }
          });
          break;
        } else {
          firstEvent = null;
          secondEvent = null;
          connectorId = null;
          secondId = null;
        }
      }
    }

    if (aspectIndex !== null) {
      let isProhibited = false;
      for (const index in futureEvents.slice(0, aspectIndex)) {
        const ev = futureEvents[index];

        if (ev.obj1 && ev.obj2 && ([ev.obj1.id, ev.obj2.id].includes(activeObject1) || [ev.obj1.id, ev.obj2.id].includes(activeObject2))) {
          if (
            isTransfer
            && firstEvent
            && firstEvent.obj1!.id === ev.obj1.id
            && firstEvent.obj2!.id === ev.obj2.id
            || Number(index) === aspectIndex
          ) break;

          connectorId = [activeObject1, activeObject2].includes(ev.obj1.id) ? ev.obj2.id : ev.obj1.id;

          isProhibited = true;
          evs.push({
            ev1: ev,
            ev2: futureEvents[aspectIndex],
            connector: {
              id: connectorId,
              type: TypeConnections.Prohibition
            }
          });
          break;
        }
      }

      if (aspectIndex !== null && !isTransfer && !isProhibited) {
        evs.push({
          ev1: futureEvents[aspectIndex]
        });
      }
    }

    setFilteredEvents(evs);
  }, [activeObject1, activeObject2, events, m]);

  if (!events) {
    return <Loader />;
  }

  const getEventLayout = (ev: IHorarEvent) => {
    let w: any = null;

    const ObjIcon = objectsIcons[ev.obj];
    const SignIcon = signsIcons[ev.sign] as React.ElementType;

    const dir = ev.m.isAfter(m) ? 1 : -1;

    switch (ev.type) {
      case HorarEventType.Border: {
        w = <>
          <ObjWrapper>
            <ObjIcon />
            {(ev?.speed || 0) < 0 && <sub>{'r'}</sub>}
          </ObjWrapper>

          {ev.dir === -1 && <ArrowRightStyled />}

          <SignWrapper>
            <SignIcon style={{ color: `var(--circle-zodiacs-elements-${signs[ev.sign].element})` }}/>
          </SignWrapper>

          {ev.dir === 1 && <ArrowRightStyled />}

          <span>{`(${degToString(dir * (ev?.dist || 0))})`}</span>
        </>;
        break;
      }

      case HorarEventType.Direction: {
        w = <>
          <ObjWrapper>
            <ObjIcon />
            {(ev?.speed || 0) < 0 && <sub>{'r'}</sub>}
          </ObjWrapper>

          <ChevronRightStyled title={`${t("chronos.app.widgets.horar.changesDirectionTo")} ${ev.dir === -1 ? t("chronos.app.widgets.horar.retrograde") : t("chronos.app.widgets.horar.direct")}`} />

          <Direction>{ev.dir === -1 ? 'r' : 'd'}</Direction>

          <SignWrapper>
            <SignIcon style={{
              color: `var(--circle-zodiacs-elements-${signs[ev.sign].element})`,
              marginLeft: '0.5em'
            }}/>
          </SignWrapper>
          <span>{degToString(ev?.dist || 0)}</span>
        </>;
        break;
      }

      case HorarEventType.Aspect: {
        const ObjIcon = objectsIcons[ev.obj1!.id];
        const OppIcon = objectsIcons[ev.obj2!.id];
        const AspIcon = aspectsIcons[ev.asp!] as React.ElementType;

        w = <>
          <ObjWrapper>
            <ObjIcon />
            {ev.obj1!.speed < 0 && <sub>{'r'}</sub>}
          </ObjWrapper>

          {dir === 1 && <ArrowRightStyled title={t("chronos.app.widgets.horar.formsAnAspect")} />}

          <ObjWrapper>
            <OppIcon />
            {ev.obj2!.speed < 0 && <sub>{'r'}</sub>}
          </ObjWrapper>

          {dir === -1 && <ArrowRightStyled title={t("chronos.app.widgets.horar.formsAnAspect")} />}

          <SignWrapper>
            <AspIcon style={{
              color: `var(--circle-aspects-${ev.asp || 0})`,
              margin: '0 0.5em'
            }}/>
          </SignWrapper>
          <span>{degToString(ev.obj1!.lon % 30)}</span>
          <span>{`(${degToString(dir * (ev?.dist || 0))})`}</span>
        </>;
        break;
      }
    }
    return <Event key={`${ev.m.toISOString()}`}>
      <span>{`${fillNumber(ev.m.date())} ${t(months[ev.m.month()])} ${ev.m.year()}`}</span>
      <span>{ev.m.format('HH:mm:ss')}</span>
      <span>{w}</span>
    </Event>;
  };

  const onChangeHandler = (obj1: ObjectType | null, obj2: ObjectType | null) => {
    setActiveObject1(obj1);
    setActiveObject2(obj2);
  };

  return (
    <Container>
      <PlanetsTabs objects={objectsTabs} activeIds={[activeObject1, activeObject2]} onChanged={onChangeHandler} />

      <Events>
        {filteredEvents.map((ev: IFilteredEvent) => {
          const eventLayout1 = getEventLayout(ev.ev1);
          let eventLayout2;
          let ConnectorIcon;
          let connector;
          if (ev.ev2) {
            eventLayout2 = getEventLayout(ev.ev2);
          }
          if (ev.connector) {
            ConnectorIcon = objectsIcons[ev.connector.id];

            connector = <Event isResult>
              <span>
                {ev.connector.type === TypeConnections.Transfer && t("chronos.app.widgets.title.horarLights")}
                {ev.connector.type === TypeConnections.Pick && t("chronos.app.widgets.horar.gatheringLight")}
                {ev.connector.type === TypeConnections.Prohibition && t("chronos.app.widgets.horar.ban")}
              </span>
              <ObjWrapper>
                <ConnectorIcon />
              </ObjWrapper>
            </Event>;
          }

          return <Group key={`${ev.ev1.m.toString()}`}>
            {eventLayout1}
            {eventLayout2}
            {connector}
          </Group>;
        })}
        {!filteredEvents.length && <Event>
          {activeObject1 === null || activeObject2 === null ? t("chronos.app.widgets.horar.chooseTwoSignifiers") : t("chronos.app.widgets.horar.noAspects")}
        </Event>}
      </Events>
    </Container>
  );
}

const Container = styled.div`
`;

const Events = styled.div`
  display: flex;
  flex-direction: column;
`;

const Event = styled.div<{ isResult?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  > span {
    width: 25%;
    display: flex;
    align-items: center;
    &:nth-last-child(1) {
      width: 50%;
    }
  }

  &:nth-last-child(1) {
    border-bottom: none;
  }
  
  ${props => props.isResult && css`
    svg {
      margin-right: 1rem;
    }
  `}
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--element-neutral);
  &:nth-last-child(1) {
    border-bottom: none;
  }
`;

const ObjWrapper = styled.div`
  position: relative;
  display: flex;

  sub {
    position: absolute;
    bottom: 0;
    right: -0.2em;
    vertical-align: sub;
    color: var(--text-secondary);
  }

  svg {
    width: 1.75em;
    color: var(--text-primary);
  }
`;

const SignWrapper = styled.div`
  /* margin-left: 0.5em; */
  display: flex;
  svg {
    width: 1.75em;
  }
`;

const Direction = styled.div`
  font-size: 1.2em;
  margin-top: -0.25em;
`;

const ArrowStyle = css`
  width: 1.25em;
  margin: 0 0.5em;
  color: var(--text-secondary);
`;

const ChevronRightStyled = styled(ChevronRightIcon) <{ title?: string }>`
  width: 0.75em;
  color: var(--text-secondary);
  margin-left: 1em;
  margin-right: 0.25em;
`;

const ArrowLeftStyled = styled(ArrowLeftIcon)`
  ${ArrowStyle}
`;

const ArrowRightStyled = styled(ArrowLeftIcon) <{ title?: string }>`
    ${ArrowStyle}
    transform: rotate(180deg);
`;
