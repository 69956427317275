import React from 'react';
import styled, { css } from 'styled-components';

import { ReactMemo } from 'src/hooks';

export default ReactMemo(function DialogView({
  children
}: {
  children: React.ReactNode | React.ReactNodeArray;
}) {

  return (
    <ContainerComponent>{children}</ContainerComponent>
  );
});

export const ContainerComponent = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 23.75rem;
  max-width: 28.125rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h2 {
    margin: 1.437rem 0 0.5rem;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.75rem;
    
    ins { text-decoration: none; }

    & + p {
      margin: 0 0 2.125rem;
      font-size: 1rem;
      line-height: 1.5rem;
      color: var(--text-secondary);
    }
  }
`;
