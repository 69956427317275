import React from 'react';

import { ScrollContainer, CloseIcon, HeaderContainer, Header, Menu, TextBlock, FooterComponent } from './index';


import { IWidgetData } from './../Widget';

import aspects from './data/aspects';
import { IObject } from 'src/libs';
import StickyHeader from './elements/StickyHeader';
import { IPromptDataAspect } from './types';
import TW from 'src/ui/Wrappers/TooltipWrapper';
import { useTranslation } from 'src/i18n/useTranslation';

const menuItems = [{ id: 'first', name: "base.description" }];

interface IObjectWithId extends IObject {
  id?: number;
}

function Aspects(props: {
  data: IWidgetData;
  onClose: () => void;
  onChanged: (key: string, value: any) => void;
  onScroll: (position: string | number) => void;
}, ref: any) {

  const aspect: IPromptDataAspect = aspects[props.data.prompt!.id];
  const AspectIcon = aspect.icon;
  const { t } = useTranslation();
  return <>
    <StickyHeader menuItems={menuItems} title={aspect.name} onClose={props.onClose} scrollTo={props.onScroll} icon={<AspectIcon />} scrollRef={ref} />

    <ScrollContainer ref={ref}>
      <a id="first-block"></a>
      <CloseIcon onClick={props.onClose}></CloseIcon>
      <HeaderContainer>
        <Header>
          <h2>{<AspectIcon />}{t(aspect.name)}</h2>
        </Header>
        <Menu id="menu-block">
          <ul>
            <li className="active" onClick={() => props.onScroll('#first-block')}>{t("base.description")}</li>
            <li onClick={() => {}} style={{ 'cursor': 'default' }}><TW text={t("base.functionalityInDev")}>{t("base.configurations")}</TW></li>
          </ul>
        </Menu>
      </HeaderContainer>

      <TextBlock>
        <ul>
          {aspect.description.list.map((item, index) => <li key={index}><span dangerouslySetInnerHTML={{ __html: t(item.text) }}></span></li>)}
        </ul>

      </TextBlock>


      <FooterComponent />

    </ScrollContainer>
  </>;
}

export default React.forwardRef(Aspects);

