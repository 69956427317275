import { IPromptDataSign } from '../../types';

import { PiscesIcon as icon } from 'src/assets/icons/astro/signs';
import image from './img/pisces.png';

const sign: IPromptDataSign = {
  name: "Pisces",
  title: "chronos.auth.planets.neptune.sign",
  icon,
  image,
  nameIn: "chronos.app.instruments.widgets.prompts.signs.pisces.nameIn",
  description: {
    short: "chronos.app.instruments.pisces.descriptionShort1",
    facts: [
      {
        name: "astro.facts.element",
        value: "chronos.app.instruments.widgets.prompts.houses.4.description.facts.4.value"
      },
      {
        name: "chronos.app.instruments.widgets.prompts.signs.aries.description.facts.1.name",
        value: "chronos.app.instruments.widgets.prompts.signs.pisces.description.facts.1.value"
      },
      {
        name: "astro.facts.cross",
        value: "chronos.app.facts.mutable"
      },
      {
        name: "chronos.app.instruments.widgets.prompts.signs.aries.description.facts.3.name",
        value: "chronos.app.instruments.widgets.prompts.signs.cancer.description.facts.3.value"
      }
    ],
    full: "chronos.app.instruments.pisces.descriptionFull1"
  }
};

export default sign;
