import { makeAutoObservable, runInAction } from 'mobx';
import api from 'src/api';
import { CurrencyCode, daysBetweenDates, IPaymentFullInfo, IPaymentCounter, IPaymentStatistics, ISubscription, IPayType } from 'src/libs'
import store from 'src/store';


export type extendedTariff = (ISubscription & { promoSum: { discount: number; original?: number; final?: number; } })

type visibilityType = -1 | 0 | 1;
export type TAmount = 0 | 1 | 5 | 10;

export interface IPaymentInfo {
  amount: string;
  currency: string;
  cardLastFour: string;
  next?: {
    amount: string;
  };
}

const paymentInfoDefault: IPaymentInfo = {
  amount: '0',
  currency: 'USD',
  cardLastFour: '0000'
}

export default class PaymentsStore {
  subscriptions: ISubscription[] = [];
  userSubscriptions: number[] = [];
  expiryDate = '';
  lastPaymentDate = '';
  robocassaUser = false;
  period = 0;
  autoPayment = false;
  activeTariff: number | null = null;
  withDiscount = false;
  amountOfdiscount = 20;
  payment: IPaymentInfo = paymentInfoDefault;

  private _statistics: IPaymentStatistics | null = null;
  get statics() { return this._statistics; }
  
  counters: IPaymentCounter[] = [];
  currentAmount: TAmount = 0;

  private readonly colors: { [key: string]: string } = {
    '1': 'var(--accent-gray)',
    '2': 'var(--accent-blue)',
    '3': 'var(--accent-red)',
    '4': 'var(--accent-magenta)'
  };

  private readonly descs: { [key: string]: string } = {
    1: "chronos.app.settings.payment.descs.1",
    2: "chronos.app.settings.payment.descs.2",
    3: "chronos.app.settings.payment.descs.3",
    4: "chronos.app.settings.payment.descs.4"
  };

  setDiscount(val: boolean): void {
    this.withDiscount = val;
  }


  setPayInfo(data: IPaymentFullInfo): void {
    runInAction(() => {
      this.subscriptions = data.subscriptions;
      this._statistics = data.statistics;
      this.counters = data.counters;
      this.userSubscriptions = data.userSubscriptions;
      this.period = data.period;
      this.expiryDate = data.expiryDate;
      this.robocassaUser = data.robocassaUser;
      this.autoPayment = data.autoPayment;
      this.lastPaymentDate = data.lastPaymentDate;
      this.payment = data.payment;
      // console.log('setPayInfo -', data)
    });
  }


  get adjustedTariffs(): extendedTariff[] {
    const regionCurrencyCode = store.settings.user.region?.currencyCode || 'RUB'

    return [...this.subscriptions]
      .filter(({ id, currencyCode }) => (this.showArchiveTariff || id !== 1) && regionCurrencyCode === currencyCode)
      .sort((a, b) => a.id - b.id)
      .map(tariff => {

        const promoData = store.promo.tariffWithDiscountIds?.includes(tariff.id) ? store.promo.tariffDiscountPromo : undefined;

        return {
          ...tariff,
          color: this.colors[tariff.id],
          detail: this.descs[tariff.id],
          promoSum: {
            discount: (Number(![1, undefined].includes(promoData?.actions.discount) ? promoData?.actions.discount : 0)) * 100,
          }
        };
      });
  }
  get shortTariffs(): extendedTariff[] {
    return this.adjustedTariffs
      // .filter(({ id }) => id !== 1)
      .map(({ id, type, name, sum, color, currencyCode, promoSum }) => ({ id, type, name, sum, color, currencyCode, promoSum }));
  }

  get selectedTariff() {
    return this.adjustedTariffs.find(({ id }) => id === this.activeTariff);
  }

  setActiveTariff(id: number | null): void {
    this.activeTariff = id;
  }

  setAutoPayment(value: boolean): void {
    this.autoPayment = value;
  }

  get isExpiredTariff(): boolean {
    return new Date(this.expiryDate) < new Date();
  }

  get userTariffId(): number {
    return this.isExpiredTariff ? 0 : this.userSubscriptions.slice(-1)[0];
  }

  get userTariffData(): ISubscription {
    return this.adjustedTariffs.find(({ id }) => id === this.userTariffId)!;
  }

  // реально активный тариф - не всегда текущий
  get realActiveTariff(): ISubscription {
    const maxTariffId = Math.max(...this.userSubscriptions);
    return this.subscriptions.find(({ id }) => id === maxTariffId)!;
  }

  get userLastActiveTariffId(): number {
    return this.userSubscriptions.slice(-1)[0];
  }

  // тип оплаты
  payTypes: Array<IPayType> = [
    { title: "chronos.app.settings.payment.monthlyPayment.title", value: 1, desc: "chronos.app.settings.payment.monthlyPayment.desc" },
    { title: "chronos.app.settings.payment.annualPayment", value: 12, badge: '-20%', desc: "chronos.app.settings.payment.annualPayment.desc" }
  ];
  activePayType: number | null = 1;

  get selectedPayType() {
    return this.payTypes.find(({ value }) => value === this.activePayType);
  }

  setActiveType(active: number) {
    this.activePayType = active;
  }

  // возможности тарифов support: [base, extended, professional]
  tariffDetails: Array<any> = [];

  paymentDetailsVisible: visibilityType = 0;

  setPaymentDetailsVisible(val: visibilityType) {
    this.paymentDetailsVisible = val;
  }

  selectedFormationVisible = false;

  setSelectedFormationVisible(value: boolean) {
    this.selectedFormationVisible = value;
  }

  successPaymentScreen = false;
  failPaymentScreen = false;
  confirmUpgradeTariff = false;

  get isDowngrade() {
    const maxTariffId = Math.max(...this.userSubscriptions);
    return this.userTariffId && this.selectedTariff && this.selectedTariff.id <= maxTariffId;
  }

  get isArchiveTariffLast() {
    return this.userSubscriptions.slice(-1)[0] === 1;
  }

  get showArchiveTariff() {
    return this.userTariffId === 1 ||
      this.isArchiveTariffLast && daysBetweenDates(this.expiryDate, new Date().toISOString()) < 3;
  }

  setSuccesPaymentScreen(value: boolean) {
    this.successPaymentScreen = value;
  }

  setFailPaymentScreen(value: boolean) {
    this.failPaymentScreen = value;
  }

  setConfirmUpgradeTariff(value: boolean) {
    this.confirmUpgradeTariff = value;
  }

  cancelTariffSelection() {
    this.setSuccesPaymentScreen(false);
    this.setFailPaymentScreen(false);
    this.setConfirmUpgradeTariff(false);
    this.setActiveTariff(null);
  }


  getCounterSteps(code: CurrencyCode) {
    return this.counters.reduce((acc, { amount, currencyCode }) => {
      if (code === currencyCode) acc.push(amount);
      return acc;
    }, [] as number[]);
  }

  getPriceForCurrentAmount(code: CurrencyCode) {
    return this.counters.find(({ amount, currencyCode }) => code === currencyCode && amount === this.currentAmount)?.price;
  }

  getPriceForAmount(forAmount: TAmount, code: CurrencyCode) {
    return this.counters.find(({ amount, currencyCode }) => code === currencyCode && amount === forAmount)?.price;
  }

  setCurrentAmount(val: TAmount) {
    this.currentAmount = val;
  }

  cancelFormationsSelection() {
    this.setSuccesPaymentScreen(false);
    this.setFailPaymentScreen(false);
    this.setCurrentAmount(0);
    this.setSelectedFormationVisible(false);
  }

  paymentList: Array<{ date: string; sum: number; type: string; text: string }> = [];

  async getPaymetList(force?: boolean) {
    if (!force && this.paymentList.length) { return }

    try {
      const result = await api.paymentList();
      runInAction(() => {
        this.paymentList = result;
      });
      // console.log('fetch payment list ok -', result)
    } catch (e) {
      console.log('fetch payment list err -', e);
    }
  }

  async getPaymentInfo(force?: boolean, loadPromos: boolean = true) {
    // console.trace();
    if (!force && this._statistics) { return }
    try {
      const result = await api.paymentFullInfo();
      this.setPayInfo(result);

      console.log()
      loadPromos && store.promo.addPromos(result.promos || []);
      return result
    } catch (e) {
      console.log('fetch payment info err -', e);
    }
  }

  async getSubscriptionsAll() {
    if (this.subscriptions.length) { return }

    try {
      const result = await api.subscriptions();
      runInAction(() => {
        this.subscriptions = result;
      });
      // console.log('fetch subscriptions ok -', result)
    } catch (e) {
      console.log('fetch subscriptions err -', e);
    }
  }

  async getTariffs() {
    try {
      const data = await api.getTariffs();
      if (data?.length) {
        this.tariffDetails = data;
      }
    } catch (e) {
      console.log(e)
    }
  }

  init() {
    if (this.tariffDetails.length) return;

    this.getTariffs();
  }

  constructor() {
    makeAutoObservable(this);
  }
}
