import React from 'react';
import styled, { css } from 'styled-components';
import Theme, { Themes } from 'src/theme';

import { Button, IPayType } from 'src/libs';

import { ReactMemo } from 'src/hooks';
import { useTranslation } from 'src/i18n/useTranslation';

export default ReactMemo(function ButtonGroup({
  items,
  active,
  onSelect
}: {
  items: Array<IPayType>;
  active: any;
  onSelect(data: any): void;
}) {
  const { t } = useTranslation();
  return (
    <ButtonGroupContainer>
      <ButtonsBox>
        {items.map(({ title, value, badge }) =>
          <ButtonWrapper active={value === active} theme={Theme.actual} key={value}>
            <Button
              size="medium"
              color={value === active ? 'accent' : 'transparent'}
              onClick={() => { onSelect(value) }}
            >
              {t(title)}
              {badge && <ins>{badge}</ins>}
            </Button>
          </ButtonWrapper>)
        }
      </ButtonsBox>
    </ButtonGroupContainer>
  );
});

const ButtonGroupContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  padding: 3px;

  button {
    padding: 0.5rem 3.2rem;
    color: rgba(255, 255, 255, 0.87)
  }

  button + button {
    margin-left: 3px;
    
  }

  ins {
    margin-left: 4px;
    color: var(--accent-blue);
    font-weight: 600;
    text-decoration: none;
  }
`;

const ButtonsBox = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.188rem;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0.438rem;
`;

const ButtonWrapper = styled.div<{ active: boolean; theme: Themes }>`
  button {
    color: ${ p => p.theme === 'dark' ? 'rgba(255, 255, 255, 0.87)' : 'var(--text-primary)' };
  }
  ${props => props.active && css`
    button {
      color: rgba(255, 255, 255, 0.87)
    }
    ins {
      color: var(--text-primary);
    }
  `}
`;
