import { Avatar, Button, PaymentCardPicker } from 'src/libs';
import { observer } from 'mobx-react';
import React from 'react';
import api, { s3urls } from 'src/api';
import { getUtmLs } from 'src/helpers/localStorage';
import { getIokaLink } from 'src/helpers/payments';
import { useCurrency } from 'src/hooks';
import { useTranslation } from 'src/i18n/useTranslation';
import AlertScreen from 'src/pages/Settings/sections/payments/AlertScreen';
import DialogView from 'src/pages/Settings/wrappers/DialogView';
import { history, routes } from 'src/router';
import store from 'src/store';
import Counter from 'src/ui/Counter';
import styled from 'styled-components';
import { IPaymentCardMethod, initPaymentMethod, paymentMethods } from './helpers';
import { TAmount } from '../../store/Payments';
import { Policy } from 'src/components/payment/Policy';


export default observer(function SelectedFormation() {
  const { user: userStore, payments: paymentStore } = store.settings;
  const { promo: promoStore } = store;
  const { avatarUrl } = userStore.profile;
  const { currentAmount, successPaymentScreen, failPaymentScreen } = paymentStore;
  const { l, t } = useTranslation();
  const [paymentMethod, setPaymentMethod] = React.useState<IPaymentCardMethod>(initPaymentMethod(userStore?.region?.region));
  const currency = useCurrency(paymentMethod.code, paymentMethod.country);
	const price = paymentStore.getPriceForCurrentAmount(paymentMethod.code)

  React.useEffect(() => {
    //@ts-ignore
    window.dataLayer.push({ 'event': 'astro_take_more_constructions', 'user_id': `${userStore.id}` })
  }, []);

  async function paymentAction() {
    //@ts-ignore
    window.dataLayer.push({ 'event': 'astro_payment_start', 'user_id': `${userStore.id}` })

    const result = await api.payment({
      type: 'additional',
      counter: currentAmount,
      cart: [],
      country: paymentMethod.country,
      language: localStorage.getItem('i18nextLng'),
      backUrl: getIokaLink('back'),
      failUrl: getIokaLink('fail'),
      successUrl: getIokaLink('success'),
      comment: getUtmLs(),
    }, 'charge');

		if (result.failed) {
			paymentStore.setFailPaymentScreen(true);
		} else {
			paymentStore.getPaymentInfo(true);
      paymentStore.getPaymetList(true);
      paymentStore.setSuccesPaymentScreen(true);
      //@ts-ignore
      window.dataLayer.push({ 'event': 'astro_payment_Success', 'user_id': `${userStore.id}` })
		}
  }

  const getLinks = () => {
		switch(paymentMethod.country) {
			case 'kz':
			case 'us':
				return (
					<>
          <a target="_blank" href={s3urls.privacyPolicyKZ}>{t("chronos.app.payments.paymentAgree.2")}</a> {t("base.union")} <a target="_blank" href={s3urls.termsOfUseKZ}>{t("chronos.app.settings.payment.selectedFormations.termOfUse")}</a>
          </>
				)
			default:
				return (
					<>
          <a target="_blank" href={s3urls.privacyPolicyRU}>{t("chronos.app.payments.paymentAgree.2")}</a> {t("base.union")} <a target="_blank" href={s3urls.termsOfUseRU}>{t("chronos.app.settings.payment.selectedFormations.termOfUse")}</a>
          </>
				)
		}
	}

  return (
    <DialogView>
      <Avatar size={64} image={avatarUrl!} />
      <h2>{t("chronos.app.settings.buildings2")}</h2>
      <p>{t("chronos.app.settings.selectedFormations.selectNumberOfBuilds")}</p>

      <Counter steps={paymentStore.getCounterSteps(paymentMethod.code)} onChange={val => { paymentStore.setCurrentAmount(val as TAmount) }} style={{ height: '3.25rem', marginBottom: '2.215rem' }} />

      { (promoStore.formationCncl.id || promoStore.freeFormationPromo) &&
        <SummaryBlock>
          <h4>{t("chronos.app.settings.selectedFormations.promo")}</h4>
          <h6>
            <span
              style={{ textDecoration: promoStore.formationCncl.canceled ? 'line-through' : 'none' }}
            >
              {t(promoStore.formationCncl.text) || l("chronos.app.settings.isFree")}
            </span>
            {
              !promoStore.freeFormationPromo
                ? <Button
                  size="small"
                  onClick={() => promoStore.switchPromo('formation')}
                  style={{ color: promoStore.formationCncl.canceled ? 'var(--colors-green)' : 'var(--colors-red)' }}
                >
                  {promoStore.formationCncl.canceled ? t("chronos.app.settings.payment.tariff.changeTariff.return") : t("chronos.app.settings.payment.tariff.changeTariff.reset")}
                </Button>
                : null
            }
          </h6>
          <p>{t("chronos.app.settings.payment.selectedFormations.oneTimePromoInfo")}</p>
        </SummaryBlock>
      }

      <PaymentCardPicker items={paymentMethods}
        value={paymentMethod}
        onChange={(value: IPaymentCardMethod) => setPaymentMethod(value)}
        t={t}
        showInfo />

      <SummaryBlock>
        <h4>{t("chronos.app.settings.payment.selectedFormations.total")}</h4>
        {
          promoStore.isFormationDiscount && price
            ? <h5><ins>{price}</ins>&nbsp;{price - (price * (promoStore.formationDiscountValue / 100))}&nbsp;{currency.symbol}</h5>
            : <h5>{price}&nbsp;{currency.symbol}</h5>
        }
        <Button disabled={!currentAmount} onClick={paymentAction}>{t("chronos.app.payments.goToPayment")}</Button>

        <Policy paymentMethod={paymentMethod} />
      </SummaryBlock>

      {successPaymentScreen &&
        <AlertScreen state="success" title={t("chronos.app.settings.selectedFormations.paymentWasSuccessful")} subTitle={t("chronos.app.settings.selectedFormations.welcome")}>
          <Button onClick={() => {
            paymentStore.setPaymentDetailsVisible(0);
            paymentStore.cancelFormationsSelection();
            history.push(routes.Dashboard.path);
          }}
          >{t("chronos.app.settings.payment.selectedFormations.startEnjojing")}</Button>
        </AlertScreen>
      }

      {failPaymentScreen &&
        <AlertScreen state="fail" title={t("chronos.app.settings.selectedFormations.somethingWentWrong")} subTitle={t("chronos.app.settings.selectedFormations.paymentFailed")}>
          <Button onClick={() => { paymentStore.setFailPaymentScreen(false) }}>{t("chronos.app.settings.payment.selectedFormations.repeatPayment")}</Button>
          <Button onClick={() => {
            paymentStore.setPaymentDetailsVisible(-1);
            setTimeout(() => paymentStore.cancelFormationsSelection(), 500);
          }} color="transparent" bordered={true}>{t("chronos.app.settings.payment.selectedFormations.backToSettings")}</Button>
        </AlertScreen>
      }
    </DialogView>
  );
});


const SummaryBlock = styled.div`
  position: relative;
  padding: 1rem 0 1.125rem;
  width: 100%;
  border-top: 1px solid var(--element-neutral);

  h4 {
    margin: 0 0 0.75rem;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: var(--text-secondary);
  }

  h5 {
    margin: 0 0 2rem;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.75rem;

    ins {
      text-decoration: line-through;
      color: var(--text-secondary);
    }
  }

  h6 {
    display: flex;
    justify-content: space-between;
    margin: 0;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.188rem;

    &:first-letter {
      text-transform: uppercase;
    }

    & + p {
      margin: 0.5rem 0 0;
      font-size: 0.875rem;
      line-height: 150%;
      color: var(--text-secondary);
    }

    button {
      position: relative;
      transform: translateY(-50%);
      background: var(--layer-red-color);
      color: var(--accent-red);
      border: none;
      opacity: 0.7;

      &:hover {
        opacity: 1;
        background: var(--layer-red-color);
      }
    }
  }

  > button {
    width: 100%;
    margin-bottom: 1.125rem;

    & + p {
      margin: 0;
      font-size: 0.75rem;
      line-height: 1.125rem;
      text-align: center;
      color: var(--text-third);

      a {
        color: inherit;
      }

      a:hover {
        text-decoration: none;
      }
    }
  }
`;
