import React from 'react';
import styled, { keyframes } from 'styled-components';
import ContentLoader from 'react-content-loader';
import { ArrowLeftIcon } from 'src/assets/icons/system';

import { history, routes } from 'src/router';

import { getDeviceDetect } from 'src/utils';

const { isTablet } = getDeviceDetect();

const FOREGROUND = 'var(--background)';
const BACKGROUND = 'var(--element-neutral)';

const ANIMATION_SPEED = 3;

export const MapPreloader = ({mapSize = 100}: {mapSize?: number}) => {

  return <Container>
    <header>
      <ArrowLeftIcon className="arrow" onClick={() => history.push(routes.Dashboard.path)}/>

      <ContentLoader
        viewBox="0 0 1872 40"
        speed={ANIMATION_SPEED}
        backgroundColor={BACKGROUND}
        foregroundColor={FOREGROUND}
      >
        <rect x="60" y="17" rx="6" ry="6" width="126" height="7" />
        <rect x={60 + 126 + 12} y="17" rx="6" ry="6" width="282" height="7" />
        <rect x={1872 - 147} y="17" rx="6" ry="6" width="126" height="7" />
      </ContentLoader>
    </header>

    <CircleWrapper mapSize={mapSize}>
      <svg viewBox="0 0 800 800">
        <circle cx="400" cy="400" r="320" stroke="var(--circle-zodiacs-border)" fill="var(--circle-zodiacs-background)"></circle>
        <circle cx="400" cy="400" r="280" fill="var(--workspace-background)"></circle>
        <circle cx="400" cy="400" r="220.00000000000003" stroke="var(--circle-border)" fill="var(--circle-internal-background)"></circle>

        <g className="circle">
          <g fill="rgba(var(--circle-zodiacs-elements-fire-rgb), 1)" stroke="rgba(var(--circle-zodiacs-elements-fire-rgb), 1)">
            <path d="
                    M 680.9893003810321 397.54784352495693
                    A 281 281 0 0 0 644.5699505591118 261.62897961092574"
            strokeWidth="2" stroke="inherit" fill="none"></path>
          </g>

          <g fill="rgba(var(--circle-zodiacs-elements-land-rgb), 1)" stroke="rgba(var(--circle-zodiacs-elements-land-rgb), 1)">
            <path d="
                      M 642.1177940840687 257.3817200080422
                      A 281 281 0 0 0 542.6182799919579 157.88220591593128"
            strokeWidth="2" stroke="inherit" fill="none">
            </path>
          </g>

          <g fill="rgba(var(--circle-zodiacs-elements-air-rgb), 1)" stroke="rgba(var(--circle-zodiacs-elements-air-rgb), 1)">
            <path d="
                      M 538.3710203890743 155.43004944088818
                      A 281 281 0 0 0 402.45215647504307 119.01069961896786" strokeWidth="2" stroke="inherit" fill="none"></path>
          </g>

          <g fill="rgba(var(--circle-zodiacs-elements-water-rgb), 1)" stroke="rgba(var(--circle-zodiacs-elements-water-rgb), 1)">
            <path d="M 397.547843524957 119.01069961896786 A 281 281 0 0 0 261.62897961092574 155.43004944088818" strokeWidth="2" stroke="inherit" fill="none"></path>
          </g>

          <g fill="rgba(var(--circle-zodiacs-elements-fire-rgb), 1)" stroke="rgba(var(--circle-zodiacs-elements-fire-rgb), 1)">
            <path d="
                      M 257.38172000804207 157.88220591593128
                      A 281 281 0 0 0 157.8822059159313 257.38172000804207
                    " strokeWidth="2" stroke="inherit" fill="none"></path>
          </g>

          <g fill="rgba(var(--circle-zodiacs-elements-land-rgb), 1)" stroke="rgba(var(--circle-zodiacs-elements-land-rgb), 1)">
            <path d="M 155.43004944088818 261.62897961092574 A 281 281 0 0 0 119.01069961896786 397.5478435249568" strokeWidth="2" stroke="inherit" fill="none"></path>
          </g>

          <g fill="rgba(var(--circle-zodiacs-elements-air-rgb), 1)" stroke="rgba(var(--circle-zodiacs-elements-air-rgb), 1)">
            <path d="
                      M 119.01069961896786 402.452156475043
                      A 281 281 0 0 0 155.43004944088815 538.3710203890741
                    " strokeWidth="2" stroke="inherit" fill="none"></path>
          </g>

          <g fill="rgba(var(--circle-zodiacs-elements-water-rgb), 1)" stroke="rgba(var(--circle-zodiacs-elements-water-rgb), 1)">
            <path d="
                      M 157.88220591593122 542.6182799919578
                      A 281 281 0 0 0 257.3817200080422 642.1177940840687
                    " strokeWidth="2" stroke="inherit" fill="none"></path>
          </g>

          <g fill="rgba(var(--circle-zodiacs-elements-fire-rgb), 1)" stroke="rgba(var(--circle-zodiacs-elements-fire-rgb), 1)">
            <path d="
                      M 261.62897961092574 644.5699505591118
                      A 281 281 0 0 0 397.54784352495705 680.9893003810321
                    " strokeWidth="2" stroke="inherit" fill="none"></path>
          </g>

          <g fill="rgba(var(--circle-zodiacs-elements-land-rgb), 1)" stroke="rgba(var(--circle-zodiacs-elements-land-rgb), 1)">
            <path d="
                      M 402.4521564750431 680.9893003810321
                      A 281 281 0 0 0 538.3710203890741 644.5699505591118
                    " strokeWidth="2" stroke="inherit" fill="none"></path>
          </g>

          <g fill="rgba(var(--circle-zodiacs-elements-air-rgb), 1)" stroke="rgba(var(--circle-zodiacs-elements-air-rgb), 1)">
            <path d="
                      M 542.6182799919579 642.1177940840687
                      A 281 281 0 0 0 642.1177940840686 542.618279991958
                    " strokeWidth="2" stroke="inherit" fill="none"></path>
          </g>

          <g fill="rgba(var(--circle-zodiacs-elements-water-rgb), 1)" stroke="rgba(var(--circle-zodiacs-elements-water-rgb), 1)">
            <path d="
                    M 644.5699505591118 538.3710203890744
                    A 281 281 0 0 0 680.9893003810321 402.452156475043
                  " strokeWidth="2" stroke="inherit" fill="none"></path>
          </g>
        </g>
      </svg>
    </CircleWrapper>

    <footer>
      <ContentLoader
        viewBox="0 0 1872 52"
        speed={ANIMATION_SPEED}
        backgroundColor={BACKGROUND}
        foregroundColor={FOREGROUND}
        style={{ zIndex: 2 }}
      >
        <rect x={12} y={0} rx="6" ry="6" width="42" height="42" />
        <rect x={12 + 42 + 10} y={0} rx="6" ry="6" width="42" height="42" />
        <rect x={12 + (42 + 10) + (42 + 10)} y={0} rx="6" ry="6" width="42" height="42" />

        <rect x={158 + 651} y={0} rx="6" ry="6" width="132" height="42" />
        <rect x={158 + 651 + 132 + 10} y={0} rx="6" ry="6" width="115" height="42" />

        <rect x={1872 - 10 - 6 - 150} y={0} rx="6" ry="6" width="42" height="42" />
        <rect x={1872 - 10 - 6 - 100} y={0} rx="6" ry="6" width="42" height="42" />
        <rect x={1872 - 10 - 6 - 50} y={0} rx="6" ry="6" width="42" height="42" />
        <rect x={1872 - 10 - 6} y={0} rx="2" ry="2" width="6" height="42" />
      </ContentLoader>
    </footer>
  </Container>
};


const Container = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;

  & > header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.5em;
    margin-top: 0.175em;

    & .arrow {
      width: 1.5rem;
      cursor: pointer;
      margin-left: 1rem;
      margin-right: -1rem;
    }
  }

  & > footer {
    width: 100%;
    margin-top: -0.5em;
  }
`;

var rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
`;

const CircleWrapper = styled.div<{mapSize?: number}>`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-grow: 0;

  & > svg {
    width: ${({mapSize = 0}) => mapSize * 1.25}px;
    height: ${({mapSize = 0}) => mapSize * 1.25}px;
  }

  & .circle {
    transform-origin: center;
    animation: ${rotation} 10s linear infinite;
  }
`;
