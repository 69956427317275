//@ts-nocheck
import React, { lazy } from 'react';

const modalComponents: { [key: string]: React.LazyExoticComponent<any> } = {
  'photo-editor': lazy(() => import('./PhotoEditor')),
  'share-form': lazy(() => import('./ShareFormAdapter')),
  'edit-form': lazy(() => import('./EditFormAdapter')),
  'drop-card-confirm': lazy(() => import('./DropCardConfirm')),
  'drop-group-confirm': lazy(() => import('./DropGroupConfirm')),
  'add-partner': lazy(() => import('./AddPartnerConfirm')),
};

export default modalComponents;
