import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import MapsImage from 'src/assets/illustrations/instruments/maps.png';
import MultipleMapsImage from 'src/assets/illustrations/instruments/multiple-maps.png';
import WidgetsImage from 'src/assets/illustrations/instruments/widgets.png';

import { Button as ButtonUI } from 'src/libs';
import Modal from 'src/ui/Modal';
import { t } from 'i18next';

export default function WelcomeInstruments(props: {
  onClose(): void;
}) {
  const [formId, setFormId] = useState(0);

  useEffect(() => {
    window.addEventListener('keyup', setFormIdHandler);
    return () => {
      window.removeEventListener('keyup', setFormIdHandler);
    };
  }, [formId]);

  const setFormIdHandler = (ev: KeyboardEvent) => {
    if (ev.code === 'ArrowLeft' && formId > 0) { return setFormId(prev => prev - 1) }
    if (ev.code === 'ArrowRight' && formId < 2) { return setFormId(prev => prev + 1) }
  };

  const pagination = (index: number) =>
    <Pagination>
      {[0, 1, 2].map(i => <Mark isActive={i === index} onClick={() => setFormId(i)} key={i} />)}
    </Pagination>;

  const form0 = React.useMemo(() =>
    <Wrap>
      <Image src={MapsImage as string} />
      {pagination(0)}
      <Title>{t("chronos.app.components.welcomeInstruments.newWorkspace")}</Title>
      <Description>{t("chronos.app.components.welcomeInstruments.newWorkspace.description")}</Description>
      <ButtonUI bordered color="transparent" onClick={() => setFormId(1)}>{t("chronos.app.components.welcomeInstruments.newWorkspace.whatElseHasChanged")}</ButtonUI>
    </Wrap>
  , []);

  const form1 = React.useMemo(() =>
    <Wrap>
      <Image src={MultipleMapsImage as string} />
      {pagination(1)}
      <Title>{t("chronos.app.components.welcomeInstruments.addSomeCards")}</Title>
      <Description>{t("chronos.app.components.welcomeInstruments.addSomeCards.description")}</Description>
      <ButtonUI bordered color="transparent" onClick={() => setFormId(2)}>{t("chronos.app.components.welcomeInstruments.addSomeCards.continue")}</ButtonUI>
    </Wrap>
  , []);

  const form2 = React.useMemo(() =>
    <Wrap>
      <Image src={WidgetsImage as string} />
      {pagination(2)}
      <Title>{t("chronos.app.components.welcomeInstruments.enableWidgets")}</Title>
      <Description>{t("chronos.app.components.welcomeInstruments.enableWidgets.description")}</Description>
      <ButtonUI bordered onClick={props.onClose}>{t("chronos.app.components.welcomeInstruments.enableWidgets.startToWork")}</ButtonUI>
    </Wrap>
  , []);

  return (
    <Modal onClose={props.onClose}>
      {formId === 0 && form0}
      {formId === 1 && form1}
      {formId === 2 && form2}
    </Modal>
  );
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 414px;
`;
const Image = styled.img`
  width: 338px;
  height: 190px;
  margin-bottom: 30px;
`;
const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`;
const Mark = styled.span<{ isActive: boolean }>`
  background: var(--element);
  border-radius: 3px;
  height: 6px;
  cursor: pointer;
  width: 6px;
  margin: 0 6px;
  
  ${props => props.isActive && css`
    width: 15px;
    background: var(--accent-blue);
  `}
`;
const Title = styled.p`
  font-size: 24px;
  margin: 0;
`;
const Description = styled.p`
  margin-top: 12px;
  text-align: center;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 24px;
  color: var(--text-secondary);
`;
