import React, { useRef } from 'react';
import { render } from 'react-dom';

import api, { ApiError, IAuth, getUTMCommentForCP } from 'src/api';
import { history } from 'src/router';
import { getURL, initialAuth } from 'src/utils';
import theme, { Themes } from 'src/theme';
import { switcherHook, useKeyHook } from 'src/hooks';
import store from 'src/store';

import AppRouter from './components/app/AppRouter';
import AppMetrics from './components/app/AppMetrics';
import Welcome from 'src/components/Welcome';
import UnderConstruction from 'src/components/UnderConstruction';
import { Loader } from 'src/libs';

import 'src/styles/style.scss';
import 'src/styles/print.scss';

import './i18n/i18n';
import { useTranslation } from 'src/i18n/useTranslation';
import { changeLanguage } from 'i18next';
import AppMobile from './components/app/AppMobile';
import AppEmptyPage from './components/app/AppEmptyPage';
import RegionSelectModal from 'src/components/region/RegionSelectModal';
import i18n from './i18n/i18n';
import dayjs from 'dayjs';

import { loadLocale } from 'src/utils';
import { setUtmLs } from './helpers/localStorage';

const TOKEN_LS = 'token';

function getLocalLanguage() {
  const languages = ['ru', 'en', 'es', 'pt'];
  const browserLanguage = window.navigator.language.substring(0, 2).toLowerCase();
  return languages.includes(browserLanguage) ? browserLanguage : 'en';
}

function Main() {
  const [auth, setAuth] = React.useState<IAuth>();
  const [localLng, setLocalLng] = React.useState(false);
  const [translationsInit, setTranslationsInit] = React.useState(false);
  const showWelcome = switcherHook(false);
  const showUnderConstruction = switcherHook(false);
  const showRegionSelect = switcherHook(false);
  const trRef = useRef(false);

  const initLng = async (lng: string) => {
    await loadLocale(lng);
    dayjs.locale(lng);
    setTranslationsInit(true);
  };

  if (
    !showRegionSelect.value && !!store.settings.user.region &&
    (!store.settings.user.region.id || store.settings.user.region.default)
  ) {
    store.settings.banners.viewMainIndiBanner(true);
    setLocalLng(true);
    showRegionSelect.on();
  }

  const t = useTranslation();

  React.useEffect(() => {
    if (localLng) {
      changeLanguage(getLocalLanguage());
    }
  }, [localLng])

  React.useEffect(() => {
    const URL = getURL();
    const sp = URL.searchParams;

    const init = async () => {
      let auth: IAuth | null = null;

      if (sp.has('lang')) {
        sp.delete('lang')
      }

      // авторизация
      if (sp.has(TOKEN_LS)) {
        try {
          auth = await api.signIn(sp.get(TOKEN_LS) ?? '');
          api.token = auth.token;
        } catch (err) {
          const apiError = (err as ApiError);
          if(apiError.message.toUpperCase() === 'BLOCKED') {
            alert('Слишком много обращений, попробуйте через 5 минут')
          } else if(!apiError.skipGoToAuth) {
            api.goToAuth();
          }
        } finally {
          sp.delete(TOKEN_LS);
          window.history.pushState({}, '', URL.toString());
        }
      } else {
        try {
          auth = await api.refreshToken(api.token?.refresh ?? '');
          api.token = auth.token;
        } catch (err) {
          if (sp.has('share')) {
            auth = initialAuth;
          } else if (!(err as ApiError).skipGoToAuth) {
            api.goToAuth();
          }
        }
      }

      if (auth) {
        // выставляем настройки приложения
        store.notifications = auth.notifications;
        store.settings.user.updateShowWelcomeInstruments(auth.showWelcomeInstruments);

        // FIMXE: auth.language - wrong value
        const lng = auth.language;

        lng && changeLanguage(lng);

        auth.id !== -1 && theme.init(auth.theme as Themes)

        if(!sp.has('share') && auth.showWelcome) {
          showWelcome.on();
        }

        auth.isUnderConstruction && showUnderConstruction.on();

        store.settings.user.auth = auth;
        !sp.has('share') && store.nps.setPages(auth.nps2Visited);

        if(auth.region) {
          store.settings.user.setRegion(auth.region);
        }

        let { id } = auth;

        // шэйринг страниц
        if (sp.has('share') && sp.has('id')) {
          const formId = +(sp.get('id') ?? '');
          store.shareId = formId;

          const form = await api.form(formId);
          if (form) {
            if (auth.id === -1) { theme.init(form.theme as Themes) }
            id = form.userId;
          }
        }

        if (typeof id !== 'undefined') {
          await store.initSettings(id)
          await store.initCommon(id, t);
        }

        setAuth(auth);

        const newLng = auth.language || getLocalLanguage();

        // if (newLng === i18n.language) {
        //   initLng(newLng);
        // } else {
        //   trRef.current = true;
        //   changeLanguage(newLng);
        // }

        initLng(newLng);

        if (sp.has('promo')) {
          store.promo.tryApplyPromoCode(sp.get('promo') ?? '');
          sp.delete('promo');
        }
      }

      const urlUTM = getUTMCommentForCP()
      urlUTM && setUtmLs(urlUTM)

      const spString = sp.toString();
      history.push(`${window.location.pathname}${spString ? `?${spString}` : ''}`);
    };

    i18n.on('initialized', init);

    i18n.on('loaded', async () => {
      if (trRef.current) {
        await initLng(i18n.language);
      }
    });

    const unlisten = history.listen((loc: any, action: string) => {
      store.setBasicPage(loc, action);
      switch (loc.pathname) {
        case '/consultations':
          store.nps.visitPage('calendar');
          break;
        case '/instruments':
          !sp.has('share') && store.nps.visitPage('instruments');
          break;
        case '/dashboard':
          store.nps.visitPage('dashboard');
      }
    });

    return () => unlisten();
  }, []);

  React.useEffect(() => {
    if(showRegionSelect.value && location.pathname !== '/dashboard') {
      history.push('/dashboard');
    }
  }, [showRegionSelect.value])

  useKeyHook(['KeyL'], {
    onKeyDown: () => {
      if (store.settings.user.isLimitedAccess) return
      history.push('/dashboard');
    }
  }, []);

  const closeRegionSelect = async () => {
    setLocalLng(false)
    await api.changeLanguage(i18n.language);
    if (store.notifications.length) {
      const res = await api.refreshToken(`${JSON.parse(localStorage.getItem(TOKEN_LS)!).refresh}`);
      store.notifications = res.notifications;
    }
    await i18n.changeLanguage(i18n.language);
    store.updateLanguage();

    showRegionSelect.off();
    // тут надо сохранить на бек что больше не показываем это окно
  };


  if (!auth || !translationsInit) {
    return <AppEmptyPage><Loader /></AppEmptyPage>;
  }

  if (showUnderConstruction.value && localStorage.getItem('developer') !== 'true') {
    return <AppEmptyPage><UnderConstruction /></AppEmptyPage>;
  }

  return <>
    <AppMobile />
    <AppMetrics auth={auth} />
    <AppRouter auth={auth} />

    {/*<NPS />*/}
    {showRegionSelect.value && <RegionSelectModal onClose={closeRegionSelect} />}
    {showWelcome.value && <Welcome onClose={showWelcome.off} /> }
  </>;
}

render(<Main />, document.querySelector('#root'));
