
import React, { MouseEventHandler } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { ReactMemo, useKeyHook } from 'src/hooks';

import { debounce } from 'lodash';

import { Input, Button } from 'src/libs';
import { useTranslation } from 'src/i18n/useTranslation';


export default ReactMemo(function InputForm({
  placeholder = "chronos.app.dashboard.startInput",
  initValue = '',
  onFormSubmit,
  onTextInput,
  inputDebounce = 0
}: {
  placeholder?: string;
  initValue?: string;
  onFormSubmit?: (val: string) => void;
  onTextInput?: (val: string) => void;
  inputDebounce?: number;
}) {
  const [inputValue, setInputvalue] = React.useState<string>(initValue);
  const { t } = useTranslation();
  const debouncedInput = React.useMemo(() => debounce((value: string) => {
    onTextInput?.(value);
  }, inputDebounce), []);

  const submitForm = React.useCallback((evt: any) => {
    evt.preventDefault();
    onFormSubmit?.(inputValue);
  }, [inputValue]);

  React.useEffect(() => {
    debouncedInput(inputValue);
  }, [inputValue]);

  return (
    <InputWrapper onSubmit={evt => {
      evt.preventDefault();
      onFormSubmit?.(inputValue);
    }}>
      <Input className="input" placeholder={t(placeholder)} onChange={setInputvalue} value={inputValue} />
      <Button size="small" type='submit'>Enter</Button>
    </InputWrapper>
  );
});

const InputWrapper = styled.form`
  position: relative;
  margin-bottom: 0.375rem;

  input {
    padding: 0.5rem 3.8rem 0.5rem 0.625rem;
    border-color: var(--input-border);
  }

  button {
    position: absolute;
    right: 0.438rem;
    top: 50%;
    transform: translateY(-50%);
    padding: 0.25rem 0.5rem;
    border: none;
    background-color: var(--element-neo);
    color: var(--text-secondary);
    z-index: 2;
  }
`;
