import React from 'react';
import styled from 'styled-components';

import api, {  commonSuffixes, getSuffix, commonSuffixesTr } from 'src/api';

import { IShortFormData, Button, Loader } from 'src/libs';
import { ReactComponent as Svg } from 'src/assets/illustrations/export.svg';
import { useTranslation } from 'src/i18n/useTranslation';
import { writeExcelFile } from 'src/components/Import/writeExcelFile';

export default function Export(props: {
  onClose(): void;
}) {
  const [forms, setForms] = React.useState<IShortFormData[] | null>(null);
  const { t } = useTranslation();
  const onExport = () => {
    const data = writeExcelFile(forms!);
    props.onClose();
  };

  React.useEffect(() => {
    api.forms().then(forms => setForms(forms.filter(f => !f.isTemporary)));
  }, []);

  if (!forms) return <Loader />;

  return <Container>
    <Svg />

    <h2>{t("chronos.app.settings.export.makeABackup")}</h2>

    {forms.length !== 0 ?
      <>
        <p>{t("chronos.app.settings.export.canDownloadDataBase")} <span>{forms.length} {getSuffix(forms.length, commonSuffixesTr.forms.map(str => t(str)))}</span>{t("chronos.app.settings.export.downloadNow")}</p>

        <Buttons>
          <Button
            onClick={onExport}
            color="#4093F4"
            disabled={!forms.length}
          >{t("chronos.app.settings.export.downloadDatabase")}</Button>
          <Button
            onClick={props.onClose}
            color="transparent"
          >{t("chronos.app.settings.export.doNotWantIt")}</Button>
        </Buttons>
      </> :
      <p>{t("chronos.app.settings.export.dataBaseInExel")}</p>
    }
  </Container>;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 1em;
  max-width: 25em;
  text-align: center;

  p {
    color: var(--text-secondary);
    margin-bottom: 2em;

    span {
      color: #4093F4;
    }
  }
`;

const Buttons = styled.div`
  display: flex;
`;
