import React from "react";
import styled, { css } from "styled-components";

import { debounce, isEqual } from "lodash";
import { useAsyncMemo } from 'use-async-memo';

import astro from 'src/astro';
import { degToString, CircleMode, getSign, isSynastry, signs, Input, Checkbox, Switcher, Tooltip, CircleLoader } from 'src/libs';
import { SearchIcon, ShevronDownIcon } from 'src/assets/icons/system';
import { InfoIcon1 } from 'src/assets/icons/notifications';
import { CloseSmallIcon } from "src/assets/icons/system";
import { formatMessage } from 'src/utils';
import MapModeSelector from './MapModeSelector';

import { useTranslation } from 'src/i18n/useTranslation';
import i18n from 'src/i18n/i18n';

export default React.memo(function StarCatalog({
  mode = 'natal' as CircleMode,
  list = [],
  selected = {},
  dateTime,
  withModeSelector,
  width = 'auto',
  height = '',
  onSelect,
}: {
  mode?: CircleMode;
  list: any[];
  selected: {[key: string]: any};
  dateTime: string;
  withModeSelector?: boolean;
  width?: string;
  height?: string;
  onSelect: (mode: string, list: string[], showWithObjects: boolean) => void
}) {
  const { t } = useTranslation();
  // const [mode, setMode] = React.useState<string>('natal');

  const isRu = i18n.language === 'ru';
  const LS_KEY = '_s_c';
  const modesList = ['natal', 'synastry', 'horar'];

  const degreeMessage = withModeSelector // компонент открыт в общих настройках
    ? t('chronos.app.settings.astro.degreeMessage')
    : ( mode === 'natal'
      ? t('chronos.app.settings.astro.degreeMessageNatal')
      : (isSynastry(mode)
        ? t('chronos.app.settings.astro.degreeMessageSynastry')
        : t('chronos.app.settings.astro.degreeMessageHorar')
      )
    )
  const debouncedOnSelect = React.useCallback(debounce(onSelect, 1000), []);
  
  const sortRows = (key: 'name' | 'constellation', dir: 'asc' | 'desc') => (star1: any, star2: any) => {
    const nameKey = key === 'name' 
      ? (i18n.language === 'ru' ? 'nameRu' : 'nameEn')
      : (i18n.language === 'ru' ? 'constellationRu' : 'constellation');
    return star1[nameKey] === star2[nameKey] 
        ? 0 
      : ((star1[nameKey] > star2[nameKey]) 
        ? (dir === 'asc' ? 1 : -1) 
        : (dir === 'asc' ? -1 : 1));
  };

  const scrollContainer = React.useRef<HTMLDivElement>(null);

  const [update, setUpdate] = React.useState<boolean>(false);
  const [localMode, setLocalMode] = React.useState<string>(mode);
  const [searchString, setSearchString] = React.useState<string>('');
  const [searchStringResult, setSearchStringResult] = React.useState<string>('');
  const [sortByStar, setSortByStar] = React.useState<boolean | undefined>();
  const [sortByConstellation, setSortByConstellation] = React.useState<boolean | undefined>();

  const [showWithObjects, setShowWithObjects] = React.useState<{ [key: string]: boolean }>(
    modesList.reduce((acc, mode, i) => {
      (acc as any)[mode] = selected[mode]?.showWithObjects ?? true
      return acc;
    }, {})
  );
  
  const [checkedAll, setCheckedAll] = React.useState<{[key: string]: boolean | undefined}>({});

  const [checkedItems, setCheckedItems] = React.useState<{[key: string]: number[]}>({});

  const [partialChecked, setPartialChecked] = React.useState<boolean>(false)

  


  const starList = useAsyncMemo(async (): Promise<{ horar: any[], other: any[] }> => {

    const lsResult = window.localStorage.getItem(LS_KEY);
    const { time, data } = JSON.parse(lsResult || '{}');
    
    if (false) { //time && data && (dateTime === time)
    
      return data;
    
    } else {

      let horarList = list
        .filter((star) => star.isHorar)
        .sort(sortRows('name', 'asc'));

      let otherList = list
        .sort(sortRows('name', 'asc'));

      const horarResult = [];
      for (const star of horarList) {
        let { lon } = await astro.star(dateTime, star.astroName);
        const sign = getSign(lon);
        // console.log(`*** calc horar star ${star.astroName} lon: ${lon} sign: ${t(signs[sign].en)}`)
        
        horarResult.push({
          ...star,
          lon: degToString(lon),
          degreeFromSign: degToString(lon % 30),
          signRu: t(signs[sign].ru),
          signEn: t(signs[sign].en)
        });
      }

      const otherResult = [];
      for (const star of otherList) {
        const { lon } = await astro.star(dateTime, star.astroName);
        const sign = getSign(lon);
        // console.log(`--- calc other star ${star.astroName} lon: ${lon} sign: ${sign}`)
        
        otherResult.push({
          ...star,
          lon: degToString(lon),
          degreeFromSign: degToString(lon % 30),
          signRu: t(signs[sign].ru),
          signEn: t(signs[sign].en)
        });
      }

      const result = {
        horar: horarResult,
        other: otherResult
      }

      // window.localStorage.setItem(LS_KEY, JSON.stringify({ time: dateTime, data: result }))

      return {
        horar: horarResult,
        other: otherResult
      };

    }
  }, []);

  const onCheckedAll = React.useCallback((val: boolean) => {
    setUpdate(true);
    setPartialChecked(false);
    setCheckedAll(state => ({
      ...state,
      [localMode]: val || undefined
    }));

    const list = localMode === 'horar' ? starList?.horar : starList?.other;
    setCheckedItems(state => ({
      ...state,
      [localMode]: val ? (list ? list.map((star: any) => star.id) : []) : []
    }))
  }, [localMode, starList])

  const onCheckItem = React.useCallback((id: number) => {
    const list = localMode === 'horar' ? starList?.horar : starList?.other;
    const activeItems = checkedItems[localMode].includes(id) 
      ? checkedItems[localMode].filter(item => item !== id)
      : [...checkedItems[localMode], id]

    setUpdate(true);

    setCheckedItems(state => ({
      ...state,
      [localMode]: activeItems
    }));

    setCheckedAll((state) => ({
      ...state,
      [localMode]: (activeItems.length === list?.length) || undefined
    }));

    setPartialChecked((activeItems.length !== list?.length));
    
  }, [localMode, checkedItems]);

  const checkedNumber = React.useMemo(() => {
    return checkedItems[localMode]?.length || 0
  }, [localMode, checkedItems]);

  const onShowWithObjectsChange = React.useCallback((val: boolean) => {
    setUpdate(true);
    setShowWithObjects(state => ({...state, [localMode]: val}))
  }, [localMode])

  const resultList = React.useMemo(() => {
    if (!starList) return null
    const result = (localMode === 'horar' ? starList.horar! : starList?.other!)
      .map((star) => {
        star.active = checkedItems[localMode]?.includes(star.id);
        return star
      })
      .filter(({ nameEn, nameEnAlt, nameRu, nameRuAlt, constellation, constellationRu }) => {
        const searchString = searchStringResult?.trim().toLowerCase() || '';
        return searchString.length > 2
          ? (isRu ? `${nameRu}${nameRuAlt}` : `${nameEn}${nameEnAlt}`).toLowerCase().includes(searchString) 
          || (isRu ? constellationRu : constellation).toLowerCase().includes(searchString)
          : true
      })
    if (sortByStar !== undefined) {
      result.sort(sortRows('name', !sortByStar ? 'asc' : 'desc'))
    } else 
      if (sortByConstellation !== undefined) {
      result.sort(sortRows('constellation', !sortByConstellation ? 'asc' : 'desc'))
    }

    return result;
  }, [localMode, starList, searchStringResult, checkedAll, sortByStar, sortByConstellation, checkedItems]);

  const onSearch = (val: string) => {
    debounceSearch(val);
    setSearchString(val);
  }

  const debounceSearch = React.useCallback(debounce(setSearchStringResult, 350), []);

  React.useEffect(() => {
    const starList = localMode === 'horar' ? list.filter((star) => star.isHorar) : list;

    const checkedItems_: any = modesList.reduce((acc, mode) => {
      const selectedStars = selected[mode]?.list || [];
      const selectedIds: string[] = selectedStars.map((starName: string) => {
        const id = starList?.find(({ astroName }) => astroName === starName)?.id;
        return id
      });
      (acc as any)[mode] = selectedIds.filter((item: any) => item !== undefined);
      return acc;
    }, {})

    const checkedAll_: any = modesList.reduce((acc, mode, i) => {
      (acc as any)[mode] = (selected[mode]?.list.length === starList?.length);
      return acc;
    }, {})

    setUpdate(false);
    !isEqual(checkedItems_, checkedItems) && setCheckedItems(checkedItems_);
    !isEqual(checkedAll_, checkedAll) && setCheckedAll(checkedAll_);

    setPartialChecked(Boolean(checkedItems_[localMode].length && !checkedAll_[localMode]));

  }, [localMode, selected])
  
  React.useEffect(() => {
    if (!starList || !update) return;
    const selectedList = [...(localMode === 'horar' ? starList.horar : starList.other)].reduce((acc, curr) => {
      if (curr.active) {
        acc.push(curr.astroName.trim())
      }
      return acc
    }, []);
    
    debouncedOnSelect(localMode, [...selectedList], showWithObjects[localMode]);
    
  }, [checkedItems, showWithObjects]);

  const onClearSearch = () => {
    setSearchString('');
    debounceSearch('');
  }

  return (
    <StarCatalogContainer width={width} height={height}>
      <header>
        { withModeSelector &&
          <div className="mode-selector">
            <MapModeSelector 
              modeList={modesList}
              activeMode={localMode}
              onModeSelect={(mode) => {
                setLocalMode(mode);
                scrollContainer.current?.scrollTo(0, 0)
              }}
            />
          </div>
        }
        <div className="search-container">
          <Input 
            size="small"
            value={searchString}
            onChange={onSearch}
            icon={<SearchIcon/>} 
            placeholder={t('chronos.app.ui.search')}
          />
          <button 
            className={`clear-button${searchString?.length ? ` visible` : ``}`}
            onClick={onClearSearch}
          >
            <CloseSmallIcon/>
          </button>
        </div>
      </header>
      <main>
        <div className="table-header">
          <div className="check">
            <Tooltip
              text={partialChecked ? t('chronos.app.ui.removeSelection') : t('chronos.app.ui.chooseAll')}
            >
              <Checkbox checked={checkedAll[localMode]} partialChecked={partialChecked} onChange={onCheckedAll} />
            </Tooltip>
          </div>
          <div className="star-name">
            <span>{t("chronos.app.ui.star")}</span>
            <SortButton active={sortByStar} onClick={() => { setSortByStar(!sortByStar); setSortByConstellation(undefined) }}><ShevronDownIcon/></SortButton>
          </div>
          <div className="constellation">
            <span>{t("chronos.app.ui.constellation")}</span>
            <SortButton active={sortByConstellation} onClick={() => { setSortByConstellation(!sortByConstellation); setSortByStar(undefined) }}><ShevronDownIcon /></SortButton>
          </div>
          <div className="degree">
            <span>{t("chronos.app.ui.degree") }</span>
            <Tooltip textAlign="left" text={degreeMessage}><InfoIcon1/></Tooltip>
          </div>
        </div>
        <div className="table-wrapper" ref={scrollContainer}>
          <div className="table" onClick={(evt) => {
            const parent = (evt.target as HTMLElement).closest('.tr')
            if (!parent) return;
            const id = Number((parent as HTMLElement).dataset.idx);
            onCheckItem(id);
          }}>
            {
              resultList?.map((star: any, idx: number) => {
                return <div className="tr" key={`star_row_${star.id}`} data-idx={star.id}>
                  <div className="td check"><Checkbox checked={star.active}/></div>
                  <div className="td star-name">
                    <span>{isRu ? star.nameRu : star.nameEn}</span>
                    {((isRu && star.nameRuAlt) || star.nameEnAlt) && 
                      <span className="star-alt-name"> {isRu ? `${star.nameRuAlt ? `(${star.nameRuAlt})` : ``}` : `${star.nameRuAlt ? `(${star.nameEnAlt})` : ''}`} </span>
                    }
                  </div>
                  <div className="td constellation">{isRu ? star.constellationRu : star.constellation}</div>
                  <div className="td degree">{`${star.degreeFromSign}, ${isRu ? star.signRu : star.signEn}`}</div> 
                </div>
              })
            }
          </div>
          {
            !resultList?.length && searchString
              ? <span className="empty-message">Объектов не найдено</span>
              : !starList
                ? <span className="loader"><CircleLoader /></span>
                : null
          }
        </div>
        <div className="table-footer">
          <span>{t('chronos.app.ui.objectsSelected')} <strong>{checkedNumber} {isRu && formatMessage(checkedNumber, ['объекта', 'объектов', 'объектов'], t)}</strong></span>
        </div>
      </main>
      <footer>
        <div>
          <div className="footer-title">{t('chronos.app.ui.displayStars')}</div>
          <div className="footer-subtitle">{t('chronos.app.ui.displayStarsSubtitle')}</div>
        </div>
        <div className="footer-controls">
          <span>{showWithObjects[localMode] ? t("chronos.app.ui.on") : t("chronos.app.ui.off")}</span>
          <Switcher checked={showWithObjects[localMode]} onChange={onShowWithObjectsChange}/>
        </div>
      </footer>
    </StarCatalogContainer>
  );
});

const StarCatalogContainer = styled.section<{ width: string; height: string; }>`
  position: relative;
  width: ${p => p.width};

  & header {
    display: flex;
    position: relative;
    justify-content: space-between;
    gap: 9rem;

    & .mode-selector {
      flex: 1.5;
    }
    
    & .search-container {
      position: relative;
      flex: 1;

      .clear-button {
        position: absolute;
        appearance: none;
        border: none;
        width: 1.5rem;
        height: 100%;
        top: 0;
        right: 0.5rem;
        padding: 0;
        color: var(--text-secondary);

        background-color: transparent;
        
        opacity: 0;

        transition: all 0.2s ease;

        &:hover {
          color: var(--text-primary);
        }

        &.visible {
          opacity: 1;
        }
      }
    }
  }

  & main {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 1.5rem;
    max-height: 25rem;
    ${p => p.height && css`
      max-height: ${p.height};
    `}
    overflow: hidden;
    background-color: var(--bg-sidebar);
    border-radius: 0.625rem;
    border: 1px solid var(--input-border);
  }

  & footer {
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: center;
    gap: 4rem;
    height: 5.938rem;
    padding: 1.75rem;
    margin-top: 1.25rem;
    background-color: var(--bg-sidebar);
    border-radius: 0.625rem;

    .footer-title {
      font-size: 1rem;
      font-weight: 600;
    }

    .footer-subtitle {
      margin-top: 0.25rem;
      font-size: 0.813rem;
      color: var(--text-secondary);
    }

    .footer-controls {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center;
      gap: 0.5rem;
    }
  }


  .table-header {
    position: relative;
    display: flex;
    width: 100%;
    background-color: var(--bg-sidebar);
    border-bottom: 1px solid var(--button-border);

    & > div {
      display: flex;
      position: relative;
      justify-content: space-between;
      align-items: center;
      padding: 0.75rem;
      font-size: 0.75rem;
      font-weight: 600;
      color: var(--text-secondary);
      border-right: 1px solid var(--button-border);

      /* span {
        display: inline-block;
        max-width: 30%;
        overflow: hidden;
        text-overflow: ellipsis;
      } */
    }

    & svg {
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
    }
  } 

  & .table-wrapper {
    position: relative;
    right: auto;
    min-height: 18rem;
    overflow-y: scroll;
    scroll-behavior: smooth;

    ::-webkit-scrollbar { 
      display: none; 
    }

    .empty-message,
    .loader {
      position: absolute;
      top: 50%;
      display: block;
      left: 50%;
      transform: translate(-50%,-50%);

      font-size: 0.875rem;
      font-weight: 600;
    }
    
  }

  & .table {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    & .tr {
      display: flex;
      position: relative;
      transition: background-color ease 0.3s;
      cursor: default;

      &:last-child .td {
        border-bottom: none;
      }

      &:hover {
        background-color: var(--bg-element-hover-hover);
        transition: background-color ease 0.3s;
      }
    }

    & .td {
        display: flex;
        align-items: center;
        padding: 0.75rem;
        font-size: 0.875rem;
        font-weight: 600;
        border-right: 1px solid var(--button-border);
        border-bottom: 1px solid var(--button-border);
        
        &.check .ui-checkbox {
          pointer-events: none;
        }
      }
    }

    & .table-footer {
      padding: 0.75rem;
      font-size: 0.75rem;
      font-weight: 600;
      color: var(--text-secondary);
      border-top: 1px solid var(--button-border);
    }

    .check {
      width: 3%;
      justify-content: center !important;
    }
    
    .star-name {
      width: 32%;

      & .star-alt-name {
        margin-left: 0.5rem;
        color: var(--text-third);
        font-size: 0.75rem;
        font-weight: normal;
      }
    }

    .constellation {
      width: 32%;
    }

    .degree {
      justify-content: flex-start !important;
      flex: 1;
      border-right: none !important;

      svg {
        margin-left: 0.5rem;
        margin-right: auto;
        color: #737373;
      }
    }

`;

const SortButton = styled.div<{active: boolean | undefined}>`
  position: absolute;
  right: 0.75rem;
  max-height: 1.5rem;
  opacity: 1;

  & svg {
    transition: transform 0.2s ease;
    ${p => p.active === undefined && css`
      color: var(--text-third);
      opacity: 0.7;
    `}
    ${p => p.active && css`
      transform: rotate(180deg);
    `}
  }
`;