import { observable, makeAutoObservable, runInAction } from 'mobx';

import { formatMessage } from 'src/utils';
import api from 'src/api';
import store from 'src/store';
import { show, IPromocode, IPromocodeAction, TProcessorPromoProduct, IPromocodeDiscountAction, IPromocodeSubscriptionAction, IPromocodeCounterAction } from 'src/libs';
import { HTTP_ERRORS_DESCRIPTION } from 'src/config';
import { ITranslator } from 'src/i18n/useTranslation';
import dayjs from 'dayjs';

export type TPromoPeriodType = 'months' | 'days';

interface IPromoView {
  id: number;
  name: string;
  text: string;
  periodType: string;
  period: number;

  recurrent: boolean;
  discount: number;
  discountStart: string;
  discountEnd: string;
  canceled: boolean;
}

export default class PromoStore {

  private t: (v: string, vars?: any) => string;

  constructor() {
    makeAutoObservable(this);
  }

  init(t: ITranslator) {
    this.t = t.t;
  }

  promoCode = '';
  
  private promoList: IPromocode[] = [];

  addPromos(promos: IPromocode[], showInfo?: boolean) {
    runInAction(() => {
      const result = [
        ...new Map(promos.map(item => [item['id'], item])).values() // избавляемся от возможных дублей
      ].filter((promo: IPromocode) => this.isDiscountAction(promo?.actions));

      this.promoList = result;

      this.fillPromoViews();

      if (showInfo ?? true) {
        setTimeout(() => this.showAllPromoInfo(), 1000);
      }
    });

  }

  private tariffListAsString(ids: number[]): string {
    return store.settings?.payments?.shortTariffs.reduce((acc, current, i) => {
      if (ids.includes(current.id)) {
        return `${acc}${acc.length > 0 ? `, ${current.name}` : current.name}`;
      }
      return acc;
    }, '');
  }

  private translateTariffList = (text: string) => {
    const arrOfTafiffs = text.split(',');
    if (arrOfTafiffs.length > 1) {
      return arrOfTafiffs.map((item, id) => {
        if (id === 0) return this.t(item)
        return ` ${this.t(item.trim())}`
      }).join(',')
    }

    return this.t(text)
  }

  // кэши для отменяемых скидочных тарифов
  'tariffCncl': IPromoView = this.getDiscountPromoView();
  'formationCncl': IPromoView = this.getDiscountPromoView();
  checkedPromoCodeId: number | null = null

  getDiscountPromoView() {
    return observable({
      id: 0,
      name: '',
      text: '',
      periodType: 'months',
      period: 1,

      discount: 1,
      recurrent: false,
      discountStart: '',
      discountEnd: '',

      canceled: false
    });
  }

  fillPromoViews() {
    if (this.tariffDiscountPromo) {
      ({
        id: this.tariffCncl.id,
        title: this.tariffCncl.name,
        text: this.tariffCncl.text,
        periodType: this.tariffCncl.periodType,
        period: this.tariffCncl.period,
        recurrent: this.tariffCncl.recurrent,
        discount: this.tariffCncl.discount,
        discountStart: this.tariffCncl.discountStart,
        discountEnd: this.tariffCncl.discountEnd,
        // используем деструктуризацию для мапы промокода и его подсвойств в this.tariffCncl
      } = { ...this.tariffDiscountPromo, text: this.tariffDiscountTextInfo, ...this.tariffDiscountPromo?.actions } as any);
      this.tariffCncl.canceled = false;
    }

    if (this.formationDiscountPromo) {
      ({
        id: this.formationCncl.id,
        title: this.formationCncl.name,
        text: this.formationCncl.text,
        periodType: this.formationCncl.periodType,
        period: this.formationCncl.period,
        recurrent: this.formationCncl.recurrent,
        discount: this.formationCncl.discount,
        discountStart: this.formationCncl.discountStart,
        discountEnd: this.formationCncl.discountEnd
        // используем деструктуризацию для мапы промокода и его подсвойств в this.tariffCncl
      } = { ...this.formationDiscountPromo, text: this.shortFormationDiscountInfo, ...this.formationDiscountPromo?.actions } as any);

      this.formationCncl.canceled = false;
    }
  }

  isTariffsAction(action: IPromocodeAction): boolean {
    return action.app === 'processor' && (action?.products as TProcessorPromoProduct).every((v) => typeof v === 'number');
  }

  isFormationsAction(action: IPromocodeAction): boolean {
    return action.app === 'processor' && (action?.products as TProcessorPromoProduct).includes('formations');
  }

  isDiscountAction(action: IPromocodeAction): boolean {
    const act = action as IPromocodeDiscountAction;
    return (act?.discount > 0) && (act?.discount < 1);
  }

  // Скидка на тарифы
  tariffDiscountCb(item: IPromocode): boolean {
    return this.isTariffsAction(item?.actions)
      && this.isDiscountAction(item?.actions);
  }
  get isTariffDiscount(): boolean {
    return this.promoList.some(this.tariffDiscountCb.bind(this));
  }
  get tariffDiscountPromo(): IPromocode | undefined {
    return this.promoList.find(this.tariffDiscountCb.bind(this));
  }

  get tariffWithDiscountIds(): number[] {
    const promo = this.tariffDiscountPromo;
    return (promo?.actions?.products as number[]) || [];
  }

  get tariffDiscountValue(): number {
    const promo = this.tariffDiscountPromo;
    const discount = (promo?.actions as IPromocodeDiscountAction)?.discount;
    return (discount > 0 && discount < 1) ? discount : 0;
  }

  get tariffWithDiscountRecurrent(): boolean {
    return Boolean((this.tariffDiscountPromo?.actions as IPromocodeSubscriptionAction)?.recurrent);
  }

  get tariffDiscountTextInfo() {
    const promo = this.promoList.find(this.tariffDiscountCb.bind(this));
    return this._description(promo?.actions as IPromocodeAction);
  }

  get shortTariffDiscountInfo() {
    if (!this.tariffDiscountPromo) {
      return '';
    }
    const { actions: action } = this.tariffDiscountPromo;
    const dSection = this.d['discount'];
    return `
        ${this.t(dSection?.title)}
        ${dSection?.discount?.(action?.discount) || ''}
      `;
  }

  // Бесплатные тарифы
  freeTariffCb(promocode: IPromocode) {
    return promocode?.actions.app === 'processor' && (promocode?.actions?.products as TProcessorPromoProduct).every((v) => typeof v === 'number')
      && [null, 1].includes((promocode?.actions as IPromocodeDiscountAction)?.discount);
  }
  get isFreeTariff() {
    return this.promoList.some(this.freeTariffCb.bind(this));
  }
  get freeTariffPromo() {
    return this.promoList.find(this.freeTariffCb.bind(this));
  }
  get freeTariffId() {
    return (this.freeTariffPromo?.actions?.products as TProcessorPromoProduct)?.[0];
  }

  get freeTariffPeriodInfo() {
    const action = this.freeTariffPromo?.actions as IPromocodeSubscriptionAction;
    const dSection = this.d['subscription'];
    return `${dSection?.period?.[action?.periodType!]?.(action?.period)}`;
  }

  get freeTariffShortInfo() {
    return this.t("chronos.app.promoStore.isFree", { freeTariffPeriodInfo: this.freeTariffPeriodInfo });
  }

  // Скидка на построения
  formationDiscountCb(item: IPromocode) {
    return this.isDiscountAction(item?.actions) && this.isFormationsAction(item?.actions);
  }
  get isFormationDiscount() {
    return Boolean(this.formationDiscountPromo);
  }
  get formationDiscountPromo() {
    return this.promoList.find(this.formationDiscountCb.bind(this));
  }
  get formationDiscountValue() {
    const promo = this.formationDiscountPromo;
    return (promo?.actions as IPromocodeAction)?.discount! * 100;
  }

  get shortFormationDiscountInfo() {
    if (!this.formationDiscountPromo) {
      return this.t("chronos.app.store.promoStore.noDescriptions");
    }
    const { actions: action } = this.formationDiscountPromo;
    const dSection = this.d['discount']
    return `
        ${this.t(dSection?.title)}
        ${dSection?.discount?.(action?.discount) || ''}
      `;
  }

  // Бесплатные построения
  freeFormationsCb(item: IPromocode) {
    return this.isFormationsAction(item?.actions) && !this.isDiscountAction(item?.actions);
  }

  get freeFormationPromo() {
    return this.promoList.find(this.freeFormationsCb.bind(this));
  }

  get isFreeFormations() {
    return Boolean(this.freeFormationPromo);
  }

  get freeFormationsValue() {
    const promo = this.freeFormationPromo;
    return (promo?.actions as IPromocodeCounterAction)?.value;
  }

  private d: { [key: string]: any } = {
    'subscription': {
      title: "chronos.app.config.PromoStore.freeSubscription",
      tariff: (products: number[]) => this.t(this.translateTariffList(this.tariffListAsString(products))),
      period: {
        'days': (num: number, t: Function) => `${this.t("base.on")} ${num} ${formatMessage(num, ["chronos.app.day.nom", "chronos.app.day.gen", "chronos.app.day.plural"], this.t)}`,
        'months': (num: number, t: Function) => `${this.t("base.on")} ${num} ${formatMessage(num, ["chronos.app.month.lower", "chronos.app.month.lower.gen", "chronos.app.month.lower.gen.plural"], this.t)}`
      }
    },
    'discount': {
      title: "base.discount",
      subtype: {
        'formation': "base.onBuilding",
        'subscription': (subs: number[]) => `${subs.length > 1 ? this.t("base.forTariffs") : this.t("base.forTariff")} ${this.translateTariffList(this.tariffListAsString(subs))}`
      },
      discount: (percent: number) => percent ? `${Math.trunc(percent * 100)}%` : '',
      period: {
        'days': (num: number, t: Function) => `${this.t("base.on")} ${num} ${formatMessage(num, ["chronos.app.day.nom", "chronos.app.day.gen", "chronos.app.day.plural"], this.t)}`,
        'months': (num: number, t: Function) => `${this.t("base.on")} ${num} ${formatMessage(num, ["chronos.app.month.lower", "chronos.app.month.lower.gen", "chronos.app.month.lower.gen.plural"], this.t)}`
      }
    },
    'formation': {
      title: (num: number, t: Function) => `${num} ${formatMessage(num, ["base.building", "base.building.gen", "base.building.plural"], this.t)} ${this.t("base.free")}`
    }
  };

  private _description(promoAction: IPromocodeAction) {
    let action = promoAction;
    let message = this.t("chronos.app.store.promoStore.noDescriptions");
    if (!action) {
      return message;
    }

    let dSection;

    if (this.isTariffsAction(action) && !this.isDiscountAction(action)) {
      action = promoAction as IPromocodeSubscriptionAction
      dSection = this.d['subscription'];
      message = `
        ${this.t(dSection?.title)}
        ${this.t(dSection?.tariff?.(action.products)) || ''}
        ${dSection?.period?.[action.periodType!](action.period) || ''}
      `;
    } else

      if (this.isDiscountAction(action)) {
        dSection = this.d['discount'];
        message = `
          ${this.t(dSection?.title)}
          ${dSection?.discount?.(action.discount || 0) || ''}
        `;

        if (this.isTariffsAction(action)) {
          const tariffAction = action as IPromocodeSubscriptionAction;
          message = `${this.t(message)} ${this.t(dSection?.subtype?.['subscription'](tariffAction.products))}`;

          // если скидка не навсегда и не указаны даты действия скидки
          if (!tariffAction.recurrent && !tariffAction.discountStart && !tariffAction.discountEnd) {
            message = `${message} ${dSection?.period[tariffAction.periodType](tariffAction.period)}`
          }

          // если скидка не "навсегда" и указаны даты действия скидки
          if (!tariffAction.recurrent && tariffAction.discountStart && tariffAction.discountEnd) {

            const [start, end] = [dayjs(tariffAction.discountStart), dayjs(tariffAction.discountEnd)];

            let periodType = 'months';
            let period = 1;
            const monthDiff = end.diff(start, 'month');
            const dayDiff = end.diff(start, 'day');

            if (monthDiff >= 1) {
              periodType = 'months';
              period = monthDiff;
            } else {
              periodType = 'days';
              period = dayDiff;
            }

            message = `${message} ${dSection?.period[periodType](period)}`;
          }

        }
        else if (this.isFormationsAction(action)) {
          message = `${this.t(message)} ${this.t(dSection?.subtype?.['formation'])}`;
        }
      } else

    if (this.isFormationsAction(action)) {
      action = promoAction; // as IPromocodeCounterAction
      dSection = this.d['formation'];
      message = `
        ${this.t(dSection?.title(action.value))}
      `;
    }
    return message;
  }


  ////
  showSuccessPromoInfo(promoAction: IPromocodeAction) {
    const text = this._description(promoAction);
    show({
      text: `${this.t("base.youHave")} ${this.t(text)}`,
      type: 'success',
      timeout: 3000,
      closable: true
    });
  }

  showAllPromoInfo() {
    this.promoList.forEach((promoData: IPromocode) => {
      this.showSuccessPromoInfo(promoData?.actions)
    });
  }


  async checkPromocode(promoCode: string) {
    try {
      const result = await api.checkPromocode(promoCode);
      this.checkedPromoCodeId = result.id;
      return result
    } catch (err: any) {
      throw err;
    }
  }

  async usePromocode(code: string, showMessage?: boolean) {
    try {
      const result = await api.usePromocode((code as string).trim())

      const paymentsResult = await api.paymentFullInfo();
      store.settings.payments.setPayInfo(paymentsResult);

      this.addPromos((paymentsResult.promos ?? []), !Boolean('showInfo'));

      if (showMessage !== false) {
        this.showSuccessPromoInfo(result);
      }

      return result;
    } catch (err: any) {
      throw err;
    }
  }

  async cancelPromocode(promoId: string | number) {
    try {
      const result = await api.cancelPromocode(Number(promoId));
      runInAction(() => {
        this.promoList = [];
      });

      const payInfo = await api.paymentFullInfo();
      store.settings.payments.setPayInfo(payInfo);

    } catch (err: any) {
      show({
        text: this.t(err.message),
        type: 'error'
      });
      throw err;
    };
  }


  async tryApplyPromoCode(promoCode: string | number, showMessage?: boolean) {
    try {
      const checkResult: IPromocode = await this.checkPromocode(promoCode as string);
      const useResult: IPromocodeAction = await this.usePromocode(promoCode as string, showMessage);
      return true;
    } catch (err: any) {
      // debugger
      const { message: text = 'unknown', type = 'warning' } = HTTP_ERRORS_DESCRIPTION[(err.message)];
      setTimeout(() => show({ text: this.t(text), type }), 1000);
      throw err;
    }
  }

  async switchPromo(promoType: 'tariff' | 'formation', showMessage?: boolean) {
    const propKey: string = `${promoType}Cncl`;
    const promo: IPromoView = (this as any)[propKey];
    const param: string | number = promo.canceled ? promo.name : promo.id;

    // debugger

    try {
      const result = await this[promo.canceled ? 'tryApplyPromoCode' : 'cancelPromocode'](param, showMessage);
      (this as any)[propKey].canceled = !promo.canceled;
    } catch (err: any) {
      throw err;
    }
  }

}
