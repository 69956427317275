import React from "react";

export interface IInstrumentContext {

  showTariffsPopup: boolean;

  setShowTariffsPopup: (v: boolean) => void;

  subscriptions: Array<(payload?: any) => void>;
  subscribe: (f: () => void) => void
}

export const defaultContext = (): IInstrumentContext => ({
  showTariffsPopup: false,

  setShowTariffsPopup(val: boolean) {
    this.showTariffsPopup = val;
    this.subscriptions.forEach(f => f());
  },

  subscriptions: [],
  subscribe(f: (payload?: any) => void) {
    this.subscriptions.push(f);
  }
})

export default React.createContext(defaultContext());
