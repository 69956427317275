import React, { useLayoutEffect, useEffect, useRef } from 'react';
import { getDeviceDetect } from './utils';
import { useObserver } from 'mobx-react';
import store from './store';
import { CurrencyCode, Region } from 'src/libs';

export const debugRender = false;

export function ReactMemo<P>(f: (p: P) => any) {
  return React.memo((p: P) => {
    debugRender && console.log(f.name);
    return f(p);
  });
}

export function switcherHook(initial: boolean) {
  const [value, setValue] = React.useState(initial);

  return {
    value,
    on: React.useCallback(() => setValue(true), [value]),
    off: React.useCallback(() => setValue(false), [value]),
    switch: React.useCallback(() => {
      setValue(!value);
      return !value;
    }, [value])
  };
}

interface IKeyPressHookController {
  delay?: number;
  onKeyDown?(key: string, isDelayed?: boolean): void;
  onKeyUp?(key: string, isDelayed?: boolean): void;
}

interface IParsedKeys {
  altKey: boolean;
  ctrlKey: boolean;
  shiftKey: boolean;
  code: string;
  default: string;
  splitted: string[];
}

interface IKeyHookParams {
  execute: boolean;
  ignoreActiveInput: boolean;
  stopPropagation: boolean;
  repeat: boolean;
}

const parseKeyname = (keyname: string | string[]): IParsedKeys[] => {
  const keys = Array.isArray(keyname) ? keyname : [ keyname ];

  return keys.map(key => {
    const splitted = key.split('+').map(k => k.trim());
    return {
      altKey: splitted[0].includes('Alt'),
      ctrlKey: splitted[0].includes('Control'),
      shiftKey: splitted[0].includes('Shift'),
      code: splitted[splitted.length - 1],
      default: key,
      splitted
    };
  });
};

const getDesireKey = (keys: IParsedKeys[], ev: KeyboardEvent): string => {
  for (const key of keys) {

    const isPressed = key.altKey === ev.altKey
      && key.ctrlKey === ev.ctrlKey
      && key.shiftKey === ev.shiftKey
      && key.code === ev.code;

    if (isPressed) return key.default;
  }
  return '';
};

let timeStamp = 0;

export function checkActiveInput() {
  return document.activeElement?.nodeName.toUpperCase().includes('INPUT')
    || document.activeElement?.nodeName.toUpperCase().includes('TEXTAREA');
}

const defaultParams: IKeyHookParams = {
  execute: true,
  ignoreActiveInput: false,
  stopPropagation: false,
  repeat: true
};

export function useKeyHook(
  keyname: string | string[],
  controller: IKeyPressHookController,
  dependencies: any[] = [],
  params: Partial<IKeyHookParams> = defaultParams
) {
  const timeoutId = useRef<number | null>(null);
  const ctrlTimeout = useRef<number | null>(null);
  let isCtrlOwer = true;

  const _params = {
    ...defaultParams,
    ...params
  };

  React.useEffect(() => {
    const parsedKeyname = parseKeyname(keyname);

    const onKeyDown = (evt: KeyboardEvent) => {
      if (!_params.ignoreActiveInput && checkActiveInput() || !_params.repeat && evt.repeat) return;
      const desireKey = getDesireKey(parsedKeyname, evt);
      if (evt.ctrlKey) {

        if (evt.key === 'k') {
          evt.preventDefault();
        }

        isCtrlOwer = false;
        ctrlTimeout.current = setTimeout(() => {
          isCtrlOwer = true;
        }, 1500) as unknown as number
      }
      if (!desireKey || !_params.ignoreActiveInput && checkActiveInput()) return;

      _params.stopPropagation && evt.stopImmediatePropagation();
      _params.stopPropagation && evt.preventDefault();

      if (controller.delay && !evt.repeat) timeStamp = Date.now();
      controller.onKeyDown && controller.onKeyDown(desireKey);

      if (controller.delay) {
        timeoutId.current = setTimeout(() => {
          controller.onKeyDown && controller.onKeyDown(desireKey, true);
        }, controller.delay) as unknown as number;
      }
    };

    const onKeyUp = (evt: KeyboardEvent) => {
      const desireKey = getDesireKey(parsedKeyname, evt);

      if (!desireKey || !_params.ignoreActiveInput && checkActiveInput() || !isCtrlOwer) return;

      _params.stopPropagation && evt.stopImmediatePropagation();
      _params.stopPropagation && evt.preventDefault();

      const isDelayed = !controller.delay || Date.now() - timeStamp > controller.delay;

      if (!isDelayed && timeoutId.current) clearTimeout(timeoutId.current);
      if (ctrlTimeout.current) clearTimeout(ctrlTimeout.current);

      controller.onKeyUp && controller.onKeyUp(desireKey, isDelayed);
    };

    if (!_params.execute) return;

    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('keyup', onKeyUp);
    };
  }, [ ...dependencies ]);
}

type MouseEventNames = 'mouseover' | 'mouseenter' | 'mousedown' | 'mouseup' | 'click';

export function mouseEventHook(eventName: MouseEventNames, cb: Function) {
  React.useEffect(() => {
    function handler(evt: MouseEvent) {
      evt.stopPropagation();
      cb?.(evt);
    }
    window.addEventListener(eventName, handler, true);
    return () => window.removeEventListener(eventName, handler, true);
  }, [cb, eventName]);
}

export const clickOutsideHook = (ref: any, cb: Function, deps: any[] = []) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        cb();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, ...deps]);
};

export function useDeviceDetect() {
  const [isMobile, setMobile] = React.useState(false);
  const [isTablet, setTablet] = React.useState(false);
  const [isIPad, setIPad] = React.useState(false);

  React.useEffect(() => {
    const { isMobile, isTablet, isIPad } = getDeviceDetect();

    setMobile(isMobile);
    setTablet(isTablet);
    setIPad(isIPad);
  }, []);

  return { isMobile, isTablet, isIPad };
}

export const getCurrencySymbol = (code?: string) => {
  switch (code?.toLowerCase()) {
    case 'rub':
      return '₽';
    case 'usd':
      return '$';
    case 'kzt':
      return '₸';
    default:
      return '';
  }
}

export function useCurrency(customCurrencyCode?: CurrencyCode, customCountry?: Region) {
	if (customCurrencyCode && customCountry) {
		return {
			currencyCode: customCurrencyCode,
			symbol: getCurrencySymbol(customCurrencyCode),
			country: customCountry,
		}
	}

  const {currencyCode, country} = useObserver(() => ({
    currencyCode: store.settings.user.region?.currencyCode,
    country: store.settings.user.region?.region,
  }));

  return {
    currencyCode,
    symbol: getCurrencySymbol(currencyCode),
    country,
  }
}

export function setDocumentTitle(title: string) {
  useLayoutEffect(() => {
    document.title = title;
  }, []);
}
