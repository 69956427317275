import { IPromptDataSign } from '../../types';

import { CapricornIcon as icon } from 'src/assets/icons/astro/signs';
import image from './img/capricorn.png';

const sign: IPromptDataSign = {
  name: "Capricorn",
  title: "chronos.auth.planets.saturn.sign",
  icon,
  image,
  nameIn: "chronos.app.instruments.widgets.prompts.signs.capricorn.nameIn",
  description: {
    short: "chronos.app.instruments.capricorn.descriptionShort1",
    facts: [
      {
        name: "astro.facts.element",
        value: "chronos.app.instruments.widgets.prompts.houses.2.description.facts.4.value"
      },
      {
        name: "chronos.app.instruments.widgets.prompts.signs.aries.description.facts.1.name",
        value: "chronos.app.instruments.widgets.prompts.signs.capricorn.description.facts.1.value"
      },
      {
        name: "astro.facts.cross",
        value: "astro.facts.cardinal"
      },
      {
        name: "chronos.app.instruments.widgets.prompts.signs.aries.description.facts.3.name",
        value: "chronos.app.instruments.widgets.prompts.signs.cancer.description.facts.3.value"
      }
    ],
    full: "chronos.app.instruments.capricorn.descriptionFull1"
  }
};

export default sign;
