import { useTranslation } from 'src/i18n/useTranslation';
import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import { IFactsSliderProps } from '../types';

const FactsSlider: React.FC<IFactsSliderProps> = ({
  items,
  interval = 9
}) => {
  const [current, setCurrent] = useState(0);
  const listRef = useRef(null);
  const timerRef = useRef(0);
  const { t } = useTranslation();

  useEffect(() => {
    timerRef.current = window.setTimeout(nextSlide, interval * 1000);
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [current]);

  useEffect(() => {
    moveSlide(0);
  }, [items]);

  const moveSlide = (nextI: number) => {
    const listEl = listRef.current! as HTMLElement;
    const nextEl = listEl.children[nextI] as HTMLElement;

    Array.from(listEl.children).forEach(item => item.classList.remove('active', 'animate'));
    setTimeout(() => nextEl.classList.add('active', 'animate'), 0);

    listEl.style.transform = `translateX(calc(-${nextEl.offsetLeft}px + 3rem))`;
    setCurrent(nextI);
  };

  const nextSlide = () => {
    let nextI = current + 1;
    if (nextI === items.length) { nextI = 0 }
    moveSlide(nextI);
  };

  return <Container onClick={nextSlide} interval={interval}>
    <ul ref={listRef}>
      {items.map((item, i) => {
        const className = i === 0 ? 'active animate' : undefined;
        return <li key={i} className={className}>
          <Item>
            <header><ProgressIndex>
              {i + 1}
              <svg id="svg" viewBox="0 0 2rem 2rem" xmlns="http://www.w3.org/2000/svg">
                <circle r="0.88rem" cx="0.94rem" cy="0.94rem" fill="transparent" strokeDasharray="565.48" strokeDashoffset="0"></circle>
                <circle className="bar" r="0.88rem" cx="0.94rem" cy="0.94rem" fill="transparent" ></circle>
              </svg>
            </ProgressIndex>{t(item.name)}</header>
            <span>{t(item.value)}</span>
          </Item>
        </li>;
      })}
    </ul>
  </Container>;
};

const Container = styled.div<{ interval: number }>`
  width: 100%;
  overflow-x: hidden;
  cursor: pointer;

  & ul {
    margin: 0;
    padding: 0;
    list-style: none;
    white-space: nowrap;
    transition: transform 0.5s;

    & li {
      display: inline-block;
      min-width: 14rem;
      margin-left: 3rem;

      --index-color: var(--text-secondary);
      --name-color: var(--text-third);
      --value-color: var(--text-secondary);

      &.active {
        --index-color: var(--text-primary);
        --name-color: var(--text-secondary);
        --value-color: var(--text-primary);

        & .bar {
          opacity: 1;
        }
      }

      &.animate{
        & .bar {
          animation: facts-animation ${props => props.interval}s linear infinite;
        }
      }

    }
  }
`;

const Item = styled.div`
  & > header {
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    margin-bottom: 1.125rem;
    color: var(--name-color);
  }

  & > span {
    font-size: 3rem;
    line-height: 1;
    color: var(--value-color);
  }
`;

const ProgressIndex = styled.div`
  position: relative;
  width: 1.875rem;
  height: 1.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.125rem;
  border-radius: 50%;
  color: var(--index-color);

  @keyframes facts-animation {   
    from {     
      stroke-dashoffset: 5.6rem;   
    }   
    to {     
      stroke-dashoffset: 0rem;
    } 
  }

  & svg {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  & svg circle {
    stroke-dashoffset: 0;
    transition: stroke-dashoffset 1s linear;
    stroke: var(--input-border);
    stroke-width: 0.06rem;
    position: absolute;
  }
  & svg .bar {
    stroke: var(--accent-blue);
    stroke-dashoffset: 5.6rem;
    stroke-dasharray: 5.6rem;
    opacity: 0;
  }

`;

export default React.memo(FactsSlider);
