import React from 'react';
import styled from 'styled-components';

import { ReactMemo } from 'src/hooks';

import { Icon } from 'src/libs';

import { CloseIcon } from 'src/assets/icons/system';

export default ReactMemo(function CloseButton(props: {
  onClick(): void;
  className?: string
}) {
  return <Close className={props.className} onClick={props.onClick}><Icon icon={CloseIcon} /></Close>;
});

const Close = styled.span`
  margin-left: auto;
  padding: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 0.9em !important;
    fill: var(--icon-secondary);
  }

  :hover {
    svg {
      fill: var(--icon-primary);
    }
  }
`;
