import React from 'react';
import styled from 'styled-components';

import ResultItem from './ResultItem';
import ResultMoreItem from './ResultMoreItem';
import { IInterval } from '.';

import { IEvent } from './types';

import { ArrowLeftIcon } from 'src/assets/icons/system';


import { toDateTime } from 'src/libs';
import { toDateTimeString } from 'src/api';

export default function ResultList(props: {
  allEvents: IEvent[];
  intervals: IInterval[];
  selected: string;
  selectRect(i: number, restore: boolean): Promise<any>;
}) {
  const [intervalID, setIntervalID] = React.useState(-1);

  const interval = props.intervals[intervalID];

  const intervals10 = props.intervals.slice(0, 10);

  if (!interval && props.intervals.length > 0) {
    for (let i = 0; i < props.intervals[0].begin.events.length; i++) {
      const { id } = props.intervals[0].begin.events[i];
      let max = 0;
      for (let j = 0; j < props.intervals.length; j++) {
        max = Math.max(max, props.intervals[j].begin.events[i].score);
      }
      for (let j = 0; j < props.intervals.length; j++) {
        props.intervals[j].begin.events[i].percentAmountEvents = 100 * (props.intervals[j].begin.events[i].score / max);
      }
    }
  }

  const beginTime = interval ? toDateTime(interval.begin.key).time : '';
  const endTime = interval?.end ? toDateTime(interval.end.key).time : '';

  return <ResultListC>
    {!interval && intervals10
      .map((interval, i) =>
        <ResultItem
          key={`interval_${i}`}
          index={i}
          interval={interval}
          openMore={() => setIntervalID(i)}
          selectRect={props.selectRect}
          isCurrent={toDateTimeString(new Date(interval.timestamp).toISOString()) == props.selected}
          allEvents={props.allEvents}
        />
      )}

    {interval && <>
      <IntervalHeader>
        <ArrowLeftIcon onClick={() => setIntervalID(-1)} />
        <span>{toDateTime(new Date(interval.timestamp).toISOString()).time}</span>
      </IntervalHeader>
      <IntervalTimes>
        <span>{beginTime}</span>
        {interval.end &&
          <>
            <span><ArrowRightIcon /></span>
            <span>{endTime}</span>
          </>
        }
      </IntervalTimes>
      {interval
        .begin
        .events
        .map(ev => <ResultMoreItem
          key={`event_${ev.id}`}
          event={ev}
          allEvents={props.allEvents}
        />)
      }
    </>}
  </ResultListC>;
}

const ResultListC = styled.div`
  padding-bottom: 5rem;
`;

const IntervalHeader = styled.nav`
  display: flex;
  align-items: center;
  margin-bottom: 0.4rem;
  font-size: 1.5rem;
  line-height: 2rem;
  color: var(--text-primary);

  & > svg {
    width: 1.25rem;
    cursor:pointer;
    margin-right: 0.75rem;

    &:hover {
      fill: var(--text-primary);
    }
  }
`;

const IntervalTimes = styled.span`
  display: flex;
  margin-bottom: 0.5rem;
  align-items: center;
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: var(--text-primary);

  & span {
    margin-right: 0.7rem;
  }

  & svg {
    width: 0.875rem;
  }
`;

const ArrowRightIcon = styled(ArrowLeftIcon)`
  transform: rotate(180deg);
`
