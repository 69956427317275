import React from 'react';
import styled, { css } from 'styled-components';

import { ReactMemo } from 'src/hooks';
import { useTranslation } from 'src/i18n/useTranslation';

type ColorType = 'dark' | 'blue';

export default ReactMemo(function TooltipWrapper({
  children = null,
  text = '',
  color = 'dark',
  left
}: {
  children: any;
  text: string;
  color?: ColorType;
  left?: boolean;
}) {
  const tooltipEl = React.useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const onMouseMove = (e: React.MouseEvent) => {
    if (!tooltipEl.current) return;

    if (left) {
      tooltipEl.current.style.right = `${innerWidth - e.pageX - pageXOffset + 10}px`;
      tooltipEl.current.style.top = `${e.pageY - pageYOffset + 10}px`;

      return;
    }

    tooltipEl.current.style.left = `${e.pageX - pageXOffset + 10}px`;
    tooltipEl.current.style.top = `${e.pageY - pageYOffset + 10}px`;
  };

  const onMouseOver = (_: any) => {
    if (!tooltipEl.current) return;
    tooltipEl.current.style.display = 'block';
  };

  const onMouseLeave = (_: any) => {
    if (!tooltipEl.current) return;
    tooltipEl.current.style.display = 'none';
  };

  let _text = text
    .replace(/<purple>/, '<span style=\'color: var(--colors-purple)\'>')
    .replace(/<\/purple>/, '</span>')
    .replace(/<orange>/, '<span style = \'color: var(--colors-orange)\'> ')
    .replace(/<\/orange>/, '</span> ');
    
  return (
    <span
      onMouseMove={onMouseMove}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      style={{ height: 'fit-content' }}
    >
      {children}
      {text && <Tooltip color={color} ref={tooltipEl} dangerouslySetInnerHTML={{ __html: _text }} />}
    </span>
  );
});

const Tooltip = styled.div<{ color: ColorType }>`
  display: none;
  position: fixed;
  border-radius: 4px;
  background-color: var(--bg-100);
  color: var(--text-primary);
  padding: 0.20rem 0.4rem 0.15rem 0.4rem;
  font-size: 0.75rem;
  text-align:center;
  line-height: 1.2rem;
  z-index: 100;
  border: 1px solid var(--element-neutral);

  ${props => props.color === 'blue' && css`
    background: var(--accent-blue);
    color: var(--text-primary);
  `}
`;
