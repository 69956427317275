import { IPromptDataAspect } from '../../types';

import { TredecileIcon as icon } from 'src/assets/icons/astro/aspects';

const aspect: IPromptDataAspect = {
  type: "Tredecile",
  deg: 108,
  name: "chronos.app.instruments.widgets.prompts.aspects.tredecile.name",
  icon,
  description: {
    list: [
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Semiquintile.0"
      }
    ]
  }
};

export default aspect;
