import { IPromptDataPlanet } from '../../types';

import image from './img/Moon.png';
import { MoonIcon as icon } from 'src/assets/icons/astro/objects';

const planet: IPromptDataPlanet = {
  name: "Moon",
  title: "chronos.auth.planets.moon.title",
  image,
  icon,
  description: {
    short: "chronos.app.instruments.widgets.prompts.planets.moon.description.short",
    astronomy: "chronos.app.instruments.moon.astronomy1",
    facts: [
      {
        name: "chronos.app.instruments.widgets.prompts.planets.sun.description.facts.0.name",
        value: "chronos.app.instruments.widgets.prompts.planets.moon.description.facts.0.value"
      },
      {
        name: "chronos.app.instruments.widgets.prompts.planets.mars.description.facts.4.name",
        value: "chronos.app.instruments.widgets.prompts.planets.moon.description.facts.1.value"
      },
      {
        name: "chronos.app.instruments.widgets.prompts.planets.moon.description.facts.2.name",
        value: "chronos.app.instruments.widgets.prompts.planets.moon.description.facts.2.value"
      },
      {
        name: "chronos.app.instruments.widgets.prompts.planets.mars.description.facts.1.name",
        value: "chronos.app.instruments.widgets.prompts.planets.moon.description.facts.3.value"
      },
      {
        name: "chronos.app.instruments.widgets.prompts.planets.moon.description.facts.4.name",
        value: "chronos.app.instruments.widgets.prompts.planets.moon.description.facts.4.value"
      },
      {
        name: "chronos.app.instruments.widgets.prompts.planets.moon.description.facts.5.name",
        value: "chronos.app.instruments.widgets.prompts.planets.moon.description.facts.5.value"
      }
    ],
    astrology: "chronos.app.instruments.moon.astrology1"
  },
  interpretation: {
    signs: {
      general: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.general",
      Aries: [
        {
          icon: "👀",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Aries.0.text"
        },
        {
          icon: "🏂",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Aries.1.text"
        },
        {
          icon: "🏃‍♀️",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Aries.2.text"
        },
        {
          icon: "👸",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Aries.3.text"
        },
        {
          icon: "👩‍👧",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Aries.4.text"
        }
      ],
      Taurus: [
        {
          icon: "😏",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Taurus.0.text"
        },
        {
          icon: "💁‍♀️",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Taurus.1.text"
        },
        {
          icon: "💆‍♀️",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Taurus.2.text"
        },
        {
          icon: "💔",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Taurus.3.text"
        },
        {
          icon: "👩‍👧",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Taurus.4.text"
        }
      ],
      Gemini: [
        {
          icon: "😃",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Gemini.0.text"
        },
        {
          icon: "😨",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Gemini.1.text"
        },
        {
          icon: "🌫",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Gemini.2.text"
        },
        {
          icon: "🙅‍♀️",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Gemini.3.text"
        },
        {
          icon: "👩‍👧",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Gemini.4.text"
        }
      ],
      Cancer: [
        {
          icon: "👨‍👩‍👧‍👦",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Cancer.0.text"
        },
        {
          icon: "🤗",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Cancer.1.text"
        },
        {
          icon: "🙇‍♀️",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Cancer.2.text"
        },
        {
          icon: "💆‍♀️",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Cancer.3.text"
        },
        {
          icon: "👩‍👧",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Cancer.4.text"
        }
      ],
      Leo: [
        {
          icon: "💃",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Leo.0.text"
        },
        {
          icon: "🤯",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Leo.1.text"
        },
        {
          icon: "🍻",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Leo.2.text"
        },
        {
          icon: "💆‍♀️",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Leo.3.text"
        },
        {
          icon: "👩‍👧",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Leo.4.text"
        }
      ],
      Virgo: [
        {
          icon: "🧠",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Virgo.0.text"
        },
        {
          icon: "🕵️‍♀️",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Virgo.1.text"
        },
        {
          icon: "🙅‍♀️",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Virgo.2.text"
        },
        {
          icon: "🤗",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Virgo.3.text"
        },
        {
          icon: "👩‍👧",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Virgo.4.text"
        }
      ],
      Libra: [
        {
          icon: "😘",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Libra.0.text"
        },
        {
          icon: "⚖️",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Libra.1.text"
        },
        {
          icon: "🙅‍♀️",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Libra.2.text"
        },
        {
          icon: "🤝",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Libra.3.text"
        },
        {
          icon: "👩‍👧",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Libra.4.text"
        }
      ],
      Scorpio: [
        {
          icon: "🤨",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Scorpio.0.text"
        },
        {
          icon: "😖",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Scorpio.1.text"
        },
        {
          icon: "🤾‍♀️",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Scorpio.2.text"
        },
        {
          icon: "😢",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Scorpio.3.text"
        },
        {
          icon: "🤗",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Scorpio.4.text"
        }
      ],
      Sagittarius: [
        {
          icon: "🤩",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Sagittarius.0.text"
        },
        {
          icon: "😎",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Sagittarius.1.text"
        },
        {
          icon: "👩‍🏫",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Sagittarius.2.text"
        },
        {
          icon: "🤔",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Sagittarius.3.text"
        },
        {
          icon: "😄",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Sagittarius.4.text"
        }
      ],
      Capricorn: [
        {
          icon: "🤐",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Capricorn.0.text"
        },
        {
          icon: "😫",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Capricorn.1.text"
        },
        {
          icon: "😔",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Capricorn.2.text"
        },
        {
          icon: "🕵️‍♀️",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Capricorn.3.text"
        },
        {
          icon: "👩‍👧",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Capricorn.4.text"
        }
      ],
      Aquarius: [
        {
          icon: "🤗",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Aquarius.0.text"
        },
        {
          icon: "🕵️‍♀️",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Aquarius.1.text"
        },
        {
          icon: "🙅‍♀️",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Aquarius.2.text"
        },
        {
          icon: "😶",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Aquarius.3.text"
        },
        {
          icon: "😜",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Aquarius.4.text"
        },
        {
          icon: "👩‍👧",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Aquarius.5.text"
        }
      ],
      Pisces: [
        {
          icon: "💆‍♀️",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Pisces.0.text"
        },
        {
          icon: "😥",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Pisces.1.text"
        },
        {
          icon: "🧘‍♀️",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Pisces.2.text"
        },
        {
          icon: "🏕",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Pisces.3.text"
        },
        {
          icon: "🤗",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Pisces.4.text"
        },
        {
          icon: "👩‍👧",
          text: "chronos.app.instruments.widgets.prompts.planets.moon.interpretation.signs.Pisces.5.text"
        }
      ]
    }
  }
};

export default planet;
