import { useTranslation } from 'src/i18n/useTranslation';
import React from 'react';

import './event-input.js';

export default function EventInput(props: any) {
  const ref = React.useRef<HTMLDivElement & {
    events: any;
    onSupport: any;
    onComplete: any;
  }>(null);

  React.useEffect(() => {
    const { current } = ref;
    if (!current) { return }

    current.events = props.events;
    current.onSupport = props.onSupport;
    current.onComplete = props.onComplete;

    current.style.setProperty('--foreground', 'var(--dropdown-color)');
    current.style.setProperty('--background', 'var(--dropdown-background)');
    current.style.setProperty('--primary', 'var(--font-primary)');
    current.style.setProperty('--secondary', 'var(--font-inverse)');
  }, [props]);

  // @ts-ignore
  return <event-input ref={ref}></event-input>;
}
