import React from 'react';
import { render } from 'react-dom';
import styled, { css } from 'styled-components';

import { IObject, ObjectType, signs } from 'src/libs';

import { objectsIcons, signsIcons } from 'src/icons';
import store from 'src/store';
import { ICustomizationData } from 'src/api';
import { WidgetTypes } from 'src/pages/Instruments/Widgets/Widget';

interface LRObject {
  id: number;
  lon: number;
  alon: number;
  speed: number;
}

interface HorarProps {
  isActiveWidgets?: {id: WidgetTypes, isActive?: string | boolean}[];
  objects: IObject[];
}

function arrangeObjects(objects: LRObject[], dist: number) {
  const length = 30;
  const res = objects.sort((o1, o2) => o1.alon - o2.alon);

  for (let i = 0; i < 100; i++) {
    const moves = res.map(() => 0);

    for (let j = -1; j < res.length - 1; j++) {
      const min = j === -1 ? res[res.length - 1].alon - length : res[j].alon;
      const max = res[j + 1].alon;
      const d = Math.max(0, (dist - max + min) / 2);
      moves[j === -1 ? res.length - 1 : j] -= d;
      moves[j + 1] += d;
    }

    res.forEach((a, i) => a.alon += moves[i]);

    if (!moves.some(m => Math.abs(m) >= 0.1)) { break }
  }

  return res;
}

export default class Horar extends React.Component<HorarProps> {
  private readonly _dots = React.createRef<HTMLDivElement>();
  private readonly _groups = React.createRef<HTMLDivElement>();
  private readonly _ref = React.createRef<HTMLDivElement>();
  private readonly personalization: ICustomizationData = store.settings.personalization.current;

  componentDidMount() {
    this.update(this.props);
    window.addEventListener('sidebar', this.fixPosition);
  }

  componentWillUnmount() {
    window.removeEventListener('sidebar', this.fixPosition);
  }

  shouldComponentUpdate(newProps: HorarProps) {
    this.update(newProps);
    return false;
  }

  renderObjects = (objects: LRObject[]) => {
    for (const { id, lon, alon, speed } of objects) {
      const sign = Math.floor(lon / 30);
      const color = `rgb(var(--circle-zodiacs-${signs[sign].element}-rgb-${this.personalization.circle.colorThemes.zodiacs.active}))`;

      const dot = this._dots.current!.childNodes[id] as HTMLDivElement;
      dot.style.border = `2px solid ${color}`;
      dot.style.top = `calc(${lon % 30 * 10 / 3}% - 0.175em)`;
      dot.style.left = id <= ObjectType.Saturn ? '0.25em' : '-0.75em';

      const group = this._groups.current!.childNodes[id] as HTMLDivElement;
      group.style.top = `${alon % 35 * 100 / 30}%`;

      const SignIcon: any = signsIcons[sign];
      const ObjIcon: any = objectsIcons[id];

      render(
        <>
          <SignIcon style={{
            position: 'absolute',
            color,
            left: id <= ObjectType.Saturn ? '1.25em' : '-2.75em'
          }} />
          <ObjIcon style={{
            position: 'absolute',
            color: 'var(--text-primary)',
            left: id <= ObjectType.Saturn ? '2.75em' : '-4.25em'
          }} />
          {speed < 0 && <span style={{
            position: 'absolute',
            color: 'var(--text-primary)',
            left: id <= ObjectType.Saturn ? '5em' : '-3.75em',
            bottom: '0.5em',
            fontSize: '0.8em'
          }}>r</span>}
        </>,
        group
      );
    }
  };

  fixPosition = () => {
    const wigetContainerEl = document.getElementById('widget-container');
    if (this._ref?.current) {
      const indent = (wigetContainerEl ? wigetContainerEl.clientWidth : 0) + (this.props?.isActiveWidgets?.length ? document.documentElement.clientWidth / 20 : document.documentElement.clientWidth / 12);
      this._ref.current!.style.right = this.props.isActiveWidgets?.length ? `${indent}px` : `${indent}px`;
    }
  }

  update = (newProps: HorarProps) => {
    const lobjects: LRObject[] = [];
    const robjects: LRObject[] = [];


    this.fixPosition();

    for (let id = ObjectType.Sun; id <= ObjectType.Pluto; id++) {
      const { lon, speed } = newProps.objects[id];
      const objects = id <= ObjectType.Saturn ? robjects : lobjects;
      objects.push({
        id,
        lon,
        alon: lon % 30,
        speed
      });
    }

    const dist = 1.25;

    this.renderObjects(arrangeObjects(lobjects, dist));
    this.renderObjects(arrangeObjects(robjects, dist));
  };

  render() {
    const dashes: any[] = [];

    for (let i = 0; i <= 30; i += 1) {
      if (i % 5 == 0) {
        dashes.push(<DashNum key={i.toString()} style={{ top: `calc(${100 * i / 30}% - 0.75em)` }}>{i}</DashNum>);
      } else {
        dashes.push(<Dash key={i.toString()} style={{ top: `${100 * i / 30}%` }} />);
      }
    }

    const dots: any[] = [];
    const groups: any[] = [];

    for (let i = ObjectType.Sun; i <= ObjectType.Pluto; i++) {
      dots.push(<Dot key={i.toString()} />);
      groups.push(<Group key={i.toString()} />);
    }

    return (
      <Container isActiveWidgets={this.props.isActiveWidgets} ref={this._ref}>
        {dashes}

        <Groups ref={this._dots}>
          {dots}
        </Groups>

        <Groups ref={this._groups}>
          {groups}
        </Groups>
      </Container>
    );
  }
}

const Container = styled.div<{ isActiveWidgets?: any }>`
  display: flex;  
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  right: 10%;

  max-height: 80vh;
  height: 75%;
  top: 10vh;

  svg {
    width: 1.5em;
  }
`;

const Dash = styled.span`
  position: absolute;
  width: 0.5em;
  border-top: 1px solid var(--text-secondary);
`;

const DashNum = styled.span`
  position: absolute;
  width: 2.5em;
  height: 1.5em;
  color: var(--text-secondary);
  background-color: var(--bg-100);
  border-radius: 1.5em;
  font-size: 0.75em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Groups = styled.div`
  position: absolute;
  height: 100%;
`;

const Group = styled.div`
  display: flex;  
  align-items: center;  
  position: absolute;
`;

const Dot = styled.div`
  position: absolute;
  border: 0.1em solid white;
  border-radius: 50%;
  width: 0.5em;
  height: 0.5em;
  box-sizing: border-box;
`;
