import * as i18n from 'react-i18next';

// FIXME: duplicate

export interface ITranslator { 
  t: (v: string, vars?: any) => string;
  U: (v: string, vars?: any) => string;
  l: (v: string, vars?: any) => string;
}

export const defaultTranslator: ITranslator = {
  t: (v) => v,
  U: (v) => v,
  l: (v) => v
};

export function useTranslation(): ITranslator {
  const { t } = i18n.useTranslation();

  const result = { 
    t,
    U: (v: string, vars?: any) => {
      const x = t(v, vars);
      return x ? x[0].toUpperCase() + x.substring(1) : '';
    },
    l: (v: string, vars?: any) => {
      const x = t(v, vars);
      return x ? x[0].toLowerCase() + x.substring(1) : '';
    }
  }

  if(!window.hasOwnProperty('translator')) (window as any).translator = result; 

  return result;
}