import React from 'react';
import styled, { css } from 'styled-components';

export default function SettingsGroup({
  title = '',
  subtitle = '',
  disabled,
  children = null
}: {
  title?: string;
  subtitle?: string;
  disabled?: { title: string; message: string };
  children: React.ReactNode | Array<React.ReactNode>;
}) {

  return (
    <Container disabled={Boolean(disabled)}>
      <header>
        {(title || subtitle) &&
          <div className="title-block">
            <div className="title">{title}</div>
            <div className="subtitle">{subtitle}</div>
          </div>
        }
      </header>
      <div className="content">
        {children}
      </div>

      {
        disabled &&
        <div className="message">
          <h3>{disabled.title}</h3>
          <p>{disabled.message}</p>
        </div>
      }
    </Container>
  );
}

export const Container = styled.div<{ disabled?: boolean }>`
  position: relative;
  margin-bottom: 2.125rem;

  & > header {
    display: flex;
    justify-content: space-between;
    color: var(--text-primary);

    & .title {
      font-size: 1.125rem;
      line-height: 1.312rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    & .subtitle {
      font-size: 0.875rem;
      line-height: 1.312rem;
      color: var(--text-secondary);
      margin-bottom: 1.125rem;
    }
  }

  & .tapped-block + .tapped-block {
    margin-left: 1.312rem;
  }

  ${props => props.disabled && css`
    & > .message {
      display: flex;
      position: absolute;
      flex-direction: column;
      width: 80%;
      /* max-height: 80%; */
      padding: 5rem 3.125rem;
      background-color: var(--workspace-widget-background);
      border-radius: 0.5rem;
      color: var(--text-primary);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); 
      z-index: 4;

      h3{
        margin: 0;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 1.75rem;
        text-align: center;
      }

      h3 + p {
        font-size: 1.125rem;
        line-height: 1.687rem;
        text-align: center;
      }
    }

    &:after {
      display: block;
      position: absolute;
      content: '';
      top: -0.312rem;
      left: -0.312rem;
      right: -0.312rem;
      bottom: -0.312rem;
      background-color: var(--disabled-layer-color);
      border-radius: 4px;
      z-index: 3;
    }
  `}
`;
