import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import TW from 'src/ui/Wrappers/TooltipWrapper';

import { CloseSmallIcon } from 'src/assets/icons/system';

import { IStickyHeaderProps } from '../types';

import { scrollOffset } from '../index';
import { useTranslation } from 'src/i18n/useTranslation';

const StickyHeader: React.FC<IStickyHeaderProps> = ({
  title,
  icon,
  onClose,
  scrollTo,
  scrollRef,
  menuItems
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const currentBlockId = useRef<string>('first');

  useEffect(() => {
    //@ts-ignore
    const scrollEl = scrollRef!.current! as HTMLDivElement;
    scrollEl.addEventListener('scroll', onScroll);
    return () => {
      scrollEl.removeEventListener('scroll', onScroll);
    };
  }, []);

  const onScroll = (e: Event) => {
    const scrollEl = e.target as HTMLDivElement;
    const menuEl = scrollEl.querySelector('#menu-block') as HTMLElement;
    const top = scrollEl.scrollTop;
    if (top > menuEl.offsetTop + 40) {
      headerRef.current!.classList.add('active');
    } else {
      headerRef.current!.classList.remove('active');
    }

    updateMenu(top);
  };

  const updateMenu = (top: number) => {
    const menuItems = menuRef.current!.querySelectorAll('ul li');

    for (let i = menuItems.length - 1; i >= 0; i--) {
      const menuItem = menuItems[i] as HTMLLIElement;
      const blockId = menuItem.dataset.blockId || 'first';
      //@ts-ignore
      const blockEl = scrollRef!.current!.querySelector(`#${blockId}-block`);

      if(blockEl) {
        if (top + scrollOffset + 10 >= blockEl.offsetTop) {
          if (blockId !== currentBlockId.current) {
            menuItems.forEach(item => item.classList.remove('active'));
            menuItem.classList.add('active');
            currentBlockId.current = blockId;
          }
          break;
        }
      }
    }
  };
  const { t } = useTranslation();
  return <Container ref={headerRef}>
    <header>
      <h2>{icon}&nbsp;{t(title)}</h2>
      <SmallCloseIcon onClick={onClose}></SmallCloseIcon>
    </header>
    <Menu ref={menuRef}>
      <ul>
        {menuItems.map((item: any, index: number) => <li
          className={index === 0 ? 'active' : ''}
          onClick={() => scrollTo(`#${item.id}-block`)}
          data-block-id={item.id}
          key={item.id}
        >{t(item.name)}</li>)}
      </ul>
    </Menu>
  </Container>;
};

// <li onClick={() => {}} style={{'cursor': 'default'}} data-block-id="conf"><TW text="Функционал в разработке">Конфигурации</TW></li>

const Container = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 20;
  box-sizing: border-box;
  transform: translateY(-100%);
  transition: transform 0.5s;
  
  padding: 1.25rem 1.25rem 0rem;
  color: var(--text-secondary);
  background: var(--workspace-widget-background);
  box-shadow: var(--workspace-box-shadow);

  /* &.active {
    transform: translateY(0);
  } */

  & header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.375rem;

    & h2 {
      display: flex;
      align-items: center;
      margin: 0;
      font-size: 1.125rem;
      line-height: 1.125rem;
      font-weight: 600;
      color: var(--text-primary);

      & svg {
        margin-right: 0.675rem;
        height: 1.5rem;
        color: var(--text-secondary);
      }
    }
  }
`;

const SmallCloseIcon = styled(CloseSmallIcon)`
  width: 1.5rem;
  cursor: pointer;

  &:hover{
    color: var(--text-primary);
  }
`;

const Menu = styled.nav`
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0.875rem;

    & li {
      display: inline-block;
      position: relative;
      padding-bottom: 1.125rem;
      margin-right: 2.25rem;
      cursor: pointer;

      &.active {
        color: var(--text-primary);
        
        &:before{
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          height: 0.125rem;
          width: 100%;
          background-color: var(--accent-blue);
        }
      }
    }
  }
`;

export default StickyHeader;
