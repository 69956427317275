import React, { lazy } from 'react';

const sections: { [key: string]: React.LazyExoticComponent<any> } = {
  'payments': lazy(() => import('./payments/index')),
  'personal': lazy(() => import('./Personal')),
  'consultations': lazy(() => import('./Consultations/index')),
  'maps': lazy(() => import('./Maps')),
  'changes': lazy(() => import('./Changes')),
  'editor': lazy(() => import('../PersonalizationEditor'))
};

export default sections;
