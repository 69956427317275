import React, { Suspense } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useKeyHook } from 'src/hooks';
import { observer } from 'mobx-react';

import store from 'src/store';
import modalComponent from './components/index';

export default observer(function DashboardModal() {

  const { dashboard: dashboardStore } = store;
  const Component = modalComponent[dashboardStore?.modalData?.component];

  useKeyHook(['Escape'], {
    onKeyDown() {
      dashboardStore.hideModal();
    }
  }, []);

  return (
    dashboardStore.modalData ?
      <>
        {
          dashboardStore.modalData.options.overlay && <Overlay className="modal-overlay"/>
        }
        <Container className="modal-wrapper">
          <Suspense fallback={<span>loading...</span>}>
            {/* @ts-ignore */}
            <Component payload={dashboardStore?.modalData?.payload} onClose={() => dashboardStore.hideModal()}/>
          </Suspense>
        </Container>
      </>
      : null
  );
});

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--layer-color);
  z-index: 997;
`;

const Container = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 0px;
  height: 0px;
  top: 50%;
  left: 50%;
  /* margin-top: -1rem; */
  z-index: 998;
`;
