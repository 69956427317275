import { IPromptDataAspect } from '../../types';

import { QuincunxIcon as icon } from 'src/assets/icons/astro/aspects';

const aspect: IPromptDataAspect = {
  type: "Quincunx",
  deg: 150,
  name: "chronos.app.instruments.widgets.prompts.aspects.quincunx.name",
  icon,
  description: {
    list: [
      {
        text: "chronos.app.instruments.widgets.prompts.aspects.map.aspects.Quincunx.0"
      }
    ]
  }
};

export default aspect;


