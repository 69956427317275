import { IPromptDataHouse } from '../../types';

import House1 from './1';
import House2 from './2';
import House3 from './3';
import House4 from './4';
import House5 from './5';
import House6 from './6';
import House7 from './7';
import House8 from './8';
import House9 from './9';
import House10 from './10';
import House11 from './11';
import House12 from './12';

const houses: IPromptDataHouse[] = [
  House1,
  House2,
  House3,
  House4,
  House5,
  House6,
  House7,
  House8,
  House9,
  House10,
  House11,
  House12
];

export default houses;
