import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';


import dayjs, { ManipulateType } from 'dayjs';
import MinMax from 'dayjs/plugin/minMax';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(MinMax);
dayjs.extend(timezone);

import { debugRender } from 'src/hooks';
import { checkGenChanged, months } from 'src/utils';
import Notify from 'src/ui/Wrappers/Notify';
import { Button, fillNumber, IFormData } from 'src/libs';
import { nowISOString, parseISOString } from 'src/api';
import { t } from 'i18next';

export type TimeLineMode = null | 'year' | 'month' | 'day' | 'hour' | 'minute' | 'second';

const modes = [
  'year',
  'month',
  'day',
  'hour',
  'minute',
  'second'
];

export interface ITimeLineProps {
  mode: TimeLineMode;
  value: string;
  onChange(dt: string, clearGen?: boolean): void;
  onHide(): void;
  form: IFormData;
  isNatal: boolean;
  openInTemporaryMap(dateTime: string): void;
}

interface StateType {
  notification: boolean
}

export default class TimeLine extends React.Component<ITimeLineProps, StateType> {
  private readonly _ref = React.createRef<HTMLDivElement>();
  private _items: HTMLSpanElement[] = [];
  private _middle = 0;
  private _interval = 0;
  private _mode: TimeLineMode = null;
  private _value = nowISOString();
  private touchStartX: number = 0;

  private changedMiddle: number = 0;
  private changedValue: string = '';

  public state = {
    notification: false
  }

  private getMoment(i: number) {
    const m = dayjs.utc(this._value).add(i, this._mode as ManipulateType);
    return parseISOString(m.toISOString());
  }

  private getValue(i: number) {
    return this.getMoment(i)[modes.indexOf(this._mode ?? '')];
  }

  private isTime() {
    return this._mode == 'hour' || this._mode == 'minute' || this._mode == 'second';
  }

  private createFirst(i: number) {
    const label = document.createElement('span');

    label.textContent = (() => {
      const m = this.getMoment(i);

      switch (this._mode) {
        case 'second': return `${fillNumber(m[3])}:${fillNumber(m[4])}`;
        case 'minute': return m[3].toString();
        case 'hour': return `${fillNumber(m[2])}.${fillNumber(m[1])}.${fillNumber(m[0], 4)}`;
        case 'day': return t(months[m[1] - 1]);
        case 'month': return fillNumber(m[0], 4);
      }
      return '';
    })();

    label.classList.add('first');

    return label;
  }

  componentDidMount() {
    window.addEventListener('resize', this.update);
    window.addEventListener('keydown', this.onKeyDown);
    window.addEventListener('click', this.onClick);
    window.addEventListener('wheel', this.onWheel);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.update);
    window.removeEventListener('keydown', this.onKeyDown);
    window.removeEventListener('click', this.onClick);
    window.removeEventListener('wheel', this.onWheel);
  }

  shouldComponentUpdate(props: ITimeLineProps) {
    if (props.mode != this._mode || props.value != this._value) {
      props.mode ? this.disableWidgetsScroll() : this.enableWidgetsScroll();

      this._mode = props.mode;
      this._value = props.value;

      this.update();

      return true;
    }

    return false;
  }

  disableWidgetsScroll() {
    const widgetContainer = document.getElementById('widget-container');
    const mapsContainer = document.getElementById('maps-container');
    if (widgetContainer) widgetContainer.style.overflow = 'hidden';
    if (mapsContainer) mapsContainer.style.overflow = 'hidden';
  }

  enableWidgetsScroll() {
    const widgetContainer = document.getElementById('widget-container');
    const mapsContainer = document.getElementById('maps-container');

    if (widgetContainer) widgetContainer.style.overflow = 'auto';
    if (mapsContainer) mapsContainer.style.overflow = 'auto';
  }

  onWheel = (ev: any) => {
    if (!this._mode) return;
    // if (!this._mode || ev.y / window.innerHeight < 0.9) return;
    this.move(Math.sign(ev.deltaY));
  };

  onTouchStart = (ev: any) => {
    ev.stopPropagation();
    const { clientX } = ev?.changedTouches[0];
    this.touchStartX = clientX;
  }

  onTouch = (ev: any) => {
    ev.stopPropagation();
    const { clientX } = ev?.changedTouches[0];
    this.move(Math.sign(this.touchStartX - clientX));
  }

  onKeyDown = _.throttle((ev: any) => {
    if (!this._mode) { return }
    if (ev.key === 'Escape') this.props.onHide();

    if (ev.key === 'ArrowLeft') this.move(-1);
    if (ev.key === 'ArrowRight') this.move(1);
  }, 100);

  onClick = (ev: any) => {
    if (!this._ref.current!.contains(ev.target)) {
      this.props.onHide();
    }
  };

  move(dir: number) {
    const minDate = dayjs('1200-0-1');
    const maxDate = dayjs('2400-0-1');
    const prevDate = dayjs(this._value);
    const date = dayjs.utc(this._value).add(dir, this._mode as ManipulateType);

    if (dayjs.min(date, minDate) === date) {
      dir = minDate.diff(prevDate, this._mode!);
    }

    if (dayjs.max(date, maxDate) === date) {
      dir = maxDate.diff(prevDate, this._mode!);
    }

    const currentValue = dayjs.utc(this._value).add(dir, this._mode as ManipulateType).toISOString();

    const {
      gen,
      natal: { dt, place: _place }
    } = this.props.form;

    const newValue = dayjs.utc(this._value).add(dir, this._mode as ManipulateType).toISOString();
    const isChanged = checkGenChanged(gen, currentValue, dt, _place, _place);

    if (this.props.isNatal && isChanged) {
      this.setState({ notification: true });
      this.changedMiddle = this._middle + dir;
      this.changedValue = newValue;
      this.forceUpdate();
    } else {
      this._middle += dir;
      this._value = newValue;
      this.props.onChange(this._value);
      this.refresh();
    }
  }

  openIntemporaryMap() {
    this.props.openInTemporaryMap(this.changedValue);
    this.setState({ notification: false });
    this.forceUpdate();
  }

  applyChanges() {
    this._middle = this.changedMiddle;
    this._value = this.changedValue;

    this.props.onChange(this._value, true);
    this.refresh();

    this.setState({ notification: false });
    this.forceUpdate();
  }

  update = () => {
    const ref = this._ref.current as HTMLDivElement;
    ref.style.display = this._mode ? 'flex' : 'none';

    if (!this._mode) { return }

    const rect = ref.getBoundingClientRect();

    this._middle = this._interval = 2 * Math.ceil(rect.width / 60);

    this._items = [];

    while (ref.firstChild) {
      ref.removeChild(ref.firstChild);
    }

    for (let i = 0; i <= this._interval * 2; i++) {
      const item = document.createElement('span');
      item.textContent = this.getValue(i - this._interval).toString();
      this._items.push(item);
      ref.append(item);
    }

    this.refresh();
  };

  refresh() {
    while (this._middle < this._interval) {
      const item = this._items.pop() as HTMLSpanElement;
      this._items.unshift(item);
      this._ref.current!.prepend(item);
      this._middle++;
    }

    while (this._middle > this._interval) {
      const item = this._items.shift() as HTMLSpanElement;
      this._items.push(item);
      this._ref.current!.append(item);
      this._middle--;
    }

    const itemWidth = this._mode == 'year' ? 90 : 60;

    const firstValue = this.isTime() ? 0 : 1;

    for (let i = 0; i <= this._interval * 2; i++) {
      const item = this._items[i];

      let centerK = 0; // смещение центрального от остальных
      if (i - this._middle > 0) { centerK = 15 }
      else if (i - this._middle < 0) { centerK = -15 }

      const martingLeftPX = (i - this._middle) * itemWidth + centerK;
      const martingLeftRem = martingLeftPX / 16;
      item.style.marginLeft = `${martingLeftRem}rem`;

      const value = this.getValue(i - this._interval);

      item.textContent = value.toString();

      if (i == this._interval) {
        item.classList.add('current');
      } else {
        item.classList.remove('current');
      }

      item.onclick = (ev: any) => {
        if (ev.target.closest('.current')) {
          this.props.onHide();
        } else {
          this.move(i - this._middle);
        }
      };

      if (value == firstValue) {
        item.append(this.createFirst(i - this._interval));
      }

      if (this._mode == 'year') {
        item.classList.add('in-range');
      } else {
        item.classList.remove('in-range');
      }
    }

    if (this._mode != 'year') {
      const mode = this._mode as ManipulateType;

      const min = this.isTime() ? 0 : 1;
      const max = (() => {
        switch (mode) {
          case 'hour': return 23;
          case 'day': return dayjs(this._value).daysInMonth();
          case 'month': return 12;
        }
        return 59;
      })();

      const value = this.getValue(0);
      const first = Math.max(0, this._interval - value + min);
      const last = Math.min(this._interval * 2, this._interval + (max - value));

      for (let i = first; i <= last; i++) {
        this._items[i].classList.add('in-range');
      }
    }
  }

  render() {
    debugRender && console.log('TimeLine');
    return (
      <Wrapper>
        <Container
          ref={this._ref}
          onTouchStart={this.onTouchStart}
          onTouchEnd={this.onTouch}
        />
        {
          (this.state.notification === true)
          ? (
              <Notify
                className='notification'
                type={'warning'}
                title={t("chronos.app.timeControl.buildingsWillBurn")}
                content={t("chronos.app.timeControl.ifUoyChangeDataWarning")}
                buttons={
                  <>
                    <Button
                      style={{ background: `var(--colors-blue)` }}
                      size="small"
                      onClick={() => { this.openIntemporaryMap() }}
                    >
                      {t("chronos.app.instruments.openInTmpMap")}
                    </Button>
                    <Button
                      size="small"
                      color="transparent"
                      style={{ background: 'transparent', border: 'none', marginLeft: '1rem' }}
                      onClick={() => {
                        this.applyChanges();
                      } }
                    >
                      {t("chronos.auth.confirmationEmail.changeData")}
                    </Button>
                  </>
                }

                onClose={() => {
                  this.setState({ notification: false });
                  this.forceUpdate();
                }}
                style={{ top: '-16rem', left: '38%' }}
              />
            )
          : (null)
        }
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  position: absolute;
  bottom: 4.5rem;
  left: 0;
  width: 100%;
`;

const Container = styled.div`
  position: absolute;
  display: none;

  top: 0;
  left: 0;
  width: 100%;

  align-items: center;
  justify-content: center;

  & > span {
    color: var(--text-third);
    position: absolute;
    cursor: pointer;
    user-select: none;
    text-align: center;
    font-size: 0.875rem;

    transition: margin-left 0.2s linear;
    padding: 0.05rem 1rem;

    &:hover {
      opacity: 0.6;
    }
  }

  .current {
    background-color: var(--accent-blue);
    color: var(--colors-white) !important;
    border-radius: 1rem;
    min-height: 1.5rem;
    display: flex;
    align-items: center;
    font-size: 1rem;
    padding: 0 0.5rem;

    &:hover {
      opacity: 0.9;
    }
  }

  .in-range {
    color: var(--text-primary);
  }

  .first {
    position: absolute;
    bottom: 2rem;
    left: 1.3rem;
    font-size: 0.8rem;
    white-space: nowrap;
  }
`;
