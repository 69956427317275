

import React, { useEffect } from 'react';

import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { Suspense } from 'react';

import Instruments from 'src/pages/Instruments';
import AppSettings from 'src/pages/Settings';

import NotFound from 'src/pages/NotFound';
import { routes, history } from 'src/router';
import { IAuth, INotification, Notifications } from 'src/libs';
import MultiNotifications from '../MultiNotifications';
import api from 'src/api';
import ModalAlert from 'src/pages/Instruments/Widgets/TablePrediction/modal';
import store from 'src/store';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

const Consultations = React.lazy(() => import('src/pages/Consultations'));
const Dashboard = React.lazy(() => import('src/pages/Dashboard'));
const Meeting = React.lazy(() => import('src/pages/Consultations/Meeting'));
const Courses = React.lazy(() => import('src/pages/Courses'));

export default  observer(function RouterSwitch({
  auth
}: {
  auth: IAuth
}) {
  const [notifications, setNotifications] = React.useState<INotification[]>([]);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [nextLocation, setNextLocation] = React.useState<string | null>(null);
  const unblockHandle = React.useRef<any>();
  const [countCalc, setCountCalc] = React.useState<number>(0);

  useEffect(() => {
    const ntfs: INotification[] = auth.notifications;
    const ntfs_: INotification[] = ntfs.filter(incN =>
      incN.id !== 'private_group' &&
      !incN.actions?.includes('update') &&
      !notifications.some(stN => incN.id === stN.id)
    );
    
    setNotifications(ntfs_);
  }, [auth])

  const onCloseNotification = (items: INotification[]) => {
    api.closeNotifications(items.map(n => ({
      id: n.id,
      value: n.value
    })));
    auth.notifications = notifications.slice(items.length);
    setNotifications(auth.notifications);
  };
  
  
  useEffect(() => {
    
    const res = toJS(store.instruments.countCalcTablePrediction.value);
    setCountCalc(res)
    unblockHandle.current = history.block(({ pathname, search }) => {

      const calcId = new URLSearchParams(history.location.search).get('id')
      // const startCalc = calcId !== null ? store.instruments.startStopCalcTablePrediction[+calcId]?.value : false
      const startCalc = store.instruments.startStopCalcTablePrediction2.value
        // if (countCalc > 0 && countCalc <= 98 && 
          if (res > 0 && res <= 98 && 
            (history.location.pathname !== pathname || 
              (history.location.pathname === pathname && new URLSearchParams(history.location.search).get('id') !== new URLSearchParams(search).get('id')) 
            ) && startCalc) {
              
              handleShowModal(pathname+search)
        return false;
      }
      });
    return function () {
      unblockHandle.current.current && unblockHandle.current.current()
    }
  }, [store.instruments.countCalcTablePrediction.value])

  function handleShowModal(nextLocation: string) {
    setIsModalVisible(true)
    setNextLocation(nextLocation);
  }
  function handleCancel() {
    setIsModalVisible(false)
    setNextLocation(null);
  }
  function handleConfirm() {
    if (unblockHandle) {
      unblockHandle.current()
    }
    setIsModalVisible(false)
    if(nextLocation){
      history.push(nextLocation)
    }
    setNextLocation(null);
    setCountCalc(0)
    store.instruments.setstartStopCalcTablePrediction2(false)
    store.instruments.isCountUpdateBlocked = true;
    store.instruments.setCountCalcTablePrediction(0)
    setTimeout(() => {
      store.instruments.isCountUpdateBlocked = false;
    }, 1000);
  }

  return <>
    <Router history={history}>
      <Switch>
        <Redirect from='/' exact to={routes.Dashboard.path} />

        <Route path={routes.Instruments.path} exact component={Instruments} />
        <Route path={routes.Dashboard.path} exact render={() => (<Suspense fallback={<div></div>}><Dashboard/></Suspense>)} />

        <Redirect from={routes.AppSettings.path} exact to={`${routes.AppSettings.path}/payments`} />
        <Route path={`${routes.AppSettings.path}/:section([A-Za-z]+)?`} exact component={AppSettings}/>
        <Route path={`${routes.AppSettings.path}/:section/:subsection`} exact component={AppSettings} />

        <Route path={routes.Consultations.path} exact render={() => (<Suspense fallback={<div></div>}><Consultations/></Suspense>)} />
        <Route path={routes.Meeting.path} exact render={() => (<Suspense fallback={<div></div>}><Meeting/></Suspense>)} />

        <Route path={routes.Courses.path} render={() => (<Suspense fallback={<div></div>}><Courses/></Suspense>)} />

        <Route component={NotFound} />
      </Switch>
      
      {notifications.length > 0 && <MultiNotifications
        items={notifications}
        onClose={onCloseNotification}
      />}

      <ModalAlert
        countCalc={countCalc}
        isOpen={isModalVisible}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    </Router>
    <Notifications />
  </>
})