import React, { useEffect, useState, Fragment, ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { ReactMemo } from 'src/hooks';

import { Dispositors } from 'src/helpers/Dispositors';
import { ObjectType, StatusType } from 'src/helpers/utils';
import { default as TW } from 'src/ui/Wrappers/TooltipWrapper';
import { objectsIcons } from 'src/icons';
import { CircleMode, IChain, IChainObject, astroObjects } from 'src/libs';
import { IWidgetProps } from '../TabPanelWrapper';

import {
  ArrowLeft,
  ArrowTop,
  ArrowBottom,
  PathCurve,
  PathHorizontal,
  PathVertical,
  ArrowRight
} from 'src/pages/Instruments/Widgets/Dispositors/paths';
import store from 'src/store';
import { useTranslation } from 'src/i18n/useTranslation';
import { isPartner } from '../../utils';

// FIXME: duplicate
interface IObjIcon {
  objectId: number;
  position: {
    x0: number;
    y0: number;
    x1: number | null;
    y1: number | null;
  };
  element: ReactElement;
}

export default ReactMemo(function DispositorsWidget(props: IWidgetProps) {
  const [chains, setChains] = useState<IChain[] | null>(null);
  const [activeStatus, setActiveStatus] = useState(0);
  const { astro: astroStore } = store;
  const settings = store.activeAstroProfile?.widgets.dispositors;
  const { t } = useTranslation();
  const map = props.tab && isPartner(props.tab as CircleMode)
    ? props.data.maps.find(map => map.mode === props.tab) || props.data.partnersMaps?.[props.tab] || props.data.natalMap
    : props.data.natalMap;

  useEffect(() => {
    setChains(Dispositors(map.objects, activeStatus).chains);
  }, [props.data.maps, activeStatus, props.tab]);

  const RetroIcon = ({ className = undefined }: { className?: string }) => <Retro className={className}>
    <text x="20" y="20" fontSize="10" color="inherit" textAnchor="middle">{'r'}</text>
  </Retro>;

  const highlightObject = (object: any = null) => {
    props.onChanged && props.onChanged(
      'highlights',
      object !== null ? getHighlightedObjects(object) : []
    );
  };

  const getHighlightedObjects = (object: any): any[] => {
    const result: any[] = [];

    const chain = chains!.find(chain => object in chain.objects);

    const generateResult = (obj: any) => {
      result.push({
        id: obj,
        type: 'object',
        map: 'natal'
      });

      chain!.objects[obj].prev.forEach(prev => {
        if (result.some(res => res.id === prev)) { return }
        generateResult(prev);
      });
    };

    generateResult(object);
    return result;
  };

  const isOffset = chains?.some(chain => Object.keys(chain.objects).reduce((acc, object) => {
    if (chain.objects[object].center) { return ++acc }
    return acc;
  }, 0) > 2);

  const generateArrow = (elements: any[]) => elements.map(element => {
    const { x0, x1, y0, y1 } = element.position;

    if (x0 === x1 && y0 === y1 || x1 === null || y1 === null) { return }

    const getVerticalPath = (isCenter: boolean) => {
      const content = [];

      const hasBarrier = elements.some(element => isCenter && element.position.x0 === x0 &&
            (element.position.y0 > y0 && element.position.y0 < y1 || element.position.y0 < y0 && element.position.y0 > y1));

      if (hasBarrier) {
        content.push(
          <Cell column={x0 * 2} row={y0 * 2 + 1} key={`curve_with_barrier_${x0}_${y0}`}>
            <Vector>
              <PathCurve direction={y1 < y0 ? 'bottom' : 'top'} />
            </Vector>
          </Cell>,
          <Cell column={x0 * 2} row={y1 * 2 + 1} key={`arrow_with_barrier_${x0}_${y0}`}>
            <Vector>
              <ArrowRight />
              <PathCurve direction={y1 > y0 ? 'bottom' : 'top'} />
            </Vector>
          </Cell>
        );
      }

      const initial = y0 * 2 + 1 - (y1 * 2 + 1) - (y0 > y1 ? 1 : -1);

      for (let i = initial; i !== 0; y0 > y1 ? i-- : i++) {
        const row = y0 * 2 + 1 - i;

        content.push(
          <Cell
            column={!hasBarrier ? isCenter ? x0 * 2 + 1 : x0 * 2 - 1 : 2}
            row={row}
            key={`arrow_${x0}_${y0}_${x0 !== x1 ? x0 * 2 - 1 : 1}_${i}`}
          >
            <Vector>
              {i === initial && y0 > y1 && !hasBarrier && <ArrowTop />}
              <PathVertical />
              {i === initial && y0 < y1 && !hasBarrier && <ArrowBottom />}
            </Vector>
          </Cell>
        );
      }

      return content;
    };

    return (
      <Fragment key={`frag_${x0}_${y0}`}>
        {x0 !== x1 && <Cell column={x0 * 2} row={y0 * 2 + 1} key={`arrow_horiz_${x0}_${y0}`}>
          <Vector>
            {y0 === y1 && <ArrowLeft />}
            <PathHorizontal />
          </Vector>
        </Cell>}
        {y0 !== y1 && x0 !== x1 && <Cell column={x0 * 2 - 1} row={y0 * 2 + 1} key={`curve_${x0}_${y0}`}>
          <Vector>
            <PathCurve />
          </Vector>
        </Cell>}
        {y0 !== y1 && getVerticalPath(x0 === x1)}
      </Fragment>
    );
  });

  // FIXME: duplicate
  const renderChain = (objects: Record<string, IChainObject>): any[] => {
    const elements: IObjIcon[] = [];
    const passed: string[] = [];

    const chainSize = {
      x: 0,
      y: 0
    };

    const centers: string[] = [];

    const firstCenter: string = Object.keys(objects)
      .find(object => objects[object].center)!;

    const sortCenters = (object: string) => {
      if (centers.includes(object)) { return }

      centers.push(object);

      objects[object].prev.forEach((prev: number) => {
        if (objects[prev].center) { sortCenters(prev.toString()) }
      });
    };

    sortCenters(firstCenter);

    const getObjIcon = (
      objectId: number,
      x0: number,
      y0: number,
      center: boolean,
      minor: boolean
    ): IObjIcon => {
      const ObjIcon = objectsIcons[objectId];

      return {
        objectId,
        position: {
          x0, y0, x1: null, y1: null
        },
        element:
          <Cell className={`cell-${astroObjects[objectId].en}`} onMouseEnter={() => highlightObject(objectId)} onMouseLeave={() => highlightObject()} column={x0 * 2 + 1} row={y0 * 2 + 1} key={`icon_${x0}_${y0}`}>
            <TW text={t(astroObjects[objectId].ru)}>
              <Icon center={center} minor={minor}>
                <ObjIcon className={astroObjects[objectId].en} />
                {minor && <RetroIcon className={'retro'} />}
              </Icon>
            </TW>
          </Cell>

      };
    };

    const generateObject = (
      object: string,
      x0 = 0,
      y0 = 0,
    ) => {
      if (passed.includes(object)) { return }

      if (elements.some((el: any) => el.position.x0 === x0 && el.position.y0 === y0)) {
        y0 = chainSize.y + 1;
      }

      passed.push(object);
      elements.push(getObjIcon(Number(object), x0, y0, objects[object].center, objects[object].minor));
      x0++;

      let passedCenters = false;
      objects[object].prev.map((obj: string | number, i: number) => {
        obj = obj.toString();
        if (objects[obj].center) {
          passedCenters = true;
          return;
        }

        y0 += i === 0 || passedCenters ? 0 : 1;
        while (elements.some((el: any) => el.position.x0 - x0 === 1 && el.position.y0 - y0 >= 0)) { y0 += 1 }

        generateObject(obj, x0, y0);
      });

      chainSize.x = chainSize.x < x0 ? x0 : chainSize.x;
      chainSize.y = chainSize.y < y0 ? y0 : chainSize.y;
    };

    centers.map(object => {
      generateObject(object, isOffset ? 1 : 0);
    });

    elements.map(element => {
      objects[element.objectId].prev.forEach(prev => {
        const prevElement = elements.find(el => el.objectId === prev);

        prevElement!.position.x1 = element.position.x0;
        prevElement!.position.y1 = element.position.y0;
      });
    });

    const elementsRemover = (objectId: number) => {
      elements.map((element, i) => {
        if (element.objectId === objectId) {
          elements.splice(i, 1);
        }
      });
    };

    settings.planets.map((planet: any) => {
      if (planet.id === 'Chiron' && planet.isActive === false) {
        elementsRemover(ObjectType.Chiron);
      } else if (planet.id === 'Lilith' && planet.isActive === false) {
        elementsRemover(ObjectType.Lilith);
      } else if (planet.id === 'Nodes' && planet.isActive === false) {
        elementsRemover(ObjectType.NorthNode);
        elementsRemover(ObjectType.SouthNode);
      } else if (planet.id === 'Selena' && planet.isActive === false) {
        elementsRemover(ObjectType.Selena);
      } else if (planet.id === 'Eris' && planet.isActive === false) {
        elementsRemover(ObjectType.Eris);
      }
    });

    return [
      elements.map(el => el.element),
      generateArrow(elements)
    ];
  };

  return (
    <Container>
      <Tabs>
        <Tab active={activeStatus === StatusType.ruler} onClick={() => setActiveStatus(StatusType.ruler)}>{t("astro.byOwnership")}</Tab>
        <Tab active={activeStatus === StatusType.exaltation} onClick={() => setActiveStatus(StatusType.exaltation)}>{t("astro.byExaltation")}</Tab>
        {/* <Tab active={activeStatus === StatusType.triplicity} onClick={() => setActiveStatus(StatusType.triplicity)}>По родству</Tab>*/}
        {/* <Tab active={activeStatus === StatusType.bounds} onClick={() => setActiveStatus(StatusType.bounds)}>Нейтрально</Tab>*/}
        {/* <Tab active={activeStatus === StatusType.face} onClick={() => setActiveStatus(StatusType.face)}>По вражде</Tab>*/}
        <Tab active={activeStatus === StatusType.fall} onClick={() => setActiveStatus(StatusType.fall)}>{t("astro.byFall")}</Tab>
        <Tab active={activeStatus === StatusType.detriment} onClick={() => setActiveStatus(StatusType.detriment)}>{t("astro.byIssuance")}</Tab>
      </Tabs>
      {
        chains?.map((chain: IChain, i: number) =>
          <Row key={`chain_${i}`} isOffset={isOffset}>
            {renderChain(chain.objects)}
          </Row>
        )
      }
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Tabs = styled.div`
  display: flex;
  border-bottom: 1px solid var(--button-border);
  margin-bottom: 1.875rem;
`;

const Tab = styled.div<{active: boolean}>`
  width: 25%;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 1rem 0;
  text-align: center;
  color: var(--text-secondary);
  ${props => props.active && css`
    border-bottom: 3px solid var(--accent-blue);
    color: var(--text-primary);
  `}
`;

const Row = styled.div<{ isOffset: boolean | undefined }>`
  display: grid;
  grid-template-columns: ${props => props.isOffset && '0'} repeat(auto-fill, 2rem);
  margin-bottom: 2rem;
  grid-auto-rows: 2rem;
`;

const Icon = styled.div<{center: boolean; minor: boolean}>`
  display: flex;
  width: 2rem;
  height: 2rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  background: var(--input-background);
  position: relative;

  border: 1px solid ${props => props.center ? 'var(--accent-blue)' : 'var(--input-border)'};
  border-radius: 4px;
  font-size: 0.875rem;

  & > svg {
    width: 1.125rem;
    color: ${props => props.minor ? 'var(--colors-red)' : 'var(--text-primary)'};
    fill: ${props => props.minor ? 'var(--colors-red)' : 'var(--text-primary)'};
  }
`;

const Cell = styled.div<{column: number; row: number}>`
  display: flex;
  align-items: center;
  grid-column-start: ${props => props.column};
  grid-column-end: ${props => props.column};
  grid-row-start: ${props => props.row};
  grid-row-end: ${props => props.row};
`;

const Vector = styled.svg.attrs(_ => ({
  viewBox: '0 0 20 20',
  width: '2rem',
  height: '2rem',
  fill: 'none',
  stroke: 'currentColor'
}))<{ viewBox: string; width: string; height: string; fill: string ; stroke: string }>``;

const Retro = styled.svg.attrs(_ => ({
  viewBox: '0 0 20 20',
  width: '2rem',
  fill: 'var(--colors-red)',
  color: 'var(--colors-red)'
}))`
  position: absolute;
  stroke-width: 0;
  overflow: initial;
`;
