import React from 'react';
import styled from 'styled-components';

import { astroObjects, ObjectType } from 'src/libs';

import { objectsIcons } from 'src/icons';

import Tooltip from 'src/components/TooltipWrapper';

import { IRule } from './types';

import { DirectionsIcon, NatalIcon } from 'src/assets/icons/maps';
import { useTranslation } from 'src/i18n/useTranslation';

function isObjectType(v: string) {
  return Object.values(ObjectType).some(p => p === v);
}

function housesGroupString(item: {
  label: string;
  items: string[];
}, color: string) {
  const res: any[] = [item.label];
  const { t } = useTranslation();
  item.items.forEach((house, i) => {

    res.push(<Tooltip key={i} text={`<${color}>${t("astro.house")} ${house}</${color}>`}>
      {color === 'purple' && <Purple>{+house}</Purple>}
      {color === 'orange' && <Orange>{+house}</Orange>}
    </Tooltip>);
    if (i < item.items.length - 1) res.push(', ');
  });

  return res;
}

function housesAllString(items: string[], color = 'purple') {
  const kItems = items
    .filter(item => item.substr(0, 1).toUpperCase() === 'K')
    .map(item => item.substr(1));

  const pItems = items
    .filter(item => item.substr(0, 1).toUpperCase() === 'P')
    .map(item => item.substr(1));

  const cItems = items
    .filter(item => item.substr(0, 1).toUpperCase() === 'C')
    .map(item => item.substr(1));

  const allItems = [];
  const { t } = useTranslation();
  if (pItems.length > 0) { allItems.push({ label: t("chronos.app.instruments.widgets.ruleItem.planetsIn"), items: pItems }) }
  if (cItems.length > 0) { allItems.push({ label: t("chronos.app.instruments.widgets.ruleItem.rulers"), items: cItems }) }
  if (kItems.length == 1) { allItems.push({ label: t("chronos.app.instruments.widgets.ruleItem.cusps"), items: kItems }) }
  if (kItems.length > 1) { allItems.push({ label: t("chronos.app.instruments.widgets.ruleItem.cusps"), items: kItems }) }

  if (allItems.length == 0) { return 0 }

  return <>
    {allItems
      .map((item, i) => housesGroupString(item, color))
      .reduce((prev, cur) => [prev, ', ', cur])
    }
  </>;
}

function getDirIcon(s: string) {
  const id = Object.values(ObjectType).findIndex(p => p === s);
  const Icon: any = objectsIcons[id];

  return {
    ru: astroObjects[id].ru,
    icon: <Icon width="1.2em" />
  };
}

export default function RuleItem({ rule }: {
  rule: IRule;
}) {
  const aspectsString = rule.asp?.split(' ').join(', ');
  const { t } = useTranslation();
  const dirs = rule.dir?.split(' ') || [];
  const dirWithoutIcon = dirs.filter(item => item[0] !== '&' && !isObjectType(item));
  const dirHousesString = housesAllString(dirWithoutIcon, 'purple');
  const dirWithIcon = dirs.filter(item => item[0] !== '&' && isObjectType(item)).map(getDirIcon);
  const dirWithConnectIcon = dirs.filter(item => item[0] === '&' && isObjectType(item.substr(1))).map(item => getDirIcon(item.substr(1)));
  const dirWithConnectControl = dirs.filter(item => item.startsWith('&C')).map(item => item.substr(2));

  const nats = rule.nat?.split(' ') || [];
  const natWithoutIcon = nats.filter(item => item[0] !== '&' && !isObjectType(item));
  const natHousesString = housesAllString(natWithoutIcon, 'orange');
  const natWithIcon = nats.filter(item => item[0] !== '&' && isObjectType(item)).map(getDirIcon);
  const natWithConnectIcon = nats.filter(item => item[0] === '&' && isObjectType(item.substr(1))).map(item => getDirIcon(item.substr(1)));
  const natWithConnectControl = nats.filter(item => item.startsWith('&C')).map(item => item.substr(2));

  return <Container>
    <Aspects>{aspectsString}</Aspects>
    <Row>
      <ModeIcon>
        <Tooltip text={t("chronos.app.instruments.widgets.ruleItem.directionToNatal")}>
          <Purple><DirectionsIcon /></Purple>
        </Tooltip>
        <i></i>
      </ModeIcon>
      <Text>
        {dirWithoutIcon.length > 0 && dirHousesString}
        {dirWithoutIcon.length > 0 && dirWithIcon.length > 0 && ', '}
        {dirWithIcon.length > 0 && <Purple>
          {dirWithIcon.map((item, i) => <Tooltip key={`planet_${i}`} text={`<purple>${t(item.ru)}</purple>`}>
            {item.icon}
          </Tooltip>)}
        </Purple>}
        {dirWithConnectIcon.length + dirWithConnectControl.length > 0 && <>
          {`${dirWithoutIcon.length + dirWithIcon.length > 0 ? ', ' : ''}${t("chronos.app.instruments.widgets.ruleItem.planetsInConjuctionWith")} `}
          <Purple>
            {dirWithConnectIcon.map((item, i) =>
              <Tooltip key={`planet_${i}`} text={`<purple>${t(item.ru)}</purple>`}>
                {item.icon}
              </Tooltip>)
            }
          </Purple>
          {dirWithConnectControl.length > 0 && <>
            {`${dirWithConnectIcon.length > 0 ? ', ' : ''}${t("chronos.app.instruments.widgets.ruleItem.planetsInConjuctionWithRules")} `}
            <Purple>{dirWithConnectControl.join(', ')}</Purple>
          </>}
        </>}
      </Text>
    </Row>
    <Row>
      <ModeIcon>
        <Tooltip text={t("chronos.app.instruments.widgets.ruleItem.directionToNatal")}>
          <Orange><NatalIcon /></Orange>
        </Tooltip>
      </ModeIcon>
      <Text>
        {natWithoutIcon.length > 0 && natHousesString}
        {natWithoutIcon.length > 0 && natWithIcon.length > 0 && ', '}
        {natWithIcon.length > 0 && <Orange>
          {natWithIcon.map((item, i) => <Tooltip key={`planet_${i}`} text={`<orange>${item.ru}</orange>`}>
            {item.icon}
          </Tooltip>)}
        </Orange>}
        {natWithConnectIcon.length + natWithConnectControl.length > 0 && <>
          {`${natWithoutIcon.length + natWithIcon.length > 0 ? ', ' : ''}${t("chronos.app.instruments.widgets.ruleItem.planetsInConjuctionWith")} `}
          <Orange>
            {natWithConnectIcon.map((item, i) =>
              <Tooltip key={`planet_${i}`} text={`<orange>${item.ru}</orange>`}>
                {item.icon}
              </Tooltip>)
            }
          </Orange>
          {natWithConnectControl.length > 0 && <>
            {`${natWithConnectIcon.length > 0 ? ', ' : ''}${t("chronos.app.instruments.widgets.ruleItem.planetsInConjuctionWithRules")} `}
            <Orange>{natWithConnectControl.join(', ')}</Orange>
          </>}
        </>}
      </Text>
    </Row>
  </Container >;
}

const Container = styled.div`
  font-size: 0.875rem;
  color: var(--text-secondary);
`;

const Aspects = styled.div`
  margin-bottom: 0.25rem;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1.2rem 1fr;
  grid-gap: 0.5rem;
  margin-bottom: 0.1rem;
`;

const ModeIcon = styled.div`
  display: grid;
  grid-template-rows: 1.2rem 1fr;
  grid-gap: 0.1rem;

  min-height: 1.65rem;

  & svg {
    width: 100%;
    fill: currentColor;
  }

  & > i {
    &::before{
      content: '';
      display: block;
      height: 100%;
      width: 2px;
      margin: 0 auto;
      background-color: var(--text-secondary);
    }
  }
`;

const Purple = styled.span`
  color: var(--accent-usual);
  fill: var(--accent-usual);
`;

const Orange = styled.span`
  color: var(--colors-orange);
  fill: var(--colors-orange);
`;

const Text = styled.div`
  & svg {
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 0.2rem;
  }
`;
