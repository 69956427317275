import React from 'react';
import styled, { css } from 'styled-components';

export default function SvgButton(props: {
  icon: any;
  onClick(): void;
  disabled?: boolean;
  title?: string;
}) {
  const disabled = props.disabled || false;

  const onClick = (ev: any) => {
    ev.stopPropagation();
    ev.preventDefault();

    if (disabled) {
      return;
    }

    props.onClick();
  };

  return (
    <Wrap
      title={props.title}
      onClick={onClick}
      disabled={disabled}
    >
      {props.icon}
    </Wrap>
  );
}

const Wrap = styled.div<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  user-select: none;

  opacity: 0.6;

  :hover {
    opacity: 1;
  }
  
  ${props => props.disabled && css`
    opacity: 0.25;
    cursor: default;

    :hover {
      opacity: 0.25;
    }
  `}

  transition: opacity 0.2s ease-in-out;
`;
