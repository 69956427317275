import React from 'react';

export const ArrowLeft = () => <path d="M 5 13 L 2 10 L 5 7" stroke="inherit" strokeWidth="1.25"/>;
export const ArrowTop = () => <path d="M 13 5 L 10 2 L 7 5" stroke="inherit" strokeWidth="1.25"/>;
export const ArrowBottom = () => <path d="M 13 15 L 10 18 L 7 15" stroke="inherit" strokeWidth="1.25"/>;
export const ArrowRight = () => <path d="M 15 13 L 18 10 L 15 7" stroke="inherit" strokeWidth="1.25"/>;
export const PathVertical = ({hasTopIndent = false, hasBottomIndent = false}) =>
    <path d={`M 10 ${hasTopIndent ? 2 : -2} L 10 ${hasBottomIndent ? 18 : 22}`} stroke="inherit" strokeWidth="1.25"/>;
 export const PathHorizontal = ({hasLeftIndent = false, hasRightIndent = false}) =>
    <path d={`M ${hasRightIndent ? 18 : 22} 10 H ${hasLeftIndent ? 2 : -2}`} stroke="inherit" strokeWidth="1.25"/>;
export const PathCurve = ({ direction = 'bottom', hasArrowRight = false, hasRightIndent = false }: any) =>
    direction === 'bottom'
        ? <path d={`M 10 -2 L 10 5 Q 10 10 15 10 L ${hasArrowRight || hasRightIndent ? 18 : 22} 10`} stroke="inherit" strokeWidth="1.25"/>
        : <path d={`M 10 22 L 10 15 Q 10 10 15 10 L ${hasArrowRight || hasRightIndent ? 18 : 22} 10`} stroke="inherit" strokeWidth="1.25"/>