import dayjs from 'dayjs';
import { makeAutoObservable } from 'mobx';
import api from "../api";


export default class NPSStore {
  constructor() {
    makeAutoObservable(this);
    this.getData();
  }

  private getData() {
    const nps = localStorage.getItem('nps');
    let parsed = null;
  
    if(nps) {
      try {
        parsed = JSON.parse(nps);
        
      } catch {}
    }
  
    const data = parsed || {
      pages: {
        calendar: false,
        dashboard: false,
        instruments: false,
      },
      lastQuestion: null,
    };
  
    return data;
  }
  
  private setData(data: any) {
    localStorage.setItem('nps', JSON.stringify(data));
  }
  
  visitPage(page: string) {
    let { pages, lastQuestion } = this.getData();

    const pagesId = `nps_2_visit_${page}`
    if (!this.pages?.includes(pagesId)) {
      this.setPages([pagesId])
      api.closeNotifications([{
        id: pagesId,
        value: true
      }])
    }
    
    const questionTime = !lastQuestion
    || (lastQuestion && dayjs(lastQuestion).add(1, 'month').isBefore(dayjs()));
    
    if((pages && !pages[page]) || questionTime) {
      pages[page] = true;
      this.showNPS = !Object.keys(pages).some(key => !pages?.[key]) && questionTime;
      lastQuestion = this.showNPS ? new Date().toISOString() : lastQuestion;

      this.setData({
        pages,
        lastQuestion,
      });
    }
  }

  setPages(data: string[]) {
    this.pages = [...this.pages, ...data]
  }

  // visitPage(page: string) {
  //   const pagesId = `nps_2_visit_${page}`
  //   if (!this.pages?.includes(pagesId)) {
  //     this.setPages([pagesId])
  //     api.closeNotifications([{
  //       id: pagesId,
  //       value: true
  //     }])
  //   }
  // }

  private lastQuestion: string | null = null;
  // pages: {[key: string]: boolean} | null = null;
  pages: string[] = []
  showNPS: boolean = false;
}